// Zircon - Home component

import React from "react"
import { Card, CardBody, CardTitle } from 'reactstrap';

function Home({validatePayment}) {

  return (
    <div>
      <div className="container">
        <div className="row align-items-start">
          <div className="col-12 col-md-8 mx-auto">
            <Card as="a" onClick={validatePayment} style={{ cursor: "pointer" }}>
              <CardBody>
                <CardTitle>Welcome home</CardTitle>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home