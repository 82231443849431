// Zircon - Terms component

import React from "react"
import { Card, CardBody, CardTitle } from 'reactstrap';

function Terms(props) {

  return (
    <div>
      <div className="container">
        <div className="row align-items-start">
          <div className="col-12 col-md-8 mx-auto">
            <Card as="a" onClick={props.validatePayment} style={{ cursor: "pointer" }}>
              <CardBody>
                <CardTitle>Käyttöehdot</CardTitle>
                {/* <CardTitle><h4>Klikkaa tästä:&#8194;
                  <a href="https://zydeemi.fi/autokanta-sovelluksen-kayttoehdot/" target="_blank" rel="noopener">
                    Käyttöehdot
                  </a></h4>
                </CardTitle> */}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms