// Zircon - VerifyPromoToken component


import { useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';
import hookup from "./hookup";
import { msg } from './message';
import { getTimeStamp } from './util';


// VerifyPromoToken is called when user has typed in promo token
//
function VerifyPromoToken({ promoToken, regNumber, vin, regType, setAuto, lng }) {

  const navigate = useNavigate();
  const isFetching = useRef(false); // Flag to track data fetching

  console.log('VerifyPromoToken, promoToken', promoToken, 'regNumber', regNumber);

  useEffect(() => {
    console.log(getTimeStamp(), 'VerifyPromoToken, first useEffect begins');

    if (promoToken === null || promoToken === undefined || promoToken.length === 0) {
      navigate('/regnumber');
      alert(msg('haku keskeytetty', lng));
      return;
    }

    if (!isFetching.current) {
      isFetching.current = true;

      hookup({promoToken: promoToken, regNumber: regNumber, vin: vin, regType: regType})
        .then((data) => { setAuto(data); navigate('/show'); })
        .catch((error) => { console.error('Error during API call:', error); navigate('/regnumber'); });
    }
    console.log(getTimeStamp(), 'VerifyPromoToken, first useEffect ends');
  }, [promoToken, regNumber, vin, regType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container">
      <br></br>
      <Card className="border-0 custom-card">
        <CardBody>
          <CardTitle className="display-4">*** Haetaan tietoja ***</CardTitle>
        </CardBody>
      </Card>
    </div>
  );
};


export default VerifyPromoToken;