// paymentProcess.js

import axios from 'axios';
import { getTimeStamp } from './util';


// Create payment through backend 
// Redirect the user to payment provider's website
//
export default async function initPaymentProcess(regNumber, vin, regType, timeout = 15000) {

  const tempRegNumber = regNumber.length > 0 ? regNumber : 'x';
  const tempRegType = regType.length > 0 ? regType : '';
  const tempVin = vin.length > 0 ? vin : '';
  const searchKeys = `${'?regNumber='}${tempRegNumber}${'&vin='}${tempVin}${'&regType='}${tempRegType}`;

  console.log('initPaymentProcess, url', `${process.env.REACT_APP_BACKEND_PAYMENT}${searchKeys}`);

  // alert('initPaymentProcess, url', `${process.env.REACT_APP_BACKEND_PAYMENT}${searchKeys}`);

  return new Promise(async (resolve, reject) => {

    // Call backend that will call the provider to create a new payment
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_PAYMENT}${searchKeys}`, { timeout });
      if (response.status === 200) {
        console.log(getTimeStamp(), 'paymentProcess returns true');
        const providerUrl = response.data.href;
        console.log(getTimeStamp, 'user will be redirected to', providerUrl);
        delay(5000);
        // control handed over to browser
        window.location.href = providerUrl;
        // No need for resolve here
        // resolve({
        //   success: true
        // });
      } else {
        console.error(getTimeStamp(), 'paymentProcess returns false');
        resolve({
          success: false,
          message: 'paymentProcess fails'
        });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.error(getTimeStamp(), 'Request canceled:', error.message);
      } else if (error.code === 'ECONNABORTED') {
        console.error(getTimeStamp(), 'Request timeout:', error.message);
      } else {
        console.error(getTimeStamp(), 'Error fetching data from API:', error.message);
      }
      resolve({
        success: false,
        message: 'Muu virhe kyselyssä',
        details: error.message
      });
    }
  });
}


function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}