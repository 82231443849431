// hookup.js
//


import axios from 'axios';
import { hasProperty, getTimeStamp } from './util';


// Send a request to API
// 
export default async function hookup({ payToken = null, promoToken = null, regNumber = null, vin = null, regType = null, timeout = 15000 } = {}) {

  console.log(getTimeStamp(), 'hookup begins, payToken', payToken, 'promoToken', promoToken);

  let apiUrl = '';

  if (payToken) {
    apiUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}${'?payToken='}${payToken}`;
  } else if (promoToken && (regNumber || vin)) {
    // Convert the token to an ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(promoToken);
    let hashedPromoToken = '';
    try {
      const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      hashedPromoToken = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    } catch (error) {
      console.error('Error during hashing:', error);
    }

    apiUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}${'?promoToken='}${hashedPromoToken}${'&regNumber='}${regNumber}${'&vin='}${vin}${'&regType='}${regType}`;
  } else {
    console.log(getTimeStamp(), 'hookup, invalid argument');
    return;
  }
  console.log(getTimeStamp(), 'hookup, apiUrl', apiUrl);

  return axios.get(apiUrl, { timeout })
    .then(response => {
      console.log(getTimeStamp(), 'hookup, about to return response.data after axios.get');
      if (response.data === null || response.data === undefined) {
        console.log(getTimeStamp(), 'hookup, response.data null or undefined');
      }
      // check for content error in the response
      if (!hasProperty(response.data, "virhekoodi")) {
        // errors not found, go ahead and return response     
        console.log(getTimeStamp(), 'hookup, response.data no error');
        return response.data;
      } else {
        console.log(getTimeStamp(), 'hookup, response.data error');
        return response.data;
      }
    }) // Return response.data directly
    .catch(error => {
      if (error.response && error.response.status === 401) {
        console.error('Error fetching data:', error);
        throw new Error('Unauthorized access'); // Throw an error with a message
      } else {
        console.error('Error fetching data:', error);
        throw error; // Re-throw other errors
      }
    });
}

