// Zircon - Lookup component
//
// Search (run API call) and display response.

import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getTimeStamp } from './util';


async function hashToken(token) {

  console.log(getTimeStamp(), 'hashToken begins, token', token);

  if (!token) return null; // Handle missing token

  // Convert token string to ArrayBuffer
  const buffer = new TextEncoder().encode(token);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', buffer);

  // Convert hashBuffer to a string
  const hashedToken = Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  console.log(getTimeStamp(), 'hashToken ends, token', token);
  return hashedToken;
}


function fetchData(regNumber, regType, vin, hashedToken, payToken) {

  console.log(getTimeStamp(), 'fetchData begins, hashedToken', hashedToken, 'payToken', payToken);

  //if (!hashedToken) return null; // Handle missing hashedToken

  const tempRegNumber = regNumber.length > 0 ? regNumber : 'x'; // if regNumber blank, add dummy value
  const tempRegType = regType.length > 0 ? '?regType=' + regType : ''; // if regType not blank, add argument name for url
  const tempVin = vin.length > 0 ? '&vin=' + vin : ''; // if vin not blank, add argument name for url

  let temp = '';
  if (hashedToken) {
    temp = '&token=' + hashedToken;
  } else {
    temp = '&payToken=' + payToken;
  }

  console.log(getTimeStamp(), 'fetchData, url', `${process.env.REACT_APP_BACKEND_ENDPOINT}${tempRegNumber}${tempRegType}${tempVin}${temp}`);

  return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}${tempRegNumber}${tempRegType}${tempVin}${temp}`)
    .then(response => response.data) // Return response.data directly
    .catch(error => {
      // Handle errors
      console.error('Error fetching data:', error);
      return null; // Or return a default value for error handling
    });
}


// L o o k u p
//
const Lookup = ({ regNumber, vin, regType, token, payToken, auto, setAuto, appStatus, setAppStatus }) => {
// async function Lookup({ regNumber, vin, regType, token, payToken, auto, setAuto, appStatus, setAppStatus }) {
  console.log(getTimeStamp(), 'FU begins');

  const [hashedToken, setHashedToken] = useState(null); // Store the hashed token
  const [searchStatus, setSearchStatus] = useState('not started');
  const isFetching = useRef(false); // Flag to track data fetching
  const navigate = useNavigate();

  useEffect(() => {
    console.log(getTimeStamp(), 'FU, first useEffect begins, token', token, 'hashedToken', hashedToken, 'payToken', payToken);
    if (payToken) {
      return;
    }
    if (token) {
      hashToken(token)
        .then((hashedToken) => setHashedToken(hashedToken))
        .catch((error) => console.error('Error during hashing:', error));
    }
    console.log(getTimeStamp(), 'FU, first useEffect ends, token', token, 'hashedToken', hashedToken);
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps
  // Why hashedToken and payToken are not dependencies?
  // hashedToken is a result, payToken short-circuits the effect.
  // useEffect should run only when token changes

  useEffect(() => {
    console.log(getTimeStamp(), 'FU, second useEffect begins, hashedToken', hashedToken, 'isFetching', isFetching);
    if ((hashedToken || payToken) && searchStatus === 'not started' && !isFetching.current) {
      console.log(getTimeStamp(), 'FU, second useEffect about to call setSearchStatus', searchStatus, 'isFetching', isFetching);
      isFetching.current = true;
      setSearchStatus('calling API');
      console.log(getTimeStamp(), 'FU, second useEffect called setSearchStatus', searchStatus, 'isFetching', isFetching);
      fetchData(regNumber, '1', '', hashedToken, payToken)
        .then((data) => { setAuto(data); setSearchStatus('done'); setAppStatus('search ok'); })
        .catch((error) => { console.error('Error during API call:', error); setAppStatus('search failed'); });
    }
    console.log(getTimeStamp(), 'FU, second useEffect ends');
  }, [hashedToken]); // eslint-disable-line react-hooks/exhaustive-deps
  // useEffect should run only when hashedToken changes

  // useEffect(() => {
  //   console.log(getTimeStamp(), 'FU, second useEffect begins, hashedToken', hashedToken, 'isFetching', isFetching.current);
  //   if ((hashedToken || payToken) && searchStatus === 'not started' && !isFetching.current) {
  //     console.log(getTimeStamp(), 'FU, second useEffect about to call setSearchStatus', searchStatus, 'isFetching', isFetching.current);
  //     isFetching.current = true;
  //     setSearchStatus('calling API');
  //     console.log(getTimeStamp(), 'FU, second useEffect called setSearchStatus', searchStatus, 'isFetching', isFetching.current);

  //     try {
  //       const data = fetchData(regNumber, '1', '', hashedToken, payToken);
  //       setAuto(data);
  //       setSearchStatus('done');
  //       setAppStatus('search ok');
  //     } catch (error) {
  //       console.error('Error during API call:', error);
  //       setSearchStatus('failed');  // Update searchStatus on error
  //       setAppStatus('search failed');
  //     } finally {
  //       isFetching.current = false;  // Always reset isFetching
  //     }
  //   }
  //   console.log(getTimeStamp(), 'FU, second useEffect ends');
  // }, [hashedToken, payToken]); // Include payToken in the dependency array


  useEffect(() => {
    console.log(getTimeStamp(), 'FU, third useEffect begins, appStatus', appStatus);
    if (appStatus === 'initial' || appStatus === 'input regNumber' || appStatus === 'paymentlooksok' || appStatus === 'callingAPI') {
      return;
    } else if (appStatus === 'search ok') {
      navigate('/show');
    } else {
      navigate('/regnumber');
    }
  }, [appStatus]);

  return null;

  // return (
  //   <div>
  //     {token ? (
  //       <p>hashedToken: {hashedToken}, payToken {payToken}, searchStatus {searchStatus}</p>
  //     ) : (
  //       <p>Checking token, searching..., payToken {payToken}, searchStatus {searchStatus}</p>
  //     )}
  //       <p>auto {JSON.stringify(auto.merkkiSelvakielinen)}</p>
  //       {/* <p>auto {JSON.stringify(localAuto.current.merkkiSelvakielinen)}</p> */}
  //   </div>
  // );
}


// async function Lookup({ regNumber, vin, regType, token, payToken, auto, setAuto, appStatus, setAppStatus }) {
//   console.log(getTimeStamp(), 'FU begins');

//   const [hashedToken, setHashedToken] = useState(null); // Store the hashed token
//   const [searchStatus, setSearchStatus] = useState('not started');
//   const isFetching = useRef(false); // Flag to track data fetching
//   const navigate = useNavigate();

//   useEffect(() => {
//     console.log(getTimeStamp(), 'FU, first useEffect begins, token', token, 'hashedToken', hashedToken, 'payToken', payToken);
//     if (payToken) {
//       return;
//     }
//     if (token) {
//       hashToken(token)
//         .then((hashedToken) => setHashedToken(hashedToken))
//         .catch((error) => console.error('Error during hashing:', error));
//     }
//     console.log(getTimeStamp(), 'FU, first useEffect ends, token', token, 'hashedToken', hashedToken);
//   }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

//     // the second useEffect is triggered when hashedToken changes. 
//   // it performs the Axios request using regNumber and the hashed token
//   useEffect(() => {

//     console.log(getTimeStamp(), 'Lookup, second useEffect begins');
//     console.log(getTimeStamp(), 'Lookup, second useEffect, token', token, 'hashedToken', hashedToken);

//     let temp = '';
//     if (hashedToken === 'inprocess' || hashedToken === '') {
//       return;
//     } else if (hashedToken === 'nohashedtoken') {
//       temp = '&payToken=' + payToken;
//     } else {
//       temp = '&token=' + hashedToken;
//     }

//     // console.log('Lookup, second useEffect starts');
//     setSearchStatus('searching')
//     const tempRegNumber = regNumber.length > 0 ? regNumber : 'x'; // if regNumber blank, add dummy value
//     const tempRegType = regType.length > 0 ? '?regType=' + regType : ''; // if regType not blank, add argument name for url
//     const tempVin = vin.length > 0 ? '&vin=' + vin : ''; // if vin not blank, add argument name for url

//     console.log(getTimeStamp(), 'Lookup, second useEffect', `${process.env.REACT_APP_BACKEND_ENDPOINT}${tempRegNumber}${tempRegType}${tempVin}${temp}`);

//     axios
//       //.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}${tempRegNumber}${tempRegType}${tempVin}&token=${hashedToken}&payToken=${payToken}`)
//       .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}${tempRegNumber}${tempRegType}${tempVin}${temp}`)
//       .then(response => {
//         setSearchStatus('search done');
//         setAppStatus('search done');
//         // setLookupStatus('done');
//         setAuto(response.data);
//         // checkForAlerts(setAlerts, response.data, lng);
//       })
//       .catch(function (error) {
//         setSearchStatus('search failed');
//         setAppStatus('search failed');
//         // setLookupStatus('done');
//       })

//     console.log(getTimeStamp(), 'Lookup, second useEffect ends');

//   }, [hashedToken]); // eslint-disable-line react-hooks/exhaustive-deps



  // useEffect(() => {
  //   console.log(getTimeStamp(), 'FU, second useEffect begins, hashedToken', hashedToken, 'isFetching', isFetching.current);
  //   if ((hashedToken || payToken) && searchStatus === 'not started' && !isFetching.current) {
  //     console.log(getTimeStamp(), 'FU, second useEffect about to call setSearchStatus', searchStatus, 'isFetching', isFetching.current);
  //     isFetching.current = true;
  //     setSearchStatus('calling API');
  //     console.log(getTimeStamp(), 'FU, second useEffect called setSearchStatus', searchStatus, 'isFetching', isFetching.current);

  //     try {
  //       const data = await fetchData(regNumber, '1', '', hashedToken, payToken);
  //       setAuto(data);
  //       setSearchStatus('done');
  //       setAppStatus('search ok');
  //     } catch (error) {
  //       console.error('Error during API call:', error);
  //       setSearchStatus('failed');  // Update searchStatus on error
  //       setAppStatus('search failed');
  //     } finally {
  //       isFetching.current = false;  // Always reset isFetching
  //     }
  //   }
  //   console.log(getTimeStamp(), 'FU, second useEffect ends');
  // }, [hashedToken, payToken]); // Include payToken in the dependency array

//   useEffect(() => {
//     console.log(getTimeStamp(), 'FU, third useEffect begins, appStatus', appStatus);
//     if (appStatus === 'initial' || appStatus === 'input regNumber' || appStatus === 'paymentlooksok' || appStatus === 'callingAPI') {
//       return;
//     } else if (appStatus === 'search ok') {
//       navigate('/show');
//     } else {
//       navigate('/regnumber');
//     }
//   }, [appStatus]);

//   return null;
// }



export default Lookup;


// const SomeComponent = () => {

//   const [hashedToken, setHashedToken] = useState(null); // Store the hashed token
//   const [fetchedData, setFetchedData] = useState(null); // Store fetched data


//   try {
//     const x = await hashToken(token);
//     setHashedToken(hashedToken);
//   } catch {
//     // handle error
//   }

//   if (hashedToken) {
//     try {
//       const response = await fetchData(hashedToken);
//       setFetchedData(response);
//     } catch {
//       // handle error
//     }

//   }

//   // navigate somewhere
// }


