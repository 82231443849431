// joiner 2023-10-24T09:31:08.415Z 

const TCODES = [
  {"code": "Asiakaslajit", "codeValue": "0", "text": "Yksityinen", "lng": "fi"},
  {"code": "Asiakaslajit", "codeValue": "1", "text": "Yritys", "lng": "fi"},
  {"code": "Asiakaslajit", "codeValue": "1", "text": "Företagskund", "lng": "sv"},
  {"code": "Asiakaslajit", "codeValue": "0", "text": "Privatkund", "lng": "sv"},
  {"code": "Asiointikielet", "codeValue": "fi", "text": "Finska", "lng": "sv"},
  {"code": "Asiointikielet", "codeValue": "sv", "text": "Svenska", "lng": "sv"},
  {"code": "Asiointikielet", "codeValue": "fi", "text": "Suomi", "lng": "fi"},
  {"code": "Asiointikielet", "codeValue": "sv", "text": "Ruotsi", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "0", "text": "Ei rekisterissä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "1", "text": "Ennakkoilmoitettu, ei rekisteröity", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "2", "text": "Rekisteröintikatsastettu, ei rekisteröity", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "3", "text": "Rekisterissä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "4", "text": "Poistettu liikenteestä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "5", "text": "Poistettu rekisteristä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "6", "text": "Poistettu lopullisesti rekisteristä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "7", "text": "Siirtolupa", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "8", "text": "Vientirekisteröity", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "9", "text": "Kiinnitystarkastettu", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ATO08004", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "SOLSUOHUOLSEL", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ATO00832", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168815", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168817", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ATO01308", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "KAYMENOP", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168609", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ATO05824", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168610", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168826", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "KAYAUMSI", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168856", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168894", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "0", "text": "icke registerförd/registerfört", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "1", "text": "Förhandsanmäld/inte registrerad", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "2", "text": "registreringsbesiktad/registreringsbesiktat", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "3", "text": "registrerad/registrerat", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "4", "text": "avställd/avställt", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "5", "text": "avregistrerad/avregistrerat (VDS)", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "6", "text": "slutligt avregistrerad/avregistrerat (skrotad/skrotat)", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "7", "text": "förflyttningstillstånd", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "8", "text": "exportregistrerad/ exportregistrerat", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "9", "text": "granskad för inteckning", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC020000869", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ASLJUKCAROY", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "BIG11282", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC010003192B", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC010004629B", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC020000869", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT09748", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT15907", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16116", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16124", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16125", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "GRE_ATONOS-TRE", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "inn01120", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NETAMICOMELIUS", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH00164", "text": "ei käytössä", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH00509", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH02138", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH00164", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH02138", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH00509", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16124", "text": "ei käytössä  Lehtiset Oy", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16116", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16125", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT15907", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT09748", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "inn01120", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NETAMICOMELIUS", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC010004629B", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC010003192B", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "GRE_ATONOS-TRE", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ASLJUKCAROY", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "BIG11282", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "0", "text": "Mopo", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "1", "text": "Auto", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "2", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "3", "text": "Traktori tai moottorityökone", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "4", "text": "Maastoajoneuvo", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "5", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "7", "text": "Siirtokilpi", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "8", "text": "Vientirekisteröinti", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "0", "text": "Moped", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "1", "text": "Bil", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "2", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "3", "text": "Traktor eller motorredskap", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "4", "text": "Terrängfordon", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "5", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "7", "text": "Förflyttningsskylt", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "8", "text": "Exportregistrering", "lng": "sv"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "0", "text": "Ei rajoitusta", "lng": "fi"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "1", "text": "Henkilötietoja ei luovuteta", "lng": "fi"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "2", "text": "HETUa ei luovuteta", "lng": "fi"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "3", "text": "Osoitetietoja ei luovuteta", "lng": "fi"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "8", "text": "Osoitteenluovutuskielto", "lng": "fi"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "9", "text": "Turvakielto", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "1", "text": "Erittäin vakava rajoitus (ajo- tai käyttökielto)", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "2", "text": "Vakava rajoitus", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "3", "text": "Muu rajoitus", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "4", "text": "Vakava huomautus", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "5", "text": "Muu huomautus", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "1", "text": "Mycket allvarlig begränsning (kör- eller användningsförbud)", "lng": "sv"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "2", "text": "Allvarlig begränsning", "lng": "sv"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "3", "text": "Annan begränsning", "lng": "sv"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "4", "text": "Allvarlig anmärkning", "lng": "sv"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "5", "text": "Annan anmärkning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "36", "text": "Lisävero vireillä", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "36", "text": "Anhängig tilläggsskatt", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "37", "text": "Fordonsskatt har förfallit till betalning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "37", "text": "Ajoneuvovero erääntynyt", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "38", "text": "Siirtohuomautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "38", "text": "Förflyttningsanmärkning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "39", "text": "Ulkomaisten kilpien palautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "39", "text": "Återlämning av utländska skyltar", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "35", "text": "Katsastuksen ajoaika <pvm> loppuun", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "35", "text": "Besiktningen gäller t.o.m. utgången av <datum>", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "101", "text": "Haltijan hallintaluovutusilmoitus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "102", "text": "Luovutusilmoitus tuntemattomalle", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "103", "text": "Kiinnityshakemus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "104", "text": "Diplomaattirekisteröinti", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "01", "text": "Muutoskielto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "02", "text": "Vireilläolo", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "03", "text": "Ajokielto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "04", "text": "Autoverovapaus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "05", "text": "Myyntirajoitus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "06", "text": "Valvontakatsastusvelvollisuus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "07", "text": "Valvontakatsastusvelvollisuus laiminlyöty", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "08", "text": "Muutoskatsastusvelvollisuus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "09", "text": "Takaisinkutsu", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "10", "text": "Määräaikaiskatsastus suorittamatta/hyväksymättä", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "11", "text": "Ajoneuvo anastettu", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "12", "text": "Kilvet anastettu", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "13", "text": "Trafin muutoskielto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "14", "text": "Muutoskielto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "15", "text": "Viretieto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "16", "text": "Kiinnitystieto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "17", "text": "Vakuutuksen maksamattomuustieto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "18", "text": "Liikenteestä poisto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "19", "text": "Lopullinen poisto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "20", "text": "Rekisteristä poisto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "21", "text": "Voimassaoleva vientirekisteröinti", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "22", "text": "Ajoneuvovero erääntynyt", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "23", "text": "Ajoneuvo käyttökiellossa/Lisävero", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "24", "text": "Vanha ajoneuvovero/dieselvero erääntynyt", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "25", "text": "Kilpien haltuunotto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "26", "text": "Autoveron palautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "27", "text": "Muu huomautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "28", "text": "Tavallinen huomautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "29", "text": "Seisonta-aika", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "30", "text": "Kilpien palautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "31", "text": "Kilpien säilytys", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "32", "text": "Lisäkilpi", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "33", "text": "Ajoneuvolla luovutusilmoitus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "34", "text": "Ajokielto kilpien haltuunotosta", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "01", "text": "Ändringsförbud", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "02", "text": "Anhängig", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "03", "text": "Körförbud", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "04", "text": "Befrielse från bilskatt", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "05", "text": "Försäljningsbegränsning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "06", "text": "Övervakningsbesiktningsskyldighet", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "07", "text": "Övervakningsbesiktningsskyldighet försummad", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "08", "text": "Ändringsbesiktningsskyldighet", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "09", "text": "Återkallelse", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "10", "text": "Periodisk besiktning har inte utförts/godkänts", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "11", "text": "Fordonet stulet", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "12", "text": "Skyltarna stulna", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "13", "text": "Ändringsförbud av Trafi", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "14", "text": "Ändringsförbud", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "15", "text": "Statusuppgift", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "16", "text": "Inteckningsuppgift", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "17", "text": "Uppgift om obetald försäkring", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "18", "text": "Avställning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "19", "text": "Slutlig avregistrering", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "20", "text": "Avregistrering", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "21", "text": "Gällande exportregistrering", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "22", "text": "Fordonsskatten har förfallit till betalning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "23", "text": "Tilläggsskatt för fordonsskatt har förfallit till betalning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "24", "text": "Gammal fordonsskatt/dieselskatt har förfallit till betalning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "25", "text": "Besittningstagande av skyltar", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "26", "text": "Returnering av bilskatt", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "27", "text": "Annan anmärkning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "28", "text": "Vanlig anmärkning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "29", "text": "Avställningstid", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "30", "text": "Returnering av skyltar", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "31", "text": "Förvaring av skyltar", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "32", "text": "Tilläggsskylt", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "33", "text": "Fordonet har överlåtelseanmälan", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "34", "text": "Körförbud från besittningstagande av skyltar", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "40", "text": "Vakuutus/rekisteröinti laiminlyöty", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "40", "text": "Försäkring/registrering försummad", "lng": "sv"},
  {"code": "KdKatsaAlleajosuojanSijainti", "codeValue": "1", "text": "Fram", "lng": "sv"},
  {"code": "KdKatsaAlleajosuojanSijainti", "codeValue": "2", "text": "Bak", "lng": "sv"},
  {"code": "KdKatsaAlleajosuojanSijainti", "codeValue": "1", "text": "Edessä", "lng": "fi"},
  {"code": "KdKatsaAlleajosuojanSijainti", "codeValue": "2", "text": "Takana", "lng": "fi"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "1", "text": "ABS", "lng": "sv"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "2", "text": "ALB", "lng": "sv"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "3", "text": "ABS + ALB", "lng": "sv"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "4", "text": "EBS", "lng": "sv"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "5", "text": "EBS", "lng": "sv"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "1", "text": "ABS", "lng": "fi"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "2", "text": "ALB", "lng": "fi"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "3", "text": "ABS + ALB", "lng": "fi"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "4", "text": "EBS", "lng": "fi"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "5", "text": "EBS", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.13", "text": "Kontrollbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.1", "text": "Registreringsbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.10", "text": "Kopplingsbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.11", "text": "Rättelse av tekniska uppgifter", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.12", "text": "Förhandsanmälan", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.14", "text": "Vägkontroll, lätt", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.15", "text": "Annullering", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.18", "text": "Enskilt godkännande", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.19", "text": "Rättelse av enskilt godkännande", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.2", "text": "Ändringsbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.3", "text": "Periodisk besiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.4", "text": "Påställningsbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.5", "text": "TFÄ/ADR-besiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.6", "text": "Exportbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.7", "text": "Inteckningskontroll", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.8", "text": "Godkännande av enskilt fordon", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.9", "text": "Vägkontroll, tung", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "9.5.1", "text": "Uppdatering av begränsningsuppgifter", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.1", "text": "Rekisteröintikatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.10", "text": "Kytkentäkatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.11", "text": "Teknisten tietojen korjaus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.12", "text": "Ennakkoilmoitus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.13", "text": "Valvontakatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.14", "text": "Tienvarsitarkastus, kevyt", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.15", "text": "Mitätöinti", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.18", "text": "Yksittäishyväksyntä", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.19", "text": "Yksittäishyväksynnän korjaus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.2", "text": "Muutoskatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.3", "text": "Määräaikaiskatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.4", "text": "Liikenteeseenottokatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.5", "text": "VAK/ADR -katsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.6", "text": "Vientikatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.7", "text": "Kiinnitystarkastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.8", "text": "Yksittäisen ajoneuvon hyväksyntä", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.9", "text": "Tienvarsitarkastus, raskas", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "9.5.1", "text": "Rajoitustietojen ylläpito", "lng": "fi"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "1", "text": "avbruten", "lng": "sv"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "2", "text": "godkänd", "lng": "sv"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "3", "text": "avvisad", "lng": "sv"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "4", "text": "annullerad", "lng": "sv"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "1", "text": "keskeytetty", "lng": "fi"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "2", "text": "hyväksytty", "lng": "fi"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "3", "text": "hylätty", "lng": "fi"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "4", "text": "mitätöity", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "0", "text": "Valmistajan kilpi", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "0", "text": "Tillverkarens skylt", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "1", "text": "Valmistenumero", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "1", "text": "Tillverkningsnummer", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "10", "text": "Muu jarru ja hidastin", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "10", "text": "Annan broms och retarder", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "11", "text": "Ajovakautusjärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "11", "text": "Kör stabiliserings system", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "12", "text": "Lähivalo", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "12", "text": "Närljus", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "13", "text": "Kaukovalo", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "13", "text": "Fjärrljus", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "14", "text": "Hätävilkut", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "14", "text": "Nödblinkers", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "15", "text": "Heijastin ja heijastavat pinnat", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "15", "text": "Reflektor", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "16", "text": "Äänimerkinantolaite", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "16", "text": "Signalanordning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "17", "text": "Ajovalo", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "17", "text": "Körljus", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "18", "text": "Etuvalo", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "18", "text": "Framljus", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "19", "text": "Lisävalot", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "19", "text": "Tilläggsljus", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "2", "text": "Rekisterimerkinnät", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "2", "text": "Registeranteckningar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "20", "text": "Takavalo", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "20", "text": "Bakljus", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "21", "text": "Jarruvalo", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "21", "text": "Bromsljus", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "22", "text": "Suuntavalo", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "22", "text": "Körriktningsljus", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "23", "text": "Rekisterikilven valo", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "23", "text": "Registerskyltensljus", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "24", "text": "Muut valot ja valaistut kilvet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "24", "text": "Övr. ljus och upplysta skyltar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "25", "text": "Akku ja johtimet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "25", "text": "Batteri och ledningar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "26", "text": "Bensiinimoottorin pakokaasumittaus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "26", "text": "Avgasutsläppning bensinmotor", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "27", "text": "Dieselmoottorin pakokaasumittaus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "27", "text": "Avgasutsläppning dieselmotor", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "28", "text": "Melu", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "28", "text": "Buller", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "29", "text": "Öljyvuodot", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "29", "text": "Oljeläckor", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "3", "text": "Asiapaperit", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "3", "text": "Fordonshandlingar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "30", "text": "Radiohäiriöt", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "30", "text": "Radiostörningar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "31", "text": "Pakokaasupäästöt", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "31", "text": "Avgasutsläpp", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "32", "text": "Polttoainevuodot", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "32", "text": "Bränsleläckor", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "33", "text": "Nestevuodot", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "33", "text": "Vätskeläckor", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "34", "text": "Sisäinen valvontajärjestelmä (OBD)", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "34", "text": "Fordonsburna övervakningssystemet (OBD)", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "35", "text": "Alustan kotelot ja pohjalevy", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "35", "text": "Chassiets balkar och bottenplatta", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "36", "text": "Runko", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "36", "text": "Chassi", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "37", "text": "Akseliston kiinnityspalkit", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "37", "text": "Axlarnas infästningsbalkar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "38", "text": "Pakoputkisto", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "38", "text": "Avgassystem", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "39", "text": "Kori", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "39", "text": "Karosseri", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "4", "text": "Rekisterikilpi", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "4", "text": "Registerskylt", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "40", "text": "Polttoainesäiliö ja -putket", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "40", "text": "Bränsletank och -ledningar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "41", "text": "Suojarakenteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "41", "text": "Skyddskonstruktioner", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "42", "text": "Kuormakori", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "42", "text": "Lastkorg", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "43", "text": "Muut alustan ja korin rakenteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "43", "text": "Chassiets och karosseriets övriga konstruktioner", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "44", "text": "Alustan suojaus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "44", "text": "Chassiets rostskydd", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "45", "text": "Etuakselisto", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "45", "text": "Framvagn", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "46", "text": "Taka-akselisto", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "46", "text": "Bakvagn", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "47", "text": "Jousitus ja iskunvaimennus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "47", "text": "Fjädring och stötdämpning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "48", "text": "Renkaat ja vanteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "48", "text": "Däck och fälgar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "49", "text": "Akselisto", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "49", "text": "Axel", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "5", "text": "Luvanvaraisen liikenteen tunnisteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "5", "text": "Igenkänningstecken för bil med trafiktillstånd", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "50", "text": "Teli", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "50", "text": "Boggi", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "51", "text": "Ohjausnivelet ja -tangot", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "51", "text": "Styrleder och -stag", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "52", "text": "Ohjausvaihde", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "52", "text": "Styrväxel", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "53", "text": "Ohjauslaitteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "53", "text": "Styrinrättning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "54", "text": "Ohjautuva akselisto", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "54", "text": "Styrande axel", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "55", "text": "Muut laitteet ja varusteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "55", "text": "Övriga anordningar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "56", "text": "Voimansiirto", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "56", "text": "Kraftöverföring", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "57", "text": "Turvavyöt ja -varusteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "57", "text": "Säkerhetsbälten och -utrustning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "58", "text": "Kytkentälaitteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "58", "text": "Kopplingsanordningar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "59", "text": "Varoituskolmio", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "59", "text": "Varningstriangel", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "6", "text": "Käyttöjarru", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "6", "text": "Färdbroms", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "60", "text": "Kilvet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "60", "text": "Skyltar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "61", "text": "Moottori", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "61", "text": "Motor", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "62", "text": "Lukkolaite, luvattoman käytön esto", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "62", "text": "Låsanordning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "63", "text": "Energiantuottolaitteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "63", "text": "Energiproduktionsapparat", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "64", "text": "Tuulilasi ja puhdistuslaitteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "64", "text": "Vindruta och vindruteanordningar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "65", "text": "Muut ikkunat", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "65", "text": "Övriga rutor", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "66", "text": "Peilit", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "66", "text": "Speglar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "67", "text": "Näkökenttä", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "67", "text": "Synfält", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "68", "text": "Koeajo", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "68", "text": "Provkörning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "69", "text": "Korin sisustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "69", "text": "Karosseri inredning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "7", "text": "Käyttöjarrun dynamometritesti", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "7", "text": "Färdbromsens dynamometertest", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "70", "text": "Hallintalaitteet ja ilmaisimet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "70", "text": "Manöverorgan och indikatorer", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "71", "text": "Nopeusmittari ja ajopiirturi", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "71", "text": "Hastighetsmätare och färdskrivare", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "72", "text": "Nopeudenrajoitin", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "72", "text": "Hastighetsbegränsare", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "73", "text": "Muut mittarit", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "73", "text": "Övriga mätaren", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "74", "text": "Taksivarustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "74", "text": "Taxiutrustning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "75", "text": "Koulu- ja opetusajoneuvovarustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "75", "text": "Skolfordonutrustning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "9", "text": "Parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "76", "text": "Matkailuajoneuvovarustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "76", "text": "Husvagnutrustning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "77", "text": "Ruumisautovarustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "77", "text": "Likbilutrustning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "78", "text": "Linja-autovarustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "78", "text": "Bussutrustning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "79", "text": "Paloautovarustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "79", "text": "Brandbilutrustning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "8", "text": "Seisontajarrun dynamometritesti", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "8", "text": "Parkeringsbromsens dynamometertest", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "80", "text": "Sairasautovarustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "80", "text": "Ambulansutrustning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "81", "text": "Vaarallisten aineiden kuljetus varustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "81", "text": "VAK/ADR-utrustning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "82", "text": "Erityskäyttöön varustetun ajoneuvon varustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "82", "text": "Försett för specialbruk", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "83", "text": "TIR-varustus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "83", "text": "TIR-utrustning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "9", "text": "Seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "85", "text": "Gasinstallation", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "85", "text": "Kaasuasennus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "86", "text": "Käytettävän polttoaineen vaihtojärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "86", "text": "System för byte av bränslet som används", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "87", "text": "Ilmanvaihtokotelo", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "87", "text": "Ventilationskapsel", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "88", "text": "Mätare för nivån i behållaren", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "88", "text": "Säiliön tason mittari", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "89", "text": "Komponenter i systemet för gaspåfyllning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "89", "text": "Kaasun täyttöjärjestelmän osat", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "90", "text": "Elektronisk styrenhet", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "90", "text": "Elektroninen ohjausyksikkö", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "91", "text": "Kaasuvuoto", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "91", "text": "Gasläckage", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "92", "text": "Märkning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "92", "text": "Merkintä", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "93", "text": "Korkejännitekomponenttien merkintä", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "93", "text": "Märkning på högspänningskomponenter", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "94", "text": "Regeneratiivinen jarrujärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "94", "text": "Regenerativa bromssystem", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "95", "text": "Dragning av lågspänningsledningar", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "95", "text": "Matalajännitejohdotus", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "96", "text": "Sähköinen voimalaite", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "96", "text": "Elektriskt drivsystem", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "97", "text": "Laddningsbart elenergilagringssystem", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "97", "text": "Ladattava energiavarastojärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "98", "text": "REESS-manöveranordning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "98", "text": "REESS Hallintajärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "99", "text": "Elektroniset muuttajat", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "99", "text": "Elektroniska omvandlare", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "100", "text": "Ajomoottori", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "100", "text": "Dragmotor", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "101", "text": "Reservkraftkälla", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "101", "text": "Apuvoimalaitteet", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "102", "text": "Indikator för aktivt körläge", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "102", "text": "Aktiivisen ajotilan ilmaisin", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "103", "text": "Indikator för körriktning", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "103", "text": "Ajosuunnan tila -ilmaisin", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "104", "text": "Externt laddsystem för REESS", "lng": "sv"},
  {"code": "KdKatsaKohde", "codeValue": "104", "text": "REESS Ulkoinen latausjärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "84", "text": "Turvajärjestelmät", "lng": "fi"},
  {"code": "KdKatsaKohde", "codeValue": "84", "text": "Säkerhetssystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/15", "text": "(1.1.10) Jarrutehostin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/9/2", "text": "(7.13) eCall-system", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/4/0", "text": "(FIN.G5) Kaasun täyttöjärjestelmän osat", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/4/0", "text": "(FIN.G5) Komponenter i systemet för gaspåfyllning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/5/0", "text": "(FIN.G6) Elektroninen ohjausyksikkö", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/5/0", "text": "(FIN.G6) Elektronisk styrenhet", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/26", "text": "(6.2) Glastak", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/26", "text": "(6.2) Lasikatto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/7/4", "text": "(6.2) Skylt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/7/4", "text": "(6.2) Kilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/0/4", "text": "(FIN.C14.6 ) Rullstolslyft och ramper", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/0/4", "text": "(FIN.C14.6) Pyörätuolinostin ja kulkuluiska", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/14", "text": "(6.1.6) Kilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/14", "text": "(6.1.6) Skylt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/9/2", "text": "(7.13) eCall- järjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/6/0", "text": "(FIN.G7) Gasläckage", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/7/0", "text": "(FIN.G8) Merkintä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/7/0", "text": "(FIN.G8) Märkning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/0/0", "text": "(FIN.F1) Korkejännitekomponenttien merkintä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/0/0", "text": "(FIN.F1) Märkning på högspänningskomponenter", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/1/0", "text": "(FIN.F2) Regeneratiivinen jarrujärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/1/0", "text": "(FIN.F2) Regenerativa bromssystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/2/0", "text": "(FIN.F3) Dragning av lågspänningsledningar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/2/0", "text": "(FIN.F3) Matalajännitejohdotus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/3/0", "text": "(FIN.F4) Sähköinen voimalaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/3/0", "text": "(FIN.F4) Elektriskt drivsystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/4/0", "text": "(FIN.F5) Ladattava energiavarastojärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/4/0", "text": "(FIN.F5) Laddningsbart elenergilagringssystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/5/0", "text": "(FIN.F6) REESS Hallintajärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/5/0", "text": "(FIN.F6) REESS-manöveranordning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/6/0", "text": "(FIN.F7) Elektroniska omvandlare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/6/0", "text": "(FIN.F7) Elektroniset muuttajat", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/7/0", "text": "(FIN.F8) Dragmotor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/7/0", "text": "(FIN.F8) Ajomoottori", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/8/0", "text": "(FIN.F9) Apuvoimalaitteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/8/0", "text": "(FIN.F9) Reservkraftkälla", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/9/0", "text": "(FIN.F10) Aktiivisen ajotilan ilmaisin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/9/0", "text": "(FIN.F10) Indikator för aktivt körläge", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/10/0", "text": "(FIN.F11) Indikator för körriktning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/10/0", "text": "(FIN.F11) Ajosuunnan tila -ilmaisin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/11/0", "text": "(FIN.F12) REESS Ulkoinen latausjärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "11/11/0", "text": "(FIN.F12) Externt laddsystem för REESS", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/0/0", "text": "(FIN.G1) Gasinstallation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/0/0", "text": "(FIN.G1) Kaasuasennus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/1/0", "text": "(FIN.G2) System för byte av bränslet som används", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/1/0", "text": "(FIN.G2) Käytettävän polttoaineen vaihtojärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/2/0", "text": "(FIN.G3) Ventilationskapsel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/2/0", "text": "(FIN.G3) Ilmanvaihtokotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/3/0", "text": "(FIN.G4) Säiliön tason mittari", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/3/0", "text": "(FIN.G4) Mätare för nivån i behållaren", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "10/6/0", "text": "(FIN.G7) Kaasuvuoto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/0/0", "text": "(0.2) Valmistajan kilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/0/0", "text": "(0.2) Tillverkarens skylt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/1/0", "text": "(0.2) Valmistenumero", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/1/0", "text": "(0.2) Tillverkningsnr.", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/2/0", "text": "(0.2) Rekisterimerkinnät", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/2/0", "text": "(0.2) Reg.anteckningar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/3/0", "text": "(0.1) Asiapaperit", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/3/0", "text": "(0.1) Fordonshandlingar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/4/0", "text": "(0.1) Rekisterikilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/4/0", "text": "(0.1) Registerplåt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/5/0", "text": "(FIN.A2.1) Liikenneluvan haltijan nimi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/5/0", "text": "(FIN.A2.1) Trafiktillståndets innehavare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/5/1", "text": "(0.1) Rekisteritunnuksen kehys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "0/5/1", "text": "(0.1) Registerplåtens ram", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/0", "text": "(1.1) Käyttöjarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/0", "text": "(1.1) Färdbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/1", "text": "(1.1.12) Jarruletku", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/1", "text": "(1.1.12) Bromsslang", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/10", "text": "(1.1.16) Jarrusatula", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/10", "text": "(1.1.16) Bromshus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/11", "text": "(1.1.16) Jarrusatulan kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/11", "text": "(1.1.16) Bromshusets fästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/12", "text": "(1.1.14) Jarrukilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/12", "text": "(1.1.14) Bromssköld", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/13", "text": "(1.1.14) Jarrukilven kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/13", "text": "(1.1.14) Bromssköldens fästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/14", "text": "(1.1.17) Kuorman tunteva jarruvoimansäädin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/14", "text": "(1.1.17) Bromskraftregulator som beaktar last", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/24", "text": "(1.2) 3. axelns bromsar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/15", "text": "(1.1.10) Servobroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/16", "text": "(1.1.10) Jarrutehostimen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/16", "text": "(1.1.10) Servobromsens fästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/17", "text": "(1.1.10) Jarrupääsylinteri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/17", "text": "(1.1.10) Huvudcylinder", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/18", "text": "(1.1.10) Jarrupääsylinterin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/18", "text": "(1.1.10) Huvudcylinders fästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/19", "text": "(1.8)(1.1.10) Jarruneste", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/19", "text": "(1.8)(1.1.10) Bromsvätska", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/2", "text": "(1.1.12) Jarruletkun tuenta", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/2", "text": "(1.1.12) Bromsslangens upphängning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/20", "text": "(1.1.21) Ilmankuivain", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/1", "text": "(4.6) Backljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/20", "text": "(1.1.21) Lufttorkare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/21", "text": "(1.1.3) Paineilmasäiliö", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/21", "text": "(1.1.3) Pressluftbehållare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/22", "text": "(1.1.3) Paineenrajoitin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/22", "text": "(1.1.3) Bromskraftbegränsare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/23", "text": "(1.1.7) Poljinventtiili", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/23", "text": "(1.1.7) Pedalventil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/24", "text": "(0.2) Todistus säädöstenmukaisuudesta", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/24", "text": "(0.2) Intyg för uppfyllande av bestämmelse", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/25", "text": "(1.1.12)(1.1.8) Pv:n jarruletku", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/25", "text": "(1.1.12)(1.1.8) Släpvagnens bromsslang", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/26", "text": "(1.1.8) Pv:n jarruletkun liittimet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/26", "text": "(1.1.8) Släpvagnens bromsslangens fästningar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/27", "text": "(1.1.13) Jarruhihnat", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/27", "text": "(1.1.13) Bromsbanden", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/28", "text": "(1.1.7) Siirtelyventtiili", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/28", "text": "(1.1.7) Övergångsventil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/29", "text": "(1.1.7) Pikapäästöventtiili", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/29", "text": "(1.1.7) Snabblossningsventil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/3", "text": "(1.1.12) Jarruletkun liitos", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/3", "text": "(1.1.12) Bromsslangens koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/30", "text": "(1.1.18) Autom. säätyvä jarruvipu", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/30", "text": "(1.1.18) Automatiskt justerbar bromsarm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/31", "text": "(1.1.14) Jarrurumpu", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/31", "text": "(1.1.14) Bromstrumma", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/32", "text": "(1.1.15) Jarruvipu", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/32", "text": "(1.1.15) Bromsarm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/33", "text": "(1.1.16) Jarrusylinteri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/33", "text": "(1.1.16) Bromscylinder", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/34", "text": "(1.1.16) Ilmausruuvi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/34", "text": "(1.1.16) Luftningsskruv", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/35", "text": "(1.6) Lukkiutumaton jarrujärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/35", "text": "(1.6) Låsningsfritt bromssystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/36", "text": "(1.1.22) Mittausliitin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/36", "text": "(1.1.22) Mätningsanslutning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/37", "text": "(1.1.7) Releventtiili", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/37", "text": "(1.1.7) Reläventil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/38", "text": "(1.1.8) Duo-Matic liitin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/38", "text": "(1.1.8) Duo-Matic", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/39", "text": "(1.1.15) Levittäjäakseli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/39", "text": "(1.1.15) S-kam", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/4", "text": "(1.1.11) Jarruputken liitos", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/4", "text": "(1.1.11) Bromsrörets anslutning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/40", "text": "(1.1.14) Jarrulevyn suojakilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/40", "text": "(1.1.14) Bromsskivans skyddsplåt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/41", "text": "(1.1.17) ALB:n säätökilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/41", "text": "(1.1.17) Justerskylt för ALB", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/42", "text": "(1.1.5) Käsikäyttöinen pv-jarruohjausventtiili", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/42", "text": "(1.1.5) Handmanövrerad sv-bromsventil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/43", "text": "(1.2) Käsikäyttöinen etujarrutehon puolittaja", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/43", "text": "(1.2) Handmanövrerad bromskrafthalverare för frambroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/44", "text": "(1.7) Elektronisesti ohjattu jarrujärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/44", "text": "(1.7) Elektronisk styrt bromssystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/45", "text": "(1.1.20) Perävaunun jarruventtiilli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/45", "text": "(1.1.20) Släpvagnens bromsventil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/46", "text": "(1.1.7) Pv:n jarrujen ohjausventtiili autossa", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/46", "text": "(1.1.7) Släpvagnsbromsens styrventil på bilen", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/1", "text": "(4.5) Fram dimljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/47", "text": "(1.1.7) EBS modulaattoriventtiili", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/47", "text": "(1.1.7) EBS modulatorventil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/48", "text": "(1.1.12) Jarruletkun liitosholkki", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/48", "text": "(1.1.12) Bromsslangens presskoppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/49", "text": "(1.2) Paine-ennakko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/49", "text": "(1.2) Förtryck", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/5", "text": "(1.1.11) Jarruputki", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/5", "text": "(1.1.11) Bromsrör", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/6", "text": "(1.1.11) Jarruputken tuenta", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/6", "text": "(1.1.11) Bromsrörets fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/7", "text": "(1.1.14) Jarrulevy", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/7", "text": "(1.1.14) Bromsskiva", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/8", "text": "(1.1.13) Jarrupalat", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/8", "text": "(1.1.13) Bromsklossar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/9", "text": "(1.1.13) Jarrupalojen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/0/9", "text": "(1.1.13) Bromsklossarnas fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/1", "text": "(1.2) Etujarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/1", "text": "(1.2) Frambroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/11", "text": "(1.2) Dyn arvo 1. akseli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/11", "text": "(1.2) Dyn. värde på 1. axeln", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/12", "text": "(1.2) Dyn arvo 2. akseli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/12", "text": "(1.2) Dyn. värde på 2. axeln", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/13", "text": "(1.2) Dyn arvo 3. akseli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/13", "text": "(1.2) Dyn. värde på 3. axeln", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/14", "text": "(1.2) Dyn arvo 4. akseli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/14", "text": "(1.2) Dyn. värde på 4. axeln", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/15", "text": "(1.2) Dyn arvo 5. akseli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/15", "text": "(1.2) Dyn. värde på 5. axeln", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/2", "text": "(1.2) Takajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/2", "text": "(1.2) Bakbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/20", "text": "(1.2) Telin jarrutussuhde", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/20", "text": "(1.2) Bromsförhållande på boggie", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/21", "text": "(1.2) Jarruvoiman jakautuminen", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/21", "text": "(1.2) Bromskraftens fördelning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/22", "text": "(1.2) 1. akselin jarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/22", "text": "(1.2) 1. axelns bromsar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/23", "text": "(1.2) 2. akselin jarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/23", "text": "(1.2) 2. axelns bromsar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/24", "text": "(1.2) 3. akselin jarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/10", "text": "(4.12) Tilläggsfjärrljusets lyktinsatsens glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/25", "text": "(1.2) 4. akselin jarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/25", "text": "(1.2) 4. axelns bromsar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/26", "text": "(1.2) 5. akselin jarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/26", "text": "(1.2) 5. axelns bromsar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/3", "text": "(1.2) Poljintuntuma", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/3", "text": "(1.2) Pedalkänsla", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/30", "text": "(1.2) Jarruviive", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/30", "text": "(1.2) Bromsfördröjning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/31", "text": "(1.2) Havahtumispaine", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/31", "text": "(1.2) Öppningstryck", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/4", "text": "(1.2) Ajoneuvon jarrutussuhde", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/4", "text": "(1.2) Fordonets bromsförhållande", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/5", "text": "(1.2) Akselin 1 jarrutussuhde", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/5", "text": "(1.2) 1. axelns bromsförhållande", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/6", "text": "(1.2) Akselin 2 jarrutussuhde", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/6", "text": "(1.2) 2. axelns bromsförhållande", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/7", "text": "(1.2) Akselin 3 jarrutussuhde", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/7", "text": "(1.2) 3. axelns bromsförhållande", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/8", "text": "(1.2) Akselin 4 jarrutussuhde", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/8", "text": "(1.2) 4. axelns bromsförhållande", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/9", "text": "(1.2) Akselin 5 jarrutussuhde", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/1/9", "text": "(1.2) 5. axelns bromsförhållande", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/1", "text": "(1.4)(1.1.6) Seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/1", "text": "(1.4)(1.1.6) Parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/11", "text": "(1.4) Dyn arvo 1.aks seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/11", "text": "(1.4) Dyn. värde på 1. axelns parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/12", "text": "(1.4) Dyn arvo 2.aks seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/12", "text": "(1.4) Dyn. värde på 2. axelns parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/13", "text": "(1.4) Dyn arvo 3.aks seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/13", "text": "(1.4) Dyn. värde på 3. axelns parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/14", "text": "(1.4) Dyn arvo 4.aks seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/14", "text": "(1.4) Dyn. värde på 4. axelns parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/15", "text": "(1.4) Dyn arvo 5. aks seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/15", "text": "(1.4) Dyn. värde på 5. axelns parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/22", "text": "(1.4) 1. akselin seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/22", "text": "(1.4) 1. axelns parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/23", "text": "(1.4) 2. akselin seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/23", "text": "(1.4) 2. axelns parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/24", "text": "(1.4) 3. akselin seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/24", "text": "(1.4) 3. axelns parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/25", "text": "(1.4) 4. akselin seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/25", "text": "(1.4) 4. axelns parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/26", "text": "(1.4) 5. akselin seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/2/26", "text": "(1.4) 5. axelns parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/3/1", "text": "(1.1.16) Seisontajarrun vaijerit/tangot", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/3/1", "text": "(1.1.16) Parkeringsbromsens vajer/stag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/3/2", "text": "(1.1.16) Seisontajarrun vaijereiden/tankojen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/3/2", "text": "(1.1.16) Parkeringsbromsens vajer/stagens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/3/3", "text": "(1.1.7) Seisontajarruventtiili", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/3/3", "text": "(1.1.7) Parkeringsbromsventil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/3/4", "text": "(1.1.16) Seisontajarrusylinteri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/3/4", "text": "(1.1.16) Handbromscylinder", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/1", "text": "(1.1.19) Muu jarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/1", "text": "(1.1.19) Annan broms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/2", "text": "(1.5)(1.1.19) Hidastin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/2", "text": "(1.5)(1.1.19) Retarder", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/3", "text": "(1.5) Pakokaasujarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/3", "text": "(1.5) Avgasbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/4", "text": "(1.1.23) Työntöjarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/4", "text": "(1.1.23) Påskjutbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/5", "text": "(1.3) (1.1.20) Katastrofijarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/5", "text": "(1.3) (1.1.20) Katastrofbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/6", "text": "(1.) Sähköjarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/4/6", "text": "(1.) Elbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/5/1", "text": "(7.12) Ajovakautusjärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/5/1", "text": "(7.12) Körstabiliseringssystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/0", "text": "(4.1) Lähivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/0", "text": "(4.1) Närljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/1", "text": "(4.1.2) Lähivalon suuntaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/1", "text": "(4.1.2) Närljusets inställning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/10", "text": "(4.12) Kahdennuslähivalon kuvio", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/10", "text": "(4.12) Dubbleringsnärljusets ljusbild", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/11", "text": "(4.12) Kahdennuslähivalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/11", "text": "(4.12) Dubbleringsnärljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/12", "text": "(4.12) Kahdennuslähivalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/12", "text": "(4.12) Dubbleringsnärljusets fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/13", "text": "(4.12) Kahdennuslähivalon umpio", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/13", "text": "(4.12) Dubbleringsnärljusets lyktinsats", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/14", "text": "(4.12) Kahdennuslähivaloumpion lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/14", "text": "(4.12) Dubbleringsnärljusets glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/15", "text": "(4.12) Kahdennuslähivaloumpion heijastin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/15", "text": "(4.12) Dubbleringsnärljusets reflektor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/2", "text": "(4.1.1) Lähivalon kuvio", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/2", "text": "(4.1.1) Närljusets ljusbild", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/3", "text": "(4.1.3)(4.1.4) Lähivalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/3", "text": "(4.1.3)(4.1.4) Närljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/4", "text": "(4.1.1) Lähivalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/4", "text": "(4.1.1) Närljusets fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/5", "text": "(4.1.1) Lähivaloumpio", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/5", "text": "(4.1.1) Närljus lyktinsats", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/6", "text": "(4.1.1) Lähivalon lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/6", "text": "(4.1.1) Närljusets glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/7", "text": "(4.1.1) Lähivalon heijastin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/7", "text": "(4.1.1) Närljusets reflektor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/8", "text": "(4.12) Kahdennuslähivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/8", "text": "(4.12) Dubbleringsnärljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/9", "text": "(4.12) Kahdennuslähivalon suuntaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/0/9", "text": "(4.12) Dubbleringsnärljusets inställning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/0", "text": "(4.1.1) Kaukovalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/0", "text": "(4.1.1) Fjärrljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/1", "text": "(4.1.3)(4.1.4) Kaukovalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/1", "text": "(4.1.3)(4.1.4) Fjärrljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/10", "text": "(4.12) Lisäkaukovaloumpion lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/5", "text": "(4.3.2)(4.3.3) Keskijarruvalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/11", "text": "(4.12) Lisäkaukovaloumpion heijastin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/11", "text": "(4.12) Tilläggsfjärrljusets lyktinsatsens reflektor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/2", "text": "(4.1.1) Kaukovalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/2", "text": "(4.1.1) Fjärrljusets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/3", "text": "(4.1.1) Kaukovaloumpio", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/3", "text": "(4.1.1) Fjärrljus lyktinsats", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/4", "text": "(4.1.1) Kaukovaloumpion lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/4", "text": "(4.1.1) Fjärrljus lyktinsatsens glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/5", "text": "(4.1.1) Kaukovaloumpion heijastin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/5", "text": "(4.1.1) Fjärrlus lyktinsatsens reflektor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/6", "text": "(4.12) Lisäkaukovalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/6", "text": "(4.12) Tilläggsfjärrljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/7", "text": "(4.12) Lisäkaukovalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/7", "text": "(4.12) Tilläggsfjärrljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/8", "text": "(4.12) Lisäkaukovalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/8", "text": "(4.12) Tilläggsfjärrljusets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/9", "text": "(4.12) Lisäkaukovaloumpio", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/1/9", "text": "(4.12) Tilläggsfjärrljusets lyktinsats", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/10/1", "text": "(4.4) Hätävilkut", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/10/1", "text": "(4.4) Varningsblinkers", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/11/1", "text": "(4.8)(4.12) Heijastin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/11/1", "text": "(4.8)(4.12) Reflex", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/11/2", "text": "(4.8)(4.12) Etuheijastin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/11/2", "text": "(4.8)(4.12) Framreflex", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/11/3", "text": "(4.8)(4.12) Sivuheijastin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/11/3", "text": "(4.8)(4.12) Sidoreflex", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/11/4", "text": "(4.8)(4.12) Takaheijastin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/11/4", "text": "(4.8)(4.12) Bakreflex", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/11/5", "text": "(4.8)(4.12) Heijastavat merkinnät ja mainokset", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/11/5", "text": "(4.8)(4.12) Reflekterande märken och reklam", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/12/1", "text": "(7.7) Äänimerkinantolaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/12/1", "text": "(7.7) Signalanordning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/12/2", "text": "(FIN.C) Peruutushälytin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/12/2", "text": "(FIN.C) Backningsvarnare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/13/1", "text": "(4.1.1) Ajovaloumpio", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/13/1", "text": "(4.1.1) Lyktinsats", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/13/2", "text": "(4.1.6) Ajovalon pesulaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/13/2", "text": "(4.1.6) Lykt tvätt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/13/3", "text": "(4.1.5) Lähivalon korkeudensäätölaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/13/3", "text": "(4.1.5) Närljusets höjdjusteringsanordning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/2/0", "text": "(4.2) Etuvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/2/0", "text": "(4.2) Framljus (parkeringsljus)", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/2/1", "text": "(4.2.2)(4.2.3) Etuvalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/1", "text": "(8.4.1) Moottori", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/2/1", "text": "(4.2.2)(4.2.3) Framljusets placering och koppling (parkeringsljus)", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/2/2", "text": "(4.2.1) Etuvalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/2/2", "text": "(4.2.1) Framljusets fastsättning (parkeringsljus)", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/23", "text": "(4.9) Handbromsens märklamppa", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/24", "text": "(7.1.6) Turvavyön merkkivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/24", "text": "(7.1.6) Kontrollampa för säkerhetsbälte", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/25", "text": "(7.1.6) Turvatyynyn merkkivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/25", "text": "(7.1.6) Kontrollampa för krockkudde", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/26", "text": "(5.2.3) Rengaspaineen merkkivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/26", "text": "(5.2.3) Kontrollampa för lufttryck i däck", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/27", "text": "(8.2.1.2) OBD-merkkivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/27", "text": "(8.2.1.2) Kontrollampa för OBD", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/28", "text": "(7.12) Ajovakauden hallinnan merkkivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/28", "text": "(7.12) Kontrollampa för körstabilisator", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/29", "text": "(4.9) Etuvalojen merkkivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/29", "text": "(4.9) Kontrollampa för framljusen", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/3", "text": "(1.1) Jarrupoljin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/3", "text": "(1.1) Bromspedal", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/4", "text": "(1.1.6) Seisontajarrun käyttölaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/4", "text": "(1.1.6) Parkeringsbromsens bruksanordning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/5", "text": "(6.2.7) Kytkinpoljin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/5", "text": "(6.2.7) Kopplingspedal", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/6", "text": "(6.2.7) Kaasupoljin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/6", "text": "(6.2.7) Gaspedal", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/7", "text": "(7.7) Äänimerkinantolaitteen käyttökytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/7", "text": "(7.7) Signalanordningens reglage", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/8", "text": "(6.2.7) Tuulilasinpyyhkijöiden käyttökytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/8", "text": "(6.2.7) Vindrutetorkarnas reglage", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/9", "text": "(6.2.7) Tuulilasinpesulaitteen käyttökytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/4", "text": "(6.2) TIR-vaijer", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/7/0", "text": "(7.8) Nopeusmittarin valaistus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/7/0", "text": "(7.8) Hastighetsmätares belysning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/7/1", "text": "(7.8) Hastighetsmätare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/7/2", "text": "(7.9) Ajopiirturi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/7/2", "text": "(7.9) Färdskrivare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/8/1", "text": "(7.10) Nopeudenrajoitin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/8/1", "text": "(7.10) Hastighetsbegränsare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/9/1", "text": "(1.1.4) Jarrujärjestelmän säiliöpaineen mittari", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/9/1", "text": "(1.1.4) Bromssystemets tryckmätare för behållartrycket", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/0/0", "text": "(FIN.C14.1) Taksamittari", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/0/0", "text": "(FIN.C14.1) Taxamätare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/0/1", "text": "(FIN.C14.1) Taksatiedote , tarra", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/0/1", "text": "(FIN.C14.1) Prislista, tarra", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/0/2", "text": "(4.12) Taksivalo (kupu)", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/0/2", "text": "(4.12) Taxilampa (kupa)", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/0/3", "text": "(FIN.D10) Invataksitunnus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/0/3", "text": "(FIN.D10) Igenkänningsmärke för Invataxi", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/1/0", "text": "(FIN.D10) Kouluajoneuvovarustus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/1/0", "text": "(FIN.D10) Utrustning för skolelevstransportbil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/1/1", "text": "(FIN.D10) Opetusajoneuvovarustus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/1/1", "text": "(FIN.D10) Utrustning för körundervisningsbil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/0", "text": "(FIN.D10) Matkailuautovarustus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/0", "text": "(FIN.D10) Utrustning för husbil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/1", "text": "(7.2) Tulensammutin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/1", "text": "(7.2) Brandsläckare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/2", "text": "(6.1.3) Nestekaasun pääsulkuventtiiliä osoittava kilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/2", "text": "(6.1.3) Skylt för flytgasens huvudkran", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/3", "text": "(6.1.3) Tupakoinnin nestekaasupullojen läheisyydessä kieltävä kilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/3", "text": "(6.1.3) Skylt för förbjuden rökning, i närheten av flytgasflaskorna", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/4", "text": "(6.1.3) Nestekaasulaitteiston liitäntäpaineen ilmoittava kilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/4", "text": "(6.1.3) Skylt angående flytgasanordningens anslutningstryck", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/5", "text": "(FIN.D10) Vähimmäisilmanvaihdon aukkojen sulkemisen kieltävä kilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/5", "text": "(FIN.D10) Skylt som förbjuder stängning av minimiluftväxlingsöppning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/6", "text": "(6.1.3) Kaasulaitteiston asennustarkastus- tai tyyppihyväksyntäkilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/6", "text": "(6.1.3) Gasanordningens installationsgransknings- eller typgodkänningsskylt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/7", "text": "(6.1.3) Nestekaasulaitteiston CE-merkintä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/7", "text": "(6.1.3) Flytgasanordningens CE-märkning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/1", "text": "(6.2) Korin laita", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/8", "text": "(FIN.C14.2) Sähkölaitteiden CE-merkintä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/8", "text": "(FIN.C14.2) Elanordningarnas CE-märkning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/9", "text": "(FIN.C14.2) Sähkölaitteiden asennustarkastusta osoittava tarra", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/2/9", "text": "(FIN.C14.2) Elanordningarnas installationsgranskningsdekal", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/3/0", "text": "(6.2.9) Väliseinä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/3/0", "text": "(6.2.9) Mellanvägg", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/3/1", "text": "(FIN.A2) Väri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/3/1", "text": "(FIN.A2) Färg", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/3/2", "text": "(FIN.D10) Arkkutila", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/3/2", "text": "(FIN.D10) Kistplats", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/3/3", "text": "(6.2.6) Arkkutilan istuin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/3/3", "text": "(6.2.6) Sittplats", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/3/4", "text": "(FIN.D10) Vetolava", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/3/4", "text": "(FIN.D10) Dragflak", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/1", "text": "(9.2)(9.3) Lämmitysjärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/1", "text": "(9.2)(9.3) Värmeanordning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/2", "text": "(9.5) Sisävalaistus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/2", "text": "(9.5) Innerbelysning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/3", "text": "(9.) Linja-auton varusteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/3", "text": "(9.) Bussens utrustning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/4", "text": "(7.2) Tulensammutin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/4", "text": "(7.2) Brandsläckare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/5", "text": "(7.5) Ensiapulaukku", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/5", "text": "(7.5) Förstahjälpväska", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/6", "text": "(9.) Raivausvälineet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/6", "text": "(9.) Röjningsutrustning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/7", "text": "(9.) Hinausvaijeri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/7", "text": "(9.) Bogservaijer", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/8", "text": "(9.) Varapyörä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/8", "text": "(9.) Reservhjul", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/9", "text": "(4.13) Päävirtakytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/4/9", "text": "(4.13) Huvudströmbrytare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/5/0", "text": "(FIN.D10) Paloautovarustus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/5/0", "text": "(FIN.D10) Brandbils utrustning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/5/1", "text": "(4.12) Tunnusvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/5/1", "text": "(4.12) Igenkänningsbelysning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/6/0", "text": "(FIN.D10) Sairasautovarustus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/6/0", "text": "(FIN.D10) Ambulansutrustning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/6/1", "text": "(4.12) Tunnusvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/6/1", "text": "(4.12) Igenkänningsbelysning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/0", "text": "(6.2.9) ADR/VAK varustus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/0", "text": "(6.2.9) ADR/VAK utrustning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/1", "text": "(7.2) Tulensammutin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/1", "text": "(7.2) Brandsläckare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/2", "text": "(7.6) Pyörän jarrukiila", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/2", "text": "(7.6) Bromskil för däck", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/3", "text": "(FIN.E3) Ohjaamon paloturvallisuus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/3", "text": "(FIN.E3) Hyttens brandsäkerthet", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/4", "text": "(4.13) Päävirtakytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/4", "text": "(4.13) Huvudströmbrytare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/5", "text": "(4.11) Sähköjohtimet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/5", "text": "(4.11) Elledningar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/6", "text": "(6.2) Kuormapeite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/7/6", "text": "(6.2) Presenning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/8/1", "text": "(FIN.E1) Invalidin apulaitteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/8/1", "text": "(FIN.E1) Invalidutrustning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/1", "text": "(6.2) Karossens flakläm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/10", "text": "(FIN.D10) Ilmastointikone", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/10", "text": "(FIN.D10) Luftkonditioneringsmaskin", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/11", "text": "(FIN.D10) Säiliö", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/11", "text": "(FIN.D10) Behållare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/2", "text": "(6.2) Laidan saranointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/2", "text": "(6.2) Flakläms gångjärn", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/3", "text": "(6.2) Pressukate", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/3", "text": "(6.2) Tälttak", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/4", "text": "(6.2) TIR-vaijeri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/2/3", "text": "(4.2.1) Etuvalon lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/2/3", "text": "(4.2.1) Framljusets glas (parkeringsljus)", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/0", "text": "(4.12) Lisävalot", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/0", "text": "(4.12) Tilläggsljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/1", "text": "(4.5) Etusumuvalot", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/10", "text": "(4.5.3)(4.5.4) Takasumuvalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/10", "text": "(4.5.3)(4.5.4) Bakdimljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/11", "text": "(4.5.1) Takasumuvalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/11", "text": "(4.5.1) Bakdimljusets fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/12", "text": "(4.5.1) Takasumuvalon lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/12", "text": "(4.5.1) Bakdimljusets glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/13", "text": "(4.2) Huomiovalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/13", "text": "(4.2) Varselljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/14", "text": "(4.2.2)(4.2.3) Huomiovalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/14", "text": "(4.2.2)(4.2.3) Varselljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/15", "text": "(4.2.1) Huomiovalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/15", "text": "(4.2.1) Varselljusets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/16", "text": "(4.2.1) Huomiovalon lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/16", "text": "(4.2.1) Varselljusets glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/2", "text": "(4.5.2) Etusumuvalon suuntaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/2", "text": "(4.5.2) Fram dimljusets inställning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/3", "text": "(4.5.2) Etusumuvalon kuvio", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/3", "text": "(4.5.2) Fram dimljusets ljusbild", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/4", "text": "(4.5.3)(4.5.4) Etusumuvalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/4", "text": "(4.5.3)(4.5.4) Framdimljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/5", "text": "(4.5.1) Etusumuvalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/5", "text": "(4.5.1) Framdimljusets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/6", "text": "(4.5.1) Etusumuvaloumpio", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/6", "text": "(4.5.1) Fram dimljusets lyktinsats", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/7", "text": "(4.5.1) Etusumuvaloumpion lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/7", "text": "(4.5.1) Fram dimljus lyktinsatsens glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/8", "text": "(4.5.1) Etusumuvaloumpion heijastin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/8", "text": "(4.5.1) Fram dimljus lyktinsatsens reflektor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/9", "text": "(4.5) Takasumuvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/3/9", "text": "(4.5) Bakdimljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/0", "text": "(FIN) Ei mitään", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/0", "text": "(FIN) Ingenting", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/1", "text": "(4.2) Takavalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/1", "text": "(4.2) Bakljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/2", "text": "(4.2.2)(4.2.3) Takavalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/2", "text": "(4.2.2)(4.2.3) Bakljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/3", "text": "(4.2.1) Takavalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/3", "text": "(4.2.1) Bakljusets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/4", "text": "(4.2.1) Takavalon lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/4", "text": "(4.2.1) Bakljusets glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/5", "text": "(FIN) Ei mitään", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/4/5", "text": "(FIN) Ingenting", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/0", "text": "(4.3) Jarruvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/0", "text": "(4.3) Bromsljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/1", "text": "(8.4.1) Motor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/1", "text": "(4.3.2)(4.3.3) Jarruvalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/1", "text": "(4.3.2)(4.3.3) Bromsljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/2", "text": "(4.3.1) Jarruvalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/2", "text": "(4.3.1) Bromsljusets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/3", "text": "(4.3.1) Jarruvalon lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/3", "text": "(4.3.1) Bromsljusets glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/4", "text": "(4.3) Keskijarruvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/4", "text": "(4.3) Tilläggsbromsljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/5", "text": "(8.4.1) Boggielyft", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/5", "text": "(4.3.2)(4.3.3) Tilläggsbromsljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/6", "text": "(4.3.1) Keskijarruvalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/6", "text": "(4.3.1) Tilläggsbromsljusets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/7", "text": "(4.3.1) Keskijarruvalon lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/5/7", "text": "(4.3.1) Tilläggsbromsljusets glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/6/0", "text": "(4.4) Suuntavalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/6/0", "text": "(4.4) Körriktningsljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/6/1", "text": "(4.4.2)(4.4.3) Suuntavalon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/6/1", "text": "(4.4.2)(4.4.3) Körriktningsljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/6/2", "text": "(4.4.1) Suuntavalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/6/2", "text": "(4.4.1) Körriktningsljusets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/6/3", "text": "(4.4.1) Suuntavalon lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/6/3", "text": "(4.4.1) Körriktningsljusets glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/6/5", "text": "(4.4) Sivusuuntavalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/6/5", "text": "(4.4) Sidoblinkers", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/7/0", "text": "(4.7) Rekisterikilven valo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/7/0", "text": "(4.7) Registerskyltens ljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/7/1", "text": "(4.7.2) Rekisterikilven valon sijoitus ja kytkentä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/7/1", "text": "(4.7.2) Registerskyltljusets placering och koppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/7/2", "text": "(4.7.1) Rekisterikilven valon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/7/2", "text": "(4.7.1) Registerskyltljusets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/7/3", "text": "(4.7) Rekisterikilven valon lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/7/3", "text": "(4.7) Registerskyltljusets glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/0", "text": "(4.2) Äärivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/0", "text": "(4.2) Positionsljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/10", "text": "(4.1)(4.12) Aurauslähivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/10", "text": "(4.1)(4.12) Körljus för plogbil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/11", "text": "(4.1)(4.12) Aurauskaukovalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/11", "text": "(4.1)(4.12) Fjärrljus för plogbil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/12", "text": "(4.2)(4.12) Aurausetuvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/12", "text": "(4.2)(4.12) Framljus för plogbil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/13", "text": "(4.4)(4.12) Auraussuuntavalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/4", "text": "(6.1.1) Bakaxelbalk", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/13", "text": "(4.4)(4.12) Körriktningsljus för plogbil", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/14", "text": "(4.12) Työvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/14", "text": "(4.12) Arbetsbelysning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/15", "text": "(4.12) Valaistut kilvet ja mainokset", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/15", "text": "(4.12) Belysta skyltar och reklam", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/2", "text": "(4.6.2) Peruutusvalon sijoitus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/2", "text": "(4.6.2) Backljusets placering", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/3", "text": "(4.6.1) Peruutusvalon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/3", "text": "(4.6.1) Backljusets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/4", "text": "(4.6.1) Peruutusvalon lasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/4", "text": "(4.6.1) Backljusets glas", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/5", "text": "(4.2) Sivuvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/5", "text": "(4.2) Sidoljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/6", "text": "(4.12) Valaistut kilvet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/6", "text": "(4.12) Belysta skyltar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/7", "text": "(4.12) Tunnusvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/7", "text": "(4.12) Igenkänningsljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/8", "text": "(4.12) Työ- ja apuvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/8", "text": "(4.12) Arbets- och hjälpbelysning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/9", "text": "(4.4) Sivusuuntavalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/9", "text": "(4.4) Sidkörriktningsljus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/9/0", "text": "(4.11)(4.13) Akku ja sähkökytkennät", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/9/0", "text": "(4.11)(4.13) Ackumulatorn och elkopplingarna", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/9/1", "text": "(4.13) Akku", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/9/1", "text": "(4.13) Ackumulatorn", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/9/2", "text": "(4.11) Sähkökytkennät", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/9/2", "text": "(4.11) Elkopplingarna", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/9/3", "text": "(4.11) Konetilan sähkökytkennät", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/0", "text": "(8.4.1) Öljyvuodot", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/9/3", "text": "(4.11) Motorrummets elkopplingar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/9/4", "text": "(4.10) Perävaunun sähköliitäntä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/9/4", "text": "(4.10) Dragkopplingens elkopplingar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/0", "text": "(8.2) Pakokaasumittaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/0", "text": "(8.2) Avgasmätning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/1", "text": "(8.2.1.2) CO-pitoisuus, joutokäynnillä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/1", "text": "(8.2.1.2) CO-värde, tomgång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/10", "text": "(8.2.1.2) Lambda-arvo, joutokäynnillä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/10", "text": "(8.2.1.2) Lambda-värde, tomgång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/2", "text": "(8.2.1.2) CO-pitoisuus, kierroksilla", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/2", "text": "(8.2.1.2) CO-värde, högre tomgång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/3", "text": "(8.2) HC-pitoisuus, joutokäynnillä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/3", "text": "(8.2) HC-värde, tomgång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/4", "text": "(8.2) HC-pitoisuus, kierroksilla", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/4", "text": "(8.2) HC-värde, högre tomgång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/5", "text": "(8.2.1.2) Lambda-arvo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/5", "text": "(8.2.1.2) Lambda-värde", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/0", "text": "(8.4.1) Oljeläckor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/6", "text": "(8.2) CO2-pitoisuus, joutokäynnillä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/6", "text": "(8.2) CO2-värde, tomgång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/7", "text": "(8.2) CO2-pitoisuus, kierroksilla", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/7", "text": "(8.2) CO2-värde, högre tomgång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/8", "text": "(8.2) O2-pitoisuus, joutokäynnillä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/8", "text": "(8.2) O2-värde, tomgång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/9", "text": "(8.2) O2-pitoisuus, kierroksilla", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/0/9", "text": "(8.2) O2-värde, högre tomgång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/1/0", "text": "(8.2.2.2) Savutus, K-arvo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/1/0", "text": "(8.2.2.2) Rök, K-värde", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/1/1", "text": "(8.2.2.2) Joutokäynti rpm", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/1/1", "text": "(8.2.2.2) Tomgång rpm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/1/2", "text": "(8.2.2.2) Maksimi rpm", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/1/2", "text": "(8.2.2.2) Max rpm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/1/3", "text": "(8.2.2) Savutusmittaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/1/3", "text": "(8.2.2) Rökmätning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/2/0", "text": "(8.1) Melu", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/2/0", "text": "(8.1) Buller", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/2", "text": "(8.4.1) Vaihteisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/2", "text": "(8.4.1) Växellåda", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/3", "text": "(8.4.1) Vetopyörästö", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/3", "text": "(8.4.1) Differential", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/4", "text": "(8.4.1) Ohjaustehostin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/4", "text": "(8.4.1) Styrservo", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/5", "text": "(8.4.1) Telinnostaja", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/4", "text": "(6.1.2) Avgasrörets fastsätning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/6", "text": "(8.4.1) Kippi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/6", "text": "(8.4.1) Kipp", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/7", "text": "(8.4.1) Ohjaustehostimen pumppu", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/7", "text": "(8.4.1) Styrservopump", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/8", "text": "(8.4.1) Jakovaihteisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/3/8", "text": "(8.4.1) Fördelningsväxellåda", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/4/1", "text": "(8.3) Radiohäiriöt", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/4/1", "text": "(8.3) Radiostörningar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/5/1", "text": "(8.2) Pakokaasupäästöt", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/5/1", "text": "(8.2) Avgasutsläpp", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/5/2", "text": "(8.2.1.2) OBD -järjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/5/2", "text": "(8.2.1.2) OBD -system", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/6/1", "text": "(6.1.3) Polttoainevuodot", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/6/1", "text": "(6.1.3) Bränsleläckage", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/7/1", "text": "(8.4.1) Nestevuodot", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/7/1", "text": "(8.4.1) Vätskeläckage", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/8/1", "text": "(8.2.1.2) OBD - järjestelmä / -tiedot", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "3/8/1", "text": "(8.2.1.2) OBD -system / -data", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/0", "text": "(6.1.1) Alustan kotelot ja pohjalevy", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/0", "text": "(6.1.1) Chassiets balkar och bottenplatta", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/1", "text": "(6.1.1) Alustan korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/1", "text": "(6.1.1) Chassiets reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/10", "text": "(6.1.1) Pyörän kotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/10", "text": "(6.1.1) Hjulhus", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/11", "text": "(6.1.1) Pyörän kotelon korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/11", "text": "(6.1.1) Hjulhusets reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/12", "text": "(6.1.1) Pitkittäinen takahelmakotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/12", "text": "(6.1.1) Längsgående bakre balk", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/13", "text": "(6.1.1) Pitkittäisen takahelmakotelon korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/13", "text": "(6.1.1) Längsgående bakre balks reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/14", "text": "(6.1.1) Poikittainen takahelmakotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/14", "text": "(6.1.1) Tvärsgående bakre balk", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/15", "text": "(6.1.1) Poikittaisen takahelmakotelon korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/15", "text": "(6.1.1) Tvärgående bakre balks reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/16", "text": "(6.1.1) Poikittainen etuhelmakotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/16", "text": "(6.1.1) Tvärsgående främre balk", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/17", "text": "(6.1.1) Poikittaisen etuhelmakotelon korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/17", "text": "(6.1.1) Tvärgående främre balks reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/18", "text": "(6.1.1) Varapyöräkotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/18", "text": "(6.1.1) Reservhjulslåda", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/2", "text": "(6.1.1) Helmakotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/2", "text": "(6.1.1) Tröskellåda", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/3", "text": "(6.1.1) Helmakotelon korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/3", "text": "(6.1.1) Tröskellådans reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/4", "text": "(6.1.1) Pohjalevy", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/4", "text": "(6.1.1) Bottenplatta", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/5", "text": "(6.1.1) Pohjalevyn korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/5", "text": "(6.1.1) Bottenplattans reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/6", "text": "(6.1.1) Alustan pitkittäiskotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/6", "text": "(6.1.1) Chassiets längsgående balkar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/7", "text": "(6.1.1) Alustan pitkittäiskotelon korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/7", "text": "(6.1.1) Chassiets längsgående balkars reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/5", "text": "(6.1) Bottenpansar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/8", "text": "(6.1.1) Alustan poikittaiskotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/8", "text": "(6.1.1) Chassiets tvärgående balkar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/9", "text": "(6.1.1) Alustan poikittaiskotelon korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/0/9", "text": "(6.1.1) Chassiets tvärgående balkars reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/0", "text": "(6.1.1) Runko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/0", "text": "(6.1.1) Ram", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/1", "text": "(6.1.1) Rungon korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/1", "text": "(6.1.1) Ramens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/10", "text": "(6.1.1) Apurungon korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/10", "text": "(6.1.1) Hjälpramens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/2", "text": "(6.1.1) Pitkittäinen runkopalkki", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/2", "text": "(6.1.1) Längsgående rambalk", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/3", "text": "(6.1.1) Pitkittäisen runkopalkin korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/3", "text": "(6.1.1) Längsgående rambalkens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/4", "text": "(6.1.1) Pitkittäisen runkopalkin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/4", "text": "(6.1.1) Längsgående rambalkens infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/5", "text": "(6.1.1) Poikittainen runkopalkki", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/5", "text": "(6.1.1) Tvärgående rambalk", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/6", "text": "(6.1.1) Poikittaisen runkopalkin korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/6", "text": "(6.1.1) Tvärgående rambalkens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/7", "text": "(6.1.1) Poikittaisen runkopalkin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/7", "text": "(6.1.1) Tvärgående rambalkens infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/8", "text": "(6.1.1) Apurunko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/8", "text": "(6.1.1) Hjälpram", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/9", "text": "(6.1.1) Apurungon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/1/9", "text": "(6.1.1) Hjälpramens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/0", "text": "(6.1.1) Akseliston kiinnityspalkit", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/0", "text": "(6.1.1) Axlarnas infästningsbalkar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/1", "text": "(6.1.1) Etuakselipalkki", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/1", "text": "(6.1.1) Framaxelbalk", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/2", "text": "(6.1.1) Etuakselipalkin korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/2", "text": "(6.1.1) Framaxelbalkens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/3", "text": "(6.1.1) Etuakselipalkin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/3", "text": "(6.1.1) Framaxelbalkens infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/4", "text": "(6.1.1) Taka-akselipalkki", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/5", "text": "(6.1.1) Taka-akselipalkin korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/5", "text": "(6.1.1) Bakaxelbalkens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/6", "text": "(6.1.1) Taka-akselipalkin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/6", "text": "(6.1.1) Bakaxelbalkens infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/7", "text": "(6.1.1) Vaihteiston palkki", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/7", "text": "(6.1.1) Växellådsbalken", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/8", "text": "(6.1.1) Vaihteistopalkin korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/8", "text": "(6.1.1) Växellådbalkens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/9", "text": "(6.1.1) Vaihteistopalkin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/2/9", "text": "(6.1.1) Växellådbalkens infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/0", "text": "(6.1.2) Pakoputkisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/0", "text": "(6.1.2) Avgasrör", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/1", "text": "(6.1.2) Pakoputkiston korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/1", "text": "(6.1.2) Avgasrörets reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/2", "text": "(6.1.2) Pakoputkiston liitos", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/2", "text": "(6.1.2) Avgasrörets anslutning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/4", "text": "(6.1.2) Pakoputkiston kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/18", "text": "(5.3.3) Ylätukivarren kiinnityspiste", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/5", "text": "(6.1.2) Äänenvaimennin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/5", "text": "(6.1.2) Ljuddämpare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/6", "text": "(6.1.2) Katalysaattori", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/6", "text": "(6.1.2) Katalysator", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/7", "text": "(6.1.2) Pakosarja", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/3/7", "text": "(6.1.2) Avgasserie", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/0", "text": "(6.1.1) Kori", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/0", "text": "(6.1.1) Karosseri", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/1", "text": "(6.1.1) Korin korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/18", "text": "(6.2.9) Baklucka", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/1", "text": "(6.1.1) Karosseriets reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/10", "text": "(6.1.1) Helmakotelon korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/10", "text": "(6.1.1) Tröskellådans reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/11", "text": "(6.2.3) Ovi ja saranat", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/11", "text": "(6.2.3) Dörr och gångjärnen", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/12", "text": "(6.2.3) Oven lukko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/12", "text": "(6.2.3) Dörrlås", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/13", "text": "(6.2.10) Roiskesuoja", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/13", "text": "(6.2.10) Stänkskärm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/14", "text": "(6.2.10) Roiskesuojan korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/14", "text": "(6.2.10) Stänkskärmens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/15", "text": "(6.1.4) Puskuri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/15", "text": "(6.1.4) Stötfångare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/16", "text": "(6.2.9) Nokkapellin lukitus/turvasalpa", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/16", "text": "(6.2.9) Framhuvens låsning/säkerhetsspärr", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/17", "text": "(6.2.9) Etukansi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/17", "text": "(6.2.9) Framlucka", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/18", "text": "(6.2.9) Takakansi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/19", "text": "(6.1.5) Varapyöräteline", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/19", "text": "(6.1.5) Reservhjulsställning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/2", "text": "(6.1) Korin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/2", "text": "(6.1) Karosseriets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/20", "text": "(6.1) Sisälokasuoja", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/20", "text": "(6.1) Innerstänkskärm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/21", "text": "(6.1) Sisälokasuojan korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/21", "text": "(6.1) Innerstänkskärms reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/22", "text": "(6.1) Joustintuen kotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/5", "text": "(6.1) Pohjapanssari", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/22", "text": "(6.1) Fjäderbenets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/23", "text": "(6.1.6) Hinauslaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/23", "text": "(6.1.6) Bogseranordning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/24", "text": "(9.1.2) Hätäuloskäynti", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/24", "text": "(9.1.2) Reservutgång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/3", "text": "(6.1.1) A-pilari", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/3", "text": "(6.1.1) A-pelare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/4", "text": "(6.1.1) A-pilarin korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/20", "text": "(5.3.3) Snedstag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/4", "text": "(6.1.1) A-pelarens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/5", "text": "(6.1.1) B-pilari", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/5", "text": "(6.1.1) B-pelare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/6", "text": "(6.1.1) B-pilarin korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/6", "text": "(6.1.1) B-pelarens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/7", "text": "(6.1.1) C-pilari", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/7", "text": "(6.1.1) C-pelare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/8", "text": "(6.1.1) C-pilarin korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/8", "text": "(6.1.1) C-pelarens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/9", "text": "(6.1.1) Helmakotelo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/9", "text": "(6.1.1) Tröskellåda", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/5/0", "text": "(6.1.3) Polttoainesäiliö ja -putket", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/5/0", "text": "(6.1.3) Bränsletank och -ledningar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/5/1", "text": "(6.1.3) Polttoainesäiliö", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/5/1", "text": "(6.1.3) Bränsletank", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/5/2", "text": "(6.1.3) Polttoainesäiliön kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/5/2", "text": "(6.1.3) Bränsletankens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/5/3", "text": "(6.1.3) Polttoaineputkisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/5/3", "text": "(6.1.3) Bränsle rörsystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/5/4", "text": "(6.1.3) Polttoaineputkiston kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/5/4", "text": "(6.1.3) Bränsle rörsystemets fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/1", "text": "(6.1.4) Puskuri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/1", "text": "(6.1.4) Stötfångare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/2", "text": "(6.1.4) Alleajosuoja", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/2", "text": "(6.1.4) Underkörningsskydd", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/3", "text": "(6.1.4) Sivusuoja", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/3", "text": "(6.1.4) Sidoskydd", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/4", "text": "(6.2.9) Äänieristyssuojaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/4", "text": "(6.2.9) Ljudisoleringsskydd", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/6", "text": "(6.1) Karjapuskuri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/6/6", "text": "(6.1) Hjortbåge", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/7/0", "text": "(6.2) Kuormakori", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/7/0", "text": "(6.2) Lastutrymme", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/7/1", "text": "(6.2) Kuormakorin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/7/1", "text": "(6.2) Lastutrymmets fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/7/2", "text": "(6.2) Kuormansidontapiste", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/7/2", "text": "(6.2) Lastfästningspunkt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/7/3", "text": "(6.2) Kuormakorin etuseinä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/7/3", "text": "(6.2) Lastkorgens framvägg", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/8/0", "text": "(6.1) Muut alustan ja korin rakenteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/8/0", "text": "(6.1) Chassiets och karosseriets övriga konstruktioner", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/8/1", "text": "(6.2.10) Roiskeläppä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/8/1", "text": "(6.2.10) Stänkskydd", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/9/0", "text": "(FIN.D10) Alustan suojaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/9/0", "text": "(FIN.D10) Underredets rostskyddsbehandling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/0", "text": "(5.1) Etuakselisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/0", "text": "(5.1) Framvagn", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/1", "text": "(5.3) Alatukivarren pallonivel", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/1", "text": "(5.3) Nedre länkarmens kulled", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/10", "text": "(5.3) Ylätukivarren pallonivel", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/10", "text": "(5.3) Övre länkarmens kulled", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/11", "text": "(5.3) Ylätukivarren pallonivelen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/11", "text": "(5.3) Övre länkarmens kulleds infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/12", "text": "(5.3) Ylätukivarren pallonivelen suojakumi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/12", "text": "(5.3) Övre länkarmens kulleds skyddsgummi", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/13", "text": "(5.3) Ylätukivarren pallonivelen suojakumin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/13", "text": "(5.3) Övre länkarmens kulleds skyddsgummits fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/14", "text": "(5.3.3) Ylätukivarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/14", "text": "(5.3.3) Övre länkarm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/15", "text": "(5.3) Ylätukivarren laakerointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/15", "text": "(5.3) Övre länkarmens lagring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/16", "text": "(5.3.3) Ylätukivarren pitkittäistuenta", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/16", "text": "(5.3.3) Övre länkarmens längsgående infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/6", "text": "(5.3) Jousen lautanen", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/18", "text": "(5.3.3) Övre länkarmens fästpunkt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/19", "text": "(5.3.3) Kallistuksen vakaaja", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/19", "text": "(5.3.3) Krängningsstag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/2", "text": "(5.3) Alatukivarren pallonivelen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/2", "text": "(5.3) Nedre länkarmens kulleds infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/20", "text": "(5.3.1) Kallistuksen vakaajan kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/20", "text": "(5.3.1) Krängningsstagets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/21", "text": "(5.1.3) Etupyörän laakerointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/21", "text": "(5.1.3) Framhjulets lagring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/22", "text": "(5.2.2) Etupyörä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/22", "text": "(5.2.2) Framhjul", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/23", "text": "(5.1.2) Olkatappi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/23", "text": "(5.1.2) Kungsbult", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/24", "text": "(5.3.3) Yhdysvarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/24", "text": "(5.3.3) Förbindelstag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/25", "text": "(2.4)(5.1)(5.3) Pyörän asentokulma", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/25", "text": "(2.4)(5.1)(5.3) Hjulets inställningsvinkel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/3", "text": "(5.3) Alatukivarren pallonivelen suojakumi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/3", "text": "(5.3) Nedre länkarmens kulleds skyddsgummi", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/4", "text": "(5.3) Alatukivarren pallonivelen suojakumin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/4", "text": "(5.3) Nedre länkarmens kulleds skyddsgummits fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/5", "text": "(5.3.3) Alatukivarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/5", "text": "(5.3.3) Nedre länkarm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/6", "text": "(5.3) Alatukivarren laakerointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/3/0", "text": "(5.2) Hjul", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/6", "text": "(5.3) Nedre länkarmens lagring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/7", "text": "(5.3.3) Alatukivarren pitkittäistuenta", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/7", "text": "(5.3.3) Nedre länkarmens lagrings infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/8", "text": "(5.3.3) Alatukivarren vinotuenta", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/8", "text": "(5.3.3) Nedre länkarmens snedstag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/9", "text": "(5.3.3) Alatukivarren kiinnityspiste", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/0/9", "text": "(5.3.3) Nedre länkarmens fästpunkt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/0", "text": "(5.1) Taka-akselisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/0", "text": "(5.1) Bakvagn", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/1", "text": "(5.3.3) Takatukivarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/1", "text": "(5.3.3) Baklänkarm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/10", "text": "(5.3.3) Taka-akselin tuennan kiinnityspiste", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/10", "text": "(5.3.3) Bakaxel stödningens fästpunkt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/11", "text": "(5.3) Takatukivarren pallonivel", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/11", "text": "(5.3) Bak länkarmens kulled", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/12", "text": "(5.3) Takatukivarren pallonivelen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/12", "text": "(5.3) Bak länkarm kulledens infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/13", "text": "(5.3) Takatukivarren pallonivelen suojakumi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/13", "text": "(5.3) Bak länkarm kulledens skyddsgummi", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/14", "text": "(5.3) Takatukivarren pallonivelen suojakumin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/14", "text": "(5.3) Bak länkarm kulled skyddsgummits infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/15", "text": "(5.1.3) Takapyörän laakerointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/15", "text": "(5.1.3) Bakhjulets lagring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/16", "text": "(5.2.2) Takapyörä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/16", "text": "(5.2.2) Bakhjul", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/17", "text": "(5.3.3) Kallistuksen vakaaja", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/17", "text": "(5.3.3) Krängningsstag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/18", "text": "(5.3.1) Kallistuksen vakaajan kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/18", "text": "(5.3.1) Krängningsstagets infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/19", "text": "(5.3.3) Alatukivarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/19", "text": "(5.3.3) Nedre bärarm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/2", "text": "(5.3) Takatukivarren laakerointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/2", "text": "(5.3) Baklänkarmens lagring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/20", "text": "(5.3.3) Vinotukivarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/21", "text": "(5.3.3) Ylätukivarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/21", "text": "(5.3.3) Övre bärarm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/22", "text": "(5.3.3) Poikittainen tukivarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/22", "text": "(5.3.3) Tvärgående stödstag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/23", "text": "(2.4)(5.1)(5.3) Pyörän asentokulma", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/23", "text": "(2.4)(5.1)(5.3) Hjulvinklar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/3", "text": "(5.3.3) Takatukivarren kiinnityspiste", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/3", "text": "(5.3.3) Baklänkarmens fästpunkt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/4", "text": "(5.3.3) Taka-akselin tukitanko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/4", "text": "(5.3.3) Bakaxelns stödstång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/5", "text": "(5.3) Taka-akselin tukitangon laakerointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/5", "text": "(5.3) Bakaxel stödstångens lagring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/6", "text": "(5.3.3) Taka-akselin tukitangon kiinnityspiste", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/6", "text": "(5.3.3) Bakaxel Stödstångens fästpunkt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/7", "text": "(5.1) Taka-akseli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/7", "text": "(5.1) Bakaxel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/8", "text": "(5.1.1) Taka-akselin tuenta", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/8", "text": "(5.1.1) Bakaxelns stödning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/9", "text": "(5.3) Taka-akselin tuennan laakerointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/1/9", "text": "(5.3) Bakaxel stödningens lagring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/0", "text": "(5.3) Jousitus ja iskunvaimennus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/0", "text": "(5.3) Fjädring och stötdämpning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/1", "text": "(5.3.1) Jousi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/1", "text": "(5.3.1) Fjäder", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/10", "text": "(5.3.1) Kaasu-nestejousitus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/10", "text": "(5.3.1) Gas/vätskefjäder", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/11", "text": "(5.3.1) Kaasu-nestejousituksen putkisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/11", "text": "(5.3.1) Gas/vätskefjädringens rörsystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/14", "text": "(5.3.2) Heilahduksenvaimennin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/14", "text": "(5.3.2) Stötdämpare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/15", "text": "(5.3.2) Heilahduksenvaimentimen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/15", "text": "(5.3.2) Stötdämparnas infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/16", "text": "(5.3.5) Ilmajousi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/16", "text": "(5.3.5) Luftfjäder", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/17", "text": "(5.3.4) Riipuke", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/17", "text": "(5.3.4) Fjäderhänke", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/18", "text": "(5.3.2) Iskunvaimentimen männänvarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/18", "text": "(5.3.2) Stötdämparns kolvstång", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/2", "text": "(5.3.1) Jousilehti", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/2", "text": "(5.3.1) Fjäderblad", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/3", "text": "(5.3.1) Päälehti", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/3", "text": "(5.3.1) Huvudblad", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/4", "text": "(5.3.1) U-pultti", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/4", "text": "(5.3.1) U-bult", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/5", "text": "(5.3.4) Jousituksen laakerointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/5", "text": "(5.3.4) Fjädringens lagring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/9", "text": "(2.1.5)(2.6) Ohjaustehostin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/6", "text": "(5.3) Fjäder tallrik", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/7", "text": "(5.3) Joustintuki", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/7", "text": "(5.3) Fjäderben", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/8", "text": "(5.3) Joustintuen laakerointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/8", "text": "(5.3) Fjäderbenets lagring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/9", "text": "(5.3) Joustonrajoitin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/2/9", "text": "(5.3) Fjädringsbegränsare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/3/0", "text": "(5.2) Pyörä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/3/1", "text": "(5.2) Rengas", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/3/1", "text": "(5.2) Däck", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/3/2", "text": "(5.2.2) Vanne", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/3/2", "text": "(5.2.2) Fälg", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/4/0", "text": "(5.3.3) Reaktiotanko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/4/0", "text": "(5.3.3) Reaktionsstag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/4/1", "text": "(5.3) Reaktiotangon laakerointi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/4/1", "text": "(5.3) Reaktionsstagets lagring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/4/2", "text": "(2.4)(5.1)(5.3) Pyörän asentokulmat", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/4/2", "text": "(2.4)(5.1)(5.3) Hjulets inställningsvinkel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/5/1", "text": "(5.1) Teli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/5/1", "text": "(5.1) Boggi", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/5/2", "text": "(5.1) Nostosylinteri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "5/5/2", "text": "(5.1) Lyftcylinder", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/0", "text": "(2.1.3) Ohjausnivelet ja tangot", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/0", "text": "(2.1.3) Styrleder och -stag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/1", "text": "(2.1.3) Raidetangon pää", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/1", "text": "(2.1.3) Styrstagets led", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/7/1", "text": "(7.3) Ohjauslukko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/10", "text": "(2.1.3) Keskimmäisen välitangon nivelen suojakumin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/10", "text": "(2.1.3) Mittersta mellanstag kulled skyddsgummits fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/11", "text": "(2.1.3) Apusimpukka", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/11", "text": "(2.1.3) Hjälpsnäcka", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/12", "text": "(2.1.3) Apusimpukan kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/12", "text": "(2.1.3) Hjälpsnäckans infästning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/13", "text": "(2.1.3) Apusimpukan kiinnityspisteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/13", "text": "(2.1.3) Hjälpsnäckans fästpunkter", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/14", "text": "(2.1.3) Ohjausvarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/14", "text": "(2.1.3) Styrarm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/15", "text": "(2.1.3) Ohjausakseli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/15", "text": "(2.1.3) Rattaxel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/16", "text": "(2.3)(2.1.3) Ohjausakselin ristikkonivel", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/16", "text": "(2.3)(2.1.3) Rattaxelns korsled", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/17", "text": "(2.1.3) Ohjausakselin ristikkonivelen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/17", "text": "(2.1.3) Rattaxelns korsledens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/18", "text": "(2.3)(2.1.3) Ohjausakselin kuminivel", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/18", "text": "(2.3)(2.1.3) Rattaxelns gummiled", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/19", "text": "(2.1.3) Ohjausakselin kuminivelen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/19", "text": "(2.1.3) Rattaxel gummiledens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/2", "text": "(2.1.3) Raidetangon pään kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/2", "text": "(2.1.3) Styrstagsledens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/20", "text": "(2.1.3) Ohjauksen välitanko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/20", "text": "(2.1.3) Styrinrättningens mellanstag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/21", "text": "(2.3)(2.1.3) Ohjauksen välitangon nivel", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/21", "text": "(2.3)(2.1.3) Styrinrättningens mellanstagets led", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/22", "text": "(2.1.3) Ohjauksen välitangon nivelen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/2/1", "text": "(2.5) Kääntökehä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/22", "text": "(2.1.3) Styrinrättning mellanstag ledens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/23", "text": "(2.1.3) Ohjauksen välitangon nivelen suojakumi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/23", "text": "(2.1.3) Styrinrättning mellanstag ledens skyddsgummi", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/24", "text": "(2.1.3) Ohjauksen välitangon nivelen suojakumin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/24", "text": "(2.1.3) Styrinrättning mellanstag led skyddsgummits fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/25", "text": "(2.1.4) Ohjauksen rajoitin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/25", "text": "(2.1.4) Styrningsbegränsare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/26", "text": "(2.1.3) Kääntövarsi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/26", "text": "(2.1.3) Svängarm", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/27", "text": "(2.2.2) Ohjausvaimennin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/27", "text": "(2.2.2) Styrdämpare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/28", "text": "(2.2.2) Ohjausvaimentimen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/28", "text": "(2.2.2) Styrdämparens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/3", "text": "(2.1.3) Raidetangon pään suojakumi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/3", "text": "(2.1.3) Styrstagsledens skyddsgummi", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/4", "text": "(2.1.3) Raidetangon pään suojakumin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/2/1", "text": "(2.5) Svängkrans", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/4", "text": "(2.1.3) Styrstagsledens skyddsgummits fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/5", "text": "(2.1.3) Raidetanko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/5", "text": "(2.1.3) Styrstag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/6", "text": "(2.3)(2.1.3) Keskimmäisen välitangon nivel", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/6", "text": "(2.3)(2.1.3) Mittersta styrstagets led", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/7/1", "text": "(7.3) Rattlås", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/7", "text": "(2.1.3) Keskimmäisen välitangon nivelen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/7", "text": "(2.1.3) Mittersta styrstagsledens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/8", "text": "(2.1.3) Keskimmäinen välitanko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/8", "text": "(2.1.3) Mittersta mellanstag", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/9", "text": "(2.1.3) Keskimmäisen välitangon nivelen suojakumi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/0/9", "text": "(2.1.3) Mittersta mellanstag kulledens skyddsgummi", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/0", "text": "(2.1.1)(2.1.2) Ohjausvaihde", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/0", "text": "(2.1.1)(2.1.2) Styrväxel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/1", "text": "(2.1.1) Hammastanko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/1", "text": "(2.1.1) Styrväxel av kuggstångstyp", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/10", "text": "(2.1.1) Ohjaustehostimen putkisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/10", "text": "(2.1.1) Styrservons rörsystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/11", "text": "(2.1.1) Ohjauslaitteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/11", "text": "(2.1.1) Styrinrättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/2", "text": "(2.1.1) Hammastangon kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/2", "text": "(2.1.1) Kuggstångens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/3", "text": "(2.1.1) Hammastangon suojakumi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/3", "text": "(2.1.1) Kuggstångens skyddsgummi", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/4", "text": "(2.1.1) Hammastangon suojakumin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/4", "text": "(2.1.1) Kuggstångens skyddsgummits fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/5", "text": "(2.1.1) Hammastangon kiinnityspisteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/5", "text": "(2.1.1) Kuggstångens fästpunkter", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/6", "text": "(2.1.1) Simpukka", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/6", "text": "(2.1.1) Styrväxel av snäcktyp", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/7", "text": "(2.1.2) Simpukan kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/7", "text": "(2.1.2) Styrväxelns fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/8", "text": "(2.1.1) Simpukan kiinnityspisteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/8", "text": "(2.1.1) Styrväxelns fästpunkter", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/1", "text": "(3.2) Tuulilasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/1/9", "text": "(2.1.5)(2.6) Styrservo", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/2/2", "text": "(2.1.3) Ohjauslaitteisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/2/2", "text": "(2.1.3) Styrinrättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/3/1", "text": "(2.1) Ohjautuva akseli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "6/3/1", "text": "(2.1) Styrande axel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/0/1", "text": "(6.2.9) Takalaitanostin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/0/1", "text": "(6.2.9) Baklämslyft", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/0/2", "text": "(6.2.9) Sivulaitanostin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/0/2", "text": "(6.2.9) Sidlämslyft", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/0/3", "text": "(6.2.9) Kuormausnosturi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/0/3", "text": "(6.2.9) Lastkran", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/0", "text": "(6.1.7) Voimansiirto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/0", "text": "(6.1.7) Kraftöverföring", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/1", "text": "(6.1.7) Vetonivel", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/1", "text": "(6.1.7) Drivled", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/2", "text": "(6.1.7) Vetonivelen suojakumi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/2", "text": "(6.1.7) Drivledens skyddsgummi", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/3", "text": "(6.1.7) Vetonivelen suojakumin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/3", "text": "(6.1.7) Drivled skyddsgummits fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/4", "text": "(6.1.7) Vetoakseli", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/4", "text": "(6.1.7) Drivaxel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/5", "text": "(6.1.7) Vetoakselin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/5", "text": "(6.1.7) Drivaxelns fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/6", "text": "(6.1.7) Kardaaniakselin tukilaakeri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/6", "text": "(6.1.7) Kardanaxelns bärlager", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/7", "text": "(6.1.7) Kardaaniakselin nivel", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/7", "text": "(6.1.7) Kardanaxelns kardanknut", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/8", "text": "(6.1.7) Vaihteiston kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/8", "text": "(6.1.7) Växellådans fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/9", "text": "(6.1.7) Vetopyörästön kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/1/9", "text": "(6.1.7) Diffrentialväxelns fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/0", "text": "(7.1) Turvavyöt ja -varusteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/0", "text": "(7.1) Säkerhetsbälten och -utrustning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/1", "text": "(7.1) Turvavyö", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/1", "text": "(7.1) Säkerhetsbälte", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/2", "text": "(7.1.1) Turvavyön kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/2", "text": "(7.1.1) Säkerhetsbältets fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/3", "text": "(7.1.2) Kelauslaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/3", "text": "(7.1.2) Rullanordning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/4", "text": "(7.1.2) Turvavyön nauha", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/4", "text": "(7.1.2) Säkerhetsbältets rem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/5", "text": "(7.1.2) Turvavyön lukko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/5", "text": "(7.1.2) Säkerhetsbältets lås", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/6", "text": "(7.1.4) Turvavyön kiristimet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/6", "text": "(7.1.4) Säkerhetsbältets spännare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/7", "text": "(7.1.5) Turvatyyny", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/7", "text": "(7.1.5) Krockkudde", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/8", "text": "(7.1.5) Sivuturvatyyny", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/8", "text": "(7.1.5) Sido krockkudde", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/9", "text": "(7.1.1) Turvavyön kiinnityspiste", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/2/9", "text": "(7.1.1) Säkerhetsbältets fastsättningspunkt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/0", "text": "(6.1.6) Vetopää", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/0", "text": "(6.1.6) Dragkoppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/1", "text": "(6.1.6) Vetokoukun kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/1", "text": "(6.1.6) Dragkrokens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/10", "text": "(6.1.6) Vetoaisa", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/10", "text": "(6.1.6) Dragbom", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/11", "text": "(6.1.6) Vetopöytä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/11", "text": "(6.1.6) Svängbord", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/12", "text": "(6.1.6) Vetolaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/12", "text": "(6.1.6) Draganordning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/13", "text": "(6.1.6) Vetokoukku", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/13", "text": "(6.1.6) Dragkrok", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/2", "text": "(6.1.6) Vetokoukun kiinnityksen korjaus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/2", "text": "(6.1.6) Dragkroks fastsättningens reparation", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/3", "text": "(6.1.6) Vetopalkki", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/3", "text": "(6.1.6) Dragbalk", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/4", "text": "(6.1.6) Vetokytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/4", "text": "(6.1.6) Dragkoppling", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/5", "text": "(6.1.6) Vetokytkimen tappi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/5", "text": "(6.1.6) Dragkopplingens låstapp", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/6", "text": "(6.1.6) Vetokytkimen kara", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/6", "text": "(6.1.6) Dragkopplingens fäst axel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/7", "text": "(6.1.6) Vetokytkimen mekanismi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/7", "text": "(6.1.6) Dragkopplingens mekanism", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/8", "text": "(6.1.6) Vetokytkimen kumit", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/8", "text": "(6.1.6) Dragkopplingens gummidynor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/9", "text": "(6.1.6) Vetotappi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/3/9", "text": "(6.1.6) Dragtapp", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/4/0", "text": "(7.4) Varoituskolmio", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/4/0", "text": "(7.4) Varningstriangel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/5/0", "text": "(FIN.C14.5) Nopeuskilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/5/0", "text": "(FIN.C14.5) Hastighetsskylt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/5/1", "text": "(4.8) Pitkän ajoneuvoyhdistelmän kilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/5/1", "text": "(4.8) Skylt för lång fordonskombination", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/1", "text": "(6.1.8)(8.4.1)(6.1.9) Moottori", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/1", "text": "(6.1.8)(8.4.1)(6.1.9) Motor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/2", "text": "(6.1.8) Moottorin kumityyny", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/2", "text": "(6.1.8) Motordyna", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/3", "text": "(4.) Latausgeneraattori", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/3", "text": "(4.) Generator", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/4", "text": "(FIN.C15.2) Jäähdytin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/4", "text": "(FIN.C15.2) Kylar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/5", "text": "(4.) Käynnistysmoottori", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/5", "text": "(4.) Startmotor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/6", "text": "(FIN.C15.2) Jäähdytysneste", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/6/6", "text": "(FIN.C15.2) Kylarvätska", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/7/2", "text": "(7.3) Vaihdelukko", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/7/2", "text": "(7.3) Växellås", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/7/3", "text": "(7.3) Ajonestolaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/7/3", "text": "(7.3) Körspärr", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/8/0", "text": "(1.1.3)(1.1.7) Kompressori", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/8/0", "text": "(1.1.3)(1.1.7) Kompressor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/8/1", "text": "(1.1) Paineilmajärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/8/1", "text": "(1.1) Tryckluftsystem", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/0", "text": "(3.4)(3.5) Tuulilasin puhdistuslaitteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/0", "text": "(3.4)(3.5) Vindrutans rengöringsanordningar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/9", "text": "(6.2.7) Vindrutespolarens reglage", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/1", "text": "(3.2) Vindruta", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/2", "text": "(3.5) Pesulaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/2", "text": "(3.5) Spolare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/3", "text": "(3.4) Pyyhkimet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/3", "text": "(3.4) Torkare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/4", "text": "(3.4) Pyyhkijän sulka", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/4", "text": "(3.4) Torkarblad", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/5", "text": "(3.6) Huurteenpoisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/0/5", "text": "(3.6) Defroster", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/1/0", "text": "(3.2) Sivu- ja takalasit", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/1/0", "text": "(3.2) Sido- och bakrutor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/1/1", "text": "(3.2) Sivulasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/1/1", "text": "(3.2) Sidoruta", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/1/2", "text": "(3.2) Takalasi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/1/2", "text": "(3.2) Bakruta", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/1/3", "text": "(3.6) Takalasin huurteenpoisto", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/1/3", "text": "(3.6) Bakrutans defroster", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/2/0", "text": "(3.3) Peili", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/2/0", "text": "(3.3) Spegel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/2/1", "text": "(3.3) Ulkotaustapeili", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/2/1", "text": "(3.3) Utebackspegel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/2/2", "text": "(3.3) Ulkotaustapeilin kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/7/1", "text": "(7.8) Nopeusmittari", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/2/2", "text": "(3.3) Utebackspegelns fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/2/3", "text": "(3.3) Sisätaustapeili", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/2/3", "text": "(3.3) Innerbackspegel", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/3/0", "text": "(3.1) Näkökenttä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/3/0", "text": "(3.1) Synfält", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/4/0", "text": "(FIN) Koeajo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/4/0", "text": "(FIN) Provkörning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/4/1", "text": "(2.1) Ohjattavuus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/4/1", "text": "(2.1) Styrbarhet", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/4/2", "text": "(1.1) Jarrut", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/4/2", "text": "(1.1) Bromsar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/4/3", "text": "(1.1) Jarrupoljintuntuma", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/4/3", "text": "(1.1) Bromspedalkänsla", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/0", "text": "(6.1) Korin sisustus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/0", "text": "(6.1) Karosseriets inredning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/1", "text": "(6.2.5)(6.2.6) Istuin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/1", "text": "(6.2.5)(6.2.6) Säte", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/2", "text": "(6.2.5)(6.2.6) Istuimen kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/2", "text": "(6.2.5)(6.2.6) Sätets fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/3", "text": "(6.2.5)(6.2.6) Istuimen lukitus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/3", "text": "(6.2.5)(6.2.6) Sätets låsning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/4", "text": "(6.2.5)(6.2.6) Tilapäisistuin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/4", "text": "(6.2.5)(6.2.6) Tillfälligt säte", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/5", "text": "(6.2.5)(6.2.6) Lisäistuin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/5", "text": "(6.2.5)(6.2.6) Tilläggs säte", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/6", "text": "(FIN.E3) Turvakaari", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/5/6", "text": "(FIN.E3) Störtbåge", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/0", "text": "(6.2.7) Hallintalaitteet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/0", "text": "(6.2.7) Manöverorgan", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/1", "text": "(2.2) Ohjauspyörä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/1", "text": "(2.2) Ratt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/10", "text": "(4.4.2) Suuntavalojen käyttökytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/10", "text": "(4.4.2) Körriktningsljusens reglage", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/11", "text": "(4.1.3) Ajovalojen käyttökytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/11", "text": "(4.1.3) Körljusens reglage", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/12", "text": "(4.1.3) Valonvaihtokytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/12", "text": "(4.1.3) Körljusens omkopplare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/13", "text": "(4.9) Suuntavalojen merkkivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/13", "text": "(4.9) Körriktningsljusens kontrollampa", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/14", "text": "(4.9) Kaukovalojen merkkivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/14", "text": "(4.9) Fjärrljusets kontrollampa", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/15", "text": "(4.9) Takasumuvalon merkkivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/15", "text": "(4.9) Bakdimljusets kontrollampa", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/16", "text": "(4.9) Muiden valojen ja valaistujen kilpien merkkivalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/16", "text": "(4.9) Övriga belysningars och belysta skyltars kontrollampa", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/17", "text": "(1.1.4) Alhaisen paineen varoitusvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/17", "text": "(1.1.4) Varningslampa för lågt tryck", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/18", "text": "(1.1.4) Alhaisen paineen summeri", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/18", "text": "(1.1.4) Varningssummer för lågt tryck", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/19", "text": "(FIN.E8) Vaihteen valitsin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/19", "text": "(FIN.E8) Växelspak", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/2", "text": "(2.2.1) Ohjauspyörän kiinnitys", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/2", "text": "(2.2.1) Rattens fastsättning", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/20", "text": "(FIN.C14) Muu käyttökytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/20", "text": "(FIN.C14) Övrigt reglage", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/21", "text": "(6.2.7) Muu hallintalaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/21", "text": "(6.2.7) Övrigt manoverorg", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/22", "text": "(4.9) Aurausvalojen vaihtokytkin", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/22", "text": "(4.9) Plogningsljusens omkopplingsbrytare", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "8/6/23", "text": "(4.9) Seisontajarrun merkkivalo / varoituslaite", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/5", "text": "(6.2) TIR-Kulmanauha", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/5", "text": "(6.2) TIR-Hörnband", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/6", "text": "(6.2) TIR-Kilpi", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/6", "text": "(6.2) TIR-Skylt", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/7", "text": "(6.2) TIR-Vaijerin lenkki", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/7", "text": "(6.2) TIR-Vajerns länk", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/8", "text": "(6.2) TIR-Todistus", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/8", "text": "(6.2) TIR-Intyg", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/9", "text": "(6.2) Kuormakori", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "9/9/9", "text": "(6.2) Lastkorg", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/1", "text": "(4.6) Peruutusvalo", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/25", "text": "(6.2.10) Roiskeenestojärjestelmä", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "4/4/25", "text": "(6.2.10) Stänkskyddsanordningar", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/9/1", "text": "(FIN.E) Ajoavustimet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "7/9/1", "text": "(FIN.E) Körassistenter", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/3/5", "text": "(1.1.6) Seisontajarru", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "1/3/5", "text": "(1.1.6) Parkeringsbroms", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/16", "text": "(4.12) Muut valaisimet", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/16", "text": "(4.12) Övriga lampor", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/17", "text": "(4.12) Kulmavalaisin (cornering lamp)", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/17", "text": "(4.12) Kurvtagningslykta (cornering lamp)", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/18", "text": "(4.12) Ulkovalaisin (exterior courtesy lamp)", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/18", "text": "(4.12) Lyktor för exteriör omgivningsbelysning (exterior courtesy lamp)", "lng": "sv"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/19", "text": "(4.12) Apuvalaisin (manoeuvring lamp)", "lng": "fi"},
  {"code": "KdKatsaKomponentti", "codeValue": "2/8/19", "text": "(4.12) Manövreringslykta (manoeuvring lamp)", "lng": "sv"},
  {"code": "KdKatsaOhjauslaitteenKytkenta", "codeValue": "1", "text": "Pakko-ohjattu", "lng": "fi"},
  {"code": "KdKatsaOhjauslaitteenKytkenta", "codeValue": "2", "text": "Mekaaninen", "lng": "fi"},
  {"code": "KdKatsaOhjauslaitteenKytkenta", "codeValue": "3", "text": "Hydraulinen", "lng": "fi"},
  {"code": "KdKatsaOhjauslaitteenKytkenta", "codeValue": "4", "text": "Sähköinen", "lng": "fi"},
  {"code": "KdKatsaOhjauslaitteenKytkenta", "codeValue": "1", "text": "Tvångsstyrd", "lng": "sv"},
  {"code": "KdKatsaOhjauslaitteenKytkenta", "codeValue": "2", "text": "Mekanisk", "lng": "sv"},
  {"code": "KdKatsaOhjauslaitteenKytkenta", "codeValue": "3", "text": "Hydraulisk", "lng": "sv"},
  {"code": "KdKatsaOhjauslaitteenKytkenta", "codeValue": "4", "text": "Elektrisk", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "0", "text": "Ajoneuvon tunnistus", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "0", "text": "Fordonets identifiering", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "1", "text": "Jarrujärjestelmät", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "1", "text": "Bromslänksystemen", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "2", "text": "Valaisimet ja sähkölaitteet", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "2", "text": "Belysning och elektrisk utrustningar", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "3", "text": "Ympäristöhaitat", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "3", "text": "Miljöskador", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "4", "text": "Alusta ja kori", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "4", "text": "Chassi och karosseri", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "5", "text": "Akselistot, pyörät ja jousitus", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "5", "text": "Axlar, däck och fjädring", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "6", "text": "Ohjauslaitteet", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "6", "text": "Styrinrättning", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "7", "text": "Muut laitteet ja varusteet", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "7", "text": "Övriga anordningar", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "8", "text": "Koeajo ja näkyvyys", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "8", "text": "Provkörning och sikt", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "9", "text": "Lisätarkastukset", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "9", "text": "Övriga inspektioner", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "10", "text": "Kaasuajoneuvojen lisätarkastukset", "lng": "fi"},
  {"code": "KdKatsaPaakohde", "codeValue": "10", "text": "Extra kontroller för gasfordon", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "11", "text": "Extra kontroller för el- och hybridfordon", "lng": "sv"},
  {"code": "KdKatsaPaakohde", "codeValue": "11", "text": "Sähkö- ja hybridiajoneuvojen lisätarkastukset", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "01", "text": "Joutokäynti [%]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "02", "text": "Korotettu joutokäynti [%]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "03", "text": "[g/km]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "04", "text": "ETC [g/kWh]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "05", "text": "ESC [g/kWh]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "06", "text": "[mg/km]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "07", "text": "WHSC [mg/kWh]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "08", "text": "WHTC [mg/kWh]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "09", "text": "WLTP [mg/km]", "lng": "fi"},
  {"code": "KdKatsaPolttoainesailio", "codeValue": "1", "text": "Metall", "lng": "sv"},
  {"code": "KdKatsaPolttoainesailio", "codeValue": "2", "text": "Plast", "lng": "sv"},
  {"code": "KdKatsaPolttoainesailio", "codeValue": "1", "text": "Metalli", "lng": "fi"},
  {"code": "KdKatsaPolttoainesailio", "codeValue": "2", "text": "Muovi", "lng": "fi"},
  {"code": "KdKatsaRengaslaji", "codeValue": "1", "text": "typgodkänd", "lng": "sv"},
  {"code": "KdKatsaRengaslaji", "codeValue": "2", "text": "antecknad vid besiktning", "lng": "sv"},
  {"code": "KdKatsaRengaslaji", "codeValue": "1", "text": "tyyppihyväksytty", "lng": "fi"},
  {"code": "KdKatsaRengaslaji", "codeValue": "2", "text": "katsastuksessa merkitty", "lng": "fi"},
  {"code": "KdKatsaSivusuojanSijainti", "codeValue": "1", "text": "Till vänster", "lng": "sv"},
  {"code": "KdKatsaSivusuojanSijainti", "codeValue": "2", "text": "Till höger", "lng": "sv"},
  {"code": "KdKatsaSivusuojanSijainti", "codeValue": "1", "text": "Vasemmalla", "lng": "fi"},
  {"code": "KdKatsaSivusuojanSijainti", "codeValue": "2", "text": "Oikealla", "lng": "fi"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "1", "text": "Metall", "lng": "sv"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "2", "text": "Gummibelagd metall", "lng": "sv"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "3", "text": "Gummi", "lng": "sv"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "1", "text": "Metalli", "lng": "fi"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "2", "text": "Kumipäällysteinen metalli", "lng": "fi"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "3", "text": "Kumi", "lng": "fi"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "an", "text": "Aktivt nackstöd", "lng": "sv"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "et", "text": "Krockkudde fram", "lng": "sv"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "st", "text": "Krockkudde sida", "lng": "sv"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "vk", "text": "Bilbältessträckare", "lng": "sv"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "an", "text": "Aktiivinen niskatuki", "lng": "fi"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "et", "text": "Etuturvatyyny", "lng": "fi"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "st", "text": "Sivuturvatyyny", "lng": "fi"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "vk", "text": "Turvavyön kiristin", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "1", "text": "Obligatorisk", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "2", "text": "Installerad option", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "3", "text": "Annan meddelad", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "3", "text": "Muu ilmoitettu", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "1", "text": "Pakollinen", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "2", "text": "Asennettu valinnainen", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "1", "text": "Vänster", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "2", "text": "Mitt", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "3", "text": "Höger", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "1", "text": "Vasen", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "2", "text": "Keski", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "3", "text": "Oikea", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "AT", "text": "AT", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "EXII", "text": "EXII", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "EXIII", "text": "EXIII", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "FL", "text": "FL", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "MEMU", "text": "MEMU", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "OX", "text": "OX", "lng": "fi"},
  {"code": "KdKatsaVakavuus", "codeValue": "1", "text": "Korjauskehotus", "lng": "fi"},
  {"code": "KdKatsaVakavuus", "codeValue": "1", "text": "Reparationsuppmaning", "lng": "sv"},
  {"code": "KdKatsaVakavuus", "codeValue": "2", "text": "Hylätty", "lng": "fi"},
  {"code": "KdKatsaVakavuus", "codeValue": "2", "text": "Icke godkänd", "lng": "sv"},
  {"code": "KdKatsaVakavuus", "codeValue": "3", "text": "Ajokielto", "lng": "fi"},
  {"code": "KdKatsaVakavuus", "codeValue": "3", "text": "Körförbjud", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-1", "text": "1-akselilla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-1", "text": "På 1-axeln", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-14", "text": "1-akselilla vasemmalla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-14", "text": "På 1-axeln vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-141", "text": "1-akseli vas. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-141", "text": "På 1-axeln vänster bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-1456", "text": "1-akselilla vas. ja oik.", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-1456", "text": "På 1-axeln vänster och höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-147", "text": "1-akseli vas. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-147", "text": "På 1-axeln vänster främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-16", "text": "1-akselilla oikealla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-16", "text": "På 1-axeln höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-163", "text": "1-akseli oik. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-163", "text": "På 1-axeln höger bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-169", "text": "1-akseli oik. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-169", "text": "På 1-axeln höger främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-2", "text": "2-akselilla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-2", "text": "På 2-axeln", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-24", "text": "2-akselilla vasemmalla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-24", "text": "På 2-axeln vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-241", "text": "2-akseli vas. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-241", "text": "På 2-axeln vänster bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "33", "text": "Oikealla takana ulompi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-2456", "text": "På 2-axeln vänster och höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-247", "text": "2-akseli vas. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-247", "text": "På 2-axeln vänster främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-26", "text": "2-akselilla oikealla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-26", "text": "På 2-axeln höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-263", "text": "2-akseli oik. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-263", "text": "På 2-axeln höger bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-269", "text": "2-akseli oik. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-269", "text": "På 2-axeln höger främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-3", "text": "3-akselilla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-3", "text": "På 3-axeln", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-34", "text": "3-akselilla vasemmalla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-34", "text": "På 3-axeln vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-341", "text": "3-akseli vas. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-341", "text": "På 3-axeln vänster bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-3456", "text": "3-akseli vas. ja oik.", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-3456", "text": "På 3-axeln vänster och höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-347", "text": "3-akseli vas. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-347", "text": "På 3-axeln vänster främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-36", "text": "3-akselilla oikealla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-36", "text": "På 3-axeln höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-363", "text": "3-akseli oik. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-363", "text": "På 3-axeln höger bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-369", "text": "3-akseli oik. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-369", "text": "På 3-axeln höger främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-4", "text": "4-akselilla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-4", "text": "På 4-axeln", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-44", "text": "4-akselilla vasemmalla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-44", "text": "På 4-axeln vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-441", "text": "4-akseli vas. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-441", "text": "På 4-axeln vänster bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-4456", "text": "4-akseli vas. ja oik.", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-4456", "text": "På 4-axeln vänster och höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-447", "text": "4-akseli vas. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-447", "text": "På 4-axeln vänster främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-46", "text": "4-akselilla oikealla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-46", "text": "På 4-axeln höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-463", "text": "4-akseli oik. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-463", "text": "På 4-axeln höger bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-469", "text": "4-akseli oik. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-469", "text": "På 4-axeln höger främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-5", "text": "5-akselilla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-5", "text": "På 5-axeln", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-54", "text": "5-akselilla vasemmalla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-54", "text": "På 5-axeln vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-541", "text": "5-akseli vas. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-541", "text": "På 5-axeln vänster bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-5456", "text": "5-akseli vas. ja oik.", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-5456", "text": "På 5-axeln vänster och höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-547", "text": "5-akseli vas.  etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-547", "text": "På 5-axeln vänster främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-56", "text": "5-akselilla oikealla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-56", "text": "På 5-axeln höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-563", "text": "5-akseli oik. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-563", "text": "På 5-axeln höger bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-569", "text": "5-akseli oik. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-569", "text": "På 5-axeln höger främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "0", "text": "Ei mitään", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "0", "text": "Ingenting", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "00", "text": "Molemmat", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "00", "text": "Båda", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "1", "text": "Vasemmalla takana", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "1", "text": "Bak vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "11", "text": "Vasemmalla takana ulompi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "11", "text": "Bak vänster yttre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "12", "text": "Vasemmalla takana alempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "12", "text": "Bak vänster nedre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "123", "text": "Takana vas. ja oik.", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "123", "text": "Bak vänster och höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "13", "text": "Takana poikittainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "13", "text": "Bak tvärsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "139", "text": "V. ja o. taka ja oik etu", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "139", "text": "V. och h. bak och h. fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "1397", "text": "Kaikki", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "1397", "text": "Alla", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "147", "text": "Vas.  takana ja edessä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "147", "text": "Vänster bak och fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "15", "text": "Vasemmalla takana sisempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "15", "text": "Bak vänster inre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "159", "text": "Vas takana ja oik edessä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "159", "text": "Vänst. bak och höger fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "17", "text": "Vasemmalla pitkittäinen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "17", "text": "Vänster längsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "179", "text": "V. ja o. etu ja vas taka", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "179", "text": "V. och h. fram och v. bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "1793", "text": "Kaikki", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "1793", "text": "Alla", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "18", "text": "Vasemmalla takana ylempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "18", "text": "Vänster bak övre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "1888", "text": "Vas takan etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "1888", "text": "Vänster bak längst fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "2", "text": "Takana", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "2", "text": "Bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "22", "text": "Alempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "22", "text": "Nedre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "222", "text": "Takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "222", "text": "Längst bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "22588", "text": "Alhaalla ja ylhäällä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "22588", "text": "Upptill och nedtill", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "25", "text": "Takana sisempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "25", "text": "Bak inre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "255", "text": "Takana keskellä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "255", "text": "Bak i mitten", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "258", "text": "Takana ja edessä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "258", "text": "Bak och fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "28", "text": "Pitkittäinen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "28", "text": "Längsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "3", "text": "Oikealla takana", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "3", "text": "Höger bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "31", "text": "Takana poikittainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "31", "text": "Höger tvärsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "317", "text": "V. ja o. taka ja vas etu", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "317", "text": "V. och h. bak och v. fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "3179", "text": "Kaikki", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "3179", "text": "Alla", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "32", "text": "Oikealla takana alempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "32", "text": "Höger bak nedre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "321", "text": "Takana oik. ja vas.", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "321", "text": "Bak höger och vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "92", "text": "Höger fram nedre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "33", "text": "Höger bak yttre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "35", "text": "Oikealla takana sisempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "35", "text": "Höger bak inre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "357", "text": "Oik takana ja vas edessä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "357", "text": "Höger bak och vänst. fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "369", "text": "Oik. takana  ja edessä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "369", "text": "Höger bak och fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "38", "text": "Oikealla takana ylempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "38", "text": "Höger bak övre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "3888", "text": "Oik. takana etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "3888", "text": "Höger bak längst fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "39", "text": "Oikealla pitkittäinen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "39", "text": "Höger längsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "397", "text": "V. ja o. etu ja oik taka", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "397", "text": "V. och h. fram och h. bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "3971", "text": "Kaikki", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "3971", "text": "Alla", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "4", "text": "Vasemmalla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "4", "text": "Vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "41", "text": "Vasemmalla takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "41", "text": "Vänster bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "42", "text": "Vasemmalla alempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "42", "text": "Vänster nedre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "44", "text": "Vasemmalla ulompi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "44", "text": "Vänster yttre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "45", "text": "Vasemmalla sisempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "45", "text": "Vänster inre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "456", "text": "Vasemmalla ja oikealla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "456", "text": "Vänster och höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "4562", "text": "Vas. ja oik. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "4562", "text": "Vänster och höger bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "4568", "text": "Vas. ja oik. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "4568", "text": "Vänster och höger främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "46", "text": "Poikittainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "46", "text": "Tvärsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "47", "text": "Vasemmalla etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "47", "text": "Vänster främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "48", "text": "Vasemmalla ylempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "48", "text": "Vänster övre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "48542", "text": "Vas. etumm. ja takimm.", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "48542", "text": "Vänstra främre och bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "5", "text": "Sisempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "5", "text": "Inre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "55", "text": "Keskellä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "55", "text": "I mitten", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "554", "text": "Keskellä vasen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "554", "text": "I mitten vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "556", "text": "Keskellä oikea", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "556", "text": "I mitten höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "6", "text": "Oikealla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "6", "text": "Höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "62", "text": "Oikealla alempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "62", "text": "Höger nedre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "63", "text": "Oikealla takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "63", "text": "Höger bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "64", "text": "Poikittainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "64", "text": "Tvärsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "65", "text": "Oikealla sisempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "65", "text": "Höger inre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "654", "text": "Oikealla ja vasemmalla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "654", "text": "Höger och vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "6542", "text": "Oik. ja vas. takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "6542", "text": "Höger och vänster bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "6548", "text": "Oik. ja vas. etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "6548", "text": "H. och v. främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "66", "text": "Oikealla ulompi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "66", "text": "Höger yttre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "68", "text": "Oikealla ylempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "68", "text": "Höger övre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "68562", "text": "Oik. etumm. ja takimm.", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "68562", "text": "Högra främre och bakre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "69", "text": "Oikealla etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "69", "text": "Höger främre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "7", "text": "Vasemmalla edessä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "7", "text": "Vänster fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "71", "text": "Vasemmalla pitkittäinen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "71", "text": "Vänster längsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "713", "text": "V. ja o. taka ja vas etu", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "713", "text": "V. och h. bak och v. fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "7139", "text": "Kaikki", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "7139", "text": "Alla", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "72", "text": "Vasemmalla edessä alempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "72", "text": "Vänster fram nedre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "7222", "text": "Vas edessä takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "7222", "text": "Vänster fram längst bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "741", "text": "Vas. edessä ja takana", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "741", "text": "Vänster fram och bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "75", "text": "Vasemmalla edessä sisempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "75", "text": "Vänster fram inre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "753", "text": "Vas edessä ja oik takana", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "753", "text": "Vänst. fram och höger bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "77", "text": "Vasemmalla edessä ulompi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "77", "text": "Vänster fram yttre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "78", "text": "Vasemmalla edessä ylempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "78", "text": "Vänster fram övre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "789", "text": "Edessä vas. ja oik.", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "789", "text": "Fram vänster och höger", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "79", "text": "Edessä poikittainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "79", "text": "Fram tvärsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "7913", "text": "Kaikki", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "7913", "text": "Alla", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "793", "text": "V. ja o. etu ja oik taka", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "793", "text": "V. och h. fram och h. bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "7931", "text": "Kaikki", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "7931", "text": "Alla", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "8", "text": "Edessä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "8", "text": "Framtill", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "82", "text": "Pitkittäinen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "82", "text": "Längsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "85", "text": "Edessä sisempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "85", "text": "Fram inre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "852", "text": "Edessä ja takana", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "852", "text": "Fram och bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "855", "text": "Edessä keskellä", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "855", "text": "Fram i mitten", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "88", "text": "Ylempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "88", "text": "Övre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "88522", "text": "Ylhäällä ja alhaalla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "88522", "text": "Upptill och nedtill", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "888", "text": "Etummainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "888", "text": "Längst fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "9", "text": "Edessä oikealla", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "9", "text": "Höger fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "92", "text": "Oikealla edessä alempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "9222", "text": "Oik edessä takimmainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "9222", "text": "Höger fram längst bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "93", "text": "Oikealla pitkittäinen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "93", "text": "Höger längsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "931", "text": "V. ja o. taka ja oik etu", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "931", "text": "V. och h. bak och h. fram", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "9317", "text": "Kaikki", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "9317", "text": "Alla", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "95", "text": "Oikealla edessä  sisempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "95", "text": "Höger fram inre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "951", "text": "Oik edessä ja vas takana", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "951", "text": "Höger fram och vänst. bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "963", "text": "Oik. edessä ja takana", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "963", "text": "Höger  fram och bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "97", "text": "Edessä poikittainen", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "97", "text": "Fram tvärsgående", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "971", "text": "V. ja o. etu ja vas taka", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "971", "text": "V. och h. fram och v. bak", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "9713", "text": "Kaikki", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "9713", "text": "Alla", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "98", "text": "Oikealla edessä ylempi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "98", "text": "Höger fram övre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "987", "text": "Edessä oik. ja vas.", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "987", "text": "Fram höger och vänster", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "99", "text": "Oikealla edessä ulompi", "lng": "fi"},
  {"code": "KdKatsaVianSijainti", "codeValue": "99", "text": "Höger fram yttre", "lng": "sv"},
  {"code": "KdKatsaVianSijainti", "codeValue": "-2456", "text": "2-akselilla vas. ja oik.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "0", "text": "Ei mitään", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "0", "text": "Ingenting", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "1", "text": "Uusittava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "1", "text": "Bör förnyas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "10", "text": "Pestävä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "10", "text": "Bör tvättas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "100", "text": "Irti", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "100", "text": "Lös", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "101", "text": "Kiinnitys välyksellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "101", "text": "Fastsättningen glapp", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "102", "text": "Kiinnitys virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "102", "text": "Fastsättningen felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "103", "text": "Kiinnitys puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "103", "text": "Fastsättningen bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "104", "text": "Varmistussokka puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "104", "text": "Säkerhetssprinten fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "105", "text": "Kiinnitysraudat poistettava tai puskuri asennettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "115", "text": "Kulunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "105", "text": "Fästjärnen bör borttagas eller stötfångaren monteras", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "106", "text": "Saranointi löystynyt", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "106", "text": "Gångjärnet glappt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "107", "text": "Saranointi irti", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "107", "text": "Gångjärnet löst", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "108", "text": "Puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "108", "text": "Fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "109", "text": "Puuttuu matkustajan puolelta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "109", "text": "Fattas på passagerarens sida", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "11", "text": "Korjattava hitsaamalla", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "11", "text": "Bör repareras genom svetsning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "110", "text": "Pääntuki puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "110", "text": "Huvudstödet fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "111", "text": "Ensiapupakkaus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "111", "text": "Förstahjälpväskan fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "112", "text": "Hinausköysi puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "112", "text": "Bogserlinan fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "113", "text": "Tulensammutin puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "113", "text": "Brandsläckaren fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "114", "text": "Suojus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "114", "text": "Skyddet fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "115", "text": "Sliten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "116", "text": "Suojattava ruostevaurioiden korjauksen jälkeen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "116", "text": "Bör skyddas efter rostskadornas repartion", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "117", "text": "Murtumia", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "117", "text": "Brott", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "118", "text": "Murtuma", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "118", "text": "Brott", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "119", "text": "Halkeama näkökentässä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "119", "text": "Spricka i synfältet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "12", "text": "Mittauspöytäkirja esitettävä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "12", "text": "Mätningsprotokollet bör uppvisas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "120", "text": "Kiveniskemä näkökentässä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "120", "text": "Stenskott i synfältet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "121", "text": "Halkeama", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "121", "text": "Spricka", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "122", "text": "Nastoitettu/Nastoittamaton (sekarengastus)", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "122", "text": "Dubbad/odubbad (blandad däcktyp )", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "123", "text": "Sekarengastus", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "123", "text": "Blandad däcktyp", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "124", "text": "Rengas/vanneyhdistelmä ei yhteensopiva", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "124", "text": "Däck/fälgkombinationen inte passande", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "125", "text": "Vanne epäsopiva", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "125", "text": "Fälgen opassande", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "126", "text": "Urasyvyys liian pieni", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "126", "text": "Mönsterdjupet för grunt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "127", "text": "Renkaan kantavuus ei riitä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "127", "text": "Däckets bärförmåga otillräcklig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "128", "text": "Rengaskoko muuttunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "128", "text": "Däckdimensionen ändrats", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "129", "text": "Nastaero liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "129", "text": "Dubbskillnaden för stor", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "13", "text": "Asennettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "13", "text": "Bör monteras", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "130", "text": "Muutoskatsastus suoritettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "182", "text": "Pistorasian kansi puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "131", "text": "Erikokoiset samalla akselilla", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "131", "text": "Olika storlekar på samma axel", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "132", "text": "Vaimennuskyky riittämätön", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "132", "text": "Otillräklig dämpverkan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "133", "text": "Epämääräinen tai asiaankuulumaton ääni", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "133", "text": "Obestämt eller obehörigt ljud", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "134", "text": "Tarkasta pyöränkulmat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "134", "text": "Kontrollera hjulvinklarna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "135", "text": "Pyörän pyörimistila riittämätön", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "135", "text": "Hjulets fria utrymme otillräckligt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "136", "text": "Pyörä hankaa toista osaa vasten", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "136", "text": "Hjulet skavar mot andra delar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "137", "text": "Joustovara liian pieni", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "137", "text": "Fjädringsmån för liten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "138", "text": "Murtunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "138", "text": "Sprucken", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "139", "text": "Katkennut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "139", "text": "Brusten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "14", "text": "Peilikalvo poistettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "14", "text": "Spegelfolien bör borttagas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "140", "text": "Hankautunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "140", "text": "Sliten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "141", "text": "Kuoleentunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "141", "text": "Uttröttad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "142", "text": "Pakokaasumittausta ei suoritettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "142", "text": "Avgasmätningen inte utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "143", "text": "Pakokaasumittausta ei suoritettu. Joutokäyntikierrosluku virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "143", "text": "Avgasmätningen inte utförd. Felaktigt tomgångsvarvtal", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "144", "text": "Säätökierrosluku virheellinen.Savumittausta ei suoritettu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "144", "text": "Utsläppsmätningen inte utförd.Varvtalet felaktigt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "145", "text": "Moottorin kunto puutteellinen. Savumittausta ei suoritettu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "145", "text": "Utsläppsmätningen inte utförd. Motorns kondition bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "146", "text": "Kierrosluku riittämätön. Savumittausta ei suoritettu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "146", "text": "Utsläppsmätningen inte utförd. För lågt varvtal", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "147", "text": "Pakokaasumittausta ei suoritettu. Mittaria ei käytettävissä.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "147", "text": "Avgasmätningen inte utförd. Mätare inte till förfogande", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "148", "text": "Runsas happipitoisuus vääristää päästöarvoja.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "148", "text": "Riklig syrehalt förvränger utsläppsvärden", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "149", "text": "Pakokaasumittaustodistusta ei voitu hyväksyä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "149", "text": "Avgasmätningsprotokollet kan inte godkännas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "15", "text": "Löysässä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "15", "text": "Lös", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "150", "text": "Tarkastusmittauksessa saatiin erilaiset arvot kuin todistuksessa.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "150", "text": "Vid kontrollmätning erhölls avikande resultat", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "151", "text": "Savumittaustodistusta ei voitu hyväksyä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "151", "text": "Utsläppsmätningsprotokollet kan inte godkännas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "152", "text": "Kaikki mitatut arvot poikkeavat sallitusta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "152", "text": "Alla uppmätta värden aviker från de tillåtna värdena", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "153", "text": "CO joutokäynnillä liian korkea", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "153", "text": "CO halten på tomgång för hög", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "154", "text": "HC joutokäynnillä liian korkea", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "154", "text": "HC halten på tomgång för hög", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "155", "text": "CO korotetulla pyörimisnopeudella liian korkea", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "155", "text": "CO halten på förhöjt varvtal för hög", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "156", "text": "HC korotetulla pyörimisnopeudella liian korkea", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "156", "text": "HC halten på förhöjt varvtal för hög", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "157", "text": "Lambda poikkeaa sallituista arvoista korotetulla pyörimisnopeudella", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "157", "text": "Lambdans värde inte inom toleranserna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "158", "text": "CO ja HC joutokäynnillä liian korkeat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "158", "text": "CO- och HC-halterna för höga", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "159", "text": "CO ja HC korotetulla pyörimisnopeudella liian korkeat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "159", "text": "CO- och HC-halterna på förhöjt varvtal för höga", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "16", "text": "Valmistenumero puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "16", "text": "Tillverkningsnummern fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "160", "text": "Lambda arvoa ei mitattu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "160", "text": "Lambdans värde inte granskad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "161", "text": "CO ja HC ylittävät sallitut arvot", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "161", "text": "CO och HC-halterna överskrider de tillåtna värdena", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "162", "text": "Savutus liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "162", "text": "För kraftig rökutveckling", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "163", "text": "Mittaria ei käytettävissä. Savumittausta ei suoritettu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "163", "text": "Utsläppsmätningen inte utförd. Mätare inte tillgänglig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "164", "text": "Joutokäyntikierrosluku virheellinen, savumittausta ei voi hyväksyä.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "164", "text": "Utsläppsmätningen inte utförd.Tomgångsvarvtalet felaktigt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "165", "text": "Käyntilämpötila liian alhainen. Savumittausta ei suoritettu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "165", "text": "Utsläppsmätningen inte utförd.Brukstemperaturen för låg", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "166", "text": "Pakoputkisto vuotaa. Savumittausta ei suoritettu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "166", "text": "Utsläppsmätningen inte utförd.Avgasröret läcker", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "167", "text": "Suuntaus määräysten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "167", "text": "Riktningen mot bestämmelserna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "168", "text": "Maavara liian pieni", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "168", "text": "För liten frigång", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "169", "text": "Pakoputken pään ulkohalkaisija liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "169", "text": "Avgasrörets yttre diameter för stor", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "17", "text": "Vaurioitunut tai vaikeasti luettavissa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "17", "text": "Skadad eller svårt avläsbar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "170", "text": "Äänenvoimakkuus riittämätön", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "170", "text": "Otillräcklig ljudstyrka", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "171", "text": "Moottorin voi käynnistää vaihteen ollessa päällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "171", "text": "Startar med växeln i kör-läge", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "172", "text": "Oikosulun mahdollisuus", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "172", "text": "Fara för kortslutning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "173", "text": "Vaurioitumisriski", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "173", "text": "Fara för skada", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "174", "text": "Palovaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "174", "text": "Brandfara", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "175", "text": "Jarrujen häipymisriski", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "175", "text": "Risk för att bromsarna upphör att fungera", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "176", "text": "Irtoamisvaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "176", "text": "Risk för haveri", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "177", "text": "Onnettomuusvaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "177", "text": "Olycksfara", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "178", "text": "Toiminnan pettämisvaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "178", "text": "Fara för funktionsstörning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "179", "text": "Irtoamisvaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "179", "text": "Risk för haveri", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "18", "text": "Väärä tai viallinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "18", "text": "Sned eller felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "180", "text": "Perävaunua ei saa kytkeä.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "180", "text": "Släpvagn får inte kopplas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "181", "text": "Johtojen kytkentä epäasiallinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "181", "text": "Ledningarnas koppling osaklig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "239", "text": "Puhdistusteho riittämätön", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "182", "text": "Kopplingsdosans lock fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "183", "text": "Vuotaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "183", "text": "Läckage", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "184", "text": "Vuotaa (vaara, pakokaasut voivat tulla sisälle)", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "184", "text": "Läckage (fara,gaserna kan komma in i bilen )", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "185", "text": "Öljyvuoto", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "185", "text": "Oljeläckage", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "186", "text": "Öljyvuoto, palovaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "186", "text": "Oljeläckage,brandfara", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "187", "text": "Vuotaa polttonestettä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "187", "text": "Bränsleläckage", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "188", "text": "Polttoainejärjestelmä vuotaa moottoritilassa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "188", "text": "Bränslesystemet läcker i motorrummet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "189", "text": "Bensiinivuoto, palovaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "189", "text": "Bensinläckage, brandfara", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "19", "text": "Vaaditaan lisäselvitystä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "19", "text": "Tilläggsuppgifter fordras", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "190", "text": "Vuotaa dieselöljyä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "190", "text": "Naftaläckage", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "191", "text": "Vuotaa moottoritilassa, palovaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "191", "text": "Läckage i motorutrymmet,brandfara", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "192", "text": "Puoltaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "192", "text": "Drar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "193", "text": "Jarruvaikutus riittämätön", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "193", "text": "Otillräcklig bromseffekt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "194", "text": "Jarruissa heittoa (esim. soikea rumpu/kiero levy)", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "194", "text": "Kast i bromsarna (tex oval bromstrumma,skev bromsskiva )", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "195", "text": "Jarruvoimien ero liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "195", "text": "För stor avvikelse på bromskrafterna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "196", "text": "Jarruvoima alle 16% kokonaismassasta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "196", "text": "Bromskraften under 16% av totalmassan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "197", "text": "Käyttölaitteen liikematka yli 3/4 kokonaisliikevarasta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "197", "text": "Rörelsemån över 3/4 av totala rörelsemån", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "198", "text": "Käyttövoima ylittää 400N käsikäyttöisenä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "198", "text": "Handreglagets kraft överskrider 400N", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "199", "text": "Käyttövoima ylittää 500N jalkakäyttöisenä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "199", "text": "Bromspedalens kraft överskrider 500N", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "2", "text": "Korjattava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "2", "text": "Bör repareras", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "20", "text": "Henkilöllisyyttä ei voitu todeta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "20", "text": "Identifiteten kunde inte säkerställas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "200", "text": "Vaijerin katkeamisvaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "200", "text": "Fara för vajerbrott", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "201", "text": "Takapainoiset", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "201", "text": "Baktunga", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "202", "text": "Puoltaa tiellä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "202", "text": "Drar vid körning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "203", "text": "Puoltaa tiellä vasemmalle", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "203", "text": "Drar till vänster vid körning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "204", "text": "Puoltaa tiellä oikealle", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "204", "text": "Drar till höger vid körning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "205", "text": "Täristää ajettaessa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "205", "text": "Vibrerar vid körning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "206", "text": "Nestepinta liian alhainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "206", "text": "Vätskenivån för låg", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "207", "text": "Nestesäiliö tyhjä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "207", "text": "Vätskebehållaren tom", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "208", "text": "Laahaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "208", "text": "Ligger på", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "209", "text": "Laahaa hieman", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "209", "text": "Ligger på litet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "21", "text": "Ei vastaa rekisteritietoja", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "21", "text": "Motsvarar ej registeruppgifterna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "210", "text": "Laahaa, dynamometrimittausta ei suoritettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "210", "text": "Ligger på, dynamometertest inte utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "211", "text": "Laahaa, tarkasta myös seisontajarru", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "211", "text": "Ligger på, granska också parkeringsbromsen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "212", "text": "Laahaa, tarkasta myös käyttöjarru", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "212", "text": "Ligger på, granska också färdbromsen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "213", "text": "Irtoamisvaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "213", "text": "Risk för haveri", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "214", "text": "Etuaks. ja jousitus tarkastetaan jälkitark. yht. tapaturmavaarasta. joht", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "214", "text": "Framaxeln och fjädringen granskas i samband med eftergranskningen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "215", "text": "Vetokoukun kuula rekisterikilven edessä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "215", "text": "Dragkroken skymmer registerskylten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "216", "text": "Käsiteltävyys heikko", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "216", "text": "Svårhanterlig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "217", "text": "Jäykkätoiminen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "217", "text": "Styv", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "218", "text": "Palauttaa huonosti", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "218", "text": "Returnerar dåligt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "219", "text": "Ei palaudu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "219", "text": "Centrerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "22", "text": "Valmistenumeroa ei löytynyt", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "22", "text": "Tillverkningsnummern hittades ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "220", "text": "Toiminta takertelevaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "220", "text": "Funktionen kärvar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "221", "text": "Puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "221", "text": "Bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "222", "text": "Vaikea saada vaihteita kytketyksi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "222", "text": "Svårt att få växlarna kopplade", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "223", "text": "Kytkin luistaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "223", "text": "Kopplingen slirar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "224", "text": "Kytkin ei irroita kunnolla", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "233", "text": "Lukitus epävarma", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "224", "text": "Kopplingen frigör inte ordentligt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "225", "text": "I-vaihde ei pysy päällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "225", "text": "I-ans växel hålls ej på", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "226", "text": "II-vaihde ei pysy päällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "226", "text": "II-ans växel hålls ej på", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "227", "text": "III-vaihde ei pysy päällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "227", "text": "III-ans växel hålls ej på", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "228", "text": "IV-vaihde ei pysy päällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "228", "text": "IV-ans växel hålls ej på", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "229", "text": "Kiero, ajettavuus heikentynyt", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "229", "text": "Skev, körbarheten försämrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "23", "text": "Rekisteritiedot eivät vastaa ajoneuvoa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "23", "text": "Registeruppgifterna motsvarar inte fordonet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "230", "text": "Ei voi avata", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "230", "text": "Kan ej öppnas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "231", "text": "Teräviä reunoja", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "231", "text": "Vassa kanter", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "232", "text": "Leveys renkaaseen nähden riittämätön", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "232", "text": "Bredd otillräcklig i förhållande till däcken", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "233", "text": "Låsningen osäker", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "234", "text": "Lukitus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "234", "text": "Låsningen fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "235", "text": "Ulostyöntyviä osia", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "235", "text": "Utstående delar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "236", "text": "Poistettu, ulostyöntyviä osia", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "236", "text": "Borttagen, utstående delar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "237", "text": "Ei hyväksytty laite", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "237", "text": "Ej godkänd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "238", "text": "Pyyhinnopeus liian pieni", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "238", "text": "Torkningshastigheten för låg", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "293", "text": "Skadade", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "239", "text": "Rengöringseffekten otillräcklig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "24", "text": "Muutettu, ajettavuus huonontunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "24", "text": "Ändrar, körbarheten försämrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "240", "text": "Pyyhintäsektori vajaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "240", "text": "Torksektorn för liten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "241", "text": "Lepoasento näkökentässä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "241", "text": "Vid viloläge i synfältet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "242", "text": "Vilkkumistaajuus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "242", "text": "Blinkhastigheten felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "243", "text": "Virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "243", "text": "Felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "244", "text": "Liian alas", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "244", "text": "För lågt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "245", "text": "Korkeudensäätö ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "245", "text": "Höjdjusteringen fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "246", "text": "Virheellinen, eikä korkeudensäätö toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "246", "text": "Felaktig, höjdjusteringen fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "247", "text": "Kosteutta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "247", "text": "Fukt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "248", "text": "Kiveniskemiä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "248", "text": "Stenskott", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "249", "text": "Samentunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "249", "text": "Grumlig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "25", "text": "Muutettu, toimivuus huonontunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "25", "text": "Ändrad, funktionen försämrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "250", "text": "Valkoista valoa näkyy taaksepäin", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "250", "text": "Vitt ljus syns bakåt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "251", "text": "Suuntaus liian ylös", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "251", "text": "Riktat för högt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "252", "text": "Säätö liian ylös", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "252", "text": "Justeringen för högt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "253", "text": "Lähivalokuvio virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "253", "text": "Närbelysningens ljusbild felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "254", "text": "Sivusuuntaus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "254", "text": "Sidoriktningen felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "255", "text": "Valokuvio epäselvä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "255", "text": "Ljusbilden oklar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "256", "text": "Valokuvio virheellinen ja epäselvä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "256", "text": "Ljusbilden felaktig och oklar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "257", "text": "Valovoima heikko", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "257", "text": "Svag ljuseffekt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "258", "text": "Ylittää sallitun valovoiman (referenssi)", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "258", "text": "Överskrider tillåten ljuseffekt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "259", "text": "Valaisimet eri paria väriltään, kooltaan ja valovoimaltaan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "259", "text": "Lyktorna skilda par av färg, storlek eller ljuseffekt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "26", "text": "Muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "26", "text": "Ändrat", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "260", "text": "Tummennus määräysten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "260", "text": "Förmörkningen mot förordningen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "261", "text": "Materiaali määräysten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "261", "text": "Materialet mot förordningen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "262", "text": "Peilikalvo poistettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "262", "text": "Spegelfolien bör avlägsnas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "263", "text": "Riipuke poistettava näkökentästä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "263", "text": "Maskoten bör avlägsnas från synfältet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "264", "text": "Ei syty välittömästi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "264", "text": "Tänds inte omedelbart", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "265", "text": "Kytkentä virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "265", "text": "Felaktig koppling", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "266", "text": "Sijoitus ja kytkentä virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "266", "text": "Placering och koppling felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "267", "text": "Palaa ajovalojen ollessa kytkettynä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "267", "text": "Fungerar då körljusen är kopplade", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "268", "text": "Palaa muulloinkin kuin peruutusvaihteen ollessa kytkettynä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "268", "text": "Lyser också då backväxeln inte är kopplad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "269", "text": "Ei hyväksyttyä tyyppiä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "269", "text": "Inte av godkänd modell", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "130", "text": "Bör ändringsbesiktigas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "27", "text": "Istumapaikat eivät vastaa rekisteritietojen henkilömäärää", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "27", "text": "Modellen motsvarar inte registeruppgifterna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "270", "text": "Ei sallittu -80 tai sen jälkeen käyttöönotetuissa autoissa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "270", "text": "Inte godkänd för år -80 eller senare ibruktagna fordon", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "271", "text": "Näyttää väärin", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "271", "text": "Visar fel", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "272", "text": "Merkkivalon mukaan ei edellytyksiä toimia", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "272", "text": "Enligt varningslampan ingen funktion", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "273", "text": "Jäännösliikevara vähemmän kuin 1/4 kokonaisliikevarasta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "273", "text": "Resterande rörelsemån under 1/4 av totalrörelsen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "274", "text": "Poljin painuu pohjaan, ei jarruvaikutusta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "274", "text": "Pedalen sjunker i botten, ingen bromsverkan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "275", "text": "Poljin painuu pohjaan, mutta jarruvaikutus saadaan aikaan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "275", "text": "Pedalen sjunker i botten, bromsverkan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "276", "text": "Poljinvoima liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "276", "text": "För stor pedalkraft", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "286", "text": "Muuttanut muotoaan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "277", "text": "Liian lyhyt (paikaltaan menon riski)", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "277", "text": "För kort (med risk för att komma ur sitt läge)", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "278", "text": "Liian pitkä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "278", "text": "För lång", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "279", "text": "Halkaisija liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "279", "text": "Diametern för stor", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "28", "text": "Turvakaari ei ole määräysten mukainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "28", "text": "Säkerhetsbågen motsvarar inte förordningarna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "280", "text": "Halkaisija liian pieni", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "280", "text": "Diametern för liten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "281", "text": "Kehä liian lähellä muita hallintalaitteita", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "281", "text": "Ratten för nära de andra manöverorganen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "282", "text": "Liukastussuoja puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "282", "text": "Halkskyddet fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "283", "text": "Ei pysy päällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "283", "text": "Hålls ej på", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "284", "text": "Vajoaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "284", "text": "Sjunker", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "285", "text": "Pehmeä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "285", "text": "Mjuk", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "286", "text": "Har ändrat sin form", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "287", "text": "Lukitus ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "287", "text": "Låsningen fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "288", "text": "Lukitus epävarma", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "288", "text": "Låsningen osäker", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "289", "text": "Tyyppikilpi puuttuu, 01.01.1995 tai sen jälkeen käyttöönotetusta ajoneuv", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "289", "text": "Typskylten fattas,obligatorisk för 01.10.19995 eller senare registrerad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "29", "text": "Poistettava, laite määräysten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "29", "text": "Bör avlägsnas, regelstridig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "290", "text": "Ei hyväksyttävissä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "290", "text": "Inte godkännbar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "291", "text": "Ei hyväksyttyä mallia", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "291", "text": "Inte godkänd modell", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "292", "text": "Tehostaa vasemmalle ja oikealle eri voimilla", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "292", "text": "Effektiverar till höger och vänster med olik stor effekt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "293", "text": "Vaurioituneet", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "356", "text": "Taipunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "294", "text": "Kuluneet", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "294", "text": "Slitna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "295", "text": "Väärä väri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "295", "text": "Fel färg", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "296", "text": "Valovoima virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "296", "text": "Ljuseffekten felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "297", "text": "Varuste lauennut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "297", "text": "Utrustningen utlöst", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "298", "text": "Sininen savu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "298", "text": "Blå rök", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "299", "text": "Kulunut moottori", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "299", "text": "Sliten motor", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "3", "text": "Vaihdettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "3", "text": "Bör utbytas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "30", "text": "Rekisteröintitodistus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "30", "text": "Registreringsbevis fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "300", "text": "Varuste poistettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "300", "text": "Utrustningen avlägsnad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "301", "text": "Järjestelmä poistettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "301", "text": "Systemet fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "302", "text": "Ajonestolaite puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "302", "text": "Startblockeraren fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "303", "text": "Ajonestolaitteen toiminta virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "303", "text": "Startblockerarens funktion felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "304", "text": "Tarkastustodistus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "304", "text": "Granskningsprotokollet fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "305", "text": "Tarkastustodistus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "305", "text": "Granskningsprotokollet felaktigt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "306", "text": "Tarkastustodistus vanhentunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "306", "text": "Granskningsprotokollet föråldrat", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "307", "text": "Sisävalaistus", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "307", "text": "Innerbelysning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "308", "text": "Lämmitysjärjestelmä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "308", "text": "Uppvärmningsanordning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "309", "text": "Tehoton", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "309", "text": "Ineffektiv", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "31", "text": "Vakuutusmaksuja maksamatta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "31", "text": "Försäkringspremien obetald", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "310", "text": "Työkaluvarustus puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "310", "text": "Arbetsredskapen otillräckliga", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "311", "text": "Informaatiotarra puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "311", "text": "Info-etiketten fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "312", "text": "Putkisto vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "312", "text": "Rörnätet skadat", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "313", "text": "Ei sallittu tässä ajoneuvoluokassa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "313", "text": "Inte tillåtet i denna klass", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "314", "text": "Poikkeuslupa, asiakirja puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "314", "text": "Undantagslov, fordonshandlingar fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "315", "text": "Hidastin ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "315", "text": "Retardern ur funktion", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "316", "text": "Pakokaasujarru ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "316", "text": "Avgasbromsen ur funktion", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "317", "text": "Hidastin puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "317", "text": "Retardern fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "318", "text": "Pakokaasujarru puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "318", "text": "Avgasbromsen fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "319", "text": "Mittaus suorittamatta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "319", "text": "Mätningen inte utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "32", "text": "Käyttövoimaveroa maksamatta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "32", "text": "Dieselskatten obetald", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "320", "text": "Todistus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "320", "text": "Intyget felaktigt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "321", "text": "Moottori ei käy joutokäyntiä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "321", "text": "Motorn går inte på tomgång", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "322", "text": "Pakoputkisto vuotaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "322", "text": "Avgasröret läcker", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "323", "text": "Joutokäyntinopeus liian korkea, mittaustulosta ei voi hyväksyä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "323", "text": "Tomgången för hög", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "324", "text": "Jarruvoimien ero liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "324", "text": "För stor avvikelse på bromskrafterna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "325", "text": "Syöpynyt", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "325", "text": "Frätt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "326", "text": "Rajoittaa paineen liian alas", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "326", "text": "Reglerar trycket för lågt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "327", "text": "Sallii liian suuren paineen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "327", "text": "Tillåter för stort tryck", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "328", "text": "Likaantuneet", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "328", "text": "Smutsig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "329", "text": "Virheellisesti säädetty", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "329", "text": "Felaktigt justerad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "33", "text": "Ajoneuvovero maksamatta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "33", "text": "Fordonsskatten obetald", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "330", "text": "Soikea", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "330", "text": "Skev", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "331", "text": "Kiero", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "331", "text": "Skev", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "332", "text": "Liian lyhyt", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "332", "text": "För kort", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "333", "text": "Pituus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "333", "text": "Felaktig längd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "334", "text": "Liian alhainen, tyhjä ajoneuvo", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "334", "text": "För lågt, tomt fordon", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "335", "text": "Liian korkea, tyhjä ajoneuvo", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "335", "text": "För högt, tomt fordon", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "336", "text": "Todistus säädöstenmukaisuudesta puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "336", "text": "Intyg över uppfyllande av bestämmelser saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "337", "text": "Sinistä savua", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "337", "text": "Blå rök", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "338", "text": "Laita rikki", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "338", "text": "Sidogavel sönder", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "339", "text": "Pohja rikki", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "339", "text": "Bottnen sönder", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "34", "text": "Erikoisehdot eivät vastaa ajoneuvoa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "34", "text": "De särskillda villkoren motsvarar inte fordonet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "340", "text": "Repeämiä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "340", "text": "Bristningar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "341", "text": "Revennyt", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "341", "text": "Uppriven", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "342", "text": "Paineeton", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "342", "text": "Trycklös", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "343", "text": "Pykinyt", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "343", "text": "Sprucken", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "344", "text": "Tappi välyksellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "344", "text": "Tappen glapp", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "345", "text": "Liukupää kulunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "345", "text": "Glidkolven nött", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "346", "text": "Ruuveja puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "346", "text": "Skruvar fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "348", "text": "Ruuvit löysällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "348", "text": "Skruvarna lösa", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "349", "text": "Silmukka kulunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "349", "text": "Dragöglan nött", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "35", "text": "Ajoneuvon väri muuttunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "35", "text": "Fordonets färg ändrats", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "350", "text": "Ei lukkiudu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "350", "text": "Låses ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "351", "text": "Mutterin lukitus irti", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "351", "text": "Mutterns låsning öppen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "352", "text": "Mutteri vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "352", "text": "Muttern deformerad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "353", "text": "Korkeus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "353", "text": "Höjden felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "354", "text": "Mutterin lukitus löysä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "354", "text": "Mutterns låsning lös", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "355", "text": "Mutterin lukitus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "355", "text": "Mutterns låsning fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "41", "text": "Granskningen utförd utan lyft eller schakt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "356", "text": "Krokig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "357", "text": "Tarkastuspäivämäärä vanhentunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "357", "text": "Granskningsdagen föråldrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "358", "text": "Puutteelliset", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "358", "text": "Bristfälliga", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "359", "text": "Sisältö puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "359", "text": "Innehållet bristfälligt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "36", "text": "Poistettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "36", "text": "Avlägsnat", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "360", "text": "Vaatii viranomaisluvan ja muutoskatsastuksen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "360", "text": "Kräver myndighetstillstånd och ändringsbesiktning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "361", "text": "Ajoneuvon leveyttä muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "361", "text": "Fordonets bredd ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "362", "text": "Jarrujärjestelmän rakennetta muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "362", "text": "Bromssystemets konstruktion ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "363", "text": "Jarruvoiman jakautuminen kuljettajan säädettävissä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "363", "text": "Bromskraftens förhållande justerbar av chauffören", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "364", "text": "Vaihteiston öljyvuoto", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "364", "text": "Oljeläckage från växellådan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "365", "text": "Tasauspyörästön öljyvuoto", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "365", "text": "Oljeläkage i differentialen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "366", "text": "Opettajan peili puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "366", "text": "Lärarens spegel fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "367", "text": "Opettajan polkimen rakenne epävarma", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "367", "text": "Lärarens pedalkonstruktion osäker", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "368", "text": "Opettajan poljin puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "368", "text": "Lärarens pedal fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "369", "text": "Opettajan peilin kiinnitys virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "369", "text": "Lärarens spegelfastsättning felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "37", "text": "Muutettu, rakenne heikentynyt", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "37", "text": "Ändrad, konstruktionen försvagad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "370", "text": "Estää valojen näkyvyyttä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "370", "text": "Hindrar belysningens synbarhet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "371", "text": "Äänenvaimennus riittämätön", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "371", "text": "Ljuddämpningen är otillräcklig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "372", "text": "Peruutusvaihde ei pysy päällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "372", "text": "Backväxeln hålls inte i", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "373", "text": "Peruutusvaihde ei mene päälle", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "373", "text": "Backväxeln går inte att sätta i", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "374", "text": "Nestevuoto", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "374", "text": "Vätskeläckage", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "375", "text": "Liian alhainen, kuormattu ajoneuvo", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "375", "text": "För lågt, belastat fordon", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "376", "text": "Liian korkea, kuormattu ajoneuvo", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "376", "text": "För högt, belastat fordon", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "377", "text": "Lisäpöytäkirja puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "377", "text": "Tilläggsprotokollet fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "378", "text": "Rekisteriotteen jatko-osa puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "378", "text": "Registerutdragets tilläggsdel fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "379", "text": "Työntömäntä välyksellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "379", "text": "Påskjutskolven glapp", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "38", "text": "Vaihtoehtoinen tarkastusmenettely", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "38", "text": "Alternativt granskningssätt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "380", "text": "Työntömännän vaimennus puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "380", "text": "Påskjutskolvens dämpning otillräcklig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "381", "text": "Työntömäntä pohjaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "381", "text": "Påskjutskolven går i ändläge", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "382", "text": "Reuna ruostevaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "382", "text": "Kanten rostskadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "383", "text": "Lasi rikki", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "383", "text": "Glaset sönder", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "384", "text": "Vaihteen osoitin puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "384", "text": "Växelindikator saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "385", "text": "Symbolit virheelliset", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "385", "text": "Indikatorns symboler felaktiga", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "386", "text": "Vaihteensiirto jäykkätoiminen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "386", "text": "Växlingsmekanismen trög", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "387", "text": "Jakohihnan kunto epävarma.Savumittausta ei suoritettu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "387", "text": "Drivremmens konditon osäker. Rökmätningen inte utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "388", "text": "Mainostarra tuulilasin yläosassa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "388", "text": "Reklamtejp i vindrutans övre kant", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "389", "text": "Tarra tuulilasissa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "389", "text": "Självhäftande etikett på vindrutan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "39", "text": "< Ei mitään>", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "39", "text": "< Ingenting>", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "390", "text": "Vasen ja oikea eriväriset", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "390", "text": "Vänster och höger olika färg", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "391", "text": "Koskettaa ajoneuvon toiseen osaan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "391", "text": "Tar i annan del av fordonet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "392", "text": "Merkkivalon perusteella ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "392", "text": "Fungerar inte, enligt varningslampan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "393", "text": "Merkkivalo ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "393", "text": "Kontrollampan fungerar inte", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "410", "text": "Rakenne ei ole kiinteä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "394", "text": "Opetuspolkimella saavutettu hidastuvuus heikko", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "394", "text": "Uppnådd retardation med lärarens pedal svag", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "395", "text": "Opetuspolkimen kiinnitys epävarma", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "395", "text": "Lärarens pedalfastsättning osäker", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "396", "text": "Vaijerin kiinnitys epävarma", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "396", "text": "Vajerns fastsättning osäker", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "397", "text": "Öljymäärä vähäinen. Savutusmittausta ei suoritettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "397", "text": "För lite olja. Rökmätningen inte utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "398", "text": "Jarrukortti puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "398", "text": "Bromskort fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "399", "text": "Jää päälle", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "399", "text": "Lämnar på", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "4", "text": "Puhdistettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "4", "text": "Bör rengöras", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "40", "text": "< Ei mitään>", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "40", "text": "< Ingenting>", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "400", "text": "Mutteri puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "400", "text": "Muttern fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "401", "text": "Säädösten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "401", "text": "Emot förordningen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "402", "text": "Juuttunut, jarrumittausta ei voitu suorittaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "402", "text": "Fastnad, bromsning inte utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "403", "text": "Puuttuu, jarrumittausta ei voitu suorittaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "403", "text": "Saknas, bromstest kunde inte utföras", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "404", "text": "Vaurioitunut, jarrumittausta ei voitu suorittaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "404", "text": "Skadad, bromstest inte utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "405", "text": "Vivustossa liikaa välystä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "405", "text": "För mycket glapp i mekanismen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "406", "text": "Moottorissa on liikaa öljyä. Savutusmittausta ei voitu suorittaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "406", "text": "För mycket olja i motorn. Utsläppsmätningen inte utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "407", "text": "Tasonsäätö ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "407", "text": "Höjdlåsningen fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "408", "text": "Rakenne virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "408", "text": "Konstruktionen felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "409", "text": "Laakerointi välyksellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "409", "text": "Lagringen glapp", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "41", "text": "Tarkastus suoritettu ilman nosturia tai monttua", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "462", "text": "Valmistustodistus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "410", "text": "Konstruktionen är inte fast", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "411", "text": "Väri säädösten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "411", "text": "Färgen uppfyller inte bestämmelserna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "412", "text": "Liian matala", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "412", "text": "För låg", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "413", "text": "Istumapaikkoja liikaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "413", "text": "För många sittplatser", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "414", "text": "Mitoitus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "414", "text": "Måttsättning felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "415", "text": "Ajamista helpottavat laitteet poistettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "415", "text": "Utrustningen för underlättande av körning bör avlägsnas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "416", "text": "Ajamista helpottavat laitteet muutoskatsastettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "416", "text": "Utrustningen för underlättande av körning bör ändringsbesiktas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "417", "text": "Suuntavalon hallintalaitteen rakenne sopimaton", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "417", "text": "Riktningsljusens manöverorgan olämpligt konstruerad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "418", "text": "Tasauspyörästö ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "418", "text": "Differentialen fungerar inte", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "419", "text": "Säätö virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "419", "text": "Justeringen felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "42", "text": "Moottori muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "42", "text": "Motorn ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "420", "text": "Varmistusvaijeri vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "420", "text": "Säkrings vajer skadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "421", "text": "Varmistusvaijeri puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "421", "text": "Säkrings vajer saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "422", "text": "Vettä valaisimen sisällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "422", "text": "Vatten i lykstinsats", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "423", "text": "Nestekaasujärjestelmän tarkastustodistus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "423", "text": "Flytgassystemets granskningsprotokoll fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "424", "text": "Nestekaasujärjestelmän tarkastustodistus puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "424", "text": "Flytgassystemets granskningsprotokoll bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "425", "text": "Vivusto jäykkä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "425", "text": "Mekanismen styv", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "426", "text": "Korkeudensäätöventtiili ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "426", "text": "Höjdreglerventilen fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "427", "text": "Korotuspalojen mitoitus säädösten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "451", "text": "Justermekanismen skadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "427", "text": "Förhöjningsklossarnas mått mot bestämmelserna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "428", "text": "Epäasialliset korotuspalat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "428", "text": "Osakliga förhöjningsklossar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "429", "text": "Maalaus tai pinnoite määräysten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "429", "text": "Målning eller ytbehandling mot förordningen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "43", "text": "Renkaan leveyttä muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "43", "text": "Däckets bredd ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "430", "text": "Rengaspaine liian alhainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "430", "text": "Däckets lufttryck för lågt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "431", "text": "Jarrulaskelma virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "431", "text": "Bromsberäkningen felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "432", "text": "Jarrulaskelma puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "432", "text": "Bromsberäkningen bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "433", "text": "Jarrulaskelma, Mittauslaitteiston kalibrointitodistus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "433", "text": "Bromsberäkning, Mätutrustningens kalibreringsintyg saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "434", "text": "Peittää nopeusmittarin", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "434", "text": "Skymmer hastighetsmätaren", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "435", "text": "Toinen nopeus ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "435", "text": "Andra hastigheten fungerar inte", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "436", "text": "Äänenvoimakkuus liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "436", "text": "Ljudnivån för hög", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "437", "text": "Todistus korin mitoista puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "437", "text": "Intyg över karossens mått fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "438", "text": "Todistus rungon mitoista puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "438", "text": "Ramens måttintyg saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "439", "text": "Todistus pyörien asentokulmista puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "439", "text": "Hjulinställningsintyg saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "44", "text": "Vanteen halkaisijaa muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "44", "text": "Fälgens diameter ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "440", "text": "Todistus akselien asennosta puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "440", "text": "Intyg över axlarnas inställning fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "441", "text": "Todistus korin liitostavoista puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "441", "text": "Intyg över karossens montering fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "442", "text": "Todistus turvalaitteiden toimintakyvystä puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "442", "text": "Intyg över säkerhetsutrustningens funktionsduglighet saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "443", "text": "Todistus turvatyynyn toimintakyvystä puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "443", "text": "Intyg över krockkuddens funktionsduglighet saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "444", "text": "Todistus turvavyönkiristimen toimintakyvystä puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "444", "text": "Intyg över säkerhetsbältesspännares funktionsduglighet saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "445", "text": "Teippipinnoite säädösten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "445", "text": "Plastfilmen uppfyller inte kraven", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "446", "text": "Ottaa kiinni pyörään", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "446", "text": "Tar fast i däcket", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "447", "text": "Jäähdytysnestevuoto", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "447", "text": "Kylvätskeläckage", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "448", "text": "Akselin suuntainen välys liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "448", "text": "Glapp i axelns riktning för stort", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "449", "text": "Näkyvyyskulma ei täytä vaatimusta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "449", "text": "Synlighetsvinkeln uppfyller inte bestämmelserna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "45", "text": "Renkaan leveyttä ja vanteen halkaisijaa muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "45", "text": "Däckets bredd och diameter ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "450", "text": "Säätö ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "450", "text": "Justeringen fungerar inte", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "451", "text": "Säätölaite vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "452", "text": "Liian korkea, tyhjänä ja kuormattuna", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "452", "text": "För högt, tom och belastad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "453", "text": "Liian alhainen, tyhjänä ja kuormattuna", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "453", "text": "För lågt, tom och belastad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "454", "text": "Suojakumi vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "454", "text": "Skyddsgummet skadat", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "455", "text": "Kiertynyt", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "455", "text": "Vriden", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "456", "text": "Pysäköintilukko ei toimi automaattivaihteistossa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "456", "text": "Parkeringsspärren fungerar inte i automatväxellådan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "457", "text": "Vaihteita puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "457", "text": "Växlar saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "458", "text": "Käynnistyy muussa kuin N-ja P-asennossa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "458", "text": "Startar i annat läge än N- och P", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "459", "text": "Autoveropäätös puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "459", "text": "Bilskattebeslut fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "46", "text": "Käyttövoimaa muutetttu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "46", "text": "Brukskraften ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "460", "text": "Vaatimustenmukaisuustodistus (COC) puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "460", "text": "Intyg saknas för uppfyllande av krav (COC)", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "461", "text": "Lämpölaskelma puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "461", "text": "Värmeberäkning fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "519", "text": "Penkin verhoilu epäsiisti", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "462", "text": "Tillverkningsintyg saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "464", "text": "Sähköjärjestelmän tarkastustodistus puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "464", "text": "Elektricitetsystemets granskningsprotokoll bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "465", "text": "Sähköjärjestelmän tarkastustodistus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "465", "text": "Elektricitetssystemets granskningsprotokoll fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "466", "text": "Toiminta viiveellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "466", "text": "Funktionen fördröjd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "467", "text": "Haalistunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "467", "text": "Blacknad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "468", "text": "Nousuviive liian pitkä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "468", "text": "Anläggningstid för lång", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "469", "text": "Vapautusviive liian pitkä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "469", "text": "Lossningstid för lång", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "47", "text": "Taka-akseliston rakennetta muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "47", "text": "Bak-axelns byggnad ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "470", "text": "Käyntihäiriöitä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "470", "text": "Driftstörning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "471", "text": "Käynti epätasainen joutokäynnillä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "471", "text": "Ojämn gång på tomgång", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "472", "text": "Käynti epätasainen korotetulla pyörintänopeudella", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "472", "text": "Ojämn gång på högre varvtal", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "473", "text": "Vuotaa nestettä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "473", "text": "Vätskeläckage", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "474", "text": "Suuntaus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "474", "text": "Felaktigt riktad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "475", "text": "Asento virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "475", "text": "Felaktigt läge", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "476", "text": "Kahva puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "476", "text": "Handtag saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "477", "text": "Pistorasia vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "477", "text": "Kontaktdosan skadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "478", "text": "Pistorasian etäisyys vetokuulasta virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "478", "text": "Kontaktdosans avstånd till dragkulan felaktigt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "479", "text": "Pistorasia puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "479", "text": "Kontaktdosan saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "48", "text": "Etupenkkien mallia muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "48", "text": "Framsätets modell ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "480", "text": "Polttimo ei hyväksyttyä tyyppiä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "480", "text": "Brännaren inte av godkänd typ", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "481", "text": "Liian korkea", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "481", "text": "För högt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "482", "text": "Lukitus puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "482", "text": "Bristfällig låsning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "483", "text": "Reikä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "483", "text": "Hål", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "484", "text": "Sinetöinti puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "484", "text": "Plomberingen bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "485", "text": "Niittityyppi TIR-säädösten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "485", "text": "Nit-typen uppfyller inte TIR-bestämmelserna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "486", "text": "Kampikammion tuuletusjärjestelmä muutettu, Mittaustulos ei ole luotettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "486", "text": "Vevhusets ventilationssytem ändrad, Osäkert mätresultat", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "487", "text": "Kampikammion tuuletusjärjestelmä muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "487", "text": "Vevhusets ventilationssytem ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "488", "text": "Korjaustodistus esitettävä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "488", "text": "Reparationsintyg bör visas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "489", "text": "Vivusto irti", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "489", "text": "Mekanismen är lös", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "49", "text": "Ajoneuvon rakennetta muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "49", "text": "Fordonets byggnad ändrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "490", "text": "Vivusto vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "490", "text": "Mekanismen är skadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "491", "text": "Jousi irti", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "491", "text": "Fjädern lös", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "492", "text": "Jousi poikki", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "492", "text": "Fjädern brusten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "493", "text": "Osaluettelo puuttuu, esitettävä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "493", "text": "Delförteckning bör visas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "494", "text": "Liian hidas", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "494", "text": "För långsam", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "495", "text": "Väärä koko", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "495", "text": "Fel storlek", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "496", "text": "Rakenne ja varustus ei täytä verovapauden ehtoja", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "496", "text": "Konstruktionen och utrustn. fyller inte kraven för skattefrihet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "497", "text": "Rakenne ja varustus ei täytä ajoneuvoryhmän vaatimuksia", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "497", "text": "Konstruktionen och utrustn. fyller inte fordringarna på fordonsgruppen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "498", "text": "Kumityyny vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "498", "text": "Gummidyna skadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "499", "text": "Kiinnityskorvake vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "499", "text": "Fästörat skadat", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "5", "text": "Asiallinen hitsaus sallitaan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "5", "text": "Saklig svetsning tillåten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "50", "text": "Tarkastusta ei ole suoritettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "50", "text": "Inte granskad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "500", "text": "Palaa koko ajan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "500", "text": "Lyser oavbrytet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "501", "text": "Pullistuma", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "501", "text": "Utbuktning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "502", "text": "Liian kulunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "502", "text": "För sliten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "503", "text": "Mittauspöytäkirjan arvot eivät ole toleranssien sisällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "503", "text": "Mätningsprotokollets värden är inte inom tillåtna gränser", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "504", "text": "Todistus jarruille suoritetuista korjauksista puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "504", "text": "Intyg över bromsreparation saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "505", "text": "Nivel välyksellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "505", "text": "Glapp i led", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "506", "text": "Terävä uloke, tapaturmavaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "506", "text": "Vass utstickande del, olycksrisk", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "507", "text": "Moottorin öljymäärä alhainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "507", "text": "För litet olja i motorn", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "508", "text": "Penkin verhoilu rikki", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "508", "text": "Sätesklädseln söndrig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "509", "text": "Moottori öljyinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "509", "text": "Motorn oljig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "51", "text": "Tarkastetaan jälkitarkastuksen yhteydessä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "51", "text": "Granskas i samband med eftergranskningen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "510", "text": "Öljypohja öljyinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "510", "text": "Oljetråget oljigt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "511", "text": "Moottori/vaihteisto öljyinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "511", "text": "Motorn/växellådan oljig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "512", "text": "Vesitilassa pakokaasua", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "512", "text": "Avgaser i kylarvätskan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "513", "text": "Vaihteiston öljymäärä alhainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "513", "text": "Låg oljenivå i växellådan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "514", "text": "Vaihteisto luistaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "514", "text": "Slirar i växlarna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "515", "text": "Vaihteiston toiminta virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "515", "text": "Växellådans funktion felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "516", "text": "Akun varaustila heikko", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "516", "text": "Batteriets laddningstillstånd svagt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "517", "text": "Käyttöhihna huonokuntoinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "517", "text": "Fläktremmen i dåligt skick", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "518", "text": "Käyttöhihna luistaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "518", "text": "Fläktremmen slirar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "568", "text": "Funktionen eller verkan är inte steglös", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "519", "text": "Sätesklädseln smutsig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "52", "text": "Koeajoa ei suoritettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "52", "text": "Provkörning inte utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "520", "text": "Alustassa ruostetta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "520", "text": "Rostskador på underredet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "521", "text": "Reuna ruostunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "521", "text": "Kanten rostig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "522", "text": "Kolarikorjattu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "522", "text": "Krockskador reparerade", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "523", "text": "Ruostetta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "523", "text": "Rost", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "524", "text": "Vaurio", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "524", "text": "Skada", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "525", "text": "Vaatii tarkemman tutkimuksen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "525", "text": "Kräver noggrannare undersökning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "526", "text": "Arvo poikkeaa tavanomaisesta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "526", "text": "Värdet avviker från det normala", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "527", "text": "Kiehumispiste alhainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "527", "text": "Låg kokningspunkt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "528", "text": "Kiehumispiste liian alhainen, jarrujen toiminta epävarma", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "528", "text": "För låg kokningspunkt, bromsarnas funktion osäker", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "529", "text": "Pakkaskestävyys riittämätön, jäätymisvaara suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "529", "text": "Köldbeständigheten otillräcklig, risk för frysning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "53", "text": "Koeponnistusta ei suoritettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "53", "text": "Provbelastning inte utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "530", "text": "Ei pakkaskestävyyttä, moottori vaurioituu pakkasella", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "530", "text": "Inte köldbeständigt, motorn tar skada vid köldgrager", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "531", "text": "Pakkaskestävyys melko alhainen, jäätymisvaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "531", "text": "Köldbeständigheten låg, risk för frysning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "532", "text": "Öljymäärä riittämätön", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "532", "text": "För lite olja", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "533", "text": "Verhoilu rikki", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "533", "text": "Beklädningen sönder", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "534", "text": "Verhoilu epäsiisti", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "534", "text": "Beklädningen i dåligt skick", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "535", "text": "Vaihteistossa liikaa öljyä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "535", "text": "För hög oljenivå i växellådan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "536", "text": "Jarrupolkimen sijainti virheellinen, liikaa vasemmalla", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "536", "text": "Bromspedalens placering felaktig, för långt åt vänster", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "537", "text": "Näkyvyys opettajan peilista puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "537", "text": "Sikten via lärarens spegel bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "538", "text": "Kuormakorin kiinnitystodistus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "538", "text": "Lastkorgens monteringsintyg saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "539", "text": "Selkänoja ei käänny, kulkuyhteys takaistuimelle puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "579", "text": "Vätskeläckage, brandfara", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "539", "text": "Ryggstödet går inte att fälla framåt, passage till baksätet förhindrad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "54", "text": "Sinetöinti tai taksan asetustuloste puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "54", "text": "Plomberingen fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "540", "text": "Ei voi avata ulkoa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "540", "text": "Kan inte öppnas utifrån", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "541", "text": "Ei voi avata sisältä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "541", "text": "Kan inte öppnas inifrån", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "542", "text": "Ei pysy kiinni", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "542", "text": "Hålls inte fast", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "543", "text": "Istuimen säätölaite ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "543", "text": "Sätets justeranordning fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "544", "text": "Istuimen säätölaitteen toiminta virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "544", "text": "Sätets justeranordning fungerar felaktigt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "545", "text": "Selkänojan säätölaite ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "545", "text": "Ryggstödets justeranordning fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "546", "text": "Selkänojan säätölaitteen toiminta virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "546", "text": "Ryggstödets justeranordning fungerar felaktigt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "547", "text": "Polkimen asento virheellinen, käyttö hankalaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "547", "text": "Pedalens läge felaktigt, svår att använda", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "548", "text": "Ominaisuuksiltaan keskenään erilaiset, vasen / oikea", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "548", "text": "Olika egenskaper, vänster/höger", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "549", "text": "Jousitusta muutettu, muutoskatsastettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "549", "text": "Fjädringen ändrad, bör ändringsbesiktas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "55", "text": "Sinetöinti vaurioitunut tai puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "55", "text": "Plomberingen deformerad eller fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "550", "text": "Renkaiden vierintäkehä pienempi kuin asennustodistuksen mukaisessa renk.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "550", "text": "Däckens omkrets är mindre än de som antecknats i intyget.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "551", "text": "Todistus korin mitoista puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "551", "text": "Intyg över karossens mått fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "552", "text": "Perävaunun jarrujen yhteensopivuuslaskelma puuttuu tai puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "552", "text": "Släpvagnens bromsanpassningsberäkning är bristfällig eller saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "553", "text": "Todistus muutetun jousituksen hyväksyttävyydestä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "553", "text": "Kravenlighetsintyg för ändrad fjädring", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "554", "text": "Kilpi tai tunnus ei kuulu ajoneuvoon", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "554", "text": "Skylt eller igenkänningstecken hör inte till fordonet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "555", "text": "Kansallisuustunnus tai muu merkintä liian lähellä rekisterikilpeä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "555", "text": "Nationalitetsbeteckningen eller annat märke för nära registerskylten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "556", "text": "Kompressorissa liiallinen öljyvuoto järjestelmään", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "556", "text": "För stort oljeläckage till systemet från kompressorn", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "557", "text": "Toimintapainealue virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "557", "text": "Felaktigt arbetstrycksområde", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "558", "text": "Sisäinen vuoto", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "558", "text": "Inre läkage", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "559", "text": "Ylimääräinen jatkos", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "559", "text": "Extra skarv", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "56", "text": "Sijoitus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "56", "text": "Felaktig placering", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "560", "text": "Niveltapin lukitus puuttuu / puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "560", "text": "Ledtappens låsnings saknas/bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "561", "text": "Letkunkiristin ei ole hyväksyttyä tyyppiä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "561", "text": "Slangklämmare av icke godkänd typ", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "562", "text": "Iskunpituus liian suuri, säätö virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "562", "text": "För lång slaglängd, felaktig justering", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "563", "text": "Iskunpituus liian suuri, kitkapinnat liian kuluneet", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "563", "text": "För lång slaglängd, beläggen slitna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "564", "text": "Iskunpituus liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "564", "text": "För lång slaglängd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "565", "text": "Automaattinen säätölaite vaihdettu käsisäätöiseksi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "565", "text": "Automatiska justeranordningen utbytt mot manuell justering", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "566", "text": "Vierintävastus liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "566", "text": "Rullningsmotståndet för stort", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "567", "text": "Peruuttamisen mahdollistava laitteisto ei toimi tai puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "567", "text": "Anordning som möjliggör backning fungerar ej, eller saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "568", "text": "Toiminta tai vaikutus ei ole portaaton", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "628", "text": "Bör bytas före följande användande i trafik", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "569", "text": "Ei ole käyttötarkoitukseen hyväksyttyä tyyppiä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "569", "text": "Icke godgänd typ för användningsändamålet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "57", "text": "Sijoitus tai mitoitus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "57", "text": "Felaktig placering eller dimension", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "570", "text": "Kaasupurkausvalaisimen automaattinen korkeudensäätölaite puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "570", "text": "Automatiska höjdjusteringsanordningen för Xenon belysningen saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "571", "text": "Ruostevaurio", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "571", "text": "Rostskada", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "572", "text": "Korjattava ja pestävä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "572", "text": "Bör repareras och tvättas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "573", "text": "Tähän ajoneuvoon sopimaton", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "573", "text": "Olämpligt för detta fordon", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "574", "text": "Merkkivalo ilmaisee vian", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "574", "text": "Kontrollampa meddelar om fel", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "575", "text": "Päästötaso ei täytä vaatimuksia", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "575", "text": "Avgasutsläppsnivån uppfyller inte kraven", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "576", "text": "Äänenvaimennusta muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "576", "text": "Ljuddämpningen ändrats", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "577", "text": "Äänenvaimennusrakenteet poistettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "577", "text": "Ljuddämpningsanordning har avlägsnats", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "578", "text": "Liian voimakas", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "578", "text": "För kraftig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "579", "text": "Nestevuoto, palovaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "58", "text": "Lukumäärä virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "58", "text": "Felaktigt antal", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "580", "text": "Nestevuoto, ympäristöhaitta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "580", "text": "Vätskeläckage, påverkar miljö", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "581", "text": "Side puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "581", "text": "Förbindning saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "582", "text": "Keskitappi vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "582", "text": "Centrumtappen skadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "583", "text": "Männänvarren välys liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "583", "text": "Kolvstångens glapp för stort", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "592", "text": "Fönstret kan inte stängas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "584", "text": "Laitteisto väljä. Tarkasta korjauksen yhteydessä koko laitteisto.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "584", "text": "Konstruktionen glapp. Kontrollera hela konstruktionen vid service", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "585", "text": "Liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "585", "text": "För stor", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "586", "text": "Suojan/pinnoitteen irtoamisvaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "586", "text": "Fara att skyddet/ytbeläggningen lossnar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "587", "text": "Kotelorakenteen ruostevaurio", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "587", "text": "Lådkonstruktionen skadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "588", "text": "Liimattussa tuulilasissa halkeama reunasta reunaan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "588", "text": "Den limmade vindrutan har en spricka från kant till kant", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "589", "text": "Naarmuuntunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "589", "text": "Skråmad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "59", "text": "Raideleveyden muutos liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "59", "text": "Hjulbasens ändring för stor", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "590", "text": "Hyväksymismerkintä puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "590", "text": "Godkänningsmärkning saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "591", "text": "Ikkuna ei pysy suljettuna", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "591", "text": "Fönstret hålls inte stängt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "592", "text": "Ikkunaa ei voi nostaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "595", "text": "Kudosvaurio , rikkoutumisvaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "595", "text": "Vävskada, fara att gå sönder", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "596", "text": "Hätäjarrutustoiminto ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "596", "text": "Nödbromsfunktionen fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "597", "text": "Syöttöpaineen tyhjennystoiminto ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "597", "text": "Matartryckets tömningsfunktion fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "598", "text": "Lukituslaite välyksellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "598", "text": "Glapp i låsanordningen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "599", "text": "Mutterin suojus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "599", "text": "Mutterskydd saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "6", "text": "Ei saa korjata hitsaamalla, vaihdettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "6", "text": "Får inte repareras genom att svetsas, bör utbytas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "60", "text": "Asento virheellinen (auraus/haritus)", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "60", "text": "Felaktig hjulställning (plogning)", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "600", "text": "Kierre vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "600", "text": "Gängan skadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "601", "text": "Kiinnityskohdassa vaurio", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "601", "text": "Skada vid fastsättningspunkten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "602", "text": "Lisälukituslaite puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "602", "text": "Tilläggslåsanordning saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "603", "text": "Ensiapupakkauksen sijaintikilpi puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "603", "text": "Förstahjälpförpacknings placeringsskylt saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "604", "text": "Tulensammuttimen sijaintikilpi puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "604", "text": "Brandsläckarens placeringsskylt saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "605", "text": "Hätäuloskäytävän tunnus tai ohje puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "605", "text": "Nödutgångens kännetecken elle anvisning saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "606", "text": "Rajoitusnopeus liian suuri", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "606", "text": "Begränsade hastigheten för hög", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "61", "text": "Asento virheellinen (pystykallistuma)", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "61", "text": "Felaktig hjulställning (camber)", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "616", "text": "Asetustodistus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "616", "text": "Förordningsintyg saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "617", "text": "Toimintavalmiuden osatestejä ei ole suoritettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "617", "text": "Självdiagnossystemets deltester inte utförda", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "618", "text": "MIL- merkkivalon toimintahäiriö", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "618", "text": "MIL- funktionsstörning i märklampan", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "619", "text": "Järjestelmämuistissa on vikakoodeja", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "619", "text": "I systemminnet finns indikationer om fel", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "62", "text": "Epätasapaino (ravistaa ajossa)", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "62", "text": "Obalans (vibrerar vid körning)", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "620", "text": "Testin suorittaminen ei onnistu autosta johtuvasta syystä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "620", "text": "Testen kunde inte utföras pga av orsak som beror på bilen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "621", "text": "Lambda poikkeaa sallituista arvoista joutokäynnillä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "621", "text": "Lamdavärdet avviker från tillåtna värden på tomgång", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "622", "text": "Lambdaintegraattorin arvo ei vaihtele, säätö ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "622", "text": "Lamdaintegratorns värde ändras inte, justeringen fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "90", "text": "Flera rostskador", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "91", "text": "Kiinnityskohdassa ruostevaurioita", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "91", "text": "Rostskador i fastsättningspunkterna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "92", "text": "Useita ruostevaurioita, voidaan korjata hitsaamalla", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "92", "text": "Flera rostskador,kan repareras genom svetsning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "93", "text": "Useita ruostevaurioita, korjattava hitsaamalla", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "93", "text": "Flera rostskador,bör repareras genom svetsning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "94", "text": "Ruostevaurioitunut, uusittava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "94", "text": "Rostskadad, bör förnyas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "95", "text": "Vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "95", "text": "Skadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "96", "text": "Juuttunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "97", "text": "Rispaantunut, uusittava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "97", "text": "Rispad, bör förnyas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "98", "text": "Kiinnityskohta alustassa ruostevaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "98", "text": "Fastsättningen i underredet rostskadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "99", "text": "Virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "99", "text": "Felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "999", "text": "Takaisinkutsu hoitamatta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "999", "text": "Återkallelsen har inte verkställts", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "623", "text": "Lambdatunnistimen signaali ei vaihtele, säätö ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "623", "text": "Lamdasondens signal varierar inte, justeringen fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "624", "text": "Lambdatunnistimen lambda-arvo ei vaihtele, säätö ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "624", "text": "Lamdasondens lamda-värde ändras inte, justeringen fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "625", "text": "Syöpymisvaurio", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "625", "text": "Frätningsskada", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "626", "text": "Suojaus puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "626", "text": "Bristfälligt skydd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "627", "text": "Tuenta puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "627", "text": "Bristfällig stagning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "628", "text": "Vaihdettava ennen seuraavaa käyttöä liikenteessä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "90", "text": "Useita ruostevaurioita", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "629", "text": "Välyksellinen mm. raidetangon sisäpää, tarkasta myös etuakseliston osat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "629", "text": "Glapp, bl.a. inre styrled, granska även framaxelns delar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "63", "text": "Virheellisesti korjattu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "63", "text": "Felaktigt reparerad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "630", "text": "Välyksellinen mm. raidetangon ulkopää, tarkasta myös etuakseliston osat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "630", "text": "Glapp,bla styrstagets yttre led. Granska också de andra delarna i framva", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "631", "text": "Rakenteellinen nopeus yli 45 km/h", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "631", "text": "Konstruktionshastigheten över 45 km/h", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "632", "text": "Välityssuhde muutettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "632", "text": "Utväxlingsförhållandet ändrat", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "633", "text": "Valon väri väärä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "633", "text": "Fel färg på ljuset", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "634", "text": "Polttimo vaihdettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "634", "text": "Brännaren bör bytas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "635", "text": "Imeytysaine puuttuu / puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "635", "text": "Uppsugningsmaterial saknas / bristfälligt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "636", "text": "Henkilökohtaiset suojavarusteet puuttuvat/puutteelliset", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "636", "text": "Personliga skyddsutrustningen saknas / bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "667", "text": "Hinder i synfält", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "637", "text": "Sanko puuttuu / puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "637", "text": "Ämbare saknas / bristfälligt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "638", "text": "Lapio puuttuu / puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "638", "text": "Spade saknas / bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "639", "text": "Säkkiputki puuttuu / puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "639", "text": "Säckröret saknas / bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "64", "text": "Korjaus väärin suoritettu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "64", "text": "Reparationen felaktigt utförd", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "640", "text": "Varoitusmerkit puuttuuvat / puutteelliset", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "640", "text": "Varningsmärkning saknas / bristfällig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "641", "text": "Jätesäkit puuttuvat / puutteelliset", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "641", "text": "Avfallssäckar saknas / bristfälliga", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "642", "text": "Näkyvyys puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "642", "text": "Sikten bristfälligt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "643", "text": "Polttimon kiinnitys virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "643", "text": "Brännarens fastsättning felaktig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "644", "text": "Säännösten vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "644", "text": "Emot reglerna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "645", "text": "Lukkiutumismahdollisuus moottorin käydessä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "645", "text": "Möjligt att låsa med motorn i gång", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "646", "text": "Lukkiutumismahdollisuus virran ollessa kytkettynä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "646", "text": "Möjligt att låsa när strömmen är påkopplad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "647", "text": "Viallinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "647", "text": "Defekt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "648", "text": "Symboli virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "648", "text": "Felaktig symbol", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "649", "text": "V-vaihde ei pysy päällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "649", "text": "V-ans växel hålls ej på", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "65", "text": "Ruostevauriot virheellisesti korjattu, korjattava hitsaamalla", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "65", "text": "Rostskadorna felaktigt reparerade.Bör svetsas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "650", "text": "VI-vaihde ei pysy päällä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "650", "text": "VI-ans växel hålls ej på", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "66", "text": "Ruostevauriot virheellisesti korjattu. Osa uusittava, ei saa hitsata", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "66", "text": "Rostskadorna felaktigt reparerade.Delen bör bytas, får inte svetsas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "666", "text": "Pyörintäsuunta väärä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "666", "text": "Fel rotationsriktning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "667", "text": "Este näkökentässä", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "67", "text": "Virheellisesti korjattu, uusittava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "67", "text": "Felaktigt reparerad, bör förnyas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "68", "text": "Asennus virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "68", "text": "Felaktigt monterad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "69", "text": "Toiminta virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "69", "text": "Felaktig funktion", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "7", "text": "Kuivattava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "7", "text": "Bör torkas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "70", "text": "Toiminta määräysten vastainen/viallinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "70", "text": "Funktionen regelstridig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "71", "text": "Ei mahdollisuutta toimia", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "71", "text": "Ingen möjlighet att fungera", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "72", "text": "Toiminta epävarmaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "72", "text": "Funktionen osäker", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "73", "text": "Toimintahäiriön vaara", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "73", "text": "Fara för funktionsstörning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "74", "text": "Ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "74", "text": "Fungerar ej", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "75", "text": "Lukkiutuu avaimen ollessa paikoillaan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "75", "text": "Låses med nyckeln på plats", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "76", "text": "Peruutussalpa ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "76", "text": "Backspärren fungerar inte", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "77", "text": "Toiminta virheellinen, tarkasta maadoitus", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "77", "text": "Felaktig funktion,kontrollera jordanslutningen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "78", "text": "Avain mahdollista poistaa useassa lukkolaitteen asennossa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "78", "text": "Nyckeln kan avlägsnas ur låset i flera olika lägen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "79", "text": "Välyksellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "79", "text": "Glapp", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "8", "text": "Asiallinen korjaus sallitaan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "8", "text": "Saklig reparation tillåten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "80", "text": "Välyksellinen mm. alapallonivel. Tarkasta myös muut pyöränripust. osat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "96", "text": "Fastnad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "80", "text": "Glapp,bla nedre kulled.Granska också hjulupphängningens andra delar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "81", "text": "Välyksellinen mm. yläpallonivel. Tarkasta myös muut pyöränripust. osat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "81", "text": "Glapp,bla övre kulled.Granska också hjulupphängningens andra delar", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "82", "text": "Välyksellinen mm. alatukivarren laaker. Tarkasta muutkin kohteen osat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "9", "text": "Poistettava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "82", "text": "Glapp,bla nedre stödarmens lager.Granska också de andra delarna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "83", "text": "Välyksellinen mm. uloin raidet.pää. Tarkasta muutkin ohjauksen osat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "83", "text": "Glapp,bla styrstagets yttre led.Granska också de andra delarna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "84", "text": "Välyksellinen mm. raidetangon sisäpää. Tarkasta muutkin ohjauksen osat", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "84", "text": "Glapp,bla styrstagets inre led. Granska också de andra delarna i framvag", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "85", "text": "Välyksellinen mm. ylätukivarren laaker. Tarkasta muutkin kohteen osat.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "85", "text": "Glapp,bla övre stödarmens lager.Granska också de anrda delarna", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "86", "text": "Ohjauslaitt./pyörän tuenta välyks. Rakenteesta johtuen kohde epäselvä.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "86", "text": "Styrinrättningen/hjulupphängningen glapp.Pga konstruktionen oklart vilk", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "87", "text": "Pyörän tuenta/ohjauslaitt. välyks. Rakenteesta johtuen kohde epäselvä.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "87", "text": "Hjulupphängningen/styrinrättningen glapp.Pga konstruktionen oklart vilk", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "88", "text": "Ruostevaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "88", "text": "Rostskadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "89", "text": "Ruostunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "89", "text": "Rostig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "9", "text": "Bör avlägsnas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "739", "text": "Lehtijousen päiden helat löysät.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "757", "text": "Renkaat eivät vastaa asennuskilpeä.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "758", "text": "Saman akselin toinen vaimennin huomattavasti heikompi kuin toinen.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "759", "text": "Sijoitus vaarallisen lähellä polttoainesäiliötä tai -putkea (suojaamattomana).", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "760", "text": "Suuri irtoamisvaara.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "761", "text": "Syttymisviive.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "762", "text": "Säätöarvokilpi puuttuu tai on vaurioitunut (raskas kalusto).", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "763", "text": "Tasapainotus puutteellinen/virheellinen.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "764", "text": "Tasonsäätöventtiili ei toimi", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "765", "text": "Taka-akseli lukkiutuu liian herkästi.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "766", "text": "Teho ei ole portaattomasti muuttuva.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "767", "text": "Todistus puuttuu tai puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "768", "text": "Toiminnan ilmaisin ei toimi.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "779", "text": "Vetävän akselin renkaat eivät vastaa nopeudenrajoittimen toiminnan tarkastuksesta annettuun todistukseen tai rekisteriin merkittyjä.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "740", "text": "Lähivalaisin ja saman puolen etuvalaisin eivät toimi.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "741", "text": "Etuvalaisin ja saman puolen lähivalaisin eivät toimi.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "742", "text": "Maavara riittämätön.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "743", "text": "Merkintöjä välittömästi kilven etupuolella.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "744", "text": "Mittaustodistus puuttuu tai puutteellinen.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "745", "text": "Muu tarvittava selvitys puutteellinen tai epäselvä.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "746", "text": "Ominaisuuksiltaan erilaiset renkaat samalla akselilla.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "747", "text": "Paineilmajärjestelmän pyöräsylinterin liikevara alle 1/3.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "748", "text": "Pakkassuojalaitteen säiliö tyhjä.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "749", "text": "Pakollinen järjestelmä tai sen osa ei toimi ja perussäätö kunnossa.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "750", "text": "Pakollinen järjestelmä tai sen osa ei toimi ja perussäätö virheellinen.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "751", "text": "Pienialainen yksittäinen ruostevaurio", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "752", "text": "Poikittaispalkkeja puuttuu tai kiinnitys puutteellinen.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "753", "text": "Putki voimakkaasti syöpynyt.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "754", "text": "Päätyy korirakenteen alle siten, että palovaara ilmeinen.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "755", "text": "Rajoitinkumi puuttuu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "756", "text": "Rakenteeseen sopimaton.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "722", "text": "Jarruvoima ei muutu tasaisesti suhteessa polkimen käyttövoimaan tai liikematkaan.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "723", "text": "Jatkuva runsas savutus.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "724", "text": "Johdot huonosti kiinni tai sijoitus altis oikosululle.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "725", "text": "Jousi rakenteeseen sopimaton.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "726", "text": "Järjestelmästä puuttuu komponentti.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "727", "text": "Kahva lukittuu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "728", "text": "Kaukonäyttölaite viallinen/puuttuu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "729", "text": "Kaukovalojen merkkivalo ei toimi.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "730", "text": "Kiinnitys napaan puutteellinen tai sopimaton.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "731", "text": "Kitkapinnoissa öljyä tms.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "732", "text": "Kompressorin liiallinen öljyvuoto järjestelmään.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "733", "text": "Korin kiinnityskohdissa ruostevaurioita.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "734", "text": "Kytkentä virheellinen.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "735", "text": "Laakeri liian tiukka", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "736", "text": "Laakerointi takertelee.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "737", "text": "Laitetta käsitelty vilpillisesti.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "738", "text": "Lehtien siteitä puuttuu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "700", "text": "Aiheuttaa tarpeetonta ympäristöhaittaa.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "701", "text": "Ajoneuvon kiinteä rakenneosa tai varuste peittää kilven", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "702", "text": "Ajoneuvossa ei liikennevakuutusta.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "703", "text": "Akseliston kiinnityskohteissa ruostevaurioita.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "704", "text": "Alipainevuoto, tehottomuus.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "705", "text": "Apurunkojen kiinnityskohteissa ruostevaurioita.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "706", "text": "Asennettu muuhun ajoneuvoon, kuin tieliikennelain mukaiseen taksiin", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "707", "text": "Asennuskilpi puuttuu tai ei luettavissa.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "708", "text": "Asetuskilpi tai -tarra puuttuu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "713", "text": "Ei voida tarkastaa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "714", "text": "Epänormaali viive pyöräjarrun toiminnassa.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "715", "text": "Epätäydellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "716", "text": "Erivapaus puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "717", "text": "Ilmavuoto.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "718", "text": "Jarruletkujen kudosvauriot ja murtumat (sisempi kudos näkyvissä).", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "719", "text": "Jarruneste likaista tai saostunutta.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "720", "text": "Jarrupiiri pois toiminnasta.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "721", "text": "Jarrut eivät kytkeydy.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "701", "text": "En fast konstruktionsdel eller ett tillbehör på fordonet täcker skylten", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "702", "text": "Fordonet saknar trafikförsäkring.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "762", "text": "Reglervärdesskölden fattas eller skadad (tung materiel).", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "763", "text": "Bristfällig/felaktig balansering.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "764", "text": "Nivåregleringsventilen är ur funktion.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "765", "text": "Bakaxeln låser sig för lätt.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "766", "text": "Effekten förändras inte gradvis.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "767", "text": "Intyg fattas eller är bristfälligt.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "768", "text": "Funktionskontrollanordningen är ur bruk.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "769", "text": "Funktionstrycket är inte 0-50 % av behållartrycket.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "770", "text": "Funktionstryckintervallet är inte 50-100 % av bromstrycket.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "771", "text": "Äventyrar trafiksäkerheten.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "772", "text": "Hjälpsystem vid filbyte ur funktion.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "773", "text": "Lampan fungerar bristfälligt.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "774", "text": "Ljuskällans effekt är felaktig.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "775", "text": "Ett frivilligt system är ur funktion och grundinställningen är felaktig.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "776", "text": "Dragkopplingens fria rum otillräckligt.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "777", "text": "Dragkopplingens säkringsstift fungerar inte.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "778", "text": "Draglänk fattas.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "779", "text": "Den dragande axelns däck motsvarar inte intyget över kontrollen av hastighetsbegränsarens funktion eller anteckningarna i registret.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "780", "text": "Felaktig kraftriktning.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "781", "text": "Under 50 % av minimivärdena uppnås.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "782", "text": "Färgen har ändrats.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "783", "text": "Fel typ (Analogisk/digiskrivare).", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "784", "text": "Tanklocket saknas eller är bristfälligt.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "754", "text": "Slutar under karosskonstruktionen så att brandfaran är uppenbar.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "755", "text": "Gummistopp fattas.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "743", "text": "Märkningar direkt på skyltens framsida.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "744", "text": "Mätintyg fattas eller är bristfälligt.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "745", "text": "Annan nödvändig utredning är bristfällig eller oklar.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "746", "text": "Däck med olika egenskaper på samma axel.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "747", "text": "Slaglängden för tryckluftsystemets hjulcylinder är under 1/3.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "748", "text": "Behållaren för frostskyddsmedel är tom.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "749", "text": "Obligatoriskt system eller en systemkomponent är ur funktion och grundinställningen är korrekt.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "750", "text": "Obligatoriskt system eller en systemkomponent är ur funktion och grundinställningen är felaktig.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "751", "text": "Isolerad rostskada på litet område", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "752", "text": "Tvärgående balkar fattas eller är bristfälligt fästa.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "753", "text": "Kraftigt korroderat rör.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "774", "text": "Valonlähteen teho virheellinen.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "775", "text": "Vapaaehtoinen järjestelmä ei toimi ja perussäätö virheellinen.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "776", "text": "Vetokytkimen vapaatila riittämätön.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "777", "text": "Vetokytkimen varmistusnasta ei toimi.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "778", "text": "Vetolenkit puuttuu.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "780", "text": "Voiman suunta virheellinen.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "781", "text": "Vähimmäistason arvoista saavutetaan alle 50 %.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "782", "text": "Väri muuttunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "783", "text": "Väärän tyyppinen (Analoginen/Digipiirturi).", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "784", "text": "Täyttöaukon kansi puuttuu tai ei toimi.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "700", "text": "Orsakar onödig miljöolägenhet.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "769", "text": "Toimintapaine muu kuin 0-50 % säiliöpaineesta.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "770", "text": "Toimintapainealue muu kuin  50-100 % jarrutuspaineesta.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "771", "text": "Vaarantaa liikenneturvallisuutta.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "772", "text": "Vaihtamisopastin ei toimi.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "773", "text": "Valaisimen vajaa toiminta.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "709", "text": "Asiapaperi yksilöimätön.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "715", "text": "Ofullständig", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "710", "text": "Automallikohtainen yleistä raja-arvoa suurempi raja-arvo ylittyy.", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "711", "text": "Automatiikka ei toimi (kaasupurkausvalot ja muilla tekniikoilla toteutetut valaisimet (esimerkiksi LED) joiden teho on yli 2000 lumenia 1.10.2000 jälkeen).", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "712", "text": "Ei luettavissa", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "703", "text": "Rostskador vid axlarnas fästpunkter.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "704", "text": "Vakuumläckage, låg effekt.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "705", "text": "Rostskador vid hjälpramarnas fästpunkter.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "706", "text": "Monterad på ett annat fordon än en taxi enligt vägtrafiklagen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "707", "text": "Installationsskylten fattas eller är oläsbar.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "708", "text": "Installationsskylt eller -etikett fattas.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "709", "text": "Ospecificerat dokument.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "710", "text": "Ett för respektive bilmodell högre gränsvärde än det allmänna gränsvärdet överskrids.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "711", "text": "Automatiken är ur funktion (gasurladdningslampor och med andra tekniker tillverkade lampor (t.ex. LED) vars effekt överstiger 2000 lumen efter 1.10.2000).", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "712", "text": "Kan inte läsas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "713", "text": "Kunde inte kontrolleras", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "714", "text": "Onormal fördröjning i hjulbromsens funktion.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "716", "text": "Dispens fattas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "717", "text": "Luftläckage.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "718", "text": "Vävskador och sprickor på bromsledningar (inre vävnaden syns).", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "719", "text": "Bromsvätskan är förorenad eller innehåller sediment.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "720", "text": "En bromskrets är ur funktion.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "721", "text": "Bromsarna ansätts inte.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "722", "text": "Bromskraften förändras inte jämnt i förhållande till pedalens ansättningskraft eller slaglängden.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "723", "text": "Kontinuerligt rikligt med rök.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "724", "text": "Ledningarna är bristfälligt monterade eller placering med risk för kortslutning.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "725", "text": "Fjädern olämplig för konstruktionen.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "726", "text": "Det saknas en komponent i systemet.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "727", "text": "Handtaget låser sig.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "728", "text": "Fjärrvisningsanordning felaktig/fattas.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "729", "text": "Varningslampan för helljus är ur funktion.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "730", "text": "Bristfällig eller olämplig montering på navet.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "731", "text": "Olja e.d. på friktionsytor.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "732", "text": "För högt oljeläckage från kompressorn till systemet.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "733", "text": "Rostskador vid karossens fästpunkter.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "734", "text": "Felaktig koppling.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "735", "text": "Lagret är för spänt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "736", "text": "Lagret fastnar.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "737", "text": "Anordningen har behandlats oärligt.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "738", "text": "Band runt fjäderblad fattas.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "739", "text": "Glappa beslag i ändan av bladfjädern.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "740", "text": "En halvljusstrålkastare och främre positionslyktan på samma sida ur funktion.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "741", "text": "Främre positionslyktan och halvljusstrålkastaren på samma sida är ur funktion.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "742", "text": "Otillräcklig markfrigång.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "759", "text": "Placerad farligt nära bränsletank eller bränslerör (oskyddat).", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "760", "text": "Stor lossningsfara.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "761", "text": "Fördröjd tändning.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "756", "text": "Olämplig för konstruktionen.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "757", "text": "Däcken motsvarar inte installationsskylten.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "758", "text": "En dämpare på samma axel har sämre effekt än den andra.", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "807", "text": "Orsakar fara", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "807", "text": "Vaaraa aiheuttava", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "808", "text": "Obligatoriskt system saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "808", "text": "Pakollinen järjestelmä puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "809", "text": "Bristfällig märkning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "809", "text": "Merkintä puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "810", "text": "Liittimet vaurioituneet", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "810", "text": "Anslutningar skadade", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "811", "text": "Aiheuttaa palovaaran", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "811", "text": "Orsakar brandfara", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "812", "text": "Tukkeutunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "812", "text": "Tilltäppt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "813", "text": "Indikerar funktionsstörning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "813", "text": "Osoittaa toimintahäiriön", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "814", "text": "Förekomst av gas på grund av läckage", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "814", "text": "Vuodosta johtuva kaasun läsnäolo", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "815", "text": "Varoituslaitteen toiminta virheellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "815", "text": "Varningsanordningen fungerar felaktigt", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "816", "text": "Varningsanordningen indikerar funktionsstörning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "816", "text": "Varoituslaite osoittaa toimintahäiriön", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "817", "text": "Johdotus tai liittimet vaurioituneet", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "817", "text": "Ledningar eller anslutningar skadade", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "818", "text": "Skadad elektrisk isolering", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "818", "text": "Sähköeristys vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "819", "text": "Komponenter saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "819", "text": "Komponentteja puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "820", "text": "Varoituslaitteen toimintahäiriö", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "820", "text": "Funktionsstörning hos varningsanordningen", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "821", "text": "Järjestelmän toimintahäiriö", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "821", "text": "Funktionsstörning i systemet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "822", "text": "Kriittinen toimintahäiriö", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "822", "text": "Kritisk funktionsstörning", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "823", "text": "Störning i ventilations-/kylsystemet", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "823", "text": "Ilmanvaihdon/jäähdytyksen häiriö", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "824", "text": "Servicebrytaren skadad", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "824", "text": "Huoltoerotin vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "825", "text": "Lasikatossa halkeama reunasta reunaan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "825", "text": "Spricka i glastaket från kant till kant", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "806", "text": "Toiminta puutteellinen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "800", "text": "Istuinpäällinen estää turvalaitteen toiminnan", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "800", "text": "Sätesöverdrag förhindrar skyddsanordningens funktion", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "801", "text": "Määräyksen vastainen", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "801", "text": "Strider mot föreskriften", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "802", "text": "Skadad lagring", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "802", "text": "Laakerointi vaurioitunut", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "803", "text": "Glödlampa inte kompatibel med strålkastaren", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "803", "text": "Valaisimeen soveltumaton polttimo", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "804", "text": "Fordonet meddelar om ett fel", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "804", "text": "Ajoneuvo ilmoittaa viasta", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "805", "text": "Obligatorisk utrustning saknas", "lng": "sv"},
  {"code": "KdKatsaVika", "codeValue": "805", "text": "Pakollinen varuste puuttuu", "lng": "fi"},
  {"code": "KdKatsaVika", "codeValue": "806", "text": "Bristfällig funktion", "lng": "sv"},
  {"code": "KdKatsaYksittainMaahantuotu", "codeValue": "1", "text": "Tuotu käytettynä", "lng": "fi"},
  {"code": "KdKatsaYksittainMaahantuotu", "codeValue": "2", "text": "Tuotu uutena", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "01", "text": "Yksityinen", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "01", "text": "Privat", "lng": "sv"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "02", "text": "Luvanvarainen", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "02", "text": "Tillståndspliktig", "lng": "sv"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "03", "text": "Kouluajoneuvo", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "03", "text": "Skolfordon", "lng": "sv"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "04", "text": "Vuokraus ilman kuljettajaa", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "04", "text": "Uthyrning utan förare", "lng": "sv"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "05", "text": "Myyntivarasto", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "05", "text": "Försäljningslager (lagerförsäkring)", "lng": "sv"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "06", "text": "Luvanvarainen tavaraliikenne", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "06", "text": "Tillståndspliktig godstrafik", "lng": "sv"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "02", "text": "Leasing", "lng": "fi"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "03", "text": "Työsuhde", "lng": "fi"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "04", "text": "Muu", "lng": "fi"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "02", "text": "Leasing", "lng": "sv"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "03", "text": "Anställningsförhållande", "lng": "sv"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "04", "text": "Annat", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "01", "text": "Tavallinen poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "01", "text": "Vanlig avregistrering", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "02", "text": "Käyttämättömyyspoisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "02", "text": "Avregistrerad som obegagnad", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "03", "text": "Viranomaispoisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "03", "text": "Avregistrerad av myndighet", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "04", "text": "Vaurioituneena poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "04", "text": "Avregistrerad som skadad", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "05", "text": "Kunnan poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "05", "text": "Avregistrerad av kommun", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "06", "text": "Ahvenanmaalle poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "06", "text": "Avregistrerad till Åland", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "07", "text": "Avregistrerad till utlandet", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "08", "text": "Tuhoutuneena poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "08", "text": "Avregistrerad som förstörd", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "09", "text": "Romutuspoisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "09", "text": "Skrotavregistrering", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "10", "text": "Itse purettu / tuhottu", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "10", "text": "Själv demonterat / destruerat", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "07", "text": "Ulkomaille poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "11", "text": "Avställning för registrering i militärfordonsregistret", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "11", "text": "Sotilasajoneuvorekisteriin poisto", "lng": "fi"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "01", "text": "Luovutusilmoitus", "lng": "fi"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "02", "text": "Haltijan hallintaluovutus", "lng": "fi"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "03", "text": "Luovutus tuntemattomalle", "lng": "fi"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "01", "text": "Anmälan om överlåtelse", "lng": "sv"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "02", "text": "Innehavarens överlåtelse av besittningsrätten", "lng": "sv"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "03", "text": "Överlåtelse till okänd", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "01", "text": "1.omistaja", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "02", "text": "Muu omistaja", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "03", "text": "1.haltija", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "04", "text": "Muu haltija", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "05", "text": "Väliomistaja", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "06", "text": "Jälleenmyyjä", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "07", "text": "Vastaanottaja", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "08", "text": "Entinen omistaja", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "09", "text": "Entinen haltija", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "01", "text": "1 ägaren", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "02", "text": "Annan ägare", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "03", "text": "1 innehavaren", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "04", "text": "Annan innehavaren", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "05", "text": "Mellanägare", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "06", "text": "Återförsäljare", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "07", "text": "Mottagare", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "08", "text": "Förutvarande ägare", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "09", "text": "Förutvarande innehavare", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "01", "text": "Korjaamolle siirto", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "01", "text": "Förflyttning till verkstad", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "02", "text": "Katsastukseen siirto", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "03", "text": "Korjaamolle/katsastukseen siirto", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "03", "text": "Förflyttning till verkstad/besiktning", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "04", "text": "Rajalle siirto", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "04", "text": "Förflyttning till gränsen", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "05", "text": "Esittely", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "05", "text": "Demonstration", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "06", "text": "Kilpailu", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "06", "text": "Tävling", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "07", "text": "Autoveroasian käsittely kesken", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "07", "text": "Bilbeskattning under behandling", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "08", "text": "Siirto", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "08", "text": "Förflyttning", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "09", "text": "Muu syy", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "09", "text": "Övrig sak", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "02", "text": "Förflyttning till besiktning", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "10", "text": "Bilaffärens demonstrationsbruk", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "10", "text": "Autoliikkeen esittelykäyttö", "lng": "fi"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "01", "text": "Liikennevakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "02", "text": "Koenumerovakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "03", "text": "Varastovakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "01", "text": "Trafikförsäkring", "lng": "sv"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "02", "text": "Provnummerförsäkring", "lng": "sv"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "03", "text": "Lagerförsäkring (försäljningslager)", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "26", "text": "Protector Forsikring ASA", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "26", "text": "Protector Forsikring ASA", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "03", "text": "Lähivakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "03", "text": "Lokalförsäkring", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "04", "text": "A-vakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "04", "text": "A-vakuutus", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "13", "text": "Pohjantähti", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "13", "text": "Pohjantähti", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "14", "text": "IF sivuliike", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "15", "text": "Volvia", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "15", "text": "Volvia", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "19", "text": "Ingonord", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "19", "text": "Ingonord", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "20", "text": "Tryg", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "20", "text": "Tryg", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "21", "text": "Ups", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "21", "text": "Ups", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "22", "text": "Tapiola", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "22", "text": "Tapiola", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "29", "text": "Axa", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "29", "text": "Axa", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "30", "text": "Kansa", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "30", "text": "Kansa", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "32", "text": "Varma", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "32", "text": "Varma", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "36", "text": "Pohjola", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "36", "text": "Pohjola", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "39", "text": "AIG", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "39", "text": "AIG", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "40", "text": "Euro Insurances", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "40", "text": "Euro Insurances", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "41", "text": "IF", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "41", "text": "IF", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "43", "text": "Y-Sampo", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "43", "text": "F-Sampo", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "50", "text": "Fennia Skadeförsäkring", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "50", "text": "Fennia Skadeförsäkring", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "52", "text": "IF-TEVA", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "52", "text": "IF-TEVA", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "56", "text": "Turva", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "56", "text": "Turva", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "65", "text": "Fennia", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "65", "text": "Fennia", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "66", "text": "Ålands ömsesidiga försäkringsbolag", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "66", "text": "Ålands ömsesidiga", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "90", "text": "Valtiokonttori", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "90", "text": "Valtiokonttori", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "92", "text": "LVK", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "92", "text": "LVK", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "99", "text": "Ulkomainen", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "99", "text": "Utländsk", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "14", "text": "IF sivuliike", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "38", "text": "Suomen Vahinkovakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "38", "text": "Suomen Vahinkovakuutus", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "11", "text": "Greenval Insurance", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "11", "text": "Greenval Insurance", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "44", "text": "LähiTapiola", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "44", "text": "LähiTapiola", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "98", "text": "Ei vakuutusta", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "98", "text": "Ingen försäkring", "lng": "sv"},
  {"code": "KdSanomavirheet", "codeValue": "1124", "text": "Vakuutusyhtiö voi päättää vain oman yhtiönsä vakuutuksia", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "736", "text": "Ei myönnetty jatkokäsittelylupaa (HO).", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1129", "text": "Vakuutuksen päättäminen on sallittu vain henkilöpalvelussa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1130", "text": "Vakuutuksen alkupäivä ei saa olla yli 7 päivää aikaisempi kuin ilmoituspäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1131", "text": "Luovutusilmoitettu ostaja on jo ajoneuvon omistaja", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "737", "text": "Ei valitettu (HO).", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "737", "text": "Ei valitettu (HO).", "lng": "sv"},
  {"code": "KdSanomavirheet", "codeValue": "738", "text": "Sillensä valittajan poissaolon johdosta (HO).", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "738", "text": "Sillensä valittajan poissaolon johdosta (HO).", "lng": "sv"},
  {"code": "KdSanomavirheet", "codeValue": "1053", "text": "Varmenne on virheellinen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1054", "text": "Ajoneuvolla on myyntirajoitus", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1055", "text": "Vakuutuksen alkupäivä ei saa olla suurempi kuin ilmoituspäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1057", "text": "Haltijan hallintasuhde-tieto puuttuu", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1058", "text": "1. omistaja on alaikäinen. Ajoneuvolle merkittävä ajo-oikeuden omaava 1. haltija, joka ikänsä puolesta voi kuljettaa ajoneuvoa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1059", "text": "1. haltija on alaikäinen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1062", "text": "Annettava joko varmenne tai rekisteröintitodistuksen II-osa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1063", "text": "Rekisteröintitodistuksen II-osan järjestysnumero on virheellinen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1064", "text": "Varmenne ei ole voimassa. Ilmoituspäivä on myöhäisempi kuin varmenteen voimassaolon päättymispäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1065", "text": "Ilmoituspäivän oltava kuluva päivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1066", "text": "Luovutuspäivä ei saa olla suurempi kuin ilmoituspäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1067", "text": "Ilmoituspäivä ei saa olla suurempi kuin kuluva päivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1068", "text": "Ilmoituspäivä aikaisempi kuin ennakkoilmoituspäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1069", "text": "Vain rekisteriin merkitty omistaja tai viimeisin luovutusilmoitettu voi tehdä luovutusilmoituksen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1071", "text": "Tunnusta ei löydy HENKIstä tai se ei ole voimassa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1073", "text": "Virheellinen postinumero", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1074", "text": "Virheellinen luovutuspäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1075", "text": "Luovutuspäivä ei saa olla suurempi kuin kuluva päivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1076", "text": "Virheellinen liikenteestäpoistopäivä. Itsepalvelussa päivän tulee olla kuluva päivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1077", "text": "Virheellinen liikenteestäpoistopäivä. Päivä ei voi olla tulevaa aikaa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1079", "text": "Virheellinen poiston laatu", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1080", "text": "Ajoneuvolla on ajoneuvoveron maksamattomuustieto", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1081", "text": "Virheellinen liikenteeseenottopäivä. Päivä ei voi olla eri kuin ilmoituspäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1082", "text": "Virheellinen liikenteeseenottopäivä. Päivä ei voi olla tulevaa aikaa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1084", "text": "Virheellinen käyttötieto", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1085", "text": "Ajoneuvolta puuttuu vakuutus", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1086", "text": "Ajoneuvolla ei ole voimassaolevaa rekisteröintikatsastusta", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1088", "text": "Ajoneuvon tiedot eivät ole muuttuneet. Rekisteröintiä ei tehty", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1089", "text": "Ajoneuvo on diplomaattiajoneuvo. Rekisteröinti ei ole sallittu tässä palvelussa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1090", "text": "Muutos edellyttää uuden vakuutuksen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1092", "text": "Vakuutus ei ole voimassa. Vakuutusta ei voi päättää", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1093", "text": "Alle 15-vuotias ei voi toimia ilmoittajana tai valtuutettuna", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1096", "text": "Ajoneuvoryhmä estää toimenpiteen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1097", "text": "Liikennevakuutuksen voi päättää vain liikennekäytöstä poistetuilta ajoneuvoilta", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1098", "text": "Luovutuspäivä ei voi olla aikaisempi kuin liikennekäytöstäpoistopäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1099", "text": "Ajoneuvolla on luovutusilmoitus tuntemattomalle", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1100", "text": "Ilmoittaja ei ole omistaja tai haltija", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1101", "text": "Poistopäivä ei saa olla suurempi kuin ilmoituspäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1102", "text": "Varmennetta ei löydy", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1103", "text": "Ajoneuvon versio on vanhentunut", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1104", "text": "Ennakkoilmoittajan ennakkoilmoitussopimus ei ole voimassa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1105", "text": "Ajoneuvon katsastus ei ole voimassa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1106", "text": "Sanomalta puuttuu 1. omistaja", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1107", "text": "Ajoneuvolla ei ole liikenteestä poistoa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1108", "text": "Tekninen virhe ENNI-liittymässä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1109", "text": "Tekninen virhe KATSA-liittymässä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1110", "text": "Tekninen virhe SOKU-liittymässä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1111", "text": "Tekninen virhe TYYTI-liittymässä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1112", "text": "Tekninen virhe KILPI-liittymässä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1113", "text": "Vakuutustiedot ovat pakollisia", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1114", "text": "Sama tunnus voi olla vain kerran samalla omistajuustyypillä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1115", "text": "Ajoneuvolla on haltijan hallintaluovutus", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1117", "text": "Rekisteröintitodistuksen II-osan järjestysnumeroa ei voi antaa itsepalvelussa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1118", "text": "Annettava varmenne", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1119", "text": "Ajoneuvo on liikennekäytöstä poistettu. Uuden liikennekäytöstäpoiston tekeminen ei ole sallittu sähköisessä palvelussa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1120", "text": "Vakuutuksen muutos ei ole sallittu liikennekäyttöönotossa sähköisessä palvelussa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1121", "text": "Rekisteröintitodistuksen vastaanottajan osoite ei voi olla ulkomailla", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1122", "text": "Haltijalla ei ole oikeutta tilata todistuksen ilmoitusosaa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1123", "text": "Itsepalvelussa luovutusilmoitus on sallittu vain ostajan tunnuksella", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1125", "text": "Vakuutusta ei voida yksilöidä annetuille tiedoilla", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1126", "text": "Vakuutuksen voi päättää kuluvalla tai menneellä päivämäärällä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1127", "text": "Ajoneuvolle myönnetty siirtolupa estää ensirekisteröinnin sähköisessä palvelussa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1128", "text": "Rekisteröintitodistuksen tilaus on sallittu vain Omatrafissa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1083", "text": "Virheellinen liikenteeseenottopäivä. Itsepalvelussa päivän tulee olla kuluva päivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1078", "text": "Ajoneuvo on jo poistettu liikenteestä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1087", "text": "Ilmoittaja on tehnyt luovutusilmoituksen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1132", "text": "Ajoneuvolla on lopullinen poisto", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "357", "text": "Tarkasta ajoneuvon katsastustapahtumat. Keskeneräinen katsastus pitää poistaa tai sitä pitää jatkaa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "590", "text": "Käyttöoikeusvirhe. Oikeutesi eivät riitä kyselylajiin ja valittuun käyttötarkoitukseen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "590", "text": "Käyttöoikeusvirhe. Oikeutesi eivät riitä kyselylajiin ja valittuun käyttötarkoitukseen.", "lng": "sv"},
  {"code": "KdSanomavirheet", "codeValue": "356", "text": "Tarkista ajoneuvon tiedot. Ajoneuvo ei ole katsastusvelvollinen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "317", "text": "Vikatiedon huomautuksessa on kiellettyjä merkkejä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "318", "text": "Uudempi katsastus on olemassa. Tapahtuman voi tallentaa vain käyttöliittymällä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "319", "text": "Vastaavanlainen katsastus on jo tallennettu 5 minuutin sisällä. Jos haluat silti tallentaa, odota 5 minuuttia tai tee tallennus käyttöliittymällä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "320", "text": "Ajokieltoa ei voi lisätä hyväksytyssä katsastuksessa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "355", "text": "Tekninen virhe.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1045", "text": "Tieto sisältää kiellettyjä merkkejä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1046", "text": "Virheellinen koodiarvo", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1047", "text": "Valmistenumerolla löytyy jo voimassaoleva käyttämätön lupa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1048", "text": "Autoveropäätösnumerolla löytyy jo lupa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1049", "text": "Lupaa ei löydy autoveropäätösnumerolla", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1050", "text": "Alkuperäinen valmistenumero ei täsmää luvan valmistenumeroon", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1051", "text": "Lupa on jo käytetty", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "470", "text": "Käyttäjän oikeudet ei riitä enintään 3500 kg", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "733", "text": "Korttitoimittajalle palautettua korttia ei löydy järjestelmästä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "734", "text": "Korttitoimittajalle palautettua korttia ei voida yksilöidä. Kortteja löytyi useampia.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "735", "text": "Korttitoimittajalle palautettua korttia ei voida siirtää tuhotuksi. Virheellinen tilasiirtymä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "700", "text": "Tarkista liikennerikosratkaisun lainkohdat.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "701", "text": "Henkilötunnus puuttuu.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "702", "text": "Asiakkaan tietoja ei löydy HENKIstä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "703", "text": "Liikennerikosilmoituksen ja liikennerikosratkaisun rikosilmoitusnumerot eivät täsmää.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "704", "text": "Ennakkotiedon ja liikennerikosratkaisun seuraamustiedot poikkeavat toisistaan.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "705", "text": "Rekisteristä löytyy jo samansisältöinen liikennerikosratkaisu", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "706", "text": "Pakollisia tietoja puuttuu.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "707", "text": "Tekoajaksi viety päätöspäivä. Tarkista tekoajat.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "708", "text": "Tarkista onko ennakkotietona ilmoitettu ajokielto kokonaan kärsitty virheellinen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "709", "text": "HENKIstä löytyi useita samannimisiä henkilöitä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "710", "text": "Asiakas on passivoitu", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "711", "text": "HENKI yhteyttä ei saada", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "712", "text": "Virheellinen ajokiellon koetusajan viimeinen voimassaolopvm.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "713", "text": "Pakollisia tietoja puuttuu.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "714", "text": "Välitetty tieto on virheellinen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "715", "text": "Yhteyttä HENKIiin ei saatu.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "716", "text": "Opetuksen antajan tietoja ei löydy HENKIstä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "717", "text": "Opetuksen antajan ajo-oikeus ei ole voimassa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "718", "text": "Asiakkaalla on Henkilötietojärjestelmässä passivointi- tai kuolintieto, joten asiakkaan lisääminen ei ole mahdollista.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "719", "text": "Asiakkaan tietoja ei löydy HENKIstä. Asiakkaan tiedot on ensin lisättävä henkilötietojärjestelmään.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "720", "text": "Asiakkaan hetu on virheellinen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "721", "text": "Asiakas on kieltänyt vanhojen kuvien käytön.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "723", "text": "Vireilletulopäivämäärä ei saa olla tulevaisuudessa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "724", "text": "Arkistotunnus on väärää sarjaa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "725", "text": "Asiakkaan ajo-oikeus ei ole voimassa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "726", "text": "Asiakasta ei löydy HENKIstä eikä VTJ:stä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "727", "text": "Henkilötietojärjestelmän käyttämässä VTJ yhteydessä tapahtui häiriö.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "728", "text": "Henkilö löytyy VTJ:stä, mutta tunnus on passivoitu.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "729", "text": "Henkilö löytyy VTJ:stä, mutta HENKIssä on samalla syntymäajalla keinotunnuksellisia henkilöitä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "730", "text": "Henkilötietohaussa tapahtunut muu virhe.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "731", "text": "Hakemuksessa välitetty arkistotunnus on jo Ajo-oikeusjärjestelmässä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "732", "text": "Hakemuksen  käsittelyssä tapahtui muus virhe Ajo-oikeusjärjestelmässä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "900", "text": "Tiedot puutteelliset", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "901", "text": "Muu virhe", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "507", "text": "Ajoneuvon käyttövastaavan tietojen haku epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "508", "text": "Ajoneuvon eVaky-tietojen haku epäonnistui.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "530", "text": "Historiatietojen haussa sukupolvi oltava > 3", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "531", "text": "Hakuehdot virheelliset. Henkilön ajokorttitietoja ei haeta.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "532", "text": "Hakuehdot ristiriitaiset. Henkilön ajokorttitietoja ei haeta.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "562", "text": "Annetulla henkilötunnuksella ei löydy henkilöä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "570", "text": "Hakuehdot virheelliset. Henkilön ajokorttitietoja ei haeta.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "571", "text": "Hakuehdot ristiriitaiset. Henkilön ajokorttitietoja ei haeta.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "572", "text": "Kuljettajaa ei löydy.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "573", "text": "Annetulla henkilötunnuksella ei löydy henkilöä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "574", "text": "Ajoneuvotietojen haku epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "575", "text": "Kuljettajatietojen haku epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "576", "text": "Ajoneuvo ja kuljettajatietojen haku epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "577", "text": "Ajokorttiluokkatietojen haku epäonnistui.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "578", "text": "Henkilölle ei löydy ajokorttia", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "579", "text": "Henkilöllä ei ole voimassaolevia ajo-oikeuksia.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "580", "text": "Henkilöä ei löydy.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "581", "text": "Ajoneuvoa ei löydy.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "582", "text": "Hakuehto ei ole kyselylajin mukainen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "583", "text": "Ajoneuvon hakuehdolla löytyi useampi ajoneuvo. Tarkenna hakuehtoa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "722", "text": "Asiakas ei ole toimittanut uusia kuvia ja kieltänyt vanhojen kuvien käytön.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "101", "text": "Välityspalvelu ei saanut vastausta synkroniseen virheeseen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "102", "text": "Välityspalvelussa on tapahtunut muunnosvirhe. Vaatii väliintuloa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "103", "text": "Välityspalvelu on havainnut sopimuskumppanin lähettämässä sanomassa muotovirheen. Vaatii väliintuloa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "104", "text": "Välityspalvelu ei onnistunut lähettämään sanomaa sopimuskumppanille (yhteys poikki tms.)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "201", "text": "Sanomavälittäjä on havainnut, että autentikointitiedot eivät kelpaa. Mahdollisesti käyttäjätunnus, tietojärjestelmätunnus tai toimipaikka ovat epäkelvot, salasana on väärä tai käyttäjätunnus on suljettu pois käytöstä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "202", "text": "Sanomavälittäjä on havainnut virheen sanoman reititystiedoissa. Vaatii väliintuloa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "203", "text": "Sanomavälittäjässä on tapahtunut muunnosvirhe. Vaatii väliintuloa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "204", "text": "Autentikointipalvelimeen ei saatu yhteyttä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "205", "text": "Sanomavälittäjä on havainnut sanomassa muotovirheen. Vaatii väliintuloa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "298", "text": "Sanomavälittäjä ei onnistunut kirjoittamaan sanomaa sanomajonoon teknisestä virheestä johtuen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "299", "text": "Sanomavälittäjä havaitsi sanoman käsittelyssä teknisen virheen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "300", "text": "Pakollisia tietoja puuttuu", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "300+", "text": "Varattu sovellusvirheille.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "301", "text": "Muotovirheitä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "302", "text": "Virheellinen koodiarvo", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "303", "text": "Tapahtumasanoman kirjoitus/lähetys epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "304", "text": "Ajoneuvoa ei löydy", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "305", "text": "Tulostus epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "306", "text": "Järjestelmä ei saa yhteyttä tulostuspalveluun.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "307", "text": "ASTA-sanoman välitys käsittelyä varten epäonnistui.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "308", "text": "Virheellinen sanoma", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "309", "text": "Paluusanoman kirjoitus/lähetys epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "310", "text": "Sovellusvirhe", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "311", "text": "TYYTI-liittymäkutsussa tapahtui virhe, ota yhteys ATJ-järjestelmätukeen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "312", "text": "REKI-liittymäkutsussa tapahtui virhe, ota yhteys ATJ-järjestelmätukeen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "313", "text": "Arkistotunnus löytyy jo kannasta.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "314", "text": "Rajoitustiedon poisto ei onnistunut.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "315", "text": "VAKADR:n hyväksynnän jatkaminen ei onnistunut.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "316", "text": "Löytyi useampi ajoneuvo, tarkenna hakua.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "321", "text": "Katsastajan roolin mukaiset oikeudet eivät riitä ajoneuvoluokan määräaikaiskatsastukseen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "506", "text": "P002-haku epäonnistui (Eräpalvelut)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "323", "text": "Ajoneuvolla on rekisteröinnissä rajoitus 'lopullinen liikenteestä poisto'.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "324", "text": "Ajoneuvolla on rekisteröinnissä voimassaoleva liikenteestä poisto.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "325", "text": "Ajoneuvon tila on väärä. Sitä ei ole ensirekisteröity/muutosrekisteröity.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "326", "text": "Jatkettavaa keskeytettyä katsastussuoritetta ei löydy.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "327", "text": "Jälkitarkastus ei ole mahdollinen, koska viimeisimmän hylätyn määräaikaiskatsastuksen katsastuspäivämäärä ei ole määräajan sisällä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "328", "text": "Tulostustapa muuttui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "329", "text": "Tilan tallennus REKIin epäonnistui.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "330", "text": "Löytyy useita ajoneuvoja.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "331", "text": "Ajoneuvolla on jo ajoneuvoveron maksamattomuus-rajoitus.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "332", "text": "Ajoneuvolle ei löydy voimassaolevaa ajoneuvoveron maksamattomuus-rajoitusta", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "333", "text": "Ajoneuvolle ei löydy katsastusta", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "334", "text": "Ajoneuvolle löytyy useita katsastuksia", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "335", "text": "Katsastuksella on jo asiakirjayhteys", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "336", "text": "Arkistotunnus liitetty katsastuksen viimeisimpään tapahtumaan.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "337", "text": "Arkistotunnus liitettiin vanhaan katsastukseen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "338", "text": "Virheellinen katsastuslaji", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "339", "text": "Ei oikeutta määräaikaiskatsastuksen tekoon.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "340", "text": "WVTA:ssa poistettu tietoja, muutoksia ei tehdä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "341", "text": "Versiota ei löydy.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "342", "text": "Selitteelle ei löydy koodia.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "343", "text": "Tiedon vanha arvo ei täsmää.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "344", "text": "Uusi arvo on virheellinen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "345", "text": "Tunnistetiedot muuttuneet.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "346", "text": "Tietoa ei saa päivittää.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "347", "text": "Tietoa ei saa tyhjentää.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "348", "text": "Virheellinen turvajärjestelmä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "349", "text": "Hakemusta ei löydy.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "350", "text": "Hakemus on väärässä tilassa.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "351", "text": "Virheellinen ratkaisu.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "360", "text": "Tilausta ei löydy.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "361", "text": "Tunnus hylätty valm.tilauksesta (varattu).", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "362", "text": "Tunnus hylätty valm.tilauksesta (hylätty).", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "363", "text": "Tunnus hylätty valm.tilauksesta (tilattu erityis-/yksittäiskilpenä).", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "364", "text": "Tunnus hylätty valm.tilauksesta (ennakkoilmoitettu).", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "365", "text": "Tunnus hylätty valm.tilauksesta (rekisteröity).", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "366", "text": "Tunnus hylätty valm.tilauksesta (liikenteestäpoisto).", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "367", "text": "Tunnus hylätty valm.tilauksesta (varastossa).", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "368", "text": "Kilpitilausta ei löydy", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "369", "text": "Kilpitilauksella on jo asiakirjayhteys", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "370", "text": "Poistettavaa asiakirjayhteyttä ei löydy", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "371", "text": "Arkistotunnus on jo toisella tilauksella.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "380", "text": "Löytyy useita ajoneuvoja", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "381", "text": "Järjestelmässä on voimassaoleva anastustieto lähetetyillä yksilöintitiedoilla", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "382", "text": "Poliisiyksikkö virheellinen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "383", "text": "Päivämäärä virheellinen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "384", "text": "Tieto ei ole sallittujen arvojen puitteissa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "385", "text": "Tunnistetiedot puutteelliset", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "386", "text": "Tieto on jo tietokannassa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "387", "text": "Ajoneuvolle ei löydy tapahtumaa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "388", "text": "Päätösnumerolla ei löydy kiinnitystä/hakemusta", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "389", "text": "Varastovakuutuksen päättymispäivä on aikaisempi kuin vakuutuksen alkamispäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "390", "text": "Vakuutustyyppi ristiriidassa tunnistetietoihin", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "391", "text": "Vakuutustyyppi ristiriidassa vakuutustietoihin", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "392", "text": "Ajoneuvolla/koenumerolla ei ole anastustietoa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "393", "text": "Anastusilmoituksen tiedot puutteelliset (puuttuva tieto)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "394", "text": "Ajoneuvoa/koenumeroa ei löydy", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "395", "text": "Tullin lupatietoja ei löytynyt", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "396", "text": "Hakuehdolla löytyi useita lupia", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "397", "text": "Henkilöä ei löydy", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "398", "text": "Vakuutustiedot eivät täsmää", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "399", "text": "Ajoneuvolla/koenumerolla ei löydy maksamattomuustietoa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "400", "text": "Tiedossa puutteita tai ristiriita", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "401", "text": "Tietojen tallennus epäonnistui teknisestä syystä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "402", "text": "Valmistenumero on virheellinen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "403", "text": "Sopimusta ei löydy", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "404", "text": "Ennakkoilmoitussopimus ei ole voimassa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "405", "text": "Ei oikeutta versioon", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "406", "text": "Versiota ei löydy", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "407", "text": "Versio ei ole voimassa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "408", "text": "Oikeus ennakkoilmoittaa vain autoverottomia ajoneuvoja", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "409", "text": "Oikeus ennakkoilmoittaa vain autoverottomia EU-maissa kokoonpantuja ajoneuvoja", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "410", "text": "Valmistenumerolla on jo ajoneuvo ATJ:ssä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "411", "text": "Ennakkoilmoitus tiedostona vain valmiille ajoneuvolle", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "412", "text": "Häntäluvan voimassaolo on päättynyt", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "413", "text": "Ajoneuvo on anastettu, tallennusta/päivitystä ei voi jatkaa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "414", "text": "Tiedon koodiarvo ei täsmää", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "421", "text": "Seisonta-aika:alkupäivä- ja loppupäivä ristiriitaiset", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "422", "text": "Seisonta-aika:alkupäivä aikaisempi kuin vakuutuksen alkamispäivä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "423", "text": "Koenumerolla ei voi olla seisonta-aikaa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "424", "text": "Pyydettyä maahantuojan varastoa ei löytynyt", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "425", "text": "Tiedostossa on samalla valmistenumerolla ja versiolla ajoneuvo.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "426", "text": "Virheellinen ilmoituksen laatu", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "427", "text": "Yli 4-akseliset ajoneuvot ennakkoilmoitetaan käyttöliittymällä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "428", "text": "Poistettavaa käyttövastaavaa ei löydy.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "429", "text": "Tieto sisältää kiellettyjä merkkejä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "430", "text": "Yrityksellä on jo uudempi käyttövastaava-päivitys.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "500", "text": "Ajoneuvon kyselyviesti on virheellinen", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "501", "text": "Ajoneuvon suppeiden tietojen haku epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "502", "text": "Ajoneuvon perustietojen haku epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "503", "text": "Ajoneuvon teknisten tietojen haku epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "504", "text": "Ajoneuvon laajojen tietojen haku epäonnistui", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "505", "text": "P001-haku epäonnistui (Eräpalvelut)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "322", "text": "Katsastajan roolin mukaiset oikeudet eivät riitä kokonaismassaltaan yli 3500 kg ajoneuvojen määräaikaiskatsastukseen.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "520", "text": "InfoRec ei ole määritelty", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "521", "text": "Hakuehtoja ei ole määritelty", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "522", "text": "Hakuehtoja ei ole määritelty [rekisteritunnus, valmistenumero, järjestelmätunnus]", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "523", "text": "Rekisteritunnushaku: Ajoneuvolajia ei ole määritelty", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "524", "text": "Rekisteritunnushaku: Rekisteritunnus on liian pitkä (>9)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "525", "text": "Valmistenumerohaku: Valmistenumero on liian pitkä (>30)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "526", "text": "Järjestelmätunnushaku: Järjestelmätunnus on liian pitkä (>10)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "527", "text": "Hakuehdot: Asiakasta ei ole määritelty", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "528", "text": "Hakuehdot: Soku-tunnusta ei ole määritelty", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "529", "text": "Hakuehdot: Palvelutunnusta ei ole määritelty", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "533", "text": "Käyttövastaava on kuollut", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "540", "text": "Valmistenumerolla löytyi enemmän kuin yksi ajoneuvo", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "541", "text": "Järjestelmätunnuksella löytyi enemmän kuin yksi ajoneuvo", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "542", "text": "Rekisteritunnuksella löytyi enemmän kuin yksi ajoneuvo", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "550", "text": "Ajoneuvoa ei löydy rekisteristä, tarkista rekisteritunnus.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "551", "text": "Ajoneuvoa ei löydy", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "560", "text": "PATJA ei vastaa (timeout)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "561", "text": "PATJA ei vastaa (virhetilanne)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1024", "text": "Ajoneuvolla on tallennuksen estävä rajoitus (muutoskielto, takavarikko)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1016", "text": "Ajoneuvo on poistettu liikenteestä (Ahvenanmaalle poisto)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1017", "text": "Ajoneuvo on poistettu liikenteestä (ulkomaille poisto)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1018", "text": "Ajoneuvo on poistettu lopullisesti liikenteestä (tuhoutuneena poisto, vakuutusyhtiö/kunta)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1019", "text": "Ajoneuvo on poistettu lopullisesti liikenteestä (romutuspoisto)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1020", "text": "Ajoneuvo on poistettu lopullisesti liikenteestä (itse purettu / tuhottu)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1021", "text": "Ajoneuvo on anastettu", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1022", "text": "Ajoneuvolla on tallennuksen estävä rajoitus (Trafin muutoskielto)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1023", "text": "Ajoneuvolla on tallennuksen estävä rajoitus (muutoskielto, ulosmittaus)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "415", "text": "Valmistenumerolla löytyy useampi ajoneuvotekninen poikkeuslupa, ajoneuvoa ei voi yksilöidä.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "416", "text": "Pakollisia tietoja puuttuu tai tiedot suurempia/pienempiä kuin raja-arvot sallivat.", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1001", "text": "Pakollisia tietoja puuttuu", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1002", "text": "Virheellinen tietotyyppi", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1003", "text": "Virheellinen päivityslaji", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1004", "text": "Virheellinen vakuutusyhtiön koodi", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1005", "text": "Virheellinen vakuutustyyppi", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1006", "text": "Virheellinen vakuutuksen ottaja", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1007", "text": "Vakuutusyhtiötä ei löydy LDAP:sta", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1008", "text": "Tietokantavirhe", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1009", "text": "Muu tekninen virhe", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1010", "text": "Ajoneuvoa ei löydy", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1011", "text": "Ajoneuvolla on luovutusilmoitus", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1012", "text": "Ajoneuvo on poistettu liikenteestä (tavallinen poisto, kilvet palautettu)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1013", "text": "Ajoneuvo on poistettu liikenteestä (viranomaispoisto)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1014", "text": "Ajoneuvo on poistettu liikenteestä (vaurioituneena poisto, vakuutusyhtiö)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1015", "text": "Ajoneuvo on poistettu liikenteestä (kunnan poisto)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1025", "text": "Ajoneuvolla on tallennuksen estävä rajoitus (muutoskielto, konkurssi)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1026", "text": "Ajoneuvolla on tallennuksen estävä rajoitus (muutoskielto, saneeraus)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1027", "text": "Ajoneuvolla on tallennuksen estävä rajoitus (muutoskielto, velkajärjestely)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1028", "text": "Ajoneuvolla on tallennuksen estävä rajoitus (muutoskielto, myymis- ja hukkaamiskielto)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1029", "text": "Ajoneuvolla on tallennuksen estävä rajoitus (viretieto)", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1030", "text": "Ajoneuvon tunnus ei ole voimassa", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1031", "text": "Uusi vakuutuksen ottaja ei ole ajoneuvon omistaja tai haltija", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1032", "text": "Vakuutuksen alkamisesta on kulunut yli 7 päivää", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1033", "text": "Vakuutus alkaa yli 30 päivän päästä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1034", "text": "Ajoneuvolla on rekisterissä uudempi vakuutus", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1035", "text": "Ajoneuvolla ei ole voimassaolevaa vakuutusta", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1036", "text": "Vakuutusyhtiö ja vakuutuksenottaja ovat samat kuin rekisterissä olevat", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1037", "text": "Vakuutustyyppi ei täsmää ajoneuvon käyttötietoon", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1038", "text": "Ajoneuvolta ei löydy peruutettavaa vakuutusta", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1039", "text": "Vakuutuksen alkamisesta on kulunut yli 30 päivää", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1040", "text": "Vakuutuksen muutos ei ole ajoneuvon viimeisin tapahtuma", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1041", "text": "Vakuutuksen muutos on tehty eri vakuutusyhtiössä", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1042", "text": "Tekninen virhe: KOODI-liittymäkutsussa tapahtui virhe", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1043", "text": "Tekninen virhe: HENKI-liittymäkutsussa tapahtui virhe", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1044", "text": "Tekninen virhe: LDAP-liittymäkutsussa tapahtui virhe", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "358", "text": "Virheellinen toimipaikka tai yritystieto", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1134", "text": "Rekisteröintitodistuksen vastaanottajan osoite ei voi olla tuntematon", "lng": "fi"},
  {"code": "KdSanomavirheet", "codeValue": "1133", "text": "Ajoneuvo on poistettu liikenteestä (Sotilasajoneuvorekisteriin poisto)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra2", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra3", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra3", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra4", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra4", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb1", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb1", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb2", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb2", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb3", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb3", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb4", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb4", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sa1", "text": "Vedettävä kone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sa1", "text": "Dragen maskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sa2", "text": "Vedettävä kone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sa2", "text": "Dragen maskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sb1", "text": "Vedettävä kone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sb1", "text": "Dragen maskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sb2", "text": "Vedettävä kone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sb2", "text": "Dragen maskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T4", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T4", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T5", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T5", "text": "Traktori", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L1", "text": "Mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C4", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C4", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C5", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C5", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "KNP", "text": "Kevyt nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "KNP", "text": "Lätt fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra2", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L1", "text": "Moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L1e", "text": "Mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L1e", "text": "Moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L2", "text": "Mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L2", "text": "Moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L2e", "text": "Mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L2e", "text": "Moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L3", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L3", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L3e", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L3e", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L4", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L4", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L4e", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L4e", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L5", "text": "Kolmi- tai nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L5", "text": "Tre- eller fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L5e", "text": "Kolmipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L5e", "text": "Trehjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L6e", "text": "Kevyt nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L6e", "text": "Lätt fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L7e", "text": "Nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L7e", "text": "Fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "LTR", "text": "Liikennetraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "LTR", "text": "Trafiktraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M1", "text": "Henkilöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M1", "text": "Personbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M1G", "text": "Henkilöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M1G", "text": "Personbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M2", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M2", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M2G", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M2G", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M3", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M3", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M3G", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M3G", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MA", "text": "Maastoajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MA", "text": "Terrängfordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MTK", "text": "Moottorityökone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MTK", "text": "Motorredskap", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MUU", "text": "MUU", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MUU", "text": "ÖVRIG", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N1", "text": "Pakettiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N1", "text": "Paketbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N1G", "text": "Pakettiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N1G", "text": "Paketbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N2", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N2", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N2G", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N2G", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N3", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N3", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N3G", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N3G", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O1", "text": "Kevyt perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O1", "text": "Lätt släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O2", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O2", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O3", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O3", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O4", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O4", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra1", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra1", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "R3b", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "R3b", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "97", "text": "Lakaisukone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "97", "text": "Sopningsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "98", "text": "Hiekanlevitin", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "98", "text": "Sandspridare", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "99", "text": "Linjanrakennuskone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "99", "text": "Linjebyggnadsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "90", "text": "Bomskoplastare", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "91", "text": "(monitoimi)metsäkone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "91", "text": "(mångssysslo) skogsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "92", "text": "Haketuskone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "92", "text": "Flisningsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "90", "text": "Puomikauhakuormain", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "93", "text": "Vetotrukki", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "93", "text": "Dragtruck", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "94", "text": "Snöslunga", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "95", "text": "Hajasääritrukki", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "95", "text": "Grensletruck", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "96", "text": "Kuljetusalusta", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "94", "text": "Lumilinko", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "134", "text": "Pieni esteetön ajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "134", "text": "Litet tillgängligt fordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "135", "text": "Suuri esteetön ajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "135", "text": "Stort tillgängligt fordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "136", "text": "Autoverovapaa esteetön ajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "136", "text": "Bilskattefritt tillgängligt fordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "137", "text": "Autoverovapaa koulu- ja päivähoitokuljetusajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "137", "text": "Bilskattefritt fordon för transport av skol- och dagvårdsbarn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "926", "text": "L7e-CU tavaranelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "926", "text": "L7e-CU tung mopedbil för godsbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "22", "text": "Butikbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "23", "text": "Nosturiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "23", "text": "Kranbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "24", "text": "Invataksi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "24", "text": "Invataxi", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "25", "text": "Säiliöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "25", "text": "Tankbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "26", "text": "Maastohenkilöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "26", "text": "Terrängpersonbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "27", "text": "Nosturiauton alusta", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "27", "text": "Kranbils chassi", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "28", "text": "Hevosenkuljetusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "28", "text": "Hästtransportbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "29", "text": "Matkailuauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "29", "text": "Campingbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "3", "text": "Kevyt telttaperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "3", "text": "Lätt tältsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "30", "text": "Eläintenkuljetusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "30", "text": "Djurtransportbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "31", "text": "Rikka-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "515", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "32", "text": "Pelastusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "32", "text": "Räddningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "33", "text": "Ambulanssi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "33", "text": "Ambulans", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "34", "text": "Poliisiajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "34", "text": "Polisfordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "35", "text": "Pienoislinja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "35", "text": "Minibus", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "36", "text": "Betonipumppuauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "36", "text": "Betongpumpsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "37", "text": "Nivellinja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "37", "text": "Ledad buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "38", "text": "Nivellinja-auto (kaksikerroksinen)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "38", "text": "Ledad tvåvåningsbuss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "39", "text": "Linja-auto (kaksikerroksinen)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "39", "text": "Tvåvåningsbuss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "4", "text": "Kevyt moottoripyöränkuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "4", "text": "Lätt motorcykeltransportsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "40", "text": "Alaluokka I (M2/M3)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "40", "text": "Klass I (M2/M3)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "41", "text": "Alaluokka II (M2/M3)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "41", "text": "Klass II (M2/M3)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "42", "text": "Alaluokka III (M2/M3)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "42", "text": "Klass III (M2/M3)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "43", "text": "Alaluokka A", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "43", "text": "Klass A", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "44", "text": "Alaluokka B", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "44", "text": "Klass B", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "45", "text": "Klass I (låggolvsbuss)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "45", "text": "Alaluokka I (matalalattialinja-auto)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "46", "text": "Alaluokka II (matalalattialinja-auto)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "46", "text": "Klass II (låggolvsbuss)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "47", "text": "Alaluokka A (matalalattialinja-auto)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "47", "text": "Klass A (låggolvsbuss)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "48", "text": "Ruumisauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "48", "text": "Likbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "49", "text": "Maastoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "49", "text": "Terrängbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "5", "text": "Kevyt lentokoneenkuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "5", "text": "Lätt transportsläpvagn för flygplan", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "50", "text": "Paloauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "50", "text": "Brandbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "501", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "501", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "502", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "502", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "503", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "503", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "504", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "504", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "505", "text": "Erikoisauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "505", "text": "Specialbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "56", "text": "Huoltoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "506", "text": "Erikoisauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "506", "text": "Specialbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "507", "text": "Pakettiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "507", "text": "Paketbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "508", "text": "Henkilöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "508", "text": "Personbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "509", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "509", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "51", "text": "Nosturiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "51", "text": "Kranbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "510", "text": "Moottorityökone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "510", "text": "Motorredskap", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "511", "text": "Maastoajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "511", "text": "Terrängfordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "512", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "512", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "513", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "513", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "514", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "514", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "79", "text": "Industritraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "515", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "1", "text": "Kevyt tavarankuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "1", "text": "Lätt varutransport släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "10", "text": "Ajoneuvonkuljetusperävaunu, kokm >750kg", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "10", "text": "Fordontransportsläpvagn, totm > 750kg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "100", "text": "Ruohonleikkuukone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "100", "text": "Gräsklippningsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "101", "text": "Jäänhoitokone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "101", "text": "Isskötselmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "102", "text": "Konttinosturi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "102", "text": "Containerlyftkran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "103", "text": "Betonisekoitin", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "103", "text": "Betongblandare", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "104", "text": "Monitoimikone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "104", "text": "Mångsysslomaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "105", "text": "Lumiaura-harjapuhallin", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "105", "text": "Plog-sopblåsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "106", "text": "Moottorireki", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "106", "text": "Motorsläde", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "107", "text": "Moottorikelkka", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "107", "text": "Snöskoter", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "108", "text": "Kevyt moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "108", "text": "Lätt motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "109", "text": "2-pyöräinen", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "109", "text": "Tvåhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "110", "text": "3-pyöräinen", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "110", "text": "Trehjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "111", "text": "2-pyöräinen tavarapyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "111", "text": "Tvåhjulig varucykell", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "112", "text": "3-pyöräinen tavarapyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "112", "text": "Trehjulig varucykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "113", "text": "Invalidipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "113", "text": "Invalidcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "114", "text": "Sivuvaunumoottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "114", "text": "Motorcykel med sidvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "115", "text": "Kevyt nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "115", "text": "Lätt fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "116", "text": "Nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "116", "text": "Fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "117", "text": "Varsinaisen perävaunun vetoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "31", "text": "Avskrädebil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "118", "text": "Puoliperävaunun vetoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "118", "text": "Dragbil för påhängsvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "119", "text": "Keskiakseliperävaunun vetoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "119", "text": "Dragbil för medelaxelsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "12", "text": "Varsinainen perävaunu, (ka)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "12", "text": "Egentlig släpvagn, (lb)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "120", "text": "Auton alustalle rakennettu työkone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "120", "text": "Motorredskap som byggts på bilunderrede", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "121", "text": "Chassis", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "121", "text": "Alusta", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "122", "text": "Matkatyöperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "122", "text": "Researbetssläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "123", "text": "Betoninkuljetusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "123", "text": "Betongtransportbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "124", "text": "Jätteenkuljetusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "124", "text": "Sopbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "125", "text": "Koneenkuljetusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "125", "text": "Maskintransportbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "126", "text": "Nelipyöräinen", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "126", "text": "Fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "127", "text": "Hihnakuljetinauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "127", "text": "Remtransportörbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "128", "text": "Rinnekone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "128", "text": "Pistmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "129", "text": "Hitsausauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "129", "text": "Svetsningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "13", "text": "Keskiakseliperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "13", "text": "Släpvagn med centralaxel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "130", "text": "Kaapelikelan kuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "130", "text": "Kabeltrummasläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "131", "text": "Esteetön taksiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "131", "text": "Tillgänglig taxibil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "132", "text": "Hinattava laite", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "132", "text": "Släpanordning", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "14", "text": "Puoliperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "14", "text": "Påhängsvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "15", "text": "Erikoiskuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "15", "text": "Specialtransportsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "16", "text": "Erikoiskuljetusajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "16", "text": "Specialtransportfordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "17", "text": "Varsinainen säiliöperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "17", "text": "Egentlig tanksläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "18", "text": "Säiliöpuoliperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "18", "text": "Tankpåhängsvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "19", "text": "Dolly", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "19", "text": "Dolly", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "2", "text": "Kevyt veneenkuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "2", "text": "Lätt båttrailer", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "20", "text": "Kevytperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "20", "text": "Lätt släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "21", "text": "Museoajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "21", "text": "Museifordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "22", "text": "Myymäläauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "925", "text": "L7e-CP tung mopedbil för passagerarbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "910", "text": "L3e-A1T pienitehoinen trial-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "910", "text": "L3e-A1T trialmotorcykel med låg prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "911", "text": "L3e-A2T keskitehoinen trial-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "911", "text": "L3e-A2T trialmotorcykel med medelhög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "912", "text": "L3e-A3T suuritehoinen trial-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "912", "text": "L3e-A3T trialmotorcykel med hög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "913", "text": "L4e-A1 pienitehoinen sivuvaunullinen kaksipyöräinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "913", "text": "L4e-A1 tvåhjulig motorcykel med sidovagn och med låg prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "914", "text": "L4e-A2 keskitehoinen sivuvaunullinen kaksipyöräinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "914", "text": "L4e-A2 tvåhjulig motorcykel med sidovagn och med medelhög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "915", "text": "L4e-A3 suuritehoinen sivuvaunullinen kaksipyöräinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "915", "text": "L4e-A3 tvåhjulig motorcykel med sidovagn och med hög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "8", "text": "Hevosenkuljetusperävaunu, kokm > 750kg", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "8", "text": "Hästtransportsläpvagn, totm > 750 kg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "80", "text": "Kiinteistötraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "80", "text": "Fastighetstraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "81", "text": "Liikennetraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "81", "text": "Trafiktraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "82", "text": "Haarukkanosturi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "82", "text": "Gaffeltruck", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "83", "text": "Sivuhaarukkanosturi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "83", "text": "Sidogaffeltruck", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "84", "text": "Puomihaarukkanosturi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "84", "text": "Bomgaffeltruck", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "85", "text": "Kauhakuormain", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "85", "text": "Skoplastare", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "86", "text": "Kourakuormain", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "86", "text": "Griplastare", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "87", "text": "Kaivukone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "87", "text": "Grävmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "88", "text": "Puominosturi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "88", "text": "Bomlyftkran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "89", "text": "Kauhakuormain-kaivukone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "89", "text": "Skoplastare-grävmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "9", "text": "Matkailuperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "9", "text": "Husvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "69", "text": "Kahden toiminnan erikoisauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "69", "text": "Specialbil med två funktioner", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "7", "text": "Veneenkuljetusperävaunu, kokm > 750kg", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "7", "text": "Båttrailer, totm > 750 kg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "70", "text": "Kirjastoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "70", "text": "Biblioteksbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "71", "text": "Kaksikäyttöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "71", "text": "Bil avsedd för två ändamål", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "72", "text": "Alaluokka I (N1)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "72", "text": "Klass I (N1)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "73", "text": "Alaluokka II (N1)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "73", "text": "Klass II (N1)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "74", "text": "Alaluokka III (N1)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "74", "text": "Klass III (N1)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "75", "text": "Maataloustraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "75", "text": "Lantbrukstraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "76", "text": "Puutarhatraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "76", "text": "Trädgårdstraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "77", "text": "Maansiirtotraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "77", "text": "Jordtransporttraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "78", "text": "Metsätraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "78", "text": "Skogstraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "79", "text": "Teollisuustraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "516", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "516", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "517", "text": "L5", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "517", "text": "L5", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "518", "text": "Mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "518", "text": "Moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "519", "text": "Kevyt nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "519", "text": "Lätt fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "52", "text": "Lakaisuauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "52", "text": "Sopningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "520", "text": "Kevyt nelipyörä L6e", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "520", "text": "Lätt fyrhjuling L6e", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "521", "text": "Nelipyörä L7e", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "521", "text": "Fyrhjuling L7e", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "53", "text": "Hinausauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "53", "text": "Bogserbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "54", "text": "Kaivukoneauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "54", "text": "Grävmaskinsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "55", "text": "Porausauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "55", "text": "Borrningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "56", "text": "Servicebil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "57", "text": "Lumilinko", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "57", "text": "Snöslunga", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "58", "text": "Pankkiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "58", "text": "Bankbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "59", "text": "Viemärin/putkiston korjausauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "59", "text": "Kloaks/rörnätetsrepareringsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "6", "text": "Tavarankuljetusperävaunu, kokm > 750kg", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "6", "text": "Varutransportsläpvagn, tom > 750 kg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "60", "text": "Rehulaitosauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "60", "text": "Foderinrättningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "61", "text": "Selväkielisenä syötettävä nimitys", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "61", "text": "Definition i klartext", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "62", "text": "Nostokoriauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "62", "text": "Bil med lyftkorg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "63", "text": "Imuauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "63", "text": "Sugningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "64", "text": "Polyuretaanin ruiskutusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "64", "text": "Sprutbil för polyuretan", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "65", "text": "Näyttelyauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "65", "text": "Utställningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "66", "text": "Mittausauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "66", "text": "Mätningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "67", "text": "TV/radioauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "67", "text": "TV/radiobil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "68", "text": "Eläinlääkintäauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "68", "text": "Veterinärbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "96", "text": "Transportchassi", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "916", "text": "L5e-A kolmipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "916", "text": "L5e-A trehjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "917", "text": "L5e-B hyötykolmipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "917", "text": "L5e-B trehjuling för nyttotrafik", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "918", "text": "L6e-A kevyt maantiemönkijä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "918", "text": "L6e-A lätt fyrhjuling avsedd för väg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "919", "text": "L6e-BP henkilömopoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "919", "text": "L6e-BP lätt mopedbil för passagerarbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "920", "text": "L6e-BU tavaramopoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "920", "text": "L6e-BU lätt mopedbil för godsbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "921", "text": "L7e-A1 alaluokan A1 maantiemönkijä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "921", "text": "L7e-A1 A1 - fyrhjuling avsedd för väg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "922", "text": "L7e-A2 alaluokan A2 maantiemönkijä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "922", "text": "L7e-A2 A2 - fyrhjuling avsedd för väg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "923", "text": "L7e-B1 tavallinen maastomönkijä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "923", "text": "L7e-B1 terränggående fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "924", "text": "L7e-B2 rinnakkain istuttava maastomönkijä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "924", "text": "L7e-B2 side-by-side buggy", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "925", "text": "L7e-CP henkilönelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "900", "text": "L3e-A1 pienitehoinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "900", "text": "L3e-A1 motorcykel med låg prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "901", "text": "L1e-A moottorilla varustettu polkupyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "901", "text": "L1e-A motordriven cykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "902", "text": "L1e-B kaksipyöräinen mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "902", "text": "L1e-B tvåhjulig moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "903", "text": "L2e-P kolmipyöräinen henkilömopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "903", "text": "L2e-P trehjulig moped konstruerad för passagerarbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "904", "text": "L2e-U kolmipyöräinen tavaramopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "904", "text": "L2e-U trehjulig moped konstruerad för godsbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "905", "text": "L3e-A2 keskitehoinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "905", "text": "L3e-A2 motorcykel med medelhög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "906", "text": "L3e-A3 suuritehoinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "906", "text": "L3e-A3 motorcykel med hög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "907", "text": "L3e-A1E pienitehoinen enduro-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "907", "text": "L3e-A1E enduromotorcykel med låg prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "908", "text": "L3e-A2E keskitehoinen enduro-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "908", "text": "L3e-A2E enduromotorcykel med medelhög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "909", "text": "L3e-A3E suuritehoinen  enduro-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "909", "text": "L3e-A3E enduromotorcykel med hög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "117", "text": "Dragbil för egentlig släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "139", "text": "Ensihoitoajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "139", "text": "Prehospital akutsjukvård", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "138", "text": "Raskas moottorikelkka", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "138", "text": "Tung snöskoter", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "133", "text": "Eläintenkuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "133", "text": "Djurtransportsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "927", "text": "Yli 60 km/h traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "927", "text": "Över 60 km/tim traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "934", "text": "Luokka T4.1a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "934", "text": "Kategori T4.1a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "935", "text": "Luokka T4.1b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "935", "text": "Kategori T4.1b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "936", "text": "Luokka T4.2a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "936", "text": "Kategori T4.2a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "937", "text": "Luokka T4.2b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "937", "text": "Kategori T4.2b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "938", "text": "Luokka T4.3a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "938", "text": "Kategori T4.3a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "939", "text": "Luokka T4.3b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "939", "text": "Kategori T4.3b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "933", "text": "Luokka T3b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "933", "text": "Kategori T3b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "940", "text": "Luokka C1a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "940", "text": "Kategori C1a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "941", "text": "Luokka C1b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "941", "text": "Kategori C1b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "942", "text": "Luokka C2a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "942", "text": "Kategori C2a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "943", "text": "Luokka C2b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "943", "text": "Kategori C2b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "947", "text": "Luokka C4.1b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "947", "text": "Kategori C4.1b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "948", "text": "Luokka C4.2a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "948", "text": "Kategori C4.2a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "949", "text": "Luokka C4.2b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "949", "text": "Kategori C4.2b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "950", "text": "Luokka C4.3a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "950", "text": "Kategori C4.3a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "951", "text": "Luokka C4.3b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "951", "text": "Kategori C4.3b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "944", "text": "Luokka C3a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "944", "text": "Kategori C3a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "945", "text": "Luokka C3b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "945", "text": "Kategori C3b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "946", "text": "Luokka C4.1a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "946", "text": "Kategori C4.1a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "928", "text": "Luokka T1a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "928", "text": "Kategori T1a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "929", "text": "Luokka T1b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "929", "text": "Kategori T1b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "930", "text": "Luokka T2a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "930", "text": "Kategori T2a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "931", "text": "Luokka T2b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "931", "text": "Kategori T2b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "932", "text": "Luokka T3a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "932", "text": "Kategori T3a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "952", "text": "Luokka R3b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "952", "text": "Kategori R3b", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "6", "text": "Teliakselisto (4-akselinen)", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "6", "text": "Boggiaxelsystem (4-axlad)", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "7", "text": "Teliakselisto (5-akselinen)", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "7", "text": "Boggiaxelsystem (5-axlad)", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "1", "text": "Yksiakselinen", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "1", "text": "Enaxlad", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "2", "text": "Kaksoisakselisto", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "2", "text": "Dubbelaxelsystem", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "3", "text": "Kolmoisakselisto", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "3", "text": "Trippelaxelsystem", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "5", "text": "Teliakselisto (3 -akselinen)", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "5", "text": "Boggiaxelsystem (3-axlad)", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "4", "text": "Teliakselisto (2 -akselinen)", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "4", "text": "Boggiaxelsystem (2-axlad)", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "Y", "text": "Övrig", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1001", "text": "WVTA-anmärkning, antecknas ej på registreringsintyget", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1001", "text": "WVTA-huomautus, ei rekisteröintitodistukselle", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1002", "text": "Utrustningens inverkan på mått och massa", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1002", "text": "Varusteiden vaikutus mittoihin ja massoihin", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1003", "text": "Intervall för förnyelse av säkerhetssystem", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1003", "text": "Turvajärjestelmien uusintaväli", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1004", "text": "Avvikande metoder för mätning av utsläpp", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1004", "text": "Poikkeavat päästöjen mittausmenetelmät", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1005", "text": "Avvikande metoder för inspektion av bromsar", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1005", "text": "Poikkeavat jarrujen tarkastusmenetelmät", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1007", "text": "LTJ: ytterligare uppgifter om modellen, antecknas ej på registreringsintyget", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1007", "text": "LTJ: mallin ylimääräiset tiedot, ei rekisteröintitodistukselle", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1008", "text": "Regenerativa system/system för efterbehandling av avgaserna", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1008", "text": "Regenerointi-/pakokaasun jälkikäsittelyjärjestelmät", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1009", "text": "Åtgärder som begränsar buller i motorutrymmet och motorn", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1009", "text": "Melua rajoittavat toimenpiteet moottoritilassa ja moottorissa", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "2000", "text": "Anmärkningar som antecknas på versionens registreringsbevis", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "2000", "text": "Version rekisteröintitodistukselle tulevat huomautukset", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "2001", "text": "WVTA-anmärkning, antecknas på registreringsbeviset", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "2001", "text": "WVTA-huomautus, rekisteröintitodistukselle tuleva", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "2003", "text": "LTJ: ytterligare uppgifter om modellen, antecknas på registreringsbeviset", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "2003", "text": "LTJ: mallin ylimääräiset tiedot, rekisteröintitodistukselle tulevat", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3001", "text": "Grunduppgifter", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3001", "text": "Perustiedot", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3002", "text": "Konstruktion", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3002", "text": "Rakenne", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3003", "text": "Chassi", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3003", "text": "Kori", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3004", "text": "Mått", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3004", "text": "Mitat", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3005", "text": "Massa", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3005", "text": "Massat", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3006", "text": "Motor", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3006", "text": "Moottori", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3007", "text": "Bromsar", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3007", "text": "Jarrut", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3008", "text": "Komponentförteckning", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3008", "text": "Osaluettelo", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3009", "text": "Allmän", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3009", "text": "Yleinen", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3010", "text": "Draghjul", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3010", "text": "Vetopöytä", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3011", "text": "Dragkoppling", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3011", "text": "Vetokytkin", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3012", "text": "Dragkoppling för medelaxelsläpvagn", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3012", "text": "Kap-vetokytkin", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3013", "text": "Kopplingsuppgifter", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3013", "text": "Kytkentätiedot", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3014", "text": "Tillverkningsseriens sista fordon", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3014", "text": "Häntälupa", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "0001", "text": "Villkor för godkännande av version", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "0001", "text": "Version hyväksyntäehdot", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1000", "text": "Anmärkningar på version", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1000", "text": "Version huomautukset", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "1", "text": "Levyjarru", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "1", "text": "Skivbroms", "lng": "sv"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "2", "text": "Rumpujarru", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "2", "text": "Trumbroms", "lng": "sv"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "3", "text": "Sähköjarru", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "3", "text": "Elektrisk broms", "lng": "sv"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "4", "text": "Nestejarru", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "4", "text": "Hydraulisk broms", "lng": "sv"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "5", "text": "Moottorijarru", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "5", "text": "Motorbroms", "lng": "sv"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "Y", "text": "Övrig", "lng": "sv"},
  {"code": "KdTyytiKokoonpano", "codeValue": "E", "text": "Ei", "lng": "fi"},
  {"code": "KdTyytiKokoonpano", "codeValue": "K", "text": "Kyllä", "lng": "fi"},
  {"code": "KdTyytiKokoonpano", "codeValue": "T", "text": "Ei tiedossa", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1073/3001", "text": "Släpvagnen har inte godkänts för användning i trafik, orsak: vid registreringsbesiktning har en sådan omfattande bromskontroll, som förutsätts i 7 § i SRf 1245/2002, inte utförts", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1074/3001", "text": "Rek.katsastuksessa ei ole suoritettu määräaikaiskatsastusta vastaavaa tarkastusta. 1. Suomessa tehtävä määräaikaiskatsastus on suoritettava muista tälle todistukselle merkityistä tiedoista poiketen VNa 1245/2002 4 §:n mukaisesti viimeistään ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1074/3001", "text": "Vid reg.besiktning har en sådan kontroll som motsvarar periodisk besiktning inte utförts. Den 1. periodiska besiktningen i Finland skall, avvikande från de övriga uppgifter som antecknats i detta intyg enligt 4 § i SRf 1245/2002, utföras senast ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1075/3001", "text": "Vaihtoehtoinen rengaskoko akselilla ( ), jolloin akselimassa ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1075/3001", "text": "Alternativ däcksstorlek på axel ( ), varvid axelmassa ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1076/3001", "text": "Vaihtoehtoinen rengaskoko akselilla ( ), jolloin akselimassa ( ) kg ja kokonaismassa ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1076/3001", "text": "Alternativ däcksstorlek på axel ( ), varvid axelmassa ( ) kg och totalmassa ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1077/3001", "text": "Vaihtoehtoinen rengaskoko akselilla ( ), jolloin akselimassa ( ) kg, kokonaismassa ( ) kg ja yhdistelmämassa ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1070/3009", "text": "Hyväksytty koululaiskuljetukseen", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1070/3009", "text": "Godkänt för skolskjuts", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1071/3009", "text": "Turvavyöt koululaiskuljetuksessa enintään: Rivi 1 ( ), rivi 2 ( ), rivi 3 ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1071/3009", "text": "Säkerhetsbälten vid skolskjutsar högst: Rad 1 ( ), rad 2 ( ), rad 3 ( )", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1072/3005", "text": "Vetoaisan rajoittama vetoajoneuvon suurin teknisesti sallittu massa enintään ( ) kg", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1072/3005", "text": "Av dragstången begränsad, tekniskt tillåten största massa för dragfordonet högst ( ) kg", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1073/3001", "text": "Perävaunua ei ole hyväksytty liikenteessä käytettäväksi, syy: Rekisteröintikatsastuksessa ei ole suoritettu VNa 1245/2002 7 §:n edellyttämää laajaa jarrutarkastusta", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1083/3009", "text": "En bil avsedd för service av fordon och maskiner samt transport av reparationsmanskap och tillbehör. I fordonet fast monterad serviceutrustning: ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1077/3001", "text": "Alternativ däcksstorlek på axel ( ), varvid axelmassa ( ) kg, totalmassa ( ) kg och kombinationsmassa ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1078/3006", "text": "Ahtopaine ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1078/3006", "text": "Laddningstryck ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1079/3006", "text": "Polttoaineen suihkutuslaitteiston merkki: ( ) ja malli: ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1079/3006", "text": "Bränsleinsprutningssystemets märke: ( ) och modell: ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1080/3006", "text": "Kaasuttimien merkki: ( ), malli: ( ) ja lukumäärä: ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1080/3006", "text": "Förgasarnas märke: ( ), modell: ( ) och antal: ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1081/3006", "text": "Moottorinohjainlaitteen merkki: ( ) ja malli: ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1081/3006", "text": "Motorstyrningssystemets märke: ( ) och modell: ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1082/3009", "text": "Auton päästö- ja kulutustiedot koskevat alkuperäistä, muuttamatonta autoa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1082/3009", "text": "Uppgifter om utsläpp och bränsleförbrukning gäller för oförändrad bil.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1083/3009", "text": "Ajoneuvojen ja koneiden huoltoon sekä korjausmiehistön ja välineiden kuljetukseen tarkoitettu auto. Ajoneuvossa kiinteästi asennettuna huoltovarustus: ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1097/3009", "text": "Hyväksytty koulukevytnelipyöräksi.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1092/3009", "text": "Godkänd för användning i förarundervisning med undervisningstillstånd. Undervisningsutrustningen ska avlägsnas senast ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1093/3002", "text": "Ajoneuvossa kiskopyörät", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1093/3002", "text": "Rälshjul i fordonet", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1094/3004", "text": "Ajoneuvoon on asennettu säädettävä alustasarja. Etäisyys pyörän navan keskeltä lokasuojan kaaren ylimpään kohtaan: edessä ( )->( ) mm takana ( )->( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1094/3004", "text": "Justerbar chassiserie installerad i bilen. Avståndet från mitten av hjulnavet fram ( )->( ) mm bak ( )->( ) mm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1095/3004", "text": "Ajoneuvoon on asennettu ( ) mm alustasarja. Etäisyys pyörän navan keskeltä lokasuojan kaaren ylimpään kohtaan: edessä ( ) mm takana ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1095/3004", "text": "Chassiserie ( ) mm installerad i bilen. Avståndet från mitten av hjulnavet fram ( ) mm bak ( ) mm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1096/3009", "text": "Hyväksytty koulumopoksi ilman matkustajaa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1096/3009", "text": "Godkänd såsom skolmoped utan passagerare.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1097/3009", "text": "Godkänd såsom skolfyrhjuling.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1098/3006", "text": "Fordonet är försett med utrustning för avgasrening ( ). Objektiva tester som tillverkaren ha låtit göra, visar att utsläppen har minskat: NOx ( ) % och pm ( ) %.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1087/3003", "text": "Vaihtoehtoisesti kuljettajan lisäksi ( ) istumapaikkaa ja ( ) seisomapaikkaa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1087/3003", "text": "Alternativt utom föraren ( ) sittplatser och ( ) ståplatser.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1088/3009", "text": "Ajoneuvo on hyväksytty ulkomaisten määräysten mukaisena vain ulkomaille siirtoa varten.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1088/3009", "text": "Fordonet är godkänt enligt de utländska tekniska bestämmelser endast för transport till utomlands.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1089/3002", "text": "Suurin rakenteellinen nopeus ( ) km/h.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1089/3002", "text": "Största konstruktiva hastighet ( ) km/h.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1090/3008", "text": "Ajoneuvoon vaihdettu ennen ensirekisteröintiä ( ), yhteensä ( ) %.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1090/3008", "text": "Före första registreringen fordonets utbytna delar ( ), tillsammans ( ) %.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1091/3009", "text": "Ajoneuvoon asennettu vaatimustenmukaisuuden täyttymiseksi ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1091/3009", "text": "För överensstämmelse ( ) installerad i fordonet.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1092/3009", "text": "Hyväksytty käytettäväksi kuljettajaopetukseen opetusluvalla. Opetusvarustus poistettava ( ) mennessä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1098/3009", "text": "Fordonet är försett med utrustning för avgasrening ( ). Objektiva tester som tillverkaren har låtit göra visar att utsläppen har minskat: NOx ( ) % och pm ( ) %.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1084/3003", "text": "Tavaratilassa ana tpp 57 pykälän mukaiset istuimet ( ) henkilön tilapäistä kuljetusta varten.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1084/3003", "text": "I lastrummet i ff vsb paragraf 57 nämnda säten för tillfällig transport av ( ) personer.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1085/3009", "text": "Vaihtoehtoinen käyttö: luvanvarainen, autoverolain (413/97) 29 § 2 momentin mukainen vara-auto.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1085/3009", "text": "Alternativt bruk: tillståndspliktig, reservbil enligt 29 § 2 moment i bilskattelagen (413/97).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1086/3003", "text": "Vaihtoehtoisesti kuljettajan lisäksi ( ) istumapaikkaa, ( ) seisomapaikkaa ja ( ) pyörätuolipaikkaa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1086/3003", "text": "Alternativt utom föraren ( ) sittplatser, ( ) ståplatser och ( ) rullstolsplatser.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1004/3005", "text": "Ajoneuvon käyttö tiellä ylisuurin massoin sallittu vain LVM:n erikoiskuljetuksista antaman päätöksen mukaisin luvin.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1004/3005", "text": "Fordon med överstor massa får användas på väg endast med tillstånd, som nämns i KM:s beslut om specialtransporter.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1005/3009", "text": "Liikennetraktori.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1005/3009", "text": "Trafiktraktor.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1006/3009", "text": "Ajoneuvo on rekisteröity päätöksellä ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1006/3009", "text": "Fordonet är registrerat enligt beslut ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1007/3009", "text": "Ajoneuvo hyväksytty muuttoajoneuvosäännösten mukaisesti.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1007/3009", "text": "Fordonet godkänt enligt flyttgodsbestämmelserna", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1008/3003", "text": "Ajoneuvo on varustettu turvakaarella/-kehikolla ja kuusipisteturvavöillä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1008/3003", "text": "Fordonet är försett med en störtbåge/skyddsbur och med sexpunktsbälten.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1009/3002", "text": "Ajoneuvon rakenne on muutettu luvalla nro ( ), (toimipaikan koodi) ( )", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1009/3002", "text": "Bilens konstruktion ändrad med tillstånd nr ( ), (bes.ställets kod) ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1010/3004", "text": "Ajoneuvoon on asennettu alustasarja. Etäisyys pyörän navan keskeltä lokasuojan kaaren ylimpään kohtaan: edessä ( ) mm takana ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1010/3004", "text": "Chassiserie installerad i bilen. Avståndet från mitten av hjulnavet fram ( ) mm bak ( ) mm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1014/3013", "text": "Erilliskytkentä hyväksytty seuraaviin ajoneuvoihin: ( ), ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1014/3013", "text": "Särkopplingen godkänd för följande fordon: ( ), ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1015/3009", "text": "Hyväksytty koulumoottoripyöräksi ilman matkustajaa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1015/3009", "text": "Godkänd såsom skolmotorcykel utan passagerare.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1016/3013", "text": "Hyväksytty kytkettäväksi vain sellaiseen perävaunuun, joka on varustettu lukkiutumattomin jarruin.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1016/3013", "text": "Godkänd att kopplas endast till en sådan släpvagn som är försedd med låsningsfria bromsar.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1017/3013", "text": "Hyväksytty kytkettäväksi vain sellaiseen perävaunuun, jossa on kuorman tunteva jarruvoiman säätö.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1017/3013", "text": "Godkänd att kopplas endast till sådan släpvagn, som är försedd med reglage av bromskraften, som beaktar lasten.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1018/3013", "text": "Ilman perävaunua ajettaessa vetolaitteen pikakiinnitteinen kuulaosa pidettävä irrotettuna", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1018/3013", "text": "Vid körning utan släpvagn bör draganordningens dragkula med snabbkoppling lösgöras.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1019/3003", "text": "Kaitein erotettu lastenvaunutila.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1019/3003", "text": "Utrymmet för barnvagn avskilt med räck.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1020/3009", "text": "Korjattu ajoneuvo.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1020/3009", "text": "Reparerad fordon.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1021/3009", "text": "Korjattu ja muutettu ajoneuvo.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1021/3009", "text": "Reparerad och ändrad fordon.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1022/3003", "text": "Korjausmiehistön kuljetukseen ( ) paikkaa, ( ) omistamana.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1022/3003", "text": "För transport av reparationsmanskap ( ) platser, ägd av ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1023/3009", "text": "Kouluajoneuvovarustus.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1023/3009", "text": "Skolfordonutrustning.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1024/3003", "text": "Kuljettajan makuutila.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1024/3003", "text": "Förarens sovutrymme.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1025/3009", "text": "Liikennetraktori käytettäessä tavarankuljetukseen kytkentämassaltaan yli 10 tonnin perävaunulla muihin kuin polttoainemaksulain 7 § tarkoittamiin kuljetuksiin.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1025/3009", "text": "Trafiktraktorn som användas till godstransport med släpvagn med kopplingsmassan på över 10 ton för andra ändamål än transporter avsedd i lag om bränsleavgift 7 §.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1026/3009", "text": "Museoajoneuvotodistus (numero) / (antopäivä): ( ) / ( )", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1026/3009", "text": "Museifordonsintyg (nummer) / (beviljningsdag): ( ) / ( )", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1027/3009", "text": "Muutoskatsastus, autoverolain 63 pykälän 2 momentti sovellettavissa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1028/3009", "text": "Nopeus 100 km/h.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1011/3003", "text": "Avonaisen tavaratilan pituus vähintään 175 cm. (autovero)", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1011/3003", "text": "Öppna lastutrymmets längd minst 175 cm. (bilskatt)", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1012/3003", "text": "Doppelkabiinisen tavaratilan sisäkorkeus vähintään 130 cm ja pituus 80 cm, sekä avonaisen tavaratilan pituus vähintään 150 cm. (autovero)", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1012/3003", "text": "Doppelkabiner lastutrymmets inre höjd minst 130 cm och längd 80 cm, samt det öppna lastutrymmets längd minst 150 cm. (bilskatt)", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1038/3003", "text": "Täckta lastutrymmets volym 7 kubikmeter.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1039/3003", "text": "Umpinaisen tavaratilan tilavuus 8 kuutiometriä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1039/3003", "text": "Täckta lastutrymmets volym 8 kubikmeter.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1040/3003", "text": "Umpinaisen tavaratilan tilavuus alle 3 kuutiometriä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1040/3003", "text": "Täckta lastutrymmets volym under 3 kubikmeter.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1041/3003", "text": "Umpinaisen tavaratilan tilavuus alle 7 kuutiometriä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1041/3003", "text": "Täckta lastutrymmets volym under 7 kubikmeter.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1042/3003", "text": "Umpinaisen tavaratilan tilavuus vähintään 9 kuutiometriä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1042/3003", "text": "Täckta lastutrymmets volym minst 9 kubikmeter.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1043/3003", "text": "Vaihtoehtoinen käyttö: kuljettajan lisäksi ( ) istumapaikkaa, ( ) pöytää, kantavuus ( ) kg, kokonaismassa ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1043/3003", "text": "Alternativt bruk: utom föraren ( ) sittplatser, ( ) bord, bärförmågan ( ) kg, totalmassan ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1044/3003", "text": "Vaihtoehtoisesti kuljettajan lisäksi ( ) istumapaikkaa ja ( ) paaripaikkaa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1044/3003", "text": "Alternativt utom föraren ( ) sittplatser och ( ) bårplatser.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1045/3003", "text": "Vaihtoehtoisesti kuljettajan lisäksi ( ) istumapaikkaa ja ( ) pyörätuolipaikkaa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1028/3009", "text": "Hastigheten 100 km/h.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1029/3009", "text": "Taksamittarin asentamisesta vapautus ( ) lääninhallituksen luvalla numero ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1029/3009", "text": "Befrielse från installering av taxameter med tillstånd av ( ) länsstyrelse, nr ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1030/3009", "text": "Tarkista autovero.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1030/3009", "text": "Kontrollera bilskatten.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1031/3003", "text": "Tavaratilan tilavuus vähintään 3 kuutiometriä ja sisäkorkeus vähintään 130 cm.  (autovero)", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1031/3003", "text": "Lastutrymmets volym minst 3 kubikmeter och inre höjd minst 130 cm. (bilskatt)", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1032/3003", "text": "Tavaratilan tilavuus vähintään 3 kuutiometriä. (autovero)", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1032/3003", "text": "Lastutrymmets volym minst 3 kubikmeter. (bilskatt)", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1033/3003", "text": "Tavaratilassa tilapäisistuimet ( ) henkilön kuljetusta varten.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1033/3003", "text": "I lastutrymmet tillfälliga säten för transport av ( ) personer", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1034/3003", "text": "Tukikaaret ja telttakatos.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1034/3003", "text": "Stödbågar och tälttak.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1035/3001", "text": "Tuotu maahan autoverovapaana pakettiautona ja muutettu henkilöautoksi.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1035/3001", "text": "Importerad som bilskattefri paketbil och ändrad till personbil.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1036/3005", "text": "Liikennemerkin nro 347 osoittaman lukuarvon saa ylittää ( ) tonnia telimassan ( ) tonnia rajoissa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1036/3005", "text": "Trafikmärket nr 347 värde fås överskridas med ( ) ton då boggiemassan är högst ( ) ton.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1037/3005", "text": "Umpinainen lujitemuovikate, jolloin omamassa ( ) kg ja kantavuus ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1037/3005", "text": "Täckt hårdplasttak, varvid egenvikten ( ) kg och bärförmågan ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1038/3003", "text": "Umpinaisen tavaratilan tilavuus 7 kuutiometriä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1051/3009", "text": "Fordonet försett med alkolås nr: ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1045/3003", "text": "Alternativt utom föraren ( ) sittplatser och ( ) rullstolplatser.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1046/3004", "text": "Vaihtokorin leveys enintään ( ) cm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1046/3004", "text": "Utbyteskarosseriets bredd högst ( ) cm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1047/3009", "text": "Hyväksytty ( ) ajoneuvoksi vaarallisten aineiden kuljetukseen. Hyväksymistodistuksen numero ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1047/3009", "text": "Godkänt såsom ( ) fordon för transport av farliga ämnen. Godkännandeintygets nummer ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1069/3006", "text": "Gashandtagets vridvinkel ( ) grader", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1048/3009", "text": "Ajoneuvo varustettu haltijan/omistajan käyttöä varten seuraavin muutoksin: ( )", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1048/3009", "text": "Fordonet är utrustat för innehavarens/ägarens användning med följande ändringar: ( )", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1049/3011", "text": "Vetokytkin vain hinattavan laitteen vetoon.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1049/3011", "text": "Draganordning enbart för en släpanordning.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1050/3010", "text": "Vetopöytä on varustettu kuulakehällä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1050/3010", "text": "Dragbordet är försett med svängkrans.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1051/3009", "text": "Ajoneuvoon asennettu alkolukko nro: ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1052/3009", "text": "Muutoskatsastus, autoverolain 14 pykälän 3 momentti sovellettavissa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1052/3009", "text": "Ändringsbesiktning, bilskattelagens 14 par 3 mom tillämplig.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1053/3005", "text": "AKTA 23 pykälän 2 momentin mukaisin ehdoin yhdistelmän kokonaismassa ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1053/3005", "text": "Enligt i FAFV paragraf 23 moment 2 nämnda villkor kombinationens totalmassa ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1054/3003", "text": "AKTA 23 pykälän 2 momentin mukaisin ehdoin voimassa seuraavat yhdistelmämassat (äärimmäisten akselien väli/yhdistelmän kok.massa) ( ) cm / ( ) kg, ( ) cm / ( ) kg, () cm / ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1054/3003", "text": "Enligt i FAFV paragraf 23 moment 2 nämnda villkor i kraft följande kombinationsmassor (mellanrum av de yttersta axlarna/kombinationens totalmassa) ( ) cm / ( ) kg, ( ) cm / ( ) kg, ( ) cm / ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1055/3005", "text": "Perävaunun massa kuormaamattomana ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1055/3005", "text": "Släpvagnens massa som olastad ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1061/3009", "text": "Fordonsgrupp: tvåbruksbil.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1056/3005", "text": "Kentässä olevat massa voimassa nopeuteen ( ) km/h asti. Nopeudella ( ) km/h kokonaismassa ( ) kg ja akselimassat ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1056/3005", "text": "Massorna i fältet i kraft upp till hastigheten ( ) km/h. Vid hastigheten ( ) km/h totalmassan ( ) och axelmassorna ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1057/3001", "text": "Yksittäin Suomessa valmistettu.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1057/3001", "text": "Tillverkad i Finland som enskilt exemplar.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1058/3009", "text": "Exportbesiktning med bristfälliga uppgifter.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1058/3009", "text": "Vientikatsastus puutteellisin tiedoin.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1059/3009", "text": "Katsastuksessa todettu ajoneuvolain (2021/82) 85 pykälän mukaiseksi.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1059/3009", "text": "Konstaterats vid besiktning att vara förenlig med fordonslag (2021/82) 85 paragraf.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1060/3009", "text": "Katsastuksessa todettu ARA 38  pykälän kohdan 2 mukaiseksi.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1060/3009", "text": "Konstaterats vid besiktning att vara förenlig med FRF 38 paragraf 2 punkt.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1061/3009", "text": "Ajoneuvoryhmä: kaksikäyttöauto.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1062/3003", "text": "Ajoneuvo on varustettu turvakaarella/-kehikolla ja nelipisteturvavöillä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1062/3003", "text": "Fordonet är försett med en störtbåge/skyddsbur och med fyrpunktsbälten.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1063/3001", "text": "Aikaisempi tyyppihyväksyntänumero: ( ), variantti: ( ) ja versio: ( )", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1063/3001", "text": "Tidigare typgodkännandenummer: ( ), variant: ( ) och version: ( )", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1064/3003", "text": "Avonaisen tavaratilan pinta-ala vähintään 2,25 m² (autovero).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1064/3003", "text": "Öppna lastutrymmets yta minst 2,25 m² (bilskatt).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1065/3009", "text": "Katsastuksessa todettu ARA 38  pykälän kohdan 3 mukaiseksi.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1065/3009", "text": "Konstaterats vid besiktning att vara förenlig med FRF 38 paragraf 3 punkt", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1066/3009", "text": "Katsastuksessa todettu ARA 38  pykälän kohdan 4 mukaiseksi", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1066/3009", "text": "Konstaterats vid besiktning att vara förenlig med FRF 38 paragraf 4 punkt", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1067/3009", "text": "Asetuksen ( )/( ) liitteen ( ) poikkeusten mukaan hyväksytty ajoneuvo.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1067/3009", "text": "Fordon som godkänts enligt undantagen i förordning ( )/( ), bilaga ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1068/3006", "text": "Moottorin suurin teho todettu mittaamalla", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1068/3006", "text": "Motorns största effekt fastställd genom mätning", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1069/3006", "text": "Kaasukahvan kääntökulma ( ) astetta", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1027/3009", "text": "Ändringsbesiktning, bilskattelagens 63 par 2 mom tillämplig.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1002/3009", "text": "Katsastettu vientirekisteröintiin.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0004/1007", "text": "LTJ-korjaukset", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0004/1007", "text": "LTJ-ändringar", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0005/2003", "text": "LTJ-tyyppikoodi", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0005/2003", "text": "LTJ-typkod", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0006/2003", "text": "LTJ-mallimerkintä", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0006/2003", "text": "LTJ-modellnamn", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0007/1007", "text": "EY-hyväksyntänumero, variantti ja versio", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0007/1007", "text": "EG-typgodkännande nr., variant och version", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0008/2003", "text": "Renkaat", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0008/2003", "text": "Däck", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0010/2003", "text": "", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0010/2003", "text": "", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0017/2003", "text": "Jarrukorttitiedot", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0017/2003", "text": "Bromskort information", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1001/3009", "text": "Rakennettu ajoneuvo.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1001/3009", "text": "Byggd bil.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1003/3009", "text": "Yksinomaan palo- ja pelastustoimen tehtäviin käytettäväksi.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1003/3009", "text": "Endast för användning i brand- och räddningstjänst.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0001/2003", "text": "Muut erikoisehdot", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0001/2003", "text": "Andra särskilda villkor", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0002/1007", "text": "LTJ-ändringar", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0002/1007", "text": "LTJ-huomautukset", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0003/1007", "text": "LTJ-specialbeteckningar", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1002/3009", "text": "Besiktigad för exportregistrering.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1128/3013", "text": "Ajoneuvoon asennettu kytkentälaite ( ) rajoittaa ajoneuvolle / ajoneuvoyhdistelmälle sallittuja massoja seuraavasti: ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1128/3013", "text": "De installerade kopplinsanordningarna ( ) begränsar fordonets/fordonskombinationens tillåtna massor enligt följande: ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "0003/1007", "text": "LTJ-erikoistunnukset", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1125/3002", "text": "Auton käyttötarkoituksen edellyttämä rakenne ei mahdollista etualleajosuojan asentamista. Peruste: ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1125/3002", "text": "Den konstruktion som bilens användningsändamål förutsätter möjliggör inte installering av främre underkörningsskydd. Grund: ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1126/3002", "text": "Ajoneuvon käyttötarkoituksen edellyttämä rakenne ei mahdollista vaatimustenmukaisia sivusuojia. Peruste: ( ). Käyttötarkoituksen mahdollistamat sivusuojausta korvaavat rakenteet: ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1126/3002", "text": "Den konstruktion som fordons användningsändamål förutsätter möjliggör inte kravenliga sidoskydd. Grund: ( ). Konstruktioner som ersätter sidoskydden och som möjliggörs av användningsändamålet: ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1127/3002", "text": "Ajoneuvon käyttötarkoituksen edellyttämä rakenne ei mahdollista vaatimustenmukaista taka-alleajosuojausta. Peruste: ( ). Käyttötarkoituksen mahdollistamat taka-alleajosuojausta korvaavat rakenteet: ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1127/3002", "text": "Den konstruktion som fordons användningsändamål förutsätter möjliggör inte kravenligt bakre underkörningsskydd. Grund: ( ). Konstruktioner som ersätter det bakre underkörningsskyddet och som användningsändamålet möjliggör: ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1099/3006", "text": "Moottorin alkuperäinen teho ( ) kW.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1099/3006", "text": "Motorns ursprungliga effekt ( ) kW.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1098/3006", "text": "Ajoneuvoon on asennettu pakokaasujen puhdistuslaitteet: ( ). Valmistajan teettämien puolueettomien testien mukaan päästötaso on alentunut: NOx ( ) % ja pm ( ) %.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1098/3006", "text": "Fordonet är försett med utrustning för avgasrening ( ). Objektiva tester som tillverkaren ha låtit göra, visar att utsläppen har minskat: NOx ( ) % och pm ( ) %.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1101/3005", "text": "Kenttiin merkityt väliaikaiset, korotetut massat voimassa AKTA (1257/1992) 21 §:n 2 momentin mukaisen määräajan (tämän jälkeen akselimassat ( ) kg ja ajoneuvon suurin sallittu massa ( ) kg, yhdistelmämassa ( ) kg ja moduuliyhdistelmämassa ( ) kg).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1101/3005", "text": "De tillfälliga höjda massor som anges i fälten gäller fram till den tidpunkt som anges i 21 § 2 mom. i AKTA (1257/1992) (därefter axelmassorna ( ) kg och ett fordons högsta tillåtna massa ( ) kg, kombinationsmassa ( ) kg och ...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1102/3005", "text": "Vaihtoehtoinen rengaskoko ( ) akselilla ( ), jolloin väliaikaiset, korotetut massat (akselimassat ( ) kg ja ajoneuvon suurin sallittu massa ( ) kg) voimassa AKTA (1257/1992) 21 §:n 2 momentin mukaisen määräajan (tämän jälkeen akselimassat ( ) kg ja ...", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1102/3005", "text": "Den alternativa däckstorleken ( ) på axeln( ) då de tillfälliga, höjda massorna (axelmassorna ( ) kg och ett fordons högsta tillåtna massa ( ) kg) gäller fram till den tidpunkt som anges i 21 § 2 mom. i AKTA (1257/1992) (därefter axelmassorna ( ) kg ...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1103/3005", "text": "Vaihtoehtoinen rengaskoko ( ) akselilla ( ), jolloin väliaikaiset, korotetut massat (akselimassat ( ) kg ja ajoneuvon suurin sallittu massa ( ) kg, yhdistelmämassa ( ) kg ja moduuliyhdistelmämassa ( ) kg) voimassa AKTA (1257/1992) 21 §:n 2 momentin ...", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1103/3005", "text": "Den alternativa däckstorleken ( ) på axeln ( ) då de tillfälliga höjda massorna (axelmassorna ( ) kg och ett fordons högsta tillåtna massa ( ) kg, kombinationsmassan ( ) kg och modulkombinationsmassan ( ) kg) gäller fram till den tidpunkt ...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1104/3005", "text": "Vaihtoehtoinen käyttö normaaliliikenteessä, jolloin väliaikaiset, korotetut massat (akselimassat ( ) kg ja ajoneuvon suurin sallittu massa ( ) kg) voimassa AKTA (1257/1992) 21 §:n 2 momentin mukaisen määräajan (tämän jälkeen akselimassat ( ) kg ja ...", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1104/3005", "text": "Den alternativa användningen i normal trafik då de tillfälliga, höjda massorna (axelmassorna ( ) kg och ett fordons högsta tillåtna massa ( ) kg) gäller fram till den tidpunkt som anges i 21 § 2 mom. i AKTA (1257/1992) (därefter axelmassorna ( ) kg ...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1105/3005", "text": "Vaihtoehtoinen käyttö normaaliliikenteessä, jolloin väliaikaiset, korotetut massat (akselimassat ( ) kg ja ajoneuvon suurin sallittu massa ( ) kg, yhdistelmämassa ( ) kg ja moduuliyhdistelmämassa ( ) kg) voimassa AKTA (1257/1992) 21 §:n 2 momentin ...", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1105/3005", "text": "Den alternativa användningen i normal trafik då de tillfälliga höjda massorna (axelmassorna ( ) kg och ett fordons högsta tillåtna massa ( ) kg, kombinationsmassan ( ) kg och modulkombinationsmassan ( ) kg) gäller fram till den tidpunkt ...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1100/3005", "text": "De tillfälliga höjda massor som anges i fälten gäller fram till den tidpunkt som anges i 21 § 2 mom. i AKTA (1257/1992) (därefter axelmassorna ( ) kg och ett fordons högsta tillåtna massa ( ) kg).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1100/3005", "text": "Kenttiin merkityt väliaikaiset, korotetut massat voimassa AKTA (1257/1992) 21 §:n 2 momentin mukaisen määräajan (tämän jälkeen akselimassat ( ) kg ja ajoneuvon suurin sallittu massa ( ) kg).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1111/3013", "text": "Den största tillåtna massan för en kombination ( ) kg godkänd före den 1 oktober 2013, avvikande från 23 § 6 mom. i AKTA (1257/1992).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1112/3013", "text": "Yhdistelmän suurimmalla sallitulla massalla (( ) kg) auton ja perävaunun vetolaitteilta edellytetty D-arvo vähintään ( ) kN.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1112/3013", "text": "Med den högsta tillåtna massan för en kombination (( ) kg) har det av fordonets och släpets draganordningar förutsatts ett D-värde på minst ( ) kN.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1113/3013", "text": "Vetävien akselien massaa vastaava yhdistelmän suurin sallittu massa ilman AKTA (1257/1992) 17 §:n 7 momentin mukaista kitkanparannuslaitetta ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1113/3013", "text": "Utan anordningen för friktionsförbättring som avses i 17 § 7 mom. i AKTA (1257/1992) uppgår den högsta tillåtna kombinationsmassan som svarar mot drivaxlarnas massa till ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1106/3013", "text": "Moottorin tehon (( ) kW) rajoittamana yhdistelmän suurin sallittu massa AKTA (1257/1992) 23 §:n 6 momentin mukaisen määräajan ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1108/3013", "text": "Moduuliyhdistelmän suurimmalla sallitulla massalla ( ) kg perävaunun pienin sallittu siltasääntöpituus ( ) mm (vetokytkimestä).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1108/3013", "text": "Med den högsta tillåtna massan ( ) kg för en modulkombination är släpets minsta tillåtna broregellängd ( ) mm (från dragkopplingen).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1110/3013", "text": "Moduuliyhdistelmän suurimmalla sallitulla massalla ( ) kg perävaunujen pienin sallittu siltasääntöpituus ( ) mm (vetopöydästä).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1110/3013", "text": "Med den högsta tillåtna massan för en modulkombination ( ) kg är den minsta tillåtna broregellängden ( ) mm (från dragbordet).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1114/3013", "text": "Suurin sallittu moduuliyhdistelmämassa puoliperävaunun vetoautona ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1114/3013", "text": "Den högsta tillåtna massan för en modulkombination som dragbil för påhängsvagn ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1115/3013", "text": "Käytettäessä yhdistelmää kuljetuksiin, joihin sovelletaan vaarallisten aineiden kuljetuksia koskevia määräyksiä, kenttiin merkityt yhdistelmän suurimmat sallitut massat voimassa ainoastaan vähintään 8-akselisena AKTA (1257/1992) 23 a §:n ...", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1115/3013", "text": "Då en kombination används för transporter på vilka bestämmelserna om transport av farliga ämnen tillämpas gäller de högsta tillåtna massor för en kombination som har antecknats i fälten enbart för minst en åttaaxlad enligt villkoren i 23 a § i AKTA ...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1116/3013", "text": "Käytettäessä yhdistelmää kuljetuksiin, joihin sovelletaan vaarallisten aineiden kuljetuksia koskevia määräyksiä, yhdistelmän suurin sallittu massa vähintään 7-akselisena ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1116/3013", "text": "Då en kombination används för transporter på vilka bestämmelserna om transporter av farliga ämnen tillämpas, uppgår den högsta tillåtna massan för en kombination som minst sjuaxlad till ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1111/3013", "text": "Yhdistelmän suurin sallittu massa ( ) kg hyväksytty ennen 1.10. 2013 AKTA (1257/1992) 23 §:n 6 momentista poiketen.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1106/3013", "text": "Enligt motoreffekten (( ) kW) begränsas den högsta tillåtna massan för en kombination till ( ) kg fram till den tidpunkt som anges i 23 § 6 mom. i AKTA (1257/1992).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1107/3013", "text": "Moottorin tehon (( ) kW) rajoittamana yhdistelmän suurin sallittu massa AKTA (1257/1992) 23 §:n 6 momentin mukaisen määräajan jälkeen ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1107/3013", "text": "Enligt motoreffekten (( ) kW) begränsas den högsta tillåtna massan för en kombination till ( ) kg efter  den tidpunkt som anges i 23 § 6 mom. i AKTA (1257/1992).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1109/3013", "text": "Moottorin tehon (( ) kW) rajoittamana yhdistelmän suurin sallittu massa AKTA (1257/1992) 23 §:n 6 momentin mukaisen määräajan ( ) kg (tämän jälkeen ( ) kg).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1109/3013", "text": "Enligt motoreffekten (( ) kW) begränsas den högsta tillåtna massan för en kombination och uppgår fram till den tidpunkt som anges i 23 § 6 mom. i AKTA (1257/1992) till ( ) kg (därefter ( ) kg).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1117/3013", "text": "Käytettäessä yhdistelmää kuljetuksiin, joihin sovelletaan vaarallisten aineiden kuljetuksia koskevia määräyksiä, yhdistelmän suurin sallittu massa vähintään 7-akselisena ( ) kg ja vähintään 8-aks AKTA (1257/1992) 23 a §:n mukaisin ehdoin ( ) kg.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1117/3013", "text": "Då en kombination används för transporter på vilka bestämmelserna om transport av farliga ämnen tillämpas uppgår den högsta tillåtna massan som minst sjuaxlad till ( ) kg och för minst en åttaaxlad enligt villkoren i 23 a § i AKTA (1257/1992) till ( ) kg.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1120/3005", "text": "Tieliikenteessä suurin sallittu massa ( ) kg hyväksytty vaihtoehtoisen käyttövoiman painonlisän ( ) kg perusteella.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1120/3005", "text": "I vägtrafiken den högsta tillåtna massa ( ) kg godkänd på grund av tilläggsvikten ( ) kg som alternativa drivkraften innebär.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1121/3009", "text": "Trafin määräyksen TRAFI/4592/03.04.03.00/2015 mukainen EKL-auto.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1121/3009", "text": "En EKL-bil i enlighet med Trafis föreskrift TRAFI/4592/03.04.03.00/2015.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1122/3009", "text": "Vaihtoehtoinen käyttö: Trafin määräyksen TRAFI/4592/03.04.03.00/2015 mukainen EKL-auto.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1122/3009", "text": "Alternativ användning: En EKL-bil i enlighet med Trafis föreskrift TRAFI/4592/03.04.03.00/2015.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1123/3007", "text": "Autoon asennettu perävaunun jarrujen käsiohjausventtiili.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1123/3007", "text": "En handregleringsventil för en släpvagns bromsar installerad i bilen.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1124/3007", "text": "Autoon asennettu käsikäyttöinen etujarruvoimansäädin.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1124/3007", "text": "En bromskraftsregulator för främre bromsar har installerats i bilen.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1118/3006", "text": "Muutettu käyttämään pääosin etanolista koostuvaa polttoainetta.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1118/3006", "text": "Konverterad att använda i huvudsak etanolbaserat bränsle.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1119/3009", "text": "Määräyksen ( ) liitteen ( ) poikkeusten mukaan hyväksytty ajoneuvo.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1119/3009", "text": "Fordon som godkänts enligt undantagen i föreskrift ( ), bilaga ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1168/3007", "text": "Ajoneuvossa on autojen ja niiden perävaunujen paineilmajarrujen vaihtoehtoisista vaatimuksista annetun määräyksen 3.5 kohdassa tarkoitettu ESC-järjestelmä, jonka ei ole osoitettu täyttävän E-säännön 13 ajonvakautustoimintoa koskevia vaatimuksia.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1168/3007", "text": "Fordonet är utrustat med ett ESC-system som avses i punkt 3.5 i föreskriften om alternativa krav på tryckluftsbromsar i bilar och bilsläpvagnar och som inte har visat sig uppfylla kraven på stabilitetskontroll enligt E-reglemente nr 13.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1169/3013", "text": "Auto on jarrujen osalta hyväksytty vetämään kerrallaan useampaa kuin yhtä O3- tai O4-luokan ajoneuvoa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1169/3013", "text": "Bilen har i fråga om bromsarna godkänts för att samtidigt dra flera än ett fordon i kategori O3 eller O4.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1170/3013", "text": "Släpvagnen har i fråga om bromsarna godkänts för att dra ett fordon i kategori O3 eller O4.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1170/3013", "text": "Perävaunu on jarrujen osalta hyväksytty vetämään O3- tai O4-luokan ajoneuvoa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1167/3004", "text": "Fordonet får transporteras i överbredd på väg endast med tillstånd för specialtransport, som nämns i vägtrafiklag.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1167/3004", "text": "Ajoneuvoa saadaan ylileveänä kuljettaa tiellä vain tieliikennelain mukaisin erikoiskuljetusluvin.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1166/3005", "text": "Fordon med överstor massa får användas på väg endast med tillstånd för specialtransport, som nämns i vägtrafiklag.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1166/3005", "text": "Ajoneuvoa saadaan ylimassaisena kuljettaa tiellä vain tieliikennelain mukaisin erikoiskuljetusluvin.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1145/3013", "text": "HCT-kääntyvyysapumitat: ensimmäisen akselin ja takatelin kääntöpisteen välinen etäisyys ( ) mm ja takatelin kääntöpisteen ja vetopöydän välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1145/3013", "text": "Hjälpmått för HCT-fordons vändningsförmåga: avståndet ( ) mm mellan den första axeln och den bakre boggins svängpunkt och avståndet ( ) mm mellan den bakre boggins svängpunkt och vändskivan.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1156/3003", "text": "Ajoneuvoon asennettu flippikeula.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1156/3003", "text": "En flippfront har monterats i fordonet.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1157/3003", "text": "En lösgörbar front har monterats i fordonet.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1157/3003", "text": "Ajoneuvoon asennettu irtokeula.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1155/3013", "text": "HCT-vakausapumitat: ensimmäisen akselin ja takatelin painopisteen välinen etäisyys ( ) mm, takatelin painopisteen ja vetokytkimen välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1155/3013", "text": "Hjälpmått för HCT-fordons stabilitet: avståndet ( ) mm mellan den första axeln och den bakre boggins tyngdpunkt och avståndet ( ) mm mellan den bakre boggins tyngdpunkt och dragkopplingen.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1158/3013", "text": "ALB-venttiili poistettu: ajoneuvoa ei saa käyttää lukkiutumattomilla jarruilla varustamattoman perävaunun vetoon.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1158/3013", "text": "ALB-ventilen avlägsnats: fordonet får inte användas till att dra en släpvagn som inte är försedd med låsningsfria bromsar.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1159/3003", "text": "Ajoneuvo on varustettu turvakaarella/-kehikolla ja kolmipisteturvavöillä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1159/3003", "text": "Fordonet är försett med en störtbåge/skyddsbur och med trepunktsbälten.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1160/3003", "text": "Ajoneuvo on varustettu turvakaarella/-kehikolla ja viisipisteturvavöillä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1160/3003", "text": "Fordonet är försett med en störtbåge/skyddsbur och med fempunktsbälten.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1161/3006", "text": "Ajoneuvo muutettu käyttövoimaltaan käyttämään pääosin puukaasua.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1161/3006", "text": "Fordonet har ändrats så att drivkraften som det använder är i huvudsakligen vedgas.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1162/3002", "text": "Fordonets drifttyp har ändrats till tvåhjulsdrift.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1162/3002", "text": "Ajoneuvon vetotapa muutettu kaksivetoiseksi.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1163/3002", "text": "Ajoneuvon vetotapa muutettu nelivetoiseksi.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1163/3002", "text": "Fordonets drifttyp har ändrats till fyrhjulsdrift.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1164/3003", "text": "Fordonets framsäten har bytts ut.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1164/3003", "text": "Ajoneuvon etuistuimet vaihdettu.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1165/3003", "text": "Fordonets förarsäte har bytts ut.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1165/3003", "text": "Ajoneuvon kuljettajan istuin vaihdettu.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1146/3013", "text": "HCT-kääntyvyysapumitat: vetotapin ja telin kääntöpisteen välinen etäisyys ( ) mm ja telin kääntöpisteen ja perävaunun taaimmaisen kohdan välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1144/3013", "text": "Yhdistelmäpituudella enintään 28,00 m varsinaisen perävaunun suurin sallittu vetopituus ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1144/3013", "text": "Med en kombinationslängd på högst 28,00 m är den största tillåtna draglängden för den egentliga släpvagnen ( ) mm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1150/3013", "text": "Autoon on asennettu automääräyksen TRAFICOM/420030/03.04.03.00/2019 3.4 kohdassa tarkoitettu vaihtoehtoinen kytkentälaite ja sen edellyttämät lisäkilvet. Vaihtoehtoista kytkentälaitetta käytettäessä nopeus enintään 30 km/h.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1150/3013", "text": "På bilen har en i punkt 3.4 i föreskriften TRAFICOM/420030/03.04.03.00/2019 avsedd alternativ kopplingsanordning monterats samt de tilläggsskyltar anordningen kräver. Maxhastigheten när en alternativ kopplingsanordning används är 30 km/h.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1151/3013", "text": "Ajoneuvo ei täytä määräyksen TRAFICOM/420073/03.04.03.00/2019 3.2.1 kohdassa määrättyä kääntyvyysvaatimusta. Ajoneuvon käyttö tiellä sallittu varoitusauton kanssa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1151/3013", "text": "Fordonet uppfyller inte svängningskravet enligt punkt 3.2.1 i föreskriften TRAFICOM/420073/03.04.03.00/2019. Användning av fordonet på väg förutsätter varningsbil.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1146/3013", "text": "Hjälpmått för HCT-fordons vändningsförmåga: avståndet ( ) mm mellan kopplingstappen och boggins svängpunkt och avståndet ( ) mm mellan boggins svängpunkt och släpvagnens bakersta punkt.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1147/3013", "text": "HCT-vakausapumitat: vetotapin ja telin painopisteen välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1147/3013", "text": "Hjälpmått för HCT-fordons stabilitet: avståndet ( ) mm mellan kopplingstappen och boggins tyngdpunkt.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1148/3013", "text": "HCT-kääntyvyysapumitat: vetosilmukan ja etutelin kääntöpisteen välinen etäisyys ( ) mm, etutelin kääntöpisteen ja takatelin kääntöpisteen välinen etäisyys ( ) mm ja takatelin kääntöpisteen ja perävaunun taaimmaisen kohdan välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1148/3013", "text": "Hjälpmått för HCT-fordons vändningsförmåga: avståndet ( ) mm mellan dragöglan och den främre boggins svängpunkt, avståndet ( ) mm mellan den främre boggins svängpunkt och den bakre boggins svängpunkt och avståndet ( ) mm mellan den bakre ...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1149/3013", "text": "HCT-vakausapumitat: vetosilmukan ja etutelin painopisteen välinen etäisyys ( ) mm ja etutelin painopisteen ja takatelin painopisteen välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1149/3013", "text": "Hjälpmått för HCT-fordons stabilitet: avståndet ( ) mm mellan dragöglan och den främre boggins tyngdpunkt och avståndet ( ) mm mellan den främre boggins tyngdpunkt och den bakre boggins tyngdpunkt.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1152/3013", "text": "Ajoneuvoyhdistelmä ei täytä määräyksen TRAFICOM/420073/03.04.03.00/2019 3.3.5 kohdassa määrättyä kääntyvyysvaatimusta. Ajoneuvoyhdistelmän käyttö tiellä sallittu vain varoitusauton kanssa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1152/3013", "text": "Fordonskombinationen uppfyller inte svängningskravet enligt punkt 3.3.5 i föreskriften TRAFICOM/420073/03.04.03.00/2019. Användning av fordonskombinationen på väg förutsätter varningsbil.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1153/3013", "text": "Ajoneuvo ei täytä määräyksen TRAFICOM/420073/03.04.03.00/2019 3.2.1 kohdassa määrättyä kääntyvyysvaatimusta pituudesta ( ) mm alkaen. Ajoneuvon käyttö tiellä tätä pitempänä sallittu varoitusauton kanssa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1153/3013", "text": "Fordonet uppfyller inte svängningskravet enligt punkt 3.2.1 i föreskriften TRAFICOM/420073/03.04.03.00/2019 från och med längden ( ) mm. Användning av fordonet på väg förutsätter varningsbil när denna längd överskrids.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1154/3013", "text": "Ajoneuvoyhdistelmä ei täytä määräyksen TRAFICOM/420073/03.04.03.00/2019 3.3.5 kohdassa määrättyä kääntyvyysvaatimusta pituudesta ( ) mm alkaen. Ajoneuvoyhdistelmän käyttö tiellä tätä pitempänä sallittu vain varoitusauton kanssa.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1154/3013", "text": "Fordonskombinationen uppfyller inte svängningskravet enligt punkt 3.3.5 i föreskriften TRAFICOM/420073/03.04.03.00/2019 från och med längden ( ) mm. Användning av fordonskombinationen på väg förutsätter varningsbil när denna längd överskrids.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1132/3013", "text": "När kombinationens längd är över 18,75 m men högst 20,75 m är en medelaxelsläpvagns högsta tillåtna draglängd ( ) mm och svängningslängd ( ) mm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1133/3013", "text": "HCT-kääntyvyysapumitat: ensimmäisen akselin ja takatelin kääntöpisteen välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1133/3013", "text": "Hjälpmått för HCT-fordons vändningsförmåga: avståndet mellan den första axeln och den bakre boggins svängpunkt ( ) mm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1134/3013", "text": "HCT-kääntyvyysapumitat: ensimmäisen akselin ja takatelin kääntöpisteen välinen etäisyys ( ) mm, takatelin kääntöpisteen ja vetokytkimen välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1134/3013", "text": "Hjälpmått för HCT-fordons vändningsförmåga: avståndet ( ) mm mellan den första axeln och den bakre boggins svängpunkt och avståndet ( ) mm mellan den bakre boggins svängpunkt och dragkopplingen.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1135/3013", "text": "HCT-kääntyvyysapumitat: vetotapin ja telin kääntöpisteen välinen etäisyys ( ) mm, telin kääntöpisteen ja vetopöydän välinen etäisyys ( ) mm ja telin kääntöpisteen ja perävaunun taaimmaisen kohdan välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1131/3013", "text": "Kenttään merkitystä massasta poiketen tieliikenteessä suurin sallittu yhdistelmämassa ( ) kg hyväksytty vaihtoehtoisen käyttövoiman painonlisän ( ) kg perusteella perävaunun siltasääntöpituudella ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1131/3013", "text": "Med avvikelse från massan som anges i fältet är den högsta tillåtna kombinationsmassan i vägtrafiken ( ) kg, godkänd på grund av den extra vikt av ( ) kg för fordon med alternativ drivkraft och broregellängd av ( ) mm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1141/3013", "text": "Hjälpmått för HCT-fordons vändningsförmåga: avståndet mellan dragöglan och boggins svängpunkt ( ) mm och avståndet mellan boggins svängpunkt och släpvagnens bakersta punkt ( ) mm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1142/3013", "text": "Auto on varustettu määräyksen TRAFI/47451/03.04.03.00/2018 kohdan 5 mukaisilla turvavarusteilla.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1142/3013", "text": "Fordonet är försett med de säkerhetsutrustningar som avses i punkt 5 i föreskriften TRAFI/47451/03.04.03.00/2018.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1137/3013", "text": "HCT-kääntyvyysapumitat: vetotapin ja telin kääntöpisteen välinen etäisyys ( ) mm, telin kääntöpisteen ja vetokytkimen välinen etäisyys ( ) mm ja telin kääntöpisteen ja perävaunun taaimmaisen kohdan välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1139/3013", "text": "Hjälpmått för HCT-fordons vändningsförmåga: avståndet ( ) mm mellan dragöglan och den främre boggins svängpunkt, avståndet ( ) mm mellan den främre boggins svängpunkt och den bakre boggins svängpunkt, avståndet ( ) mm mellan den ...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1140/3013", "text": "HCT-vakausapumitat: vetosilmukan ja etutelin painopisteen välinen etäisyys ( ) mm, etutelin painopisteen ja takatelin painopisteen välinen etäisyys ( ) mm ja takatelin painopisteen ja vetopöydän välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1140/3013", "text": "Hjälpmått för HCT-fordons stabilitet: avståndet ( ) mm mellan dragöglan och den främre boggins tyngdpunkt, avståndet ( ) mm mellan den främre boggins tyngdpunkt och den bakre boggins tyngdpunkt och avståndet ( ) mm ...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1141/3013", "text": "HCT-kääntyvyysapumitat: vetosilmukan ja telin kääntöpisteen välinen etäisyys ( ) mm ja telin kääntöpisteen ja perävaunun taaimmaisen kohdan välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1137/3013", "text": "Hjälpmått för HCT-fordons vändningsförmåga: avståndet mellan kopplingstappen och boggins svängpunkt ( ) mm, avståndet mellan boggins svängpunkt och dragkopplingen ( ) mm och avståndet mellan boggins svängpunkt och släpvagnens...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1138/3013", "text": "HCT-vakausapumitat: vetotapin ja telin painopisteen välinen etäisyys ( ) mm ja telin painopisteen ja vetokytkimen välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1138/3013", "text": "Hjälpmått för HCT-fordons stabilitet: avståndet mellan kopplingstappen och boggins tyngdpunkt ( ) mm och avståndet mellan boggins tyngdpunkt och dragkopplingen ( ) mm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1139/3013", "text": "HCT-kääntyvyysapumitat: vetosilmukan ja etutelin kääntöpisteen välinen etäisyys ( ) mm, etutelin kääntöpisteen ja takatelin kääntöpisteen välinen etäisyys ( ) mm, takatelin kääntöpisteen ja vetopöydän välinen etäisyys ( ) mm ja takatelin kääntöpisteen...", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1135/3013", "text": "Hjälpmått för HCT-fordons vändningsförmåga: avståndet mellan kopplingstappen och boggins svängpunkt ( ) mm, avståndet mellan boggins svängpunkt och vändskivan ( ) mm och avståndet mellan boggins svängpunkt och släpvagnens bakersta...", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1136/3013", "text": "HCT-vakausapumitat: vetotapin ja telin painopisteen välinen etäisyys ( ) mm ja telin painopisteen ja vetopöydän välinen etäisyys ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1136/3013", "text": "Hjälpmått för HCT-fordons stabilitet: avståndet mellan kopplingstappen och boggins tyngdpunkt ( ) mm och avståndet mellan boggins tyngdpunkt och vändskivan ( ) mm.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1132/3013", "text": "Yhdistelmäpituudella yli 18,75 m mutta enintään 20,75 m keskiakseliperävaunun suurin sallittu vetopituus ( ) mm ja oikaisupituus ( ) mm.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1143/3013", "text": "Perävaunu on varustettu määräyksen TRAFI/47451/03.04.03.00/2018 kohdan 5 mukaisella ESC-järjestelmällä.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1143/3013", "text": "Släpvagnen är försedd med ett sådant ESC-system som avses i punkt 5 i föreskriften TRAFI/47451/03.04.03.00/2018.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1171/3008", "text": "Vaihdettujen osien määrä ( ) yhteensä ( ) %.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1171/3008", "text": "Utbytna delarnas antal ( ) tillsammans ( ) %.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1129/3002", "text": "Ajoneuvo on käyttötarkoituksen vuoksi hyväksytty liikenteeseen ilman roiskeenestolaitteita. Peruste: ( ).", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1129/3002", "text": "Fordonet har p.g.a. sitt användningsändamål godkänts för trafik utan stänkskyddsanordningar. Grund: ( ).", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1130/3006", "text": "Ajoneuvo hyväksytty ilman EU-säädösten mukaista OBD-järjestelmää.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1130/3006", "text": "Fordonet godkänt utan OBD-system som krävs enligt EU-rättsakterna.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1172/3006", "text": "Fordonet har ändrats så att det använder bränsle som huvudsakligen består av metan.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1172/3006", "text": "Ajoneuvo muutettu käyttämään pääosin metaanista koostuvaa polttoainetta.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1173/3006", "text": "Fordonet har konverterats till eldrift.", "lng": "sv"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1173/3006", "text": "Ajoneuvo muutettu sähkökäyttöiseksi.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1174/3006", "text": "Ajoneuvo muutettu käyttämään pääosin nestekaasua.", "lng": "fi"},
  {"code": "KdTyytiKooditettuHuomautus", "codeValue": "1174/3006", "text": "Fordonet har ändrats till att huvudsakligen använda flytgas.", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AA", "text": "Sedan (AA)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SB", "text": "Panssariajoneuvo (SB)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "BC", "text": "Puoliperävaunun vetoauto (BC)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "BD", "text": "Dragfordon för släpvagn (BD)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "BD", "text": "Perävaunun vetoajoneuvo (BD)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CA", "text": "Envåningsbuss (CA)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CA", "text": "Yksikerroksinen (CA)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CB", "text": "Tvåvåningsbuss (CB)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CB", "text": "Kaksikerroksinen (CB)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CC", "text": "Ledad envåningsbuss (CC)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CC", "text": "Nivelöity yksikerroksinen (CC)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CD", "text": "Ledad tvåvåningsbuss (CD)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CD", "text": "Nivelöity kaksikerroksinen (CD)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CE", "text": "Envånings låggolvsbuss (CE)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CE", "text": "Matalalattiainen yksikerroksinen (CE)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CF", "text": "Tvåvånings låggolvsbuss (CF)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CF", "text": "Matalalattiainen kaksikerroksinen (CF)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CG", "text": "Ledad envånings låggolvsbuss (CG)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CG", "text": "Nivelöity matalalattiainen yksikerroksinen (CG)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CH", "text": "Ledad envånings låggolvsbuss (CG)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CH", "text": "Nivelöity matalalattiainen kaksikerroksinen (CH)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CI", "text": "Envåningsbuss (CI)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CI", "text": "Yksikerroksinen (CI)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CJ", "text": "Tvåvåningsbuss (CJ)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CJ", "text": "Kaksikerroksinen (CJ)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CK", "text": "Ledad envåningsbuss (CK)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CK", "text": "Nivelöity yksikerroksinen (CK)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CL", "text": "Ledad tvåvåningsbuss (CL)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CL", "text": "Nivelöity kaksikerroksinen (CL)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CM", "text": "Envånings låggolvsbuss (CM)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CM", "text": "Matalalattiainen yksikerroksinen (CM)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CN", "text": "Tvåvånings låggolvsbuss (CN)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CN", "text": "Matalalattiainen kaksikerroksinen (CN)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CO", "text": "Ledad envånings låggolvsbuss (CO)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CO", "text": "Nivelöity matalalattiainen yksikerroksinen (CO)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CP", "text": "Ledad tvåvånings låggolvsbuss (CP)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CP", "text": "Nivelöity matalalattiainen kaksikerroksinen (CP)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CQ", "text": "Envåningsbuss (CQ)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CQ", "text": "Yksikerroksinen (CQ)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CR", "text": "Tvåvåningsbuss (CR)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CR", "text": "Kaksikerroksinen (CR)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CS", "text": "Ledad envåningsbuss (CS)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CS", "text": "Nivelöity yksikerroksinen (CS)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CT", "text": "Ledad tvåvåningsbuss (CT)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CT", "text": "Nivelöity kaksikerroksinen (CT)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CU", "text": "Envåningsbuss (CU)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CU", "text": "Yksikerroksinen (CU)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CV", "text": "Envånings låggolvsbuss (CV)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CV", "text": "Matalalattiainen yksikerroksinen (CV)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CW", "text": "Envåningsbuss (CW)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "CW", "text": "Yksikerroksinen (CW)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "DA", "text": "Påhängsvagn (DA)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "DA", "text": "Puoliperävaunu (DA)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "DB", "text": "Släpvagn med dragstång (DB)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "DB", "text": "Varsinainen perävaunu (DB)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "DC", "text": "Släpkärra (DC)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "DC", "text": "Keskiakseliperävaunu (DC)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SA", "text": "Campingbil (SA)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SA", "text": "Matkailuauto (SA)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SB", "text": "Bepansrat fordon (SB)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "BA", "text": "Avolavakuorma-auto (BA)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "BB", "text": "Skåpbil (BB)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "BB", "text": "Umpikorinen (BB)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "BC", "text": "Dragfordon för påhängsvagn (BC)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AA", "text": "Sedan (AA)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AB", "text": "Kombisedan (AB)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AB", "text": "Viistoperä (AB)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AC", "text": "Stationsvagn (AC)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AC", "text": "Farmari (AC)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AD", "text": "Kupé (AD)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AD", "text": "Coupé (AD)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AE", "text": "Cabriolet (AE)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AE", "text": "Avoauto (AE)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AF", "text": "Fordon avsett för flera ändamål (AF)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "AF", "text": "Monikäyttöajoneuvo (AF)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "BA", "text": "Lastbil (BA)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SC", "text": "Ambulans (SC)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SC", "text": "Ambulanssi (SC)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SD", "text": "Likbil (SD)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SD", "text": "Ruumisauto (SD)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SE", "text": "Husvagn (SE)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SE", "text": "Matkailuperävaunu (SE)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SF", "text": "Mobilkran (SF)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SF", "text": "Ajoneuvonosturi (SF)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SG", "text": "Andra fordon avsedda för särskilda ändamål (SG)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SG", "text": "Muut erikoiskäyttöön tarkoitetut ajoneuvot (SG)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SH", "text": "Rullstolsanpassade fordon (SH)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SH", "text": "Pyörätuolin käyttäjälle tarkoitettu ajoneuvo (SH)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "U/A", "text": "Sluten/öppen", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "U/A", "text": "Umpi/avo", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SK", "text": "Erikoiskuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SK", "text": "Släpvagn för transport av exceptionell last", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SL", "text": "Erikoiskuljetusauto", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SL", "text": "Motorfordon för transport av exceptionell last", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SM", "text": "Monilaiteajoneuvo", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SM", "text": "Redskapsbärare", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "1.7", "text": "AG Tavarafarmari", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "2.11", "text": "CX Linja-auton alusta", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "3.5", "text": "BE Avopakettiauto", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "3.6", "text": "BX Alusta-ohjaamo", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "4.4", "text": "DE Nivelöimättömällä vetoaisalla varustettu perävaunu", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "5.9", "text": "SJ Apuvaunu", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "5.10", "text": "SK Erikoiskuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "2.9", "text": "CI Avokattoinen yksikerroksinen ajoneuvo", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "3.1", "text": "BA Tavara-auto", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "2.10", "text": "CJ Avokattoinen kaksikerroksinen ajoneuvo", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "SJ", "text": "Apuvaunu (SJ)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "DF", "text": "Linkkipuoliperävaunu (DF)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "DF", "text": "Link-påhängsvagn (DF)", "lng": "sv"},
  {"code": "KdTyytiKorityyppi", "codeValue": "DG", "text": "Vetoaisalla varustettu linkkiperävaunu (DG)", "lng": "fi"},
  {"code": "KdTyytiKorityyppi", "codeValue": "DG", "text": "Link-släpvagn med dragstång (DG)", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "1", "text": "Förbrukning på landsväg", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "1", "text": "Kulutus maantieajossa", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "2", "text": "Förbrukning i stadstrafik", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "2", "text": "Kulutus kaupunkiajossa", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "3", "text": "Kombinerad förbrukning", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "3", "text": "Yhdistetty kulutus", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "4", "text": "CO2 Kombinerat", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "4", "text": "CO2 Yhdistetty", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "5", "text": "CO2 Stad", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "5", "text": "CO2 Kaupunki", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "6", "text": "CO2 Landsväg", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "6", "text": "CO2 Maantie", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "7", "text": "CO2 Viktat, kombinerat", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "7", "text": "CO2 Painotettu, yhdistetty", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "8", "text": "Förbrukning Viktad, kombinerad", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "8", "text": "Kulutus Painotettu, yhdistetty", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "10", "text": "WLTP CO2, painotettu yhdistetty", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "10", "text": "WLTP CO2 viktade, blandad körning", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "11", "text": "WLTP I, yhdistetty kulutus", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "11", "text": "WLTP I bränsleförbrukning kombinerad", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "12", "text": "WLTP I, painotettu, yhdistetty kulutus", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "9", "text": "WLTP, CO2 yhdistetty", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "9", "text": "WLTP CO2 kombinerad", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "12", "text": "WLTP I bränsleförbrukning viktade, blandad körning", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "01", "text": "Kappaletavaralava", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "01", "text": "Styckegodsflak", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "02", "text": "Maansiirtolava", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "02", "text": "Jordtransportflak", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "03", "text": "Kappaletavaral+irtokate", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "03", "text": "Styckegodsflak med betäckning", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "04", "text": "Puutavarapankot", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "04", "text": "Timmerbanke", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "05", "text": "Umpikori+säiliö", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "05", "text": "Skåp+tank", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "06", "text": "Umpikori eristetty", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "06", "text": "Skåp, isolerat", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "07", "text": "Umpikori eristämätön", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "07", "text": "Skåp, oisolerat", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "08", "text": "Painesäiliö", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "08", "text": "Tryckbehållare", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "09", "text": "VAK-säiliö", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "09", "text": "TFÄ-tank", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "10", "text": "Muu säiliö", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "10", "text": "Övrig behållare", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "11", "text": "Konttivaruste", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "11", "text": "Containerutrustning", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "12", "text": "Jätepuristinkori", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "12", "text": "Buntningspress", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "13", "text": "Ajoneuvon kulj.telineet", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "13", "text": "Fordonstransportanordning", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "14", "text": "Muu korirakenne", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "14", "text": "Övrig karosserikonstruktion", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "15", "text": "Vaihtokorilaitteet", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "15", "text": "Växelflakutrustning", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "16", "text": "Tukikaaret ja telttakatos", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "16", "text": "Stödbågar och tältskärm", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "17", "text": "Telttakatos (ee)", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "17", "text": "Tältskärm", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "18", "text": "Lämmitettävä lava (ee)", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "18", "text": "Uppvärmbar flak", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "1", "text": "Dragkoppling", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "1", "text": "Vetokytkin", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "2", "text": "Draghjul", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "2", "text": "Vetopöytä", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "3", "text": "Dragkrok", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "3", "text": "Vetokoukku", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "4", "text": "SA-dragkrok", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "4", "text": "SA-vetokoukku", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "5", "text": "Dragstång", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "5", "text": "Vetoaisa", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "6", "text": "Dragbalk", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "6", "text": "Vetopalkki", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "7", "text": "Kopplingstapp", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "7", "text": "Vetotappi", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "8", "text": "Dragkoppling för medelaxelsläpvagn", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "8", "text": "KAP-vetokytkin", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "9", "text": "Kulkoppling", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "9", "text": "Vetopää", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "1", "text": "Nostolava", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "2", "text": "Luiska liikuntaesteisille", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "3", "text": "Kuljettajan lepotila", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "4", "text": "Koko sivun niiaus", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "5", "text": "Invanostin", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "6", "text": "Etupään niiaus", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "7", "text": "Lisäaskelmat", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "1", "text": "Hissplattform", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "2", "text": "Ramp för rörelsehindrade", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "3", "text": "Rastutrymme för förare", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "4", "text": "Nigfunktion på hela sidan", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "5", "text": "Rullstolslyft", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "6", "text": "Nigfunktion i främre delen", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "7", "text": "Extra steg", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "1", "text": "Tillsatsbromssystem", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "1", "text": "Hidastin", "lng": "fi"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "2", "text": "Elektromagnetisk broms", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "2", "text": "Sähkömagneettinen jarru", "lng": "fi"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "3", "text": "Motorbroms", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "3", "text": "Moottorijarru", "lng": "fi"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "4", "text": "Avgasbroms", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "4", "text": "Pakokaasujarru", "lng": "fi"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "5", "text": "Transmissionsbroms", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "5", "text": "Voimansiirtojarru", "lng": "fi"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "Y", "text": "Annan", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "01", "text": "Nosturi", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "01", "text": "Kran", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "02", "text": "Kippi", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "02", "text": "Tipp", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "03", "text": "Takalaitanostin", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "03", "text": "Bakgavelhiss", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "04", "text": "Sivulaitanostin", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "04", "text": "Sidogavellyft", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "05", "text": "Vaihtokorilaitteet", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "05", "text": "Växelflakutrustning", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "06", "text": "Lämmityslaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "06", "text": "Uppvärmningsmaskin", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "07", "text": "Jäähdytyslaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "07", "text": "Kylanordning", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "08", "text": "Vinssi", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "08", "text": "Vinsch", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "09", "text": "Kitkavetolaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "09", "text": "Drivrulle", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "10", "text": "Konttilukot", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "10", "text": "Containerlåsanordning", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "11", "text": "Invanostin", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "11", "text": "Invahiss", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "12", "text": "Kolmikaatokippi", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "12", "text": "Trehållstipp", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "13", "text": "Aurausvarustus", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "13", "text": "Plogutrustning", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "14", "text": "Aurauspuskuri", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "14", "text": "Plogfäste", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "15", "text": "Lumiaura", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "15", "text": "Snöplog", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "16", "text": "Sohjonkeräyslevy", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "16", "text": "Moddsamlarblad", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "17", "text": "Hiekoituslaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "17", "text": "Sandspridare", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "18", "text": "Maalauslaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "18", "text": "Målningsutrustning", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "19", "text": "Harjakone", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "19", "text": "Borstmaskin", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "20", "text": "Jalkalavalaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "20", "text": "Flakväxlare", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "21", "text": "Aggregaatti", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "21", "text": "Aggregat", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "22", "text": "Kompressori", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "22", "text": "Kompressor", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "23", "text": "Tukijalat", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "23", "text": "Stödben", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "24", "text": "Ajosillat", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "24", "text": "Ramper", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "25", "text": "SA-vetokoukku", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "25", "text": "SA-dragkrok", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "Z", "text": "Muu, mikä...", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "Z", "text": "Övrig, vilken...", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "0", "text": "Musta", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "0", "text": "Svart", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "1", "text": "Ruskea (beige)", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "1", "text": "Brun (beige)", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "2", "text": "Punainen", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "2", "text": "Röd", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "3", "text": "Oranssi", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "3", "text": "Orange", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "4", "text": "Keltainen", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "4", "text": "Gul", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "5", "text": "Vihreä", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "5", "text": "Grön", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "6", "text": "Sininen", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "6", "text": "Blå", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "7", "text": "Violetti", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "7", "text": "Violett", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "8", "text": "Harmaa", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "8", "text": "Grå", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "9", "text": "Valkoinen", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "9", "text": "Vit", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "a", "text": "Vaalea", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "a", "text": "Ljus", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "b", "text": "Tumma", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "b", "text": "Mörk", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "c", "text": "Metallinhohto", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "c", "text": "Metalliclack", "lng": "sv"},
  {"code": "KdTyytiLisavarusteenTila", "codeValue": "E", "text": "Ei", "lng": "fi"},
  {"code": "KdTyytiLisavarusteenTila", "codeValue": "K", "text": "Kyllä", "lng": "fi"},
  {"code": "KdTyytiLisavarusteenTila", "codeValue": "S", "text": "Keskeneräinen", "lng": "fi"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "1", "text": "Sluten förarhytt", "lng": "sv"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "1", "text": "Umpiohjaamo", "lng": "fi"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "2", "text": "Skyddsram", "lng": "sv"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "2", "text": "Suojakehys", "lng": "fi"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "3", "text": "Förlängd förarhytt", "lng": "sv"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "3", "text": "Jatko-ohjaamo", "lng": "fi"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "4", "text": "Öppen förarhytt", "lng": "sv"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "4", "text": "Avo-ohjaamo", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "01", "text": "2 V, 2 H", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "01", "text": "2 V, 2 O", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "02", "text": "2 V, 2 H, bakdörr", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "02", "text": "2 V, 2 O, takaovi", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "03", "text": "2 V, 2 H, pardörrar baktill", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "03", "text": "2 V, 2 O, pariovi takana", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "04", "text": "1 V, 1 H", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "04", "text": "1 V, 1 O", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "05", "text": "1 V, 1 H, bakdörr", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "05", "text": "1 V, 1 O, takaovi", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "06", "text": "1 V, 1 H, pardörrar baktill", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "06", "text": "1 V, 1 O, pariovi takana", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "07", "text": "1 V, 2 H", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "07", "text": "1 V, 2 O", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "08", "text": "1 V, 2 H, bakdörr", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "08", "text": "1 V, 2 O, takaovi", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "09", "text": "1 V, 2 H, pardörrar baktill", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "09", "text": "1 V, 2 O, pariovi takana", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "10", "text": "1 H (bussar)", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "10", "text": "1 O (linja-autot)", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "11", "text": "2 H (bussar)", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "11", "text": "2 O (linja-autot)", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "12", "text": "3 H (bussar)", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "12", "text": "3 O (linja-autot)", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "X", "text": "Ej tillämpligt", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "X", "text": "Ei sovellettavissa", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "Y", "text": "Annan", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "01", "text": "CO", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "02", "text": "CO<sub>2</sub>", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "03", "text": "NO<sub>x</sub>", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "04", "text": "Savutus [m<sup>-1</sup>]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "05", "text": "Lambda-arvo", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "06", "text": "HC/THC", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "07", "text": "NMHC", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "09", "text": "CH<sub>4</sub>", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "10", "text": "Hiukkaset", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "11", "text": "HC/THC+NO<sub>x</sub>", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "12", "text": "Hiukkasten lkm * 10<sup>11</sup>", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "13", "text": "ELR-testin savutus [m<sup>-1</sup>]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "14", "text": "CO [g/min]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "15", "text": "HC [g/min]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "16", "text": "NH3 [ppm]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "17", "text": "CO [til%]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "05", "text": "Lambdavärde", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "10", "text": "Partiklar", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "15", "text": "HC [g/min]", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "16", "text": "NH3 [ppm]", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "17", "text": "CO [volym-%]", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "04", "text": "Rökvärde [m<sup>-1</sup>]", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "01", "text": "CO", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "02", "text": "CO<sub>2</sub>", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "03", "text": "NO<sub>x</sub>", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "06", "text": "Kolväten/Kolväten totalt", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "07", "text": "Andra kolväten än metan", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "09", "text": "CH<sub>4</sub>", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "11", "text": "Kolväten/Kolväten totalt+NO<sub>x</sub>", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "12", "text": "Antal partiklar * 10<sup>11</sup>", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "13", "text": "Resultat av ELR-provet rökvärde [m<sup>-1</sup>]", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "14", "text": "CO [g/min]", "lng": "sv"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "764", "text": "715/2007 - 2023/443AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "763", "text": "595/2009 - 2022/2383 E (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "761", "text": "715/2007 - 2023/443EB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "762", "text": "715/2007 - 2023/443EC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "760", "text": "715/2007 - 2023/443EA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "759", "text": "595/2009 - 2020/1181 E (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "485", "text": "715/2007 - 2016/646J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "486", "text": "715/2007 - 2016/646K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "487", "text": "715/2007 - 2016/646L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "488", "text": "715/2007 - 2016/646M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "489", "text": "715/2007 - 2016/646U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "490", "text": "715/2007 - 2016/646V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "491", "text": "715/2007 - 2016/646W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "492", "text": "715/2007 - 2016/646X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "493", "text": "715/2007 - 2016/646Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "494", "text": "715/2007 - 2016/646ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "495", "text": "715/2007 - 2016/646ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "496", "text": "715/2007 - 2016/646ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "497", "text": "715/2007 - 2016/646ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "498", "text": "715/2007 - 2016/646ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "499", "text": "715/2007 - 2016/646ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "500", "text": "715/2007 - 2016/646ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "501", "text": "715/2007 - 2016/646ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "502", "text": "715/2007 - 2016/646ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "503", "text": "715/2007 - 2016/646ZG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "504", "text": "715/2007 - 2016/646ZH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "505", "text": "715/2007 - 2016/646ZI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "506", "text": "715/2007 - 2016/427J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "507", "text": "715/2007 - 2016/427K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "508", "text": "715/2007 - 2016/427L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "509", "text": "715/2007 - 2016/427M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "510", "text": "715/2007 - 2016/427U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "511", "text": "715/2007 - 2016/427V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "512", "text": "715/2007 - 2016/427W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "513", "text": "715/2007 - 2016/427X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "514", "text": "715/2007 - 2016/427Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "515", "text": "715/2007 - 2016/427ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "516", "text": "715/2007 - 2016/427ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "517", "text": "715/2007 - 2016/427ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "518", "text": "715/2007 - 2016/427ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "519", "text": "715/2007 - 2016/427ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "520", "text": "715/2007 - 2016/427ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "521", "text": "715/2007 - 2016/427ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "522", "text": "715/2007 - 2016/427ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "523", "text": "715/2007 - 2016/427ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "524", "text": "595/2009 - 2016/1718 A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "525", "text": "595/2009 - 2016/1718 B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "526", "text": "595/2009 - 2016/1718 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "527", "text": "595/2009 - 2016/1718 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "529", "text": "134/2014-2016/1824A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "585", "text": "715/2007 - 2017/1154AG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "586", "text": "715/2007 - 2017/1154BG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "587", "text": "715/2007 - 2017/1154AH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "588", "text": "715/2007 - 2017/1154BH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "589", "text": "715/2007 - 2017/1154AI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "590", "text": "715/2007 - 2017/1154BI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "591", "text": "715/2007 - 2017/1154AJ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "592", "text": "715/2007 - 2017/1154AK (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "593", "text": "715/2007 - 2017/1154AL (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "594", "text": "715/2007 - 2017/1154AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "595", "text": "715/2007 - 2017/1154AY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "596", "text": "715/2007 - 2017/1154AZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "597", "text": "715/2007 - 2017/1221AA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "598", "text": "715/2007 - 2017/1221BA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "599", "text": "715/2007 - 2017/1221AB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "600", "text": "715/2007 - 2017/1221BB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "601", "text": "715/2007 - 2017/1221AC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "602", "text": "715/2007 - 2017/1221BC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "603", "text": "715/2007 - 2017/1221AD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "604", "text": "715/2007 - 2017/1221AE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "605", "text": "715/2007 - 2017/1221AF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "606", "text": "715/2007 - 2017/1221AG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "607", "text": "715/2007 - 2017/1221BG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "608", "text": "715/2007 - 2017/1221AH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "609", "text": "715/2007 - 2017/1221BH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "610", "text": "715/2007 - 2017/1221AI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "611", "text": "715/2007 - 2017/1221BI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "612", "text": "715/2007 - 2017/1221AJ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "613", "text": "715/2007 - 2017/1221AK (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "614", "text": "715/2007 - 2017/1221AL (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "615", "text": "715/2007 - 2017/1221AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "616", "text": "715/2007 - 2017/1221AY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "617", "text": "715/2007 - 2017/1221AZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "584", "text": "715/2007 - 2017/1154AF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "530", "text": "715/2007 - 2017/1347AA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "531", "text": "715/2007 - 2017/1347BA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "532", "text": "715/2007 - 2017/1347AB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "533", "text": "715/2007 - 2017/1347BB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "534", "text": "715/2007 - 2017/1347AC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "535", "text": "715/2007 - 2017/1347BC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "536", "text": "715/2007 - 2017/1347AD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "537", "text": "715/2007 - 2017/1347AE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "538", "text": "715/2007 - 2017/1347AF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "539", "text": "715/2007 - 2017/1347AG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "540", "text": "715/2007 - 2017/1347BG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "541", "text": "715/2007 - 2017/1347AH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "542", "text": "715/2007 - 2017/1347BH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "543", "text": "715/2007 - 2017/1347AI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "544", "text": "715/2007 - 2017/1347BI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "545", "text": "715/2007 - 2017/1347AJ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "546", "text": "715/2007 - 2017/1347AK (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "547", "text": "715/2007 - 2017/1347AL (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "548", "text": "715/2007 - 2017/1347AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "549", "text": "715/2007 - 2017/1347AY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "550", "text": "715/2007 - 2017/1347AZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "551", "text": "595/2009 - 2017/1151 A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "552", "text": "595/2009 - 2017/1151 B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "553", "text": "595/2009 - 2017/1151 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "554", "text": "595/2009 - 2017/1151 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "555", "text": "715/2007 - 2017/1151AA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "556", "text": "715/2007 - 2017/1151BA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "557", "text": "715/2007 - 2017/1151AB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "558", "text": "715/2007 - 2017/1151BB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "559", "text": "715/2007 - 2017/1151AC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "560", "text": "715/2007 - 2017/1151BC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "561", "text": "715/2007 - 2017/1151AD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "562", "text": "715/2007 - 2017/1151AE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "563", "text": "715/2007 - 2017/1151AF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "564", "text": "715/2007 - 2017/1151AG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "565", "text": "715/2007 - 2017/1151BG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "566", "text": "715/2007 - 2017/1151AH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "567", "text": "715/2007 - 2017/1151BH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "568", "text": "715/2007 - 2017/1151AI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "569", "text": "715/2007 - 2017/1151BI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "570", "text": "715/2007 - 2017/1151AJ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "571", "text": "715/2007 - 2017/1151AK (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "572", "text": "715/2007 - 2017/1151AL (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "573", "text": "715/2007 - 2017/1151AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "574", "text": "715/2007 - 2017/1151AY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "575", "text": "715/2007 - 2017/1151AZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "576", "text": "715/2007 - 2017/1154AA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "577", "text": "715/2007 - 2017/1154BA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "578", "text": "715/2007 - 2017/1154AB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "579", "text": "715/2007 - 2017/1154BB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "580", "text": "715/2007 - 2017/1154AC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "581", "text": "715/2007 - 2017/1154BC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "582", "text": "715/2007 - 2017/1154AD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "583", "text": "715/2007 - 2017/1154AE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "618", "text": "715/2007 - 2017/1347W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "619", "text": "715/2007 - 2017/1347X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "620", "text": "715/2007 - 2017/1347Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "621", "text": "715/2007 - 2017/1347ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "622", "text": "715/2007 - 2017/1347ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "623", "text": "715/2007 - 2017/1347ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "624", "text": "715/2007 - 2017/1347ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "625", "text": "715/2007 - 2017/1347ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "626", "text": "715/2007 - 2017/1347ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "627", "text": "715/2007 - 2017/1347ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "628", "text": "715/2007 - 2017/1347ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "629", "text": "715/2007 - 2017/1347ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "630", "text": "715/2007 - 2017/1151W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "631", "text": "715/2007 - 2017/1151X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "632", "text": "715/2007 - 2017/1151Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "633", "text": "715/2007 - 2017/1151ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "634", "text": "715/2007 - 2017/1151ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "635", "text": "715/2007 - 2017/1151ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "636", "text": "715/2007 - 2017/1151ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "637", "text": "715/2007 - 2017/1151ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "638", "text": "715/2007 - 2017/1151ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "639", "text": "715/2007 - 2017/1151ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "640", "text": "715/2007 - 2017/1151ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "641", "text": "715/2007 - 2017/1151ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "642", "text": "715/2007 - 2017/1154W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "643", "text": "715/2007 - 2017/1154X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "644", "text": "715/2007 - 2017/1154Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "645", "text": "715/2007 - 2017/1154ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "646", "text": "715/2007 - 2017/1154ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "647", "text": "715/2007 - 2017/1154ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "648", "text": "715/2007 - 2017/1154ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "649", "text": "715/2007 - 2017/1154ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "650", "text": "715/2007 - 2017/1154ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "651", "text": "715/2007 - 2017/1154ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "652", "text": "715/2007 - 2017/1154ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "653", "text": "715/2007 - 2017/1154ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "654", "text": "715/2007 - 2017/1221W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "655", "text": "715/2007 - 2017/1221X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "656", "text": "715/2007 - 2017/1221Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "657", "text": "715/2007 - 2017/1221ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "658", "text": "715/2007 - 2017/1221ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "659", "text": "715/2007 - 2017/1221ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "660", "text": "715/2007 - 2017/1221ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "661", "text": "715/2007 - 2017/1221ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "662", "text": "715/2007 - 2017/1221ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "663", "text": "715/2007 - 2017/1221ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "664", "text": "715/2007 - 2017/1221ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "665", "text": "715/2007 - 2017/1221ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "758", "text": "", "lng": "sv"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "758", "text": "134/2014 - 2016/1824A2 (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "742", "text": "2018/985 - 2018/985 C1 NRS-vr-1b", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "748", "text": "2018/985 - 2018/985 C1 NRE-v-1", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "757", "text": "134/2014 - 2020/239A3 (Euro 5+)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "746", "text": "2018/985 - 2018/985 C1 NRS-v-2b", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "743", "text": "2018/985 - 2018/985 C1 NRS-vi-1a", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "751", "text": "2018/985 - 2018/985 C1 NRE-v-4", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "747", "text": "2018/985 - 2018/985 C1 NRS-v-3", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "752", "text": "2018/985 - 2018/985 C1 NRE-v-5", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "754", "text": "2018/985 - 2018/985 C1 NRE-v-7", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "749", "text": "2018/985 - 2018/985 C1 NRE-v-2", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "756", "text": "134/2014 - 2020/239A2 (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "750", "text": "2018/985 - 2018/985 C1 NRE-v-3", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "753", "text": "2018/985 - 2018/985 C1 NRE-v-6", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "741", "text": "2018/985 - 2018/985 C1 NRS-vr-1a", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "744", "text": "2018/985 - 2018/985 C1 NRS-vi-1b", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "755", "text": "134/2014 - 2020/239A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "745", "text": "2018/985 - 2018/985 C1 NRS-v-2a", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "672", "text": "2016/1628 - EV1/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "673", "text": "2016/1628 - EV2/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "674", "text": "2016/1628 - EV3/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "675", "text": "2016/1628 - EV4/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "676", "text": "2016/1628 - EV5/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "677", "text": "2016/1628 - EV6/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "678", "text": "2016/1628 - EV7/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "679", "text": "2016/1628 - SM1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "680", "text": "2016/1628 - SRA1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "681", "text": "2016/1628 - SRA2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "682", "text": "2016/1628 - SRA3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "683", "text": "2016/1628 - SRB1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "684", "text": "2016/1628 - SRB2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "685", "text": "2016/1628 - SRB3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "686", "text": "2016/1628 - SYA1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "687", "text": "2016/1628 - SYA2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "688", "text": "2016/1628 - SYA3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "689", "text": "2016/1628 - SYB1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "690", "text": "2016/1628 - SYB2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "691", "text": "2016/1628 - SYB3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "692", "text": "2016/1628 - SVA1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "693", "text": "2016/1628 - SVA2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "694", "text": "2016/1628 - SVA3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "695", "text": "2016/1628 - SVB1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "696", "text": "2016/1628 - SVB2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "697", "text": "2016/1628 - SVB3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "698", "text": "2016/1628 - SV31/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "699", "text": "2016/1628 - SV32/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "700", "text": "2016/1628 - SV33/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "666", "text": "595/2009 - 2017/1347 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "667", "text": "595/2009 - 2017/1347 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "668", "text": "595/2009 - 2017/2400 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "669", "text": "595/2009 - 2017/2400 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "670", "text": "134/2014-2018/295A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "671", "text": "134/2014-2018/295A2 (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "737", "text": "715/2007 - 2017/1347DG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "738", "text": "715/2007 - 2017/1347AM (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "739", "text": "595/2009 - 2019/1939 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "740", "text": "595/2009 - 2019/1939 E (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "701", "text": "2016/1628 - AT1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "702", "text": "715/2007 - 2018/1832AA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "703", "text": "715/2007 - 2018/1832BA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "704", "text": "715/2007 - 2018/1832AB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "705", "text": "715/2007 - 2018/1832BB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "706", "text": "715/2007 - 2018/1832AC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "707", "text": "715/2007 - 2018/1832BC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "708", "text": "715/2007 - 2018/1832AD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "709", "text": "715/2007 - 2018/1832AE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "710", "text": "715/2007 - 2018/1832AF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "711", "text": "715/2007 - 2018/1832AG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "712", "text": "715/2007 - 2018/1832BG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "713", "text": "715/2007 - 2018/1832CG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "714", "text": "715/2007 - 2018/1832DG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "715", "text": "715/2007 - 2018/1832AH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "716", "text": "715/2007 - 2018/1832BH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "717", "text": "715/2007 - 2018/1832CH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "718", "text": "715/2007 - 2018/1832AI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "719", "text": "715/2007 - 2018/1832BI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "720", "text": "715/2007 - 2018/1832CI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "721", "text": "715/2007 - 2018/1832AJ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "722", "text": "715/2007 - 2018/1832AK (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "723", "text": "715/2007 - 2018/1832AL (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "724", "text": "715/2007 - 2018/1832AM (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "725", "text": "715/2007 - 2018/1832AN (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "726", "text": "715/2007 - 2018/1832AO (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "727", "text": "715/2007 - 2018/1832AP (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "728", "text": "715/2007 - 2018/1832AQ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "729", "text": "715/2007 - 2018/1832AR (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "730", "text": "715/2007 - 2018/1832AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "731", "text": "715/2007 - 2018/1832AY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "732", "text": "715/2007 - 2018/1832AZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "733", "text": "595/2009 - 2019/318 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "734", "text": "595/2009 - 2019/318 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "735", "text": "595/2009 - 2018/932 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "736", "text": "595/2009 - 2018/932 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "462", "text": "715/2007 - 2015/45J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "463", "text": "715/2007 - 2015/45K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "464", "text": "715/2007 - 2015/45L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "465", "text": "715/2007 - 2015/45M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "96", "text": "2005/55/EY - 2006/51E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "466", "text": "715/2007 - 2015/45T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "467", "text": "715/2007 - 2015/45U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "468", "text": "715/2007 - 2015/45V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "469", "text": "715/2007 - 2015/45W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "470", "text": "715/2007 - 2015/45X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "471", "text": "715/2007 - 2015/45Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "472", "text": "715/2007 - 2015/45ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "473", "text": "715/2007 - 2015/45ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "474", "text": "715/2007 - 2015/45ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "475", "text": "715/2007 - 2015/45ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "476", "text": "715/2007 - 2015/45ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "477", "text": "715/2007 - 2015/45ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "478", "text": "715/2007 - 2015/45ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "479", "text": "715/2007 - 2015/45ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "480", "text": "715/2007 - 2015/45ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "481", "text": "97/68/EY - 2012/46L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "482", "text": "97/68/EY - 2012/46M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "483", "text": "97/68/EY - 2012/46N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "484", "text": "97/68/EY - 2012/46P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "450", "text": "E83/06U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "451", "text": "E83/06V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "452", "text": "E83/06W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "453", "text": "E83/06X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "454", "text": "E83/06Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "455", "text": "E83/06ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "456", "text": "E83/06ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "457", "text": "E83/06ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "458", "text": "134/2014-134/2014A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "459", "text": "134/2014-134/2014A2 (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "460", "text": "E40A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "461", "text": "E47A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "104", "text": "97/24/EY 5 luku - 2003/77A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "105", "text": "97/24/EY 5 luku - 2003/77B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "106", "text": "97/24/EY 5 luku - 2005/30", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "107", "text": "97/24/EY 5 luku - 2005/30A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "108", "text": "97/24/EY 5 luku - 2005/30B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "109", "text": "97/24/EY 5 luku - 2006/27", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "110", "text": "97/24/EY 5 luku - 2006/27A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "111", "text": "97/24/EY 5 luku - 2006/27B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "112", "text": "97/24/EY 5 luku - 2006/72", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "430", "text": "595/2009 - 133/2014B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "431", "text": "595/2009 - 133/2014C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "432", "text": "97/68/EY - 2002/88S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "433", "text": "97/68/EY - 2004/26S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "434", "text": "97/68/EY - 2006/105S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "435", "text": "97/68/EY - 2010/26S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "436", "text": "97/68/EY - 2011/88S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "437", "text": "97/68/EY - 2012/46S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "438", "text": "97/24/EY 5 luku - 2002/51", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "439", "text": "595/2009 - 627/2014A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "440", "text": "595/2009 - 627/2014B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "441", "text": "595/2009 - 627/2014C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "442", "text": "97/24/EY 5 luku - 2013/60", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "443", "text": "97/24/EY 5 luku - 2013/60A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "444", "text": "97/24/EY 5 luku - 2013/60B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "445", "text": "97/24/EY 5 luku - 2013/60C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "446", "text": "2000/25/EY - 2014/43C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "447", "text": "2000/25/EY - 2014/43D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "448", "text": "2000/25/EY - 2014/43E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "449", "text": "E83/06T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "351", "text": "715/2007 - 171/2013M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "352", "text": "715/2007 - 171/2013N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "353", "text": "715/2007 - 171/2013O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "354", "text": "715/2007 - 171/2013P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "355", "text": "715/2007 - 171/2013Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "356", "text": "715/2007 - 171/2013R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "357", "text": "715/2007 - 171/2013S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "358", "text": "715/2007 - 171/2013T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "359", "text": "715/2007 - 171/2013U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "360", "text": "715/2007 - 171/2013V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "361", "text": "715/2007 - 171/2013W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "362", "text": "715/2007 - 171/2013X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "363", "text": "715/2007 - 171/2013Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "364", "text": "715/2007 - 171/2013ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "365", "text": "715/2007 - 171/2013ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "366", "text": "715/2007 - 171/2013ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "367", "text": "715/2007 - 171/2013ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "368", "text": "715/2007 - 171/2013ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "410", "text": "715/2007 - 136/2014K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "411", "text": "715/2007 - 136/2014L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "412", "text": "715/2007 - 136/2014M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "413", "text": "715/2007 - 136/2014T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "414", "text": "715/2007 - 136/2014U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "415", "text": "715/2007 - 136/2014V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "416", "text": "715/2007 - 136/2014W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "417", "text": "715/2007 - 136/2014X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "418", "text": "715/2007 - 136/2014Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "419", "text": "715/2007 - 136/2014ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "420", "text": "715/2007 - 136/2014ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "421", "text": "715/2007 - 136/2014ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "422", "text": "715/2007 - 136/2014ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "423", "text": "715/2007 - 136/2014ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "424", "text": "715/2007 - 136/2014ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "425", "text": "715/2007 - 136/2014ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "426", "text": "715/2007 - 136/2014ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "427", "text": "715/2007 - 136/2014ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "428", "text": "97/24/EY 5 luku - 2006/27C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "429", "text": "595/2009 - 133/2014A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "370", "text": "715/2007 - 630/2012M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "371", "text": "715/2007 - 630/2012G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "372", "text": "715/2007 - 195/2013A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "373", "text": "715/2007 - 195/2013B (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "374", "text": "715/2007 - 195/2013C (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "375", "text": "715/2007 - 195/2013D (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "376", "text": "715/2007 - 195/2013E (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "377", "text": "715/2007 - 195/2013F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "378", "text": "715/2007 - 195/2013G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "379", "text": "715/2007 - 195/2013H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "380", "text": "715/2007 - 195/2013I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "381", "text": "715/2007 - 195/2013J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "382", "text": "715/2007 - 195/2013K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "383", "text": "715/2007 - 195/2013L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "384", "text": "715/2007 - 195/2013M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "385", "text": "715/2007 - 195/2013N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "386", "text": "715/2007 - 195/2013O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "387", "text": "715/2007 - 195/2013P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "388", "text": "715/2007 - 195/2013Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "389", "text": "715/2007 - 195/2013R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "390", "text": "715/2007 - 195/2013S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "391", "text": "715/2007 - 195/2013T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "392", "text": "715/2007 - 195/2013U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "393", "text": "715/2007 - 195/2013V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "394", "text": "715/2007 - 195/2013W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "395", "text": "715/2007 - 195/2013X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "396", "text": "715/2007 - 195/2013Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "397", "text": "715/2007 - 195/2013ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "398", "text": "715/2007 - 195/2013ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "399", "text": "715/2007 - 195/2013ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "400", "text": "715/2007 - 195/2013ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "401", "text": "715/2007 - 195/2013ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "402", "text": "715/2007 - 195/2013ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "403", "text": "E49/06A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "404", "text": "E49/06B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "405", "text": "E49/06C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "406", "text": "595/2009 - 136/2014A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "407", "text": "595/2009 - 136/2014B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "408", "text": "595/2009 - 136/2014C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "528", "text": "97/68/EY - 2012/46K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "30", "text": "97/68/EY - 97/68A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "31", "text": "97/68/EY - 97/68B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "32", "text": "97/68/EY - 97/68C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "33", "text": "97/68/EY - 97/68D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "34", "text": "97/68/EY - 97/68E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "35", "text": "97/68/EY - 97/68F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "36", "text": "97/68/EY - 97/68G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "37", "text": "97/68/EY - 2001/63D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "38", "text": "97/68/EY - 2001/63E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "01", "text": "70/220/ETY - 94/12/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "02", "text": "70/220/ETY - 96/44/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "03", "text": "70/220/ETY - 96/69/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "04", "text": "70/220/ETY - 98/69/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "05", "text": "70/220/ETY - 98/69A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "06", "text": "70/220/ETY - 98/77/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "07", "text": "70/220/ETY - 1999/102/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "08", "text": "70/220/ETY - 1999/102A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "09", "text": "70/220/ETY - 1999/102B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "10", "text": "70/220/ETY - 2001/1A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "11", "text": "70/220/ETY - 2001/1B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "12", "text": "70/220/ETY - 2001/100A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "13", "text": "70/220/ETY - 2001/100B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "14", "text": "70/220/ETY - 2002/80A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "15", "text": "88/77/ETY - 96/1/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "16", "text": "88/77/ETY - 1999/96/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "17", "text": "88/77/ETY - 2001/27A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "18", "text": "97/24/EY 5 luku - 97/24A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "19", "text": "97/24/EY 5 luku - 2002/51A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "20", "text": "70/220/ETY - 2002/80B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "21", "text": "70/220/ETY - 98/69B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "22", "text": "70/220/ETY - 2003/76A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "23", "text": "70/220/ETY - 2003/76B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "24", "text": "E83/03", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "25", "text": "E83B/03", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "26", "text": "E83D/03", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "27", "text": "E83/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "28", "text": "E49/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "29", "text": "E49B/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "347", "text": "715/2007 - 171/2013I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "348", "text": "715/2007 - 171/2013J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "349", "text": "715/2007 - 171/2013K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "350", "text": "715/2007 - 171/2013L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "291", "text": "715/2007 - 459/2012R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "292", "text": "715/2007 - 459/2012S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "293", "text": "715/2007 - 459/2012T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "294", "text": "715/2007 - 459/2012U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "295", "text": "715/2007 - 459/2012V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "296", "text": "715/2007 - 459/2012M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "297", "text": "715/2007 - 459/2012H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "298", "text": "715/2007 - 459/2012L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "299", "text": "715/2007 - 630/2012T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "300", "text": "715/2007 - 630/2012A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "409", "text": "715/2007 - 136/2014J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "302", "text": "715/2007 - 630/2012L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "303", "text": "715/2007 - 630/2012I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "304", "text": "715/2007 - 630/2012H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "305", "text": "715/2007 - 630/2012K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "306", "text": "715/2007 - 143/2013A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "307", "text": "715/2007 - 143/2013B (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "308", "text": "715/2007 - 143/2013C (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "309", "text": "715/2007 - 143/2013D (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "310", "text": "715/2007 - 143/2013E (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "311", "text": "715/2007 - 143/2013F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "312", "text": "715/2007 - 143/2013G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "313", "text": "715/2007 - 143/2013H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "314", "text": "715/2007 - 143/2013I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "315", "text": "715/2007 - 143/2013J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "316", "text": "715/2007 - 143/2013K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "317", "text": "715/2007 - 143/2013L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "318", "text": "715/2007 - 143/2013M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "319", "text": "715/2007 - 143/2013N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "320", "text": "715/2007 - 143/2013O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "321", "text": "715/2007 - 143/2013P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "369", "text": "715/2007 - 171/2013ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "269", "text": "715/2007 - 459/2012ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "270", "text": "715/2007 - 459/2012ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "271", "text": "715/2007 - 459/2012ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "272", "text": "715/2007 - 459/2012F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "273", "text": "715/2007 - 459/2012J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "274", "text": "715/2007 - 630/2012W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "275", "text": "715/2007 - 630/2012X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "276", "text": "715/2007 - 630/2012Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "277", "text": "715/2007 - 630/2012ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "278", "text": "715/2007 - 630/2012ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "279", "text": "715/2007 - 630/2012ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "280", "text": "715/2007 - 630/2012ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "281", "text": "715/2007 - 630/2012ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "282", "text": "715/2007 - 630/2012ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "283", "text": "715/2007 - 630/2012F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "284", "text": "715/2007 - 630/2012J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "285", "text": "2005/55/EY - 2008/74", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "286", "text": "715/2007 - 459/2012I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "287", "text": "715/2007 - 459/2012N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "288", "text": "715/2007 - 459/2012O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "289", "text": "715/2007 - 459/2012P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "290", "text": "715/2007 - 459/2012Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "229", "text": "715/2007 - 566/2011O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "230", "text": "715/2007 - 566/2011P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "231", "text": "715/2007 - 566/2011Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "232", "text": "715/2007 - 566/2011R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "233", "text": "715/2007 - 566/2011S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "234", "text": "715/2007 - 566/2011T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "235", "text": "715/2007 - 566/2011U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "236", "text": "715/2007 - 566/2011V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "237", "text": "E83/06J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "322", "text": "715/2007 - 143/2013Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "323", "text": "715/2007 - 143/2013R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "324", "text": "715/2007 - 143/2013S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "325", "text": "715/2007 - 143/2013T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "326", "text": "715/2007 - 143/2013U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "327", "text": "715/2007 - 143/2013V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "328", "text": "715/2007 - 143/2013W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "329", "text": "715/2007 - 143/2013X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "330", "text": "715/2007 - 143/2013Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "331", "text": "715/2007 - 143/2013ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "332", "text": "715/2007 - 143/2013ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "333", "text": "715/2007 - 143/2013ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "334", "text": "715/2007 - 143/2013ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "335", "text": "715/2007 - 143/2013ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "336", "text": "715/2007 - 143/2013ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "337", "text": "97/68/EY - 2012/46Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "338", "text": "97/68/EY - 2012/46R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "339", "text": "715/2007 - 171/2013A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "340", "text": "715/2007 - 171/2013B (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "341", "text": "715/2007 - 171/2013C (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "342", "text": "715/2007 - 171/2013D (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "343", "text": "715/2007 - 171/2013E (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "344", "text": "715/2007 - 171/2013F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "345", "text": "715/2007 - 171/2013G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "346", "text": "715/2007 - 171/2013H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "191", "text": "97/68/EY - 2010/26K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "192", "text": "97/68/EY - 2010/26L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "193", "text": "97/68/EY - 2010/26M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "194", "text": "97/68/EY - 2010/26N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "195", "text": "97/68/EY - 2010/26P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "196", "text": "97/68/EY - 2010/26Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "197", "text": "97/68/EY - 2010/26R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "198", "text": "2000/25/EY - 2010/22C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "199", "text": "2000/25/EY - 2010/22D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "200", "text": "2000/25/EY - 2010/22E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "201", "text": "97/68/EY - 2006/105D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "202", "text": "97/68/EY - 2006/105E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "203", "text": "97/68/EY - 2006/105F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "204", "text": "97/68/EY - 2006/105G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "205", "text": "97/68/EY - 2006/105H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "206", "text": "97/68/EY - 2006/105I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "207", "text": "97/68/EY - 2006/105J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "208", "text": "97/68/EY - 2006/105K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "209", "text": "97/68/EY - 2006/105L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "210", "text": "97/68/EY - 2006/105M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "211", "text": "97/68/EY - 2006/105N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "212", "text": "97/68/EY - 2006/105P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "213", "text": "97/68/EY - 2006/105Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "214", "text": "97/68/EY - 2006/105R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "215", "text": "715/2007 - 566/2011A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "216", "text": "715/2007 - 566/2011B (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "217", "text": "715/2007 - 566/2011C (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "218", "text": "715/2007 - 566/2011D (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "219", "text": "715/2007 - 566/2011E (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "220", "text": "715/2007 - 566/2011F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "221", "text": "715/2007 - 566/2011G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "222", "text": "715/2007 - 566/2011H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "223", "text": "715/2007 - 566/2011I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "224", "text": "715/2007 - 566/2011J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "225", "text": "715/2007 - 566/2011K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "226", "text": "715/2007 - 566/2011L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "227", "text": "715/2007 - 566/2011M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "228", "text": "715/2007 - 566/2011N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "238", "text": "E83/06K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "239", "text": "E83/06L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "240", "text": "E83/06M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "241", "text": "2000/25/EY - 2011/72C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "242", "text": "2000/25/EY - 2011/72D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "243", "text": "2000/25/EY - 2011/72E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "244", "text": "2000/25/EY - 2011/87C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "245", "text": "2000/25/EY - 2011/87D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "246", "text": "2000/25/EY - 2011/87E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "247", "text": "97/68/EY - 2011/88H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "248", "text": "97/68/EY - 2011/88I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "249", "text": "97/68/EY - 2011/88J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "250", "text": "97/68/EY - 2011/88K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "251", "text": "97/68/EY - 2011/88L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "252", "text": "97/68/EY - 2011/88M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "253", "text": "97/68/EY - 2011/88N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "254", "text": "97/68/EY - 2011/88P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "255", "text": "97/68/EY - 2011/88Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "301", "text": "715/2007 - 459/2012K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "257", "text": "595/2009 - 582/2011A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "258", "text": "595/2009 - 582/2011B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "259", "text": "595/2009 - 582/2011C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "260", "text": "595/2009 - 64/2012A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "261", "text": "595/2009 - 64/2012B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "262", "text": "595/2009 - 64/2012C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "263", "text": "715/2007 - 459/2012W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "264", "text": "715/2007 - 459/2012X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "265", "text": "715/2007 - 459/2012Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "266", "text": "715/2007 - 459/2012ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "267", "text": "715/2007 - 459/2012ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "268", "text": "715/2007 - 459/2012ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "113", "text": "97/24/EY 5 luku - 2006/72A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "114", "text": "97/24/EY 5 luku - 2006/72B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "115", "text": "97/24/EY 5 luku - 97/24B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "116", "text": "97/24/EY 5 luku - 2006/72C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "117", "text": "97/24/EY 5 luku - 2006/120", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "118", "text": "97/24/EY 5 luku - 2006/120A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "119", "text": "97/24/EY 5 luku - 2006/120B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "120", "text": "97/24/EY 5 luku - 2006/120C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "121", "text": "2005/55/EY - 2006/81A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "122", "text": "2005/55/EY - 2006/81B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "123", "text": "2005/55/EY - 2006/81C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "124", "text": "2005/55/EY - 2006/81D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "125", "text": "2005/55/EY - 2006/81E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "126", "text": "2005/55/EY - 2006/81F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "127", "text": "2005/55/EY - 2006/81G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "128", "text": "2005/55/EY - 2006/81H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "129", "text": "2005/55/EY - 2006/81I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "130", "text": "2005/55/EY - 2006/81J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "131", "text": "2005/55/EY - 2006/81K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "132", "text": "2005/55/EY - 2008/74B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "133", "text": "2005/55/EY - 2008/74C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "134", "text": "2005/55/EY - 2008/74D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "135", "text": "2005/55/EY - 2008/74E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "136", "text": "2005/55/EY - 2008/74F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "137", "text": "2005/55/EY - 2008/74G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "138", "text": "2005/55/EY - 2008/74H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "139", "text": "2005/55/EY - 2008/74I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "256", "text": "97/68/EY - 2011/88R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "141", "text": "2005/55/EY - 2008/74K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "142", "text": "E49B/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "143", "text": "E49C/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "144", "text": "E49D/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "145", "text": "E49E/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "146", "text": "E49F/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "147", "text": "E49G/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "148", "text": "E49H/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "149", "text": "E49I/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "150", "text": "E49J/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "151", "text": "E49K/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "152", "text": "E96/00", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "153", "text": "E96D/01", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "168", "text": "715/2007 - 692/2008G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "169", "text": "715/2007 - 692/2008H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "170", "text": "715/2007 - 692/2008I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "171", "text": "715/2007 - 692/2008J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "172", "text": "715/2007 - 692/2008K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "173", "text": "715/2007 - 692/2008L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "174", "text": "715/2007 - 692/2008M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "175", "text": "715/2007 - 692/2008N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "176", "text": "715/2007 - 692/2008O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "177", "text": "715/2007 - 692/2008P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "178", "text": "715/2007 - 692/2008Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "179", "text": "715/2007 - 692/2008R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "180", "text": "715/2007 - 692/2008S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "181", "text": "715/2007 - 692/2008T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "182", "text": "715/2007 - 692/2008U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "183", "text": "715/2007 - 692/2008V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "184", "text": "97/24/EY 5 luku - 2009/108", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "185", "text": "97/24/EY 5 luku - 2009/108A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "186", "text": "97/24/EY 5 luku - 2009/108B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "187", "text": "97/24/EY 5 luku - 2009/108C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "188", "text": "97/68/EY - 2010/26H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "189", "text": "97/68/EY - 2010/26I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "190", "text": "97/68/EY - 2010/26J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "39", "text": "97/68/EY - 2001/63F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "40", "text": "97/68/EY - 2001/63G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "41", "text": "2000/25/EY - 2000/25A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "42", "text": "2000/25/EY - 2000/25B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "43", "text": "97/24/EY 5 luku - 2002/51B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "66", "text": "97/68/EY - 2004/26P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "44", "text": "97/24/EY 5 luku - 2002/51A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "45", "text": "97/24/EY 5 luku - 2002/51B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "46", "text": "70/220/ETY - 2006/96B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "47", "text": "97/24/EY 5 luku - 97/24A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "48", "text": "97/24/EY 5 luku - 97/24B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "49", "text": "E83RII-05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "50", "text": "88/77/ETY - 2001/27B1/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "51", "text": "97/68/EY - 2002/88D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "52", "text": "97/68/EY - 2002/88E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "53", "text": "97/68/EY - 2002/88F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "54", "text": "97/68/EY - 2002/88G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "55", "text": "97/68/EY - 2004/26D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "56", "text": "97/68/EY - 2004/26E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "57", "text": "97/68/EY - 2004/26F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "58", "text": "97/68/EY - 2004/26G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "59", "text": "97/68/EY - 2004/26H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "60", "text": "97/68/EY - 2004/26I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "61", "text": "97/68/EY - 2004/26J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "62", "text": "97/68/EY - 2004/26K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "63", "text": "97/68/EY - 2004/26L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "64", "text": "97/68/EY - 2004/26M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "65", "text": "97/68/EY - 2004/26N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "67", "text": "97/68/EY - 2004/26Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "68", "text": "97/68/EY - 2004/26R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "69", "text": "88/77/ETY - 2001/27B2", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "154", "text": "E96E/01", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "155", "text": "E96F/01", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "156", "text": "E96G/01", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "157", "text": "E96H/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "158", "text": "E96I/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "159", "text": "E96J/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "160", "text": "E96K/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "161", "text": "Euro 5", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "162", "text": "715/2007 - 692/2008A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "163", "text": "715/2007 - 692/2008B (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "164", "text": "715/2007 - 692/2008C (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "165", "text": "715/2007 - 692/2008D (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "166", "text": "715/2007 - 692/2008E (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "167", "text": "715/2007 - 692/2008F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "70", "text": "88/77/ETY - 2001/27C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "71", "text": "2000/25/EY - 2005/13H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "72", "text": "2000/25/EY - 2005/13I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "73", "text": "2000/25/EY - 2005/13J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "74", "text": "2000/25/EY - 2005/13K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "75", "text": "2000/25/EY - 2005/13L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "76", "text": "2000/25/EY - 2005/13M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "77", "text": "2000/25/EY - 2005/13N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "78", "text": "2000/25/EY - 2005/13P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "79", "text": "2000/25/EY - 2005/13Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "80", "text": "2000/25/EY - 2005/13R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "81", "text": "2005/55/EY - 2005/78A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "82", "text": "2005/55/EY - 2005/78B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "83", "text": "2005/55/EY - 2005/78C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "84", "text": "2005/55/EY - 2005/78D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "85", "text": "2005/55/EY - 2005/78E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "86", "text": "2005/55/EY - 2005/78F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "87", "text": "2005/55/EY - 2005/78G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "88", "text": "2005/55/EY - 2005/78H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "89", "text": "2005/55/EY - 2005/78I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "90", "text": "2005/55/EY - 2005/78J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "91", "text": "2005/55/EY - 2005/78K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "92", "text": "2005/55/EY - 2006/51A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "93", "text": "2005/55/EY - 2006/51B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "94", "text": "2005/55/EY - 2006/51C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "95", "text": "2005/55/EY - 2006/51D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "140", "text": "2005/55/EY - 2008/74J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "97", "text": "2005/55/EY - 2006/51F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "98", "text": "2005/55/EY - 2006/51G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "99", "text": "2005/55/EY - 2006/51H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "100", "text": "2005/55/EY - 2006/51I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "101", "text": "2005/55/EY - 2006/51J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "102", "text": "2005/55/EY - 2006/51K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "103", "text": "97/24/EY 5 luku - 2003/77", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "01", "text": "Trevägskatalysator", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "01", "text": "Kolmitoimikatalysaattori", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "02", "text": "OBD-system", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "02", "text": "OBD-järjestelmä", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "03", "text": "Katalysator", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "03", "text": "Katalysaattori", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "04", "text": "Syredetektor", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "06", "text": "Återcirkulation av avgaserna", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "06", "text": "Pakokaasun takaisinkierrätys", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "07", "text": "Övervakningssystem för avdunstningsutsläpp", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "07", "text": "Haihtumispäästöjen valvontajärjestelmä", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "08", "text": "Partikelfälla", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "08", "text": "Hiukkasloukku", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "09", "text": "Lågemission", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "09", "text": "Vähäpäästöinen", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "10", "text": "Ureainsprutning", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "04", "text": "Happitunnistin", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "05", "text": "Luftinsprutning eller luftpump", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "05", "text": "Ilman suihkutus tai ilmapumppu", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "10", "text": "Urearuiskutus", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "11", "text": "Vridmomentsbegränsare", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "11", "text": "Vääntömomentin rajoitin", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "Z", "text": "Annan, vilken?", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "Z", "text": "Muu, mikä...", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "0", "text": "Maakaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "0", "text": "Naturgas", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "1", "text": "Bensiini", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "1", "text": "Bensin", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "2", "text": "Moottoripetroli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "2", "text": "Motorpetrol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "3", "text": "Dieselöljy", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "3", "text": "Dieselölja", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "4", "text": "Nestekaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "4", "text": "Flytgas", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "57", "text": "L-ryhmän maakaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "57", "text": "Naturgastyp L", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "58", "text": "HL-ryhmän maakaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "58", "text": "Naturgastyp HL", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "59", "text": "CNG/Biometaani", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "59", "text": "CNG/Biometan", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "60", "text": "Biometaani", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "60", "text": "Biometan", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "61", "text": "Puu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "61", "text": "Träd", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "62", "text": "Etanoli (ED95)", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "62", "text": "Etanol (ED95)", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "63", "text": "Etanoli (E85)", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "63", "text": "Etanol (E85)", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "64", "text": "Vety-maakaasuseos", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "64", "text": "H2NG-blandning", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "65", "text": "LNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "65", "text": "LNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "66", "text": "LNG20", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "66", "text": "LNG20", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "67", "text": "Diesel/LNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "67", "text": "Diesel/LNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "68", "text": "Diesel/LNG20", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "68", "text": "Diesel/LNG20", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "45", "text": "Diesel/Etanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "45", "text": "Diesel/Etanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "46", "text": "Diesel/Metanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "46", "text": "Diesel/Metanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "47", "text": "Diesel/LPG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "47", "text": "Diesel/LPG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "48", "text": "Diesel/Biodiesel", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "48", "text": "Diesel/Biodiesel", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "49", "text": "Diesel/Biodiesel/Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "49", "text": "Diesel/Biodiesel/Elektricitet", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "50", "text": "Diesel/Biodiesel/Etanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "50", "text": "Diesel/Biodiesel/Etanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "51", "text": "Diesel/Biodiesel/Metanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "51", "text": "Diesel/Biodiesel/Metanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "52", "text": "Diesel/Biodiesel/LPG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "52", "text": "Diesel/Biodiesel/LPG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "53", "text": "Diesel/Biodiesel/CNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "53", "text": "Diesel/Biodiesel/CNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "54", "text": "Hydrogen/Elektricitet", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "54", "text": "Vety/Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "55", "text": "Dieselolja/Övrig", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "10", "text": "Biodiesel", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "10", "text": "Biodiesel", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "11", "text": "LPG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "11", "text": "LPG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "13", "text": "CNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "13", "text": "CNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "31", "text": "Moottoripetroli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "31", "text": "Motorfotogen", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "32", "text": "Diesel/Trä", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "32", "text": "Diesel/Puu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "55", "text": "Dieselöljy/Muu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "33", "text": "Bensiini/Puu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "33", "text": "Bensin/Trä", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "34", "text": "Bensiini + moottoripetroli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "34", "text": "Bensin + motorfotogen", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "37", "text": "Etanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "37", "text": "Etanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "38", "text": "Bensiini/CNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "38", "text": "Bensin/CNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "39", "text": "Bensiini/Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "39", "text": "Bensin/Elektricitet", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "40", "text": "Bensiini/Etanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "40", "text": "Bensin/Etanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "41", "text": "Bensiini/Metanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "41", "text": "Bensin/Metanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "42", "text": "Bensiini/LPG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "42", "text": "Bensin/LPG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "43", "text": "Diesel/CNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "43", "text": "Diesel/CNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "44", "text": "Diesel/Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "44", "text": "Diesel/Elektricitet", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "5", "text": "Diesel/puu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "5", "text": "Diesel/trä", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "6", "text": "Bensiini/puu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "6", "text": "Bensin/trä", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "7", "text": "Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "7", "text": "El", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "8", "text": "Bensiini+moottoripetroli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "8", "text": "Bensin+motorpetrol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "9", "text": "Se anmärkn.", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "9", "text": "Ks. huom.", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "01", "text": "Bensiini", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "01", "text": "Bensin", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "02", "text": "Dieselöljy", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "02", "text": "Dieselolja", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "03", "text": "Polttoöljy", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "03", "text": "Brännolja", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "04", "text": "Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "04", "text": "Elektricitet", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "05", "text": "Vety", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "05", "text": "Hydrogen", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "06", "text": "Kaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "06", "text": "Gas", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "07", "text": "Metanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "07", "text": "Metanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "56", "text": "H-ryhmän maakaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "56", "text": "Naturgastyp H", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "X", "text": "Ei sovellettavissa", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "X", "text": "Ej tillämplig", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "Y", "text": "Övrig", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "69", "text": "Seos", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "69", "text": "Mix", "lng": "sv"},
  {"code": "KdTyytiTelatyyppi", "codeValue": "1", "text": "Kokotela", "lng": "fi"},
  {"code": "KdTyytiTelatyyppi", "codeValue": "2", "text": "Puolitela", "lng": "fi"},
  {"code": "KdTyytiTelatyyppi", "codeValue": "1", "text": "Helt banddriven", "lng": "sv"},
  {"code": "KdTyytiTelatyyppi", "codeValue": "2", "text": "Delvis banddriven", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "8", "text": "Fast förhållande", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "10", "text": "Hjulnav", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "9", "text": "Automatisoitu", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "10", "text": "Pyörännapa", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "8", "text": "Kiinteä välityssuhde", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "9", "text": "Automatiserad", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "1", "text": "Manuell", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "1", "text": "Käsivalintainen", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "2", "text": "Automatisk", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "2", "text": "Automaattinen", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "3", "text": "Steglös", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "3", "text": "Portaaton", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "4", "text": "Manuell/automatisk", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "4", "text": "Käsivalintainen/automaattinen", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "5", "text": "Variator", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "5", "text": "Variaattori", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "6", "text": "Variabel utväxling", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "6", "text": "Muuttuvavälityksinen", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "7", "text": "Fotväxlad", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "7", "text": "Jalkavalintainen", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "X", "text": "Ej tillämpligt", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "X", "text": "Ei sovellettavissa", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "Y", "text": "Annan", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "01", "text": "Främre delen av motorrummet", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "01", "text": "Moottoritilan etuosa", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "02", "text": "Bakre delen av motorrummet", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "02", "text": "Moottoritilan takaosa", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "03", "text": "Främre stänkskärmen, H", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "03", "text": "Etulokasuoja, O", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "04", "text": "Främre stänkskärmen, V", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "04", "text": "Etulokasuoja, V", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "05", "text": "Dörröppningen, H", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "05", "text": "Oviaukko, O", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "06", "text": "Dörröppningen, V", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "06", "text": "Oviaukko, V", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "07", "text": "Golvet, V", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "07", "text": "Lattia, V", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "08", "text": "Golvet, H", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "08", "text": "Lattia, O", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "09", "text": "Underredet, H fram", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "09", "text": "Alusta, O etu", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "10", "text": "Underredet, H mitten", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "10", "text": "Alusta, O keski", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "11", "text": "Underredet, H bak", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "11", "text": "Alusta, O taka", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "12", "text": "Underredet, V fram", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "12", "text": "Alusta, V etu", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "13", "text": "Underredet, V mitten", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "13", "text": "Alusta, V keski", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "14", "text": "Underredet, V bak", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "14", "text": "Alusta, V taka", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "15", "text": "Mittgolvet", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "15", "text": "Keskilattia", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "16", "text": "Instrumentpanelen", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "16", "text": "Kojetaulu", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "17", "text": "Lastutrymmet", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "17", "text": "Tavaratila", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "18", "text": "Passagerarutrymmet", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "18", "text": "Matkustamo", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "19", "text": "Motorrummet", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "19", "text": "Moottoritila", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "20", "text": "Dörröppningen", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "20", "text": "Oviaukko", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "21", "text": "På lagerröret i styrningen, på vänster sida", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "21", "text": "Ohjauksen laakeriputkessa vas.", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "22", "text": "På lagerröret i styrningen, på höger sida", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "22", "text": "Ohjauksen laakeriputkessa oik.", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "23", "text": "På chassiröret på höger sida, framtill", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "23", "text": "Runkoputkessa oik.edessä", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "24", "text": "På chassiröret på vänster sida, framtill", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "24", "text": "Runkoputkessa vas. edessä", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "25", "text": "På chassiröret på höger sida, baktill", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "25", "text": "Runkoputkessa oik. takana", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "26", "text": "På chassiröret på vänster sida, baktill", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "26", "text": "Runkoputkessa vas. takana", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "27", "text": "Under sätet", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "27", "text": "Istuimen alla", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "28", "text": "På chassit till höger, framtill", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "28", "text": "Rungossa oikealla edessä", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "29", "text": "På chassit till vänster, framtill", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "29", "text": "Rungossa vasemmalla edessä", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "30", "text": "På chassit till höger, i mitten", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "30", "text": "Rungossa oikealla keskellä", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "31", "text": "På chassit till vänster, i mitten", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "31", "text": "Rungossa vasemmalla keskellä", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "32", "text": "På chassit till höger, baktill", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "32", "text": "Rungossa oikealla takana", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "33", "text": "På chassit till vänster, baktill", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "33", "text": "Rungossa vasemmalla takana", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "34", "text": "Golvet", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "34", "text": "Lattia", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "X", "text": "Ej tillämpligt", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "X", "text": "Ei sovellettavissa", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "Z", "text": "Annan, vad", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "Z", "text": "Muu, mikä...", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "37", "text": "Bak i ramen", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "38", "text": "I lagerröret för styrningen", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "35", "text": "I ramen", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "36", "text": "Framme i ramen", "lng": "sv"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "35", "text": "Rungossa", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "36", "text": "Rungossa edessä", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "37", "text": "Rungossa takana", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "38", "text": "Ohjauksen laakeriputkessa", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "39", "text": "a-pilari, vasen", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "40", "text": "a-pilari, oikea", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "41", "text": "b-pilari, vasen", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "42", "text": "b-pilari, oikea", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "43", "text": "c-pilari, vasen", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "44", "text": "c-pilari, oikea", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "45", "text": "d-pilari, vasen", "lng": "fi"},
  {"code": "KdTyytiValmistenumeronSijainti", "codeValue": "46", "text": "d-pilari, oikea", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "1", "text": "Brun (beige)", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "2", "text": "Punainen", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "0", "text": "Musta", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "0", "text": "Svart", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "1", "text": "Ruskea (beige)", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "2", "text": "Röd", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "3", "text": "Oranssi", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "3", "text": "Orange", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "4", "text": "Keltainen", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "4", "text": "Gul", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "5", "text": "Vihreä", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "5", "text": "Grön", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "6", "text": "Sininen", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "6", "text": "Blå", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "7", "text": "Violetti", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "7", "text": "Violett", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "8", "text": "Harmaa", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "8", "text": "Grå", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "9", "text": "Valkoinen", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "9", "text": "Vit", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "X", "text": "Monivär.", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "X", "text": "Flerfärg", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "Y", "text": "Hopea", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "Y", "text": "Silver", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "Z", "text": "Turkoosi", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "Z", "text": "Turkos", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "01", "text": "Mekaanisesti käytett.jarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "01", "text": "Mekanisk driven broms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "02", "text": "Nestetoimiset jarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "02", "text": "Hydraulisk broms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "03", "text": "Mekaan./neste jarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "03", "text": "Mekanisk/hydraulisk broms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "04", "text": "Neste/mekaan. jarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "04", "text": "Hydraulisk/Mekanisk broms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "05", "text": "Nestetoim. alipaineteh.j", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "05", "text": "Hydraulisk vakuumservobroms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "06", "text": "Nestetoim. ylipaineteh.j", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "06", "text": "Hydraulisk övertryckservobroms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "07", "text": "Paineilmajarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "07", "text": "Tryckluftbroms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "08", "text": "Työntöjarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "08", "text": "Skjutbroms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "09", "text": "Painenestejarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "09", "text": "Tryckhydraulisk broms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "10", "text": "Neste+painenestejarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "10", "text": "Hydraulisk+tryckhydraulisk broms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "11", "text": "Sähköohjatut paineilmajarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "11", "text": "Elstyrd tryckluftbroms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "12", "text": "Sähköohjatut nestetoimiset alipainetehostetut jarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "12", "text": "Elstyrd hydraulisk vakuumservobroms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "13", "text": "Paineilmakäytt.nestejarru", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "13", "text": "Tryckluftdriven hydraulisk broms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "14", "text": "Nestetoiminen työntöjarru", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "14", "text": "Hydraulisk skjutbroms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "15", "text": "Sähkötoiminen työntöjarru", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "15", "text": "Eldriven skjutbroms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "16", "text": "Sähköjarrut", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "16", "text": "Elbroms", "lng": "sv"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "17", "text": "Elektronisesti ohjattu nestejarru", "lng": "fi"},
  {"code": "KdTyytiVoimanvalitysJaTehostamistapa", "codeValue": "17", "text": "Elektroniskt styrt hydrauliskbroms", "lng": "sv"},
  {"code": "Maat", "codeValue": "249", "text": "", "lng": "sv"},
  {"code": "Maat", "codeValue": "831", "text": "Guernsey", "lng": "fi"},
  {"code": "Maat", "codeValue": "688", "text": "Serbia", "lng": "fi"},
  {"code": "Maat", "codeValue": "688", "text": "Serbien", "lng": "sv"},
  {"code": "Maat", "codeValue": "616", "text": "Polen", "lng": "sv"},
  {"code": "Maat", "codeValue": "520", "text": "Nauru", "lng": "fi"},
  {"code": "Maat", "codeValue": "470", "text": "Malta", "lng": "sv"},
  {"code": "Maat", "codeValue": "616", "text": "Puola", "lng": "fi"},
  {"code": "Maat", "codeValue": "851", "text": "Förenade kungariket", "lng": "sv"},
  {"code": "Maat", "codeValue": "901", "text": "Jugoslavian liittotasavalta", "lng": "sv"},
  {"code": "Maat", "codeValue": "531", "text": "Curacao", "lng": "fi"},
  {"code": "Maat", "codeValue": "531", "text": "Curacao", "lng": "sv"},
  {"code": "Maat", "codeValue": "899", "text": "Zaire", "lng": "sv"},
  {"code": "Maat", "codeValue": "853", "text": "Nordkorea", "lng": "sv"},
  {"code": "Maat", "codeValue": "728", "text": "Etelä-Sudanin tasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "728", "text": "Republiken Sydsudan", "lng": "sv"},
  {"code": "Maat", "codeValue": "249", "text": "Ranskan emämaa", "lng": "fi"},
  {"code": "Maat", "codeValue": "829", "text": "Neutraali vyöhyke", "lng": "fi"},
  {"code": "Maat", "codeValue": "851", "text": "Yhdistynyt kuningaskunta", "lng": "fi"},
  {"code": "Maat", "codeValue": "853", "text": "Pohjois-Korea", "lng": "fi"},
  {"code": "Maat", "codeValue": "899", "text": "Zaire", "lng": "fi"},
  {"code": "Maat", "codeValue": "901", "text": "Jugoslavian liittotasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "626", "text": "Östtimor", "lng": "sv"},
  {"code": "Maat", "codeValue": "902", "text": "EU-maa", "lng": "fi"},
  {"code": "Maat", "codeValue": "902", "text": "EU-land", "lng": "sv"},
  {"code": "Maat", "codeValue": "903", "text": "ETA-maa", "lng": "fi"},
  {"code": "Maat", "codeValue": "903", "text": "EES-land", "lng": "sv"},
  {"code": "Maat", "codeValue": "904", "text": "muu EUn ulkopuolinen maa", "lng": "fi"},
  {"code": "Maat", "codeValue": "904", "text": "övrigt land utanför EU", "lng": "sv"},
  {"code": "Maat", "codeValue": "991", "text": "Kansalaisuudeton", "lng": "fi"},
  {"code": "Maat", "codeValue": "991", "text": "Statslös", "lng": "sv"},
  {"code": "Maat", "codeValue": "997", "text": "Ei tiedossa", "lng": "fi"},
  {"code": "Maat", "codeValue": "997", "text": "Okänd", "lng": "sv"},
  {"code": "Maat", "codeValue": "998", "text": "Tieto selväkielisenä", "lng": "fi"},
  {"code": "Maat", "codeValue": "998", "text": "Uppgifterna i klartext", "lng": "sv"},
  {"code": "Maat", "codeValue": "999", "text": "Ei käytettävissä", "lng": "fi"},
  {"code": "Maat", "codeValue": "999", "text": "Ej användbar", "lng": "sv"},
  {"code": "Maat", "codeValue": "275", "text": "Palestiinan itsehallintoalue", "lng": "fi"},
  {"code": "Maat", "codeValue": "275", "text": "De palestinska områd", "lng": "sv"},
  {"code": "Maat", "codeValue": "729", "text": "Sudanin tasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "090", "text": "Salomonsaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "233", "text": "Viro", "lng": "fi"},
  {"code": "Maat", "codeValue": "248", "text": "Åland", "lng": "sv"},
  {"code": "Maat", "codeValue": "470", "text": "Malta", "lng": "fi"},
  {"code": "Maat", "codeValue": "729", "text": "Republiken Sudan", "lng": "sv"},
  {"code": "Maat", "codeValue": "626", "text": "Itä-Timor", "lng": "fi"},
  {"code": "Maat", "codeValue": "832", "text": "Jersey", "lng": "fi"},
  {"code": "Maat", "codeValue": "831", "text": "Guernsey", "lng": "sv"},
  {"code": "Maat", "codeValue": "010", "text": "Antarktis", "lng": "fi"},
  {"code": "Maat", "codeValue": "010", "text": "Antarktis", "lng": "sv"},
  {"code": "Maat", "codeValue": "020", "text": "Andorra", "lng": "fi"},
  {"code": "Maat", "codeValue": "020", "text": "Andorra", "lng": "sv"},
  {"code": "Maat", "codeValue": "031", "text": "Azerbaidzhan", "lng": "fi"},
  {"code": "Maat", "codeValue": "031", "text": "Azerbajdzjan", "lng": "sv"},
  {"code": "Maat", "codeValue": "051", "text": "Armenia", "lng": "fi"},
  {"code": "Maat", "codeValue": "051", "text": "Armenien", "lng": "sv"},
  {"code": "Maat", "codeValue": "196", "text": "Kypros", "lng": "fi"},
  {"code": "Maat", "codeValue": "196", "text": "Cypern", "lng": "sv"},
  {"code": "Maat", "codeValue": "203", "text": "Tsekki", "lng": "fi"},
  {"code": "Maat", "codeValue": "203", "text": "Tjeckien", "lng": "sv"},
  {"code": "Maat", "codeValue": "233", "text": "Estland", "lng": "sv"},
  {"code": "Maat", "codeValue": "248", "text": "Ahvenanmaa", "lng": "fi"},
  {"code": "Maat", "codeValue": "276", "text": "Saksa", "lng": "fi"},
  {"code": "Maat", "codeValue": "276", "text": "Tyskland", "lng": "sv"},
  {"code": "Maat", "codeValue": "344", "text": "Hongkong", "lng": "fi"},
  {"code": "Maat", "codeValue": "344", "text": "Hongkong", "lng": "sv"},
  {"code": "Maat", "codeValue": "348", "text": "Unkari", "lng": "fi"},
  {"code": "Maat", "codeValue": "348", "text": "Ungern", "lng": "sv"},
  {"code": "Maat", "codeValue": "410", "text": "Korean tasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "410", "text": "Republiken Korea", "lng": "sv"},
  {"code": "Maat", "codeValue": "428", "text": "Latvia", "lng": "fi"},
  {"code": "Maat", "codeValue": "428", "text": "Lettland", "lng": "sv"},
  {"code": "Maat", "codeValue": "438", "text": "Liechtenstein", "lng": "fi"},
  {"code": "Maat", "codeValue": "438", "text": "Liechtenstein", "lng": "sv"},
  {"code": "Maat", "codeValue": "440", "text": "Liettua", "lng": "fi"},
  {"code": "Maat", "codeValue": "440", "text": "Litauen", "lng": "sv"},
  {"code": "Maat", "codeValue": "496", "text": "Mongolia", "lng": "fi"},
  {"code": "Maat", "codeValue": "496", "text": "Mongoliet", "lng": "sv"},
  {"code": "Maat", "codeValue": "499", "text": "Montenegro", "lng": "fi"},
  {"code": "Maat", "codeValue": "499", "text": "Montenegro", "lng": "sv"},
  {"code": "Maat", "codeValue": "703", "text": "Slovakia", "lng": "fi"},
  {"code": "Maat", "codeValue": "703", "text": "Slovakien", "lng": "sv"},
  {"code": "Maat", "codeValue": "705", "text": "Slovenia", "lng": "fi"},
  {"code": "Maat", "codeValue": "705", "text": "Slovenien", "lng": "sv"},
  {"code": "Maat", "codeValue": "832", "text": "Jersey", "lng": "sv"},
  {"code": "Maat", "codeValue": "833", "text": "Mansaari", "lng": "fi"},
  {"code": "Maat", "codeValue": "833", "text": "Isle of Man", "lng": "sv"},
  {"code": "Maat", "codeValue": "890", "text": "Jugoslavia", "lng": "fi"},
  {"code": "Maat", "codeValue": "890", "text": "Jugoslavien", "lng": "sv"},
  {"code": "Maat", "codeValue": "891", "text": "Serbia ja Montenegro", "lng": "fi"},
  {"code": "Maat", "codeValue": "891", "text": "Serbien och Montenegro", "lng": "sv"},
  {"code": "Maat", "codeValue": "894", "text": "Sambia", "lng": "fi"},
  {"code": "Maat", "codeValue": "894", "text": "Zambia", "lng": "sv"},
  {"code": "Maat", "codeValue": "004", "text": "Afganistan", "lng": "fi"},
  {"code": "Maat", "codeValue": "004", "text": "Afghanistan", "lng": "sv"},
  {"code": "Maat", "codeValue": "008", "text": "Albania", "lng": "fi"},
  {"code": "Maat", "codeValue": "008", "text": "Albanien", "lng": "sv"},
  {"code": "Maat", "codeValue": "012", "text": "Algeria", "lng": "fi"},
  {"code": "Maat", "codeValue": "012", "text": "Algeriet", "lng": "sv"},
  {"code": "Maat", "codeValue": "016", "text": "Amerikan Samoa", "lng": "fi"},
  {"code": "Maat", "codeValue": "016", "text": "Amerikanska Samoa", "lng": "sv"},
  {"code": "Maat", "codeValue": "024", "text": "Angola", "lng": "fi"},
  {"code": "Maat", "codeValue": "024", "text": "Angola", "lng": "sv"},
  {"code": "Maat", "codeValue": "028", "text": "Antigua ja Barbuda", "lng": "fi"},
  {"code": "Maat", "codeValue": "028", "text": "Antigua och Barbuda", "lng": "sv"},
  {"code": "Maat", "codeValue": "032", "text": "Argentiina", "lng": "fi"},
  {"code": "Maat", "codeValue": "032", "text": "Argentina", "lng": "sv"},
  {"code": "Maat", "codeValue": "036", "text": "Australia", "lng": "fi"},
  {"code": "Maat", "codeValue": "036", "text": "Australien", "lng": "sv"},
  {"code": "Maat", "codeValue": "040", "text": "Itävalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "040", "text": "Österrike", "lng": "sv"},
  {"code": "Maat", "codeValue": "044", "text": "Bahama", "lng": "fi"},
  {"code": "Maat", "codeValue": "044", "text": "Bahamas", "lng": "sv"},
  {"code": "Maat", "codeValue": "048", "text": "Bahrain", "lng": "fi"},
  {"code": "Maat", "codeValue": "048", "text": "Bahrain", "lng": "sv"},
  {"code": "Maat", "codeValue": "050", "text": "Bangladesh", "lng": "fi"},
  {"code": "Maat", "codeValue": "050", "text": "Bangladesh", "lng": "sv"},
  {"code": "Maat", "codeValue": "052", "text": "Barbados", "lng": "fi"},
  {"code": "Maat", "codeValue": "052", "text": "Barbados", "lng": "sv"},
  {"code": "Maat", "codeValue": "056", "text": "Belgia", "lng": "fi"},
  {"code": "Maat", "codeValue": "056", "text": "Belgien", "lng": "sv"},
  {"code": "Maat", "codeValue": "060", "text": "Bermuda", "lng": "fi"},
  {"code": "Maat", "codeValue": "060", "text": "Bermuda", "lng": "sv"},
  {"code": "Maat", "codeValue": "064", "text": "Bhutan", "lng": "fi"},
  {"code": "Maat", "codeValue": "064", "text": "Bhutan", "lng": "sv"},
  {"code": "Maat", "codeValue": "068", "text": "Bolivia", "lng": "fi"},
  {"code": "Maat", "codeValue": "068", "text": "Bolivia", "lng": "sv"},
  {"code": "Maat", "codeValue": "070", "text": "Bosnia-Hertsegovina", "lng": "fi"},
  {"code": "Maat", "codeValue": "070", "text": "Bosnien-Hercegovina", "lng": "sv"},
  {"code": "Maat", "codeValue": "072", "text": "Botswana", "lng": "fi"},
  {"code": "Maat", "codeValue": "072", "text": "Botswana", "lng": "sv"},
  {"code": "Maat", "codeValue": "074", "text": "Bouvet nsaari", "lng": "fi"},
  {"code": "Maat", "codeValue": "074", "text": "Bouvetön", "lng": "sv"},
  {"code": "Maat", "codeValue": "076", "text": "Brasilia", "lng": "fi"},
  {"code": "Maat", "codeValue": "076", "text": "Brasilien", "lng": "sv"},
  {"code": "Maat", "codeValue": "084", "text": "Belize", "lng": "fi"},
  {"code": "Maat", "codeValue": "084", "text": "Belize", "lng": "sv"},
  {"code": "Maat", "codeValue": "086", "text": "Intian valtamer alue", "lng": "fi"},
  {"code": "Maat", "codeValue": "086", "text": "Indiska Oceanöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "484", "text": "Meksiko", "lng": "fi"},
  {"code": "Maat", "codeValue": "090", "text": "Salomonöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "092", "text": "Brittiläiset Neitsytsaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "092", "text": "Brit Jungfruöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "096", "text": "Brunei", "lng": "fi"},
  {"code": "Maat", "codeValue": "096", "text": "Brunei", "lng": "sv"},
  {"code": "Maat", "codeValue": "100", "text": "Bulgaria", "lng": "fi"},
  {"code": "Maat", "codeValue": "100", "text": "Bulgarien", "lng": "sv"},
  {"code": "Maat", "codeValue": "104", "text": "Myanmar", "lng": "fi"},
  {"code": "Maat", "codeValue": "104", "text": "Myanmar", "lng": "sv"},
  {"code": "Maat", "codeValue": "108", "text": "Burundi", "lng": "fi"},
  {"code": "Maat", "codeValue": "108", "text": "Burundi", "lng": "sv"},
  {"code": "Maat", "codeValue": "112", "text": "Valko-Venäjä", "lng": "fi"},
  {"code": "Maat", "codeValue": "112", "text": "Vitryssland", "lng": "sv"},
  {"code": "Maat", "codeValue": "116", "text": "Kambodzha", "lng": "fi"},
  {"code": "Maat", "codeValue": "116", "text": "Kambodja", "lng": "sv"},
  {"code": "Maat", "codeValue": "120", "text": "Kamerun", "lng": "fi"},
  {"code": "Maat", "codeValue": "120", "text": "Kamerun", "lng": "sv"},
  {"code": "Maat", "codeValue": "124", "text": "Kanada", "lng": "fi"},
  {"code": "Maat", "codeValue": "124", "text": "Canada", "lng": "sv"},
  {"code": "Maat", "codeValue": "132", "text": "Kap Verde", "lng": "fi"},
  {"code": "Maat", "codeValue": "132", "text": "Kap Verde", "lng": "sv"},
  {"code": "Maat", "codeValue": "136", "text": "Caymansaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "136", "text": "Caymanöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "140", "text": "Keski-Afrikka", "lng": "fi"},
  {"code": "Maat", "codeValue": "140", "text": "Centralafrika", "lng": "sv"},
  {"code": "Maat", "codeValue": "144", "text": "Sri Lanka", "lng": "fi"},
  {"code": "Maat", "codeValue": "144", "text": "Sri Lanka", "lng": "sv"},
  {"code": "Maat", "codeValue": "148", "text": "Tsad", "lng": "fi"},
  {"code": "Maat", "codeValue": "148", "text": "Tchad", "lng": "sv"},
  {"code": "Maat", "codeValue": "152", "text": "Chile", "lng": "fi"},
  {"code": "Maat", "codeValue": "152", "text": "Chile", "lng": "sv"},
  {"code": "Maat", "codeValue": "156", "text": "Kiinan kansantasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "156", "text": "Kina", "lng": "sv"},
  {"code": "Maat", "codeValue": "158", "text": "Taiwan", "lng": "fi"},
  {"code": "Maat", "codeValue": "158", "text": "Taiwan", "lng": "sv"},
  {"code": "Maat", "codeValue": "162", "text": "Joulusaari", "lng": "fi"},
  {"code": "Maat", "codeValue": "162", "text": "Julön", "lng": "sv"},
  {"code": "Maat", "codeValue": "166", "text": "Kookossaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "166", "text": "Cocosöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "170", "text": "Kolumbia", "lng": "fi"},
  {"code": "Maat", "codeValue": "170", "text": "Colombia", "lng": "sv"},
  {"code": "Maat", "codeValue": "174", "text": "Komorit", "lng": "fi"},
  {"code": "Maat", "codeValue": "174", "text": "Komorerna", "lng": "sv"},
  {"code": "Maat", "codeValue": "175", "text": "Mayotte", "lng": "fi"},
  {"code": "Maat", "codeValue": "175", "text": "Mayotte", "lng": "sv"},
  {"code": "Maat", "codeValue": "178", "text": "Kongo Tv", "lng": "fi"},
  {"code": "Maat", "codeValue": "178", "text": "Republiken Kongo", "lng": "sv"},
  {"code": "Maat", "codeValue": "180", "text": "Kongon demokraattinen tasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "180", "text": "Kongo Dem R", "lng": "sv"},
  {"code": "Maat", "codeValue": "184", "text": "Cookinsaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "184", "text": "Cooköarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "188", "text": "Costa Rica", "lng": "fi"},
  {"code": "Maat", "codeValue": "188", "text": "Costa Rica", "lng": "sv"},
  {"code": "Maat", "codeValue": "191", "text": "Kroatia", "lng": "fi"},
  {"code": "Maat", "codeValue": "191", "text": "Kroatien", "lng": "sv"},
  {"code": "Maat", "codeValue": "192", "text": "Kuuba", "lng": "fi"},
  {"code": "Maat", "codeValue": "192", "text": "Cuba", "lng": "sv"},
  {"code": "Maat", "codeValue": "200", "text": "Tsekkoslovakia", "lng": "fi"},
  {"code": "Maat", "codeValue": "200", "text": "Tjeckoslovakien", "lng": "sv"},
  {"code": "Maat", "codeValue": "204", "text": "Benin", "lng": "fi"},
  {"code": "Maat", "codeValue": "204", "text": "Benin", "lng": "sv"},
  {"code": "Maat", "codeValue": "208", "text": "Tanska", "lng": "fi"},
  {"code": "Maat", "codeValue": "208", "text": "Danmark", "lng": "sv"},
  {"code": "Maat", "codeValue": "212", "text": "Dominica", "lng": "fi"},
  {"code": "Maat", "codeValue": "212", "text": "Dominica", "lng": "sv"},
  {"code": "Maat", "codeValue": "214", "text": "Dominikaaninen tasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "214", "text": "Dominikanska republiken", "lng": "sv"},
  {"code": "Maat", "codeValue": "218", "text": "Ecuador", "lng": "fi"},
  {"code": "Maat", "codeValue": "218", "text": "Ecuador", "lng": "sv"},
  {"code": "Maat", "codeValue": "222", "text": "El Salvador", "lng": "fi"},
  {"code": "Maat", "codeValue": "222", "text": "El Salvador", "lng": "sv"},
  {"code": "Maat", "codeValue": "226", "text": "Päiväntasaajan Guinea", "lng": "fi"},
  {"code": "Maat", "codeValue": "226", "text": "Republiken Ekvatorialguinea", "lng": "sv"},
  {"code": "Maat", "codeValue": "231", "text": "Etiopia", "lng": "fi"},
  {"code": "Maat", "codeValue": "231", "text": "Etiopien", "lng": "sv"},
  {"code": "Maat", "codeValue": "232", "text": "Eritrea", "lng": "fi"},
  {"code": "Maat", "codeValue": "232", "text": "Eritrea", "lng": "sv"},
  {"code": "Maat", "codeValue": "234", "text": "Färsaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "234", "text": "Färöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "238", "text": "Falklandinsaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "238", "text": "Falklandsöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "239", "text": "Georgia ja Sandwichs", "lng": "fi"},
  {"code": "Maat", "codeValue": "239", "text": "Sydgeorgien och Sydsandwichöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "242", "text": "Fidzi", "lng": "fi"},
  {"code": "Maat", "codeValue": "242", "text": "Fiji", "lng": "sv"},
  {"code": "Maat", "codeValue": "246", "text": "Suomi", "lng": "fi"},
  {"code": "Maat", "codeValue": "246", "text": "Finland", "lng": "sv"},
  {"code": "Maat", "codeValue": "250", "text": "Ranska", "lng": "fi"},
  {"code": "Maat", "codeValue": "250", "text": "Frankrike", "lng": "sv"},
  {"code": "Maat", "codeValue": "254", "text": "Ranskan Guayana", "lng": "fi"},
  {"code": "Maat", "codeValue": "254", "text": "Franska Guyana", "lng": "sv"},
  {"code": "Maat", "codeValue": "258", "text": "Ranskan Polynesia", "lng": "fi"},
  {"code": "Maat", "codeValue": "258", "text": "Franska Polynesien", "lng": "sv"},
  {"code": "Maat", "codeValue": "260", "text": "Ranskan eteläiset ja antarktiset alueet", "lng": "fi"},
  {"code": "Maat", "codeValue": "260", "text": "Franska sydl territo", "lng": "sv"},
  {"code": "Maat", "codeValue": "262", "text": "Djibouti", "lng": "fi"},
  {"code": "Maat", "codeValue": "262", "text": "Djibouti", "lng": "sv"},
  {"code": "Maat", "codeValue": "266", "text": "Gabon", "lng": "fi"},
  {"code": "Maat", "codeValue": "266", "text": "Gabon", "lng": "sv"},
  {"code": "Maat", "codeValue": "268", "text": "Georgia", "lng": "fi"},
  {"code": "Maat", "codeValue": "268", "text": "Georgien", "lng": "sv"},
  {"code": "Maat", "codeValue": "270", "text": "Gambia", "lng": "fi"},
  {"code": "Maat", "codeValue": "270", "text": "Gambia", "lng": "sv"},
  {"code": "Maat", "codeValue": "278", "text": "Saksan demokraattinen tasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "278", "text": "Tyska demokratiska republiken", "lng": "sv"},
  {"code": "Maat", "codeValue": "288", "text": "Ghana", "lng": "fi"},
  {"code": "Maat", "codeValue": "288", "text": "Ghana", "lng": "sv"},
  {"code": "Maat", "codeValue": "292", "text": "Gibraltar", "lng": "fi"},
  {"code": "Maat", "codeValue": "292", "text": "Gibraltar", "lng": "sv"},
  {"code": "Maat", "codeValue": "296", "text": "Kiribati", "lng": "fi"},
  {"code": "Maat", "codeValue": "296", "text": "Kiribati", "lng": "sv"},
  {"code": "Maat", "codeValue": "300", "text": "Kreikka", "lng": "fi"},
  {"code": "Maat", "codeValue": "300", "text": "Grekland", "lng": "sv"},
  {"code": "Maat", "codeValue": "304", "text": "Grönlanti", "lng": "fi"},
  {"code": "Maat", "codeValue": "304", "text": "Grönland", "lng": "sv"},
  {"code": "Maat", "codeValue": "308", "text": "Grenada", "lng": "fi"},
  {"code": "Maat", "codeValue": "308", "text": "Grenada", "lng": "sv"},
  {"code": "Maat", "codeValue": "312", "text": "Guadeloupe", "lng": "fi"},
  {"code": "Maat", "codeValue": "312", "text": "Guadeloupe", "lng": "sv"},
  {"code": "Maat", "codeValue": "316", "text": "Guam", "lng": "fi"},
  {"code": "Maat", "codeValue": "316", "text": "Guam", "lng": "sv"},
  {"code": "Maat", "codeValue": "320", "text": "Guatemala", "lng": "fi"},
  {"code": "Maat", "codeValue": "320", "text": "Guatemala", "lng": "sv"},
  {"code": "Maat", "codeValue": "324", "text": "Guinea", "lng": "fi"},
  {"code": "Maat", "codeValue": "324", "text": "Guinea", "lng": "sv"},
  {"code": "Maat", "codeValue": "328", "text": "Guyana", "lng": "fi"},
  {"code": "Maat", "codeValue": "328", "text": "Guyana", "lng": "sv"},
  {"code": "Maat", "codeValue": "332", "text": "Haiti", "lng": "fi"},
  {"code": "Maat", "codeValue": "332", "text": "Haiti", "lng": "sv"},
  {"code": "Maat", "codeValue": "334", "text": "Heard ja McDonaldinsaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "334", "text": "Heard och McDonaldsöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "336", "text": "Vatikaanivaltio", "lng": "fi"},
  {"code": "Maat", "codeValue": "336", "text": "Vatikan", "lng": "sv"},
  {"code": "Maat", "codeValue": "340", "text": "Honduras", "lng": "fi"},
  {"code": "Maat", "codeValue": "340", "text": "Honduras", "lng": "sv"},
  {"code": "Maat", "codeValue": "352", "text": "Islanti", "lng": "fi"},
  {"code": "Maat", "codeValue": "352", "text": "Island", "lng": "sv"},
  {"code": "Maat", "codeValue": "356", "text": "Intia", "lng": "fi"},
  {"code": "Maat", "codeValue": "356", "text": "Indien", "lng": "sv"},
  {"code": "Maat", "codeValue": "360", "text": "Indonesia", "lng": "fi"},
  {"code": "Maat", "codeValue": "360", "text": "Indonesien", "lng": "sv"},
  {"code": "Maat", "codeValue": "364", "text": "Iran", "lng": "fi"},
  {"code": "Maat", "codeValue": "364", "text": "Iran", "lng": "sv"},
  {"code": "Maat", "codeValue": "368", "text": "Irak", "lng": "fi"},
  {"code": "Maat", "codeValue": "368", "text": "Irak", "lng": "sv"},
  {"code": "Maat", "codeValue": "372", "text": "Irlanti", "lng": "fi"},
  {"code": "Maat", "codeValue": "372", "text": "Irland", "lng": "sv"},
  {"code": "Maat", "codeValue": "376", "text": "Israel", "lng": "fi"},
  {"code": "Maat", "codeValue": "376", "text": "Israel", "lng": "sv"},
  {"code": "Maat", "codeValue": "380", "text": "Italia", "lng": "fi"},
  {"code": "Maat", "codeValue": "380", "text": "Italien", "lng": "sv"},
  {"code": "Maat", "codeValue": "384", "text": "Norsunluurannikko", "lng": "fi"},
  {"code": "Maat", "codeValue": "384", "text": "Elfenbenskusten", "lng": "sv"},
  {"code": "Maat", "codeValue": "388", "text": "Jamaika", "lng": "fi"},
  {"code": "Maat", "codeValue": "388", "text": "Jamaica", "lng": "sv"},
  {"code": "Maat", "codeValue": "392", "text": "Japani", "lng": "fi"},
  {"code": "Maat", "codeValue": "392", "text": "Japan", "lng": "sv"},
  {"code": "Maat", "codeValue": "398", "text": "Kazakstan", "lng": "fi"},
  {"code": "Maat", "codeValue": "398", "text": "Kazakstan", "lng": "sv"},
  {"code": "Maat", "codeValue": "400", "text": "Jordania", "lng": "fi"},
  {"code": "Maat", "codeValue": "400", "text": "Jordanien", "lng": "sv"},
  {"code": "Maat", "codeValue": "404", "text": "Kenia", "lng": "fi"},
  {"code": "Maat", "codeValue": "404", "text": "Kenya", "lng": "sv"},
  {"code": "Maat", "codeValue": "408", "text": "Korean demokraattinen kansantasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "408", "text": "Folkrepubliken Korea", "lng": "sv"},
  {"code": "Maat", "codeValue": "414", "text": "Kuwait", "lng": "fi"},
  {"code": "Maat", "codeValue": "414", "text": "Kuwait", "lng": "sv"},
  {"code": "Maat", "codeValue": "417", "text": "Kirgisia", "lng": "fi"},
  {"code": "Maat", "codeValue": "417", "text": "Kirgizistan", "lng": "sv"},
  {"code": "Maat", "codeValue": "418", "text": "Laos", "lng": "fi"},
  {"code": "Maat", "codeValue": "418", "text": "Laos", "lng": "sv"},
  {"code": "Maat", "codeValue": "422", "text": "Libanon", "lng": "fi"},
  {"code": "Maat", "codeValue": "422", "text": "Libanon", "lng": "sv"},
  {"code": "Maat", "codeValue": "426", "text": "Lesotho", "lng": "fi"},
  {"code": "Maat", "codeValue": "426", "text": "Lesotho", "lng": "sv"},
  {"code": "Maat", "codeValue": "430", "text": "Liberia", "lng": "fi"},
  {"code": "Maat", "codeValue": "430", "text": "Liberia", "lng": "sv"},
  {"code": "Maat", "codeValue": "434", "text": "Libya", "lng": "fi"},
  {"code": "Maat", "codeValue": "434", "text": "Libyen", "lng": "sv"},
  {"code": "Maat", "codeValue": "442", "text": "Luxemburg", "lng": "fi"},
  {"code": "Maat", "codeValue": "442", "text": "Luxemburg", "lng": "sv"},
  {"code": "Maat", "codeValue": "446", "text": "Macao", "lng": "fi"},
  {"code": "Maat", "codeValue": "446", "text": "Macao", "lng": "sv"},
  {"code": "Maat", "codeValue": "450", "text": "Madagaskar", "lng": "fi"},
  {"code": "Maat", "codeValue": "450", "text": "Madagaskar", "lng": "sv"},
  {"code": "Maat", "codeValue": "454", "text": "Malawi", "lng": "fi"},
  {"code": "Maat", "codeValue": "454", "text": "Malawi", "lng": "sv"},
  {"code": "Maat", "codeValue": "458", "text": "Malesia", "lng": "fi"},
  {"code": "Maat", "codeValue": "458", "text": "Malaysia", "lng": "sv"},
  {"code": "Maat", "codeValue": "462", "text": "Malediivit", "lng": "fi"},
  {"code": "Maat", "codeValue": "462", "text": "Maldiverna", "lng": "sv"},
  {"code": "Maat", "codeValue": "466", "text": "Mali", "lng": "fi"},
  {"code": "Maat", "codeValue": "466", "text": "Mali", "lng": "sv"},
  {"code": "Maat", "codeValue": "474", "text": "Martinique", "lng": "fi"},
  {"code": "Maat", "codeValue": "474", "text": "Martinique", "lng": "sv"},
  {"code": "Maat", "codeValue": "478", "text": "Mauritania", "lng": "fi"},
  {"code": "Maat", "codeValue": "478", "text": "Mauretanien", "lng": "sv"},
  {"code": "Maat", "codeValue": "480", "text": "Mauritius", "lng": "fi"},
  {"code": "Maat", "codeValue": "480", "text": "Mauritius", "lng": "sv"},
  {"code": "Maat", "codeValue": "484", "text": "Mexico", "lng": "sv"},
  {"code": "Maat", "codeValue": "492", "text": "Monaco", "lng": "fi"},
  {"code": "Maat", "codeValue": "492", "text": "Monaco", "lng": "sv"},
  {"code": "Maat", "codeValue": "498", "text": "Moldova", "lng": "fi"},
  {"code": "Maat", "codeValue": "498", "text": "Moldavien", "lng": "sv"},
  {"code": "Maat", "codeValue": "500", "text": "Montserrat", "lng": "fi"},
  {"code": "Maat", "codeValue": "500", "text": "Montserrat", "lng": "sv"},
  {"code": "Maat", "codeValue": "504", "text": "Marokko", "lng": "fi"},
  {"code": "Maat", "codeValue": "504", "text": "Marocko", "lng": "sv"},
  {"code": "Maat", "codeValue": "508", "text": "Mosambik", "lng": "fi"},
  {"code": "Maat", "codeValue": "508", "text": "Mo?ambique", "lng": "sv"},
  {"code": "Maat", "codeValue": "512", "text": "Oman", "lng": "fi"},
  {"code": "Maat", "codeValue": "512", "text": "Oman", "lng": "sv"},
  {"code": "Maat", "codeValue": "516", "text": "Namibia", "lng": "fi"},
  {"code": "Maat", "codeValue": "516", "text": "Namibia", "lng": "sv"},
  {"code": "Maat", "codeValue": "520", "text": "Nauru", "lng": "sv"},
  {"code": "Maat", "codeValue": "524", "text": "Nepal", "lng": "fi"},
  {"code": "Maat", "codeValue": "524", "text": "Nepal", "lng": "sv"},
  {"code": "Maat", "codeValue": "528", "text": "Alankomaat", "lng": "fi"},
  {"code": "Maat", "codeValue": "528", "text": "Nederländerna", "lng": "sv"},
  {"code": "Maat", "codeValue": "530", "text": "Alankomaiden Antillit", "lng": "fi"},
  {"code": "Maat", "codeValue": "530", "text": "Nederländska Antillerna", "lng": "sv"},
  {"code": "Maat", "codeValue": "533", "text": "Aruba", "lng": "fi"},
  {"code": "Maat", "codeValue": "533", "text": "Aruba", "lng": "sv"},
  {"code": "Maat", "codeValue": "540", "text": "Uusi-Kaledonia", "lng": "fi"},
  {"code": "Maat", "codeValue": "540", "text": "Nya Caledonien", "lng": "sv"},
  {"code": "Maat", "codeValue": "548", "text": "Vanuatu", "lng": "fi"},
  {"code": "Maat", "codeValue": "548", "text": "Vanuatu", "lng": "sv"},
  {"code": "Maat", "codeValue": "554", "text": "Uusi-Seelanti", "lng": "fi"},
  {"code": "Maat", "codeValue": "554", "text": "Nya Zeeland", "lng": "sv"},
  {"code": "Maat", "codeValue": "558", "text": "Nicaragua", "lng": "fi"},
  {"code": "Maat", "codeValue": "558", "text": "Nicaragua", "lng": "sv"},
  {"code": "Maat", "codeValue": "562", "text": "Niger", "lng": "fi"},
  {"code": "Maat", "codeValue": "562", "text": "Niger", "lng": "sv"},
  {"code": "Maat", "codeValue": "566", "text": "Nigeria", "lng": "fi"},
  {"code": "Maat", "codeValue": "566", "text": "Nigeria", "lng": "sv"},
  {"code": "Maat", "codeValue": "570", "text": "Niue", "lng": "fi"},
  {"code": "Maat", "codeValue": "570", "text": "Niue", "lng": "sv"},
  {"code": "Maat", "codeValue": "574", "text": "Norfolkinsaari", "lng": "fi"},
  {"code": "Maat", "codeValue": "574", "text": "Norfolkön", "lng": "sv"},
  {"code": "Maat", "codeValue": "578", "text": "Norja", "lng": "fi"},
  {"code": "Maat", "codeValue": "578", "text": "Norge", "lng": "sv"},
  {"code": "Maat", "codeValue": "580", "text": "Pohjois-Mariaanit", "lng": "fi"},
  {"code": "Maat", "codeValue": "580", "text": "Nordmarianerna", "lng": "sv"},
  {"code": "Maat", "codeValue": "581", "text": "Tyynenmeren saaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "581", "text": "Stillahavsöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "583", "text": "Mikronesia", "lng": "fi"},
  {"code": "Maat", "codeValue": "583", "text": "Mikronesien", "lng": "sv"},
  {"code": "Maat", "codeValue": "584", "text": "Marshallinsaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "584", "text": "Marshallöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "585", "text": "Palau", "lng": "fi"},
  {"code": "Maat", "codeValue": "585", "text": "Palau", "lng": "sv"},
  {"code": "Maat", "codeValue": "586", "text": "Pakistan", "lng": "fi"},
  {"code": "Maat", "codeValue": "586", "text": "Pakistan", "lng": "sv"},
  {"code": "Maat", "codeValue": "591", "text": "Panama", "lng": "fi"},
  {"code": "Maat", "codeValue": "591", "text": "Panama", "lng": "sv"},
  {"code": "Maat", "codeValue": "598", "text": "Papua-Uusi-Guinea", "lng": "fi"},
  {"code": "Maat", "codeValue": "598", "text": "Papua Nya Guinea", "lng": "sv"},
  {"code": "Maat", "codeValue": "600", "text": "Paraguay", "lng": "fi"},
  {"code": "Maat", "codeValue": "600", "text": "Paraguay", "lng": "sv"},
  {"code": "Maat", "codeValue": "604", "text": "Peru", "lng": "fi"},
  {"code": "Maat", "codeValue": "604", "text": "Peru", "lng": "sv"},
  {"code": "Maat", "codeValue": "608", "text": "Filippiinit", "lng": "fi"},
  {"code": "Maat", "codeValue": "608", "text": "Filippinerna", "lng": "sv"},
  {"code": "Maat", "codeValue": "612", "text": "Pitcairn", "lng": "fi"},
  {"code": "Maat", "codeValue": "612", "text": "Pitcairn", "lng": "sv"},
  {"code": "Maat", "codeValue": "620", "text": "Portugali", "lng": "fi"},
  {"code": "Maat", "codeValue": "620", "text": "Portugal", "lng": "sv"},
  {"code": "Maat", "codeValue": "624", "text": "Guinea-Bissau", "lng": "fi"},
  {"code": "Maat", "codeValue": "624", "text": "Guinea-Bissau", "lng": "sv"},
  {"code": "Maat", "codeValue": "630", "text": "Puerto Rico", "lng": "fi"},
  {"code": "Maat", "codeValue": "630", "text": "Puerto Rico", "lng": "sv"},
  {"code": "Maat", "codeValue": "634", "text": "Qatar", "lng": "fi"},
  {"code": "Maat", "codeValue": "634", "text": "Qatar", "lng": "sv"},
  {"code": "Maat", "codeValue": "638", "text": "Reunion", "lng": "fi"},
  {"code": "Maat", "codeValue": "638", "text": "Reunion", "lng": "sv"},
  {"code": "Maat", "codeValue": "642", "text": "Romania", "lng": "fi"},
  {"code": "Maat", "codeValue": "642", "text": "Rumänien", "lng": "sv"},
  {"code": "Maat", "codeValue": "643", "text": "Venäjä", "lng": "fi"},
  {"code": "Maat", "codeValue": "643", "text": "Ryssland", "lng": "sv"},
  {"code": "Maat", "codeValue": "646", "text": "Ruanda", "lng": "fi"},
  {"code": "Maat", "codeValue": "646", "text": "Rwanda", "lng": "sv"},
  {"code": "Maat", "codeValue": "654", "text": "Saint Helena, Ascension ja Tristan da Cunha", "lng": "fi"},
  {"code": "Maat", "codeValue": "654", "text": "St Helena", "lng": "sv"},
  {"code": "Maat", "codeValue": "659", "text": "Saint Kitts ja Nevis", "lng": "fi"},
  {"code": "Maat", "codeValue": "659", "text": "St Christop o Nevis", "lng": "sv"},
  {"code": "Maat", "codeValue": "660", "text": "Anguilla", "lng": "fi"},
  {"code": "Maat", "codeValue": "660", "text": "Anguilla", "lng": "sv"},
  {"code": "Maat", "codeValue": "662", "text": "Saint Lucia", "lng": "fi"},
  {"code": "Maat", "codeValue": "662", "text": "Saint Lucia", "lng": "sv"},
  {"code": "Maat", "codeValue": "666", "text": "Saint-Pierre ja Miquelon", "lng": "fi"},
  {"code": "Maat", "codeValue": "666", "text": "S:t Pierre o Miquelon", "lng": "sv"},
  {"code": "Maat", "codeValue": "670", "text": "Saint Vincent ja Grenadiinit", "lng": "fi"},
  {"code": "Maat", "codeValue": "670", "text": "St Vincent", "lng": "sv"},
  {"code": "Maat", "codeValue": "674", "text": "San Marino", "lng": "fi"},
  {"code": "Maat", "codeValue": "674", "text": "San Marino", "lng": "sv"},
  {"code": "Maat", "codeValue": "736", "text": "Sudanin tasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "678", "text": "Sao Tome ja Principe", "lng": "fi"},
  {"code": "Maat", "codeValue": "678", "text": "Sao Tome o Principe", "lng": "sv"},
  {"code": "Maat", "codeValue": "682", "text": "Saudi-Arabia", "lng": "fi"},
  {"code": "Maat", "codeValue": "682", "text": "Saudiarabien", "lng": "sv"},
  {"code": "Maat", "codeValue": "686", "text": "Senegal", "lng": "fi"},
  {"code": "Maat", "codeValue": "686", "text": "Senegal", "lng": "sv"},
  {"code": "Maat", "codeValue": "690", "text": "Seychellit", "lng": "fi"},
  {"code": "Maat", "codeValue": "690", "text": "Seychellerna", "lng": "sv"},
  {"code": "Maat", "codeValue": "694", "text": "Sierra Leone", "lng": "fi"},
  {"code": "Maat", "codeValue": "694", "text": "Sierra Leone", "lng": "sv"},
  {"code": "Maat", "codeValue": "702", "text": "Singapore", "lng": "fi"},
  {"code": "Maat", "codeValue": "702", "text": "Singapore", "lng": "sv"},
  {"code": "Maat", "codeValue": "704", "text": "Vietnam", "lng": "fi"},
  {"code": "Maat", "codeValue": "704", "text": "Vietnam", "lng": "sv"},
  {"code": "Maat", "codeValue": "706", "text": "Somalia", "lng": "fi"},
  {"code": "Maat", "codeValue": "706", "text": "Somalia", "lng": "sv"},
  {"code": "Maat", "codeValue": "710", "text": "Etelä-Afrikka", "lng": "fi"},
  {"code": "Maat", "codeValue": "710", "text": "Sydafrika", "lng": "sv"},
  {"code": "Maat", "codeValue": "716", "text": "Zimbabwe", "lng": "fi"},
  {"code": "Maat", "codeValue": "716", "text": "Zimbabwe", "lng": "sv"},
  {"code": "Maat", "codeValue": "720", "text": "Jemenin kansallisdemokraattinen tasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "720", "text": "Jemens nationella Demokratiska republiken", "lng": "sv"},
  {"code": "Maat", "codeValue": "724", "text": "Espanja", "lng": "fi"},
  {"code": "Maat", "codeValue": "724", "text": "Spanien", "lng": "sv"},
  {"code": "Maat", "codeValue": "732", "text": "Länsi-Sahara", "lng": "fi"},
  {"code": "Maat", "codeValue": "732", "text": "Västsahara", "lng": "sv"},
  {"code": "Maat", "codeValue": "736", "text": "Sudan", "lng": "sv"},
  {"code": "Maat", "codeValue": "740", "text": "Suriname", "lng": "fi"},
  {"code": "Maat", "codeValue": "740", "text": "Surinam", "lng": "sv"},
  {"code": "Maat", "codeValue": "744", "text": "Huippuvuoret", "lng": "fi"},
  {"code": "Maat", "codeValue": "744", "text": "Svalbard o Jan Mayen", "lng": "sv"},
  {"code": "Maat", "codeValue": "748", "text": "Swazimaa", "lng": "fi"},
  {"code": "Maat", "codeValue": "748", "text": "Swaziland", "lng": "sv"},
  {"code": "Maat", "codeValue": "752", "text": "Ruotsi", "lng": "fi"},
  {"code": "Maat", "codeValue": "752", "text": "Sverige", "lng": "sv"},
  {"code": "Maat", "codeValue": "756", "text": "Sveitsi", "lng": "fi"},
  {"code": "Maat", "codeValue": "756", "text": "Schweiz", "lng": "sv"},
  {"code": "Maat", "codeValue": "760", "text": "Syyria", "lng": "fi"},
  {"code": "Maat", "codeValue": "760", "text": "Syrien", "lng": "sv"},
  {"code": "Maat", "codeValue": "762", "text": "Tadzhikistan", "lng": "fi"},
  {"code": "Maat", "codeValue": "762", "text": "Tadzjikistan", "lng": "sv"},
  {"code": "Maat", "codeValue": "764", "text": "Thaimaa", "lng": "fi"},
  {"code": "Maat", "codeValue": "764", "text": "Thailand", "lng": "sv"},
  {"code": "Maat", "codeValue": "768", "text": "Togo", "lng": "fi"},
  {"code": "Maat", "codeValue": "768", "text": "Togo", "lng": "sv"},
  {"code": "Maat", "codeValue": "772", "text": "Tokelau", "lng": "fi"},
  {"code": "Maat", "codeValue": "772", "text": "Tokelau", "lng": "sv"},
  {"code": "Maat", "codeValue": "776", "text": "Tonga", "lng": "fi"},
  {"code": "Maat", "codeValue": "776", "text": "Tonga", "lng": "sv"},
  {"code": "Maat", "codeValue": "780", "text": "Trinidad ja Tobago", "lng": "fi"},
  {"code": "Maat", "codeValue": "780", "text": "Trinidad och Tobago", "lng": "sv"},
  {"code": "Maat", "codeValue": "784", "text": "Arabiemiirikunnat", "lng": "fi"},
  {"code": "Maat", "codeValue": "784", "text": "Förenade Arabemiraten", "lng": "sv"},
  {"code": "Maat", "codeValue": "788", "text": "Tunisia", "lng": "fi"},
  {"code": "Maat", "codeValue": "788", "text": "Tunisien", "lng": "sv"},
  {"code": "Maat", "codeValue": "792", "text": "Turkki", "lng": "fi"},
  {"code": "Maat", "codeValue": "792", "text": "Turkiet", "lng": "sv"},
  {"code": "Maat", "codeValue": "795", "text": "Turkmenistan", "lng": "fi"},
  {"code": "Maat", "codeValue": "795", "text": "Turkmenistan", "lng": "sv"},
  {"code": "Maat", "codeValue": "796", "text": "Turks- ja Caicossaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "796", "text": "Turks- och Caicosöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "798", "text": "Tuvalu", "lng": "fi"},
  {"code": "Maat", "codeValue": "798", "text": "Tuvalu", "lng": "sv"},
  {"code": "Maat", "codeValue": "800", "text": "Uganda", "lng": "fi"},
  {"code": "Maat", "codeValue": "800", "text": "Uganda", "lng": "sv"},
  {"code": "Maat", "codeValue": "804", "text": "Ukraina", "lng": "fi"},
  {"code": "Maat", "codeValue": "804", "text": "Ukraina", "lng": "sv"},
  {"code": "Maat", "codeValue": "807", "text": "Makedonia", "lng": "fi"},
  {"code": "Maat", "codeValue": "807", "text": "Makedonien", "lng": "sv"},
  {"code": "Maat", "codeValue": "810", "text": "Neuvostoliitto", "lng": "fi"},
  {"code": "Maat", "codeValue": "810", "text": "Sovjetunionen", "lng": "sv"},
  {"code": "Maat", "codeValue": "818", "text": "Egypti", "lng": "fi"},
  {"code": "Maat", "codeValue": "818", "text": "Egypten", "lng": "sv"},
  {"code": "Maat", "codeValue": "826", "text": "Iso-Britannia", "lng": "fi"},
  {"code": "Maat", "codeValue": "826", "text": "Storbritannien", "lng": "sv"},
  {"code": "Maat", "codeValue": "834", "text": "Tansania", "lng": "fi"},
  {"code": "Maat", "codeValue": "834", "text": "Tanzania", "lng": "sv"},
  {"code": "Maat", "codeValue": "840", "text": "Yhdysvallat", "lng": "fi"},
  {"code": "Maat", "codeValue": "840", "text": "Förenta Staterna", "lng": "sv"},
  {"code": "Maat", "codeValue": "850", "text": "USA Neitsytsaaret", "lng": "fi"},
  {"code": "Maat", "codeValue": "850", "text": "USA Jungfruöarna", "lng": "sv"},
  {"code": "Maat", "codeValue": "854", "text": "Burkina", "lng": "fi"},
  {"code": "Maat", "codeValue": "854", "text": "Burkina", "lng": "sv"},
  {"code": "Maat", "codeValue": "858", "text": "Uruguay", "lng": "fi"},
  {"code": "Maat", "codeValue": "858", "text": "Uruguay", "lng": "sv"},
  {"code": "Maat", "codeValue": "860", "text": "Uzbekistan", "lng": "fi"},
  {"code": "Maat", "codeValue": "860", "text": "Uzbekistan", "lng": "sv"},
  {"code": "Maat", "codeValue": "862", "text": "Venezuela", "lng": "fi"},
  {"code": "Maat", "codeValue": "862", "text": "Venezuela", "lng": "sv"},
  {"code": "Maat", "codeValue": "876", "text": "Wallis ja Futuna", "lng": "fi"},
  {"code": "Maat", "codeValue": "876", "text": "Wallis och Futuna", "lng": "sv"},
  {"code": "Maat", "codeValue": "882", "text": "Samoa", "lng": "fi"},
  {"code": "Maat", "codeValue": "882", "text": "Samoa", "lng": "sv"},
  {"code": "Maat", "codeValue": "886", "text": "Jemen Arabit", "lng": "fi"},
  {"code": "Maat", "codeValue": "886", "text": "Yemen Arabr", "lng": "sv"},
  {"code": "Maat", "codeValue": "887", "text": "Jemen", "lng": "fi"},
  {"code": "Maat", "codeValue": "887", "text": "Yemen", "lng": "sv"},
  {"code": "Maat", "codeValue": "905", "text": "Kosovo", "lng": "fi"},
  {"code": "Maat", "codeValue": "905", "text": "Kosovo", "lng": "sv"},
  {"code": "Maat", "codeValue": "652", "text": "SAINT BARTHÉLEMY", "lng": "fi"},
  {"code": "Maat", "codeValue": "652", "text": "SAINT BARTHÉLEMY", "lng": "sv"},
]

// 7124 code matches found

export default TCODES
